import { PipeTransform, Pipe } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { BusinessEntityType } from 'src/app/server/enums/business-entity-type.enum';

@Pipe({
	name: 'businessEntityTypeLabel'
})
export class BusinessEntityTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: BusinessEntityType) {
		const values = new Map<BusinessEntityType, string>();
		values.set(BusinessEntityType.Division, 'global_division_label');
		values.set(BusinessEntityType.Geography, 'global_geographic_label');
		values.set(BusinessEntityType.LineOfBusiness, 'global_lineOfBusiness_label');
		values.set(BusinessEntityType.BusinessProcess, 'global_businessProcess_label');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${BusinessEntityType[value]} does not exist`);
		}

		return this.translationService.get(type);
	}
}

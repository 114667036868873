import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
	selector: '[rqPopoverContent]'
})
export class PopoverContentDirective implements OnInit {
	@Input()
	public width = 100;

	@Input()
	public targetContent!: Element;

	@Input()
	public config!: { bottomOffset: number };

	constructor(public el: ElementRef) {
		el.nativeElement.classList.add('popover-content');
	}

	private get documentElement(): HTMLElement {
		return document.documentElement;
	}

	public hide() {
		Object.assign(this.el.nativeElement.style, { left: '0', top: '0' });
		this.el.nativeElement.classList.remove('show');
	}

	public ngOnInit() {
		Object.assign(this.el.nativeElement.style, {
			width: `${this.width}px`,
			left: '0'
		});
	}

	public show(ref: HTMLElement) {
		const nativeElement = this.el.nativeElement as HTMLElement;
		nativeElement.classList.add('show');

		window.requestAnimationFrame(() => {
			const popoverDomRect = nativeElement.getBoundingClientRect();
			const hoveredDomRect = ref.getBoundingClientRect();
			const offSett = 5;

			let contentWidth = this.documentElement.clientWidth;
			let contentHeight = this.documentElement.clientHeight;

			if (this.targetContent) {
				const { right, bottom } = this.targetContent.getBoundingClientRect();
				contentWidth = right;
				contentHeight = bottom;
			}

			const endX: number = popoverDomRect.left + popoverDomRect.width;
			const endY: number = this.getPopoverMaxEndY(popoverDomRect);

			if (endX > contentWidth) {
				nativeElement.style.left = `-${popoverDomRect.width - hoveredDomRect.width}px`;
			}

			if (endY > contentHeight) {
				nativeElement.style.top = `-${popoverDomRect.height + offSett}px`;
			} else {
				nativeElement.style.top = `${hoveredDomRect.height + offSett}px`;
			}
		});
	}

	private getPopoverMaxEndY(popoverDomRect: DOMRect) {
		let endY: number = popoverDomRect.top + popoverDomRect.height;

		if (this.config?.bottomOffset) {
			endY += this.config.bottomOffset;
		}

		return endY;
	}
}

import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { ThemeColorsType } from '../../types/theme-colors.type';

const btnPrimary = 'btn-primary';

@Component({
	selector: 'rq-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
	@ViewChild('rqModal', { static: true })
	public modal!: ModalComponent;

	@Input()
	public title = '';

	@Input()
	public theme!: ThemeColorsType;

	public buttonClass = btnPrimary;

	public resolve!: () => void;

	public ngOnChanges(changes: SimpleChanges) {
		const theme = changes.theme?.currentValue as ThemeColorsType;
		if (theme) {
			this.setThemeColors(theme);
		}
	}

	public show(): Promise<void> {
		const promise = new Promise<void>(resolve => {
			this.resolve = resolve;
		});
		this.modal.show();
		return promise;
	}

	public onOk(): void {
		this.modal.hide();
		this.resolve();
	}

	private setThemeColors(theme: ThemeColorsType) {
		switch (theme) {
			case 'success':
				this.buttonClass = 'btn-secondary';
				break;
			case 'danger':
				this.buttonClass = 'btn-danger';
				break;
			default:
				this.buttonClass = btnPrimary;
		}
	}
}

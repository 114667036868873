import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
	selector: 'rq-input-spinner',
	templateUrl: './input-spinner.component.html',
	styleUrls: ['./input-spinner.component.scss']
})
export class InputSpinnerComponent implements OnChanges {
	@Input()
	public isVisible = false;

	@HostBinding('class.spinner-visible')
	public isShowing = false;

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.isVisible.currentValue) {
			this.isShowing = true;
		} else {
			setTimeout(() => (this.isShowing = false), 1000);
		}
	}
}

import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
	selector: '[rqListHeader]'
})
export class ListHeaderDirective {
	@Input() public isSmallHeader = false;

	constructor(public template: TemplateRef<ListHeaderDirective>) {}
}

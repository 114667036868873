import { Component, AfterContentInit, SimpleChanges, OnChanges, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { TableDataModel } from './models/table-data.model';
import { TableService } from './_common/table.service';
import { TableColumnComponent } from './table-column/table-column.component';
import { PaginationState } from '../pager/pager.ui-model';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { FilterOutput } from '../form-elements/filter/filter.model';
import { TableSelectType } from './models/table-select.enum';
import { TableSelectOutput } from './models/table-select-output.model';
import { TableOutput } from './models/table-output.model';
import { TableAbstractComponent } from './table-abstract.component';

@Component({
	selector: 'rq-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TableService, TranslatePipe]
})
export class TableComponent extends TableAbstractComponent implements OnChanges, AfterContentInit {
	constructor(private tableService: TableService, cd: ChangeDetectorRef, translatePipe: TranslatePipe) {
		super(translatePipe, cd);
	}

	public ngOnChanges(changes: SimpleChanges) {
		super.ngOnChanges(changes);

		// eslint-disable-next-line  @typescript-eslint/no-explicit-any
		const dataSource = changes.dataSource?.currentValue as Array<TableDataModel & Record<any, any>>;

		if (dataSource) {
			const list = changes.dataSource.currentValue as Array<TableDataModel>;
			this.tableData = list;
		}
	}

	public triggerSort(column: TableColumnComponent) {
		//Sorting nested objects doesn't work
		this.selectedRowIndex = undefined;
		if (column.isSortable && this.isSortable && !this.isLoading && !this.isFirstLoadingVisible) {
			this.tableService.setSortingState(column.field, this.filterOutput);
			this.pager.reset();
			const tableOutput = new TableOutput<unknown>(this.filterOutput, this.pagerState);
			this.sortChange.emit(tableOutput);
		}
	}

	public triggerFilterChange(output: FilterOutput) {
		this.selectedRowIndex = undefined;
		this.filterOutput = output;
		this.pager.reset();
		const tableOutput = new TableOutput<unknown>(this.filterOutput, this.pagerState);
		this.filterChange.emit(tableOutput);
	}

	public triggerPageChange(state: PaginationState) {
		this.selectedRowIndex = undefined;
		this.pagerState = state;
		const tableOutput = new TableOutput<unknown>(this.filterOutput, this.pagerState);
		this.pageChange.emit(tableOutput);
	}

	public triggerSelectAllChange(target: EventTarget | null) {
		if (target) {
			const input = target as HTMLInputElement;
			this.isAllSelected = input.checked;
			this.dataSource.forEach(data => (data.isSelected = this.isAllSelected));
			const select = new TableSelectOutput(TableSelectType.All, this.isAllSelected);
			this.selectChange.emit(select);
		}
	}
}

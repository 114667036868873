<rq-modal (modalClosed)="decline()"
          class="modal-sm"
          [theme]="theme"
          #rqModal>
  <rq-modal-header>
    <ng-content select="rq-dialog-title"></ng-content>
  </rq-modal-header>
  <rq-modal-body>
    <ng-content select="rq-dialog-body"></ng-content>
  </rq-modal-body>
  <rq-modal-footer>
    <div class="dialog-buttons">
      <button (click)="decline()"
              class="modal-button btn-secondary">
        {{ options.declineLabel | translate }}
      </button>
      <button (click)="accept()"
              class="modal-button {{buttonClass}}">
        {{ options.acceptLabel | translate }}
      </button>
    </div>
  </rq-modal-footer>
</rq-modal>

<rq-table e2e-test="rq-app-risk-details-listing"
          class="m-b-md m-t-md"
          *ngIf="data"
          [dataSource]="data"
          [pagerState]="{ skip: 0, take: 5 }"
          [hasFilters]="hasFilters"
          [count]="count"
          (filterChange)="load($event)"
          (sortChange)="load($event)"
          (pageChange)="load($event)">
  <rq-table-column *ngIf="isLegalEntityVisible"
                   field="legalEntityName"
                   [isSortable]="isSortable"
                   filter="string"
                   title="global_legalEntityName_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{ dataItem.legalEntityName }}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="applicationName"
                   [isSortable]="isSortable"
                   title="businessApplications_applicationName_label"
                   filter="string">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{ dataItem.displayName }}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="controlFrameworkId"
                   [isSortable]="isSortable"
                   title="global_controlFramework_label"
                   filter="select"
                   [filterSetup]="controlFrameworkFilterSetup">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{ dataItem.controlFramework.displayName | translate}}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="averageControlRating"
                   [isSortable]="isSortable"
                   title="global_averageControlRating_label"
                   filter="range"
                   [filterSetup]="caverageControlRatingFilterSetup">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{ getLevel(dataItem.controlFramework.averageRating) }}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="inherentRisk"
                   [isSortable]="isSortable"
                   title="global_inherentRisk_label"
                   filter="range"
                   [filterSetup]="inherentRiskFilterSetup">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{ dataItem.inherentRisk | rqCurrency}}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="residualRisk"
                   [isSortable]="isSortable"
                   title="global_residualRisk_label"
                   filter="range"
                   [filterSetup]="residualRiskFilterSetup">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{ dataItem.residualRisk | rqCurrency}}</span>
    </ng-template>
  </rq-table-column>
</rq-table>

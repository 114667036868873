import { trigger, state, style, transition, animate } from '@angular/animations';

export const popupAnimation = trigger('popupAnimation', [
	state(
		'void',
		style({
			transform: 'translateY(-8px)',
			opacity: 0
		})
	),
	state(
		'*',
		style({
			transform: 'translateY(0)',
			opacity: 1
		})
	),
	transition(':enter', [animate('200ms ease-out')]),
	transition(':leave', [animate('200ms ease-in')])
]);

<header class="m-b-lg d-flex flex-column flex-grow-1">
  <h1 class="m-b-none"
      [attr.hidden]="isTitleVisible ?   null : 'hidden'">
    <ng-content select="rq-heading-title"></ng-content>
  </h1>
  <h2 class="m-b-none m-t-xs primary-l-10-color"
      [attr.hidden]="isSubTitleVisible ?  null : 'hidden'">
    <ng-content select="rq-heading-subtitle"></ng-content>
  </h2>
</header>

<section class="step-what-container p-l-sm p-r-sm p-t-md p-b-md">
  <div class="control-reduction-list"
       *ngFor="let item of items; let i = index">
    <div class="control-item"
         [class.selected]="item.isSelected"
         (click)="select(item)">
      <div class="item-label">
        <p *ngIf="item.legalEntityName"
           [rqEllipsis]="item.legalEntityName">
        </p>
        <p><strong>{{item.controlGroupName | translate}}</strong></p>
        <p [ngClass]="item.controlOptionName.length < 100 ? 'text-left' : 'text-justify'">{{item.controlOptionName |
          translate}}</p>
      </div>
    </div>
  </div>
</section>

import { BaseComponent } from '../../components/base.component';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UserPreferencesViewModel } from '../../../server/models/user-preferences-view-model.model';
import { UserPreferencesDataService } from '../../../server/services/identity/user-preferences-data.service';

@Component({
	selector: 'rq-starting-page-settings',
	templateUrl: './starting-page-settings.component.html',
	styleUrls: ['./starting-page-settings.component.scss']
})
export class StartingPageSettingsComponent extends BaseComponent implements OnInit {
	@Input()
	public isEnterprise!: boolean;

	public formGroup!: UntypedFormGroup;

	private userPreferences!: UserPreferencesViewModel;

	constructor(private fb: UntypedFormBuilder, private userPreferencesDataService: UserPreferencesDataService) {
		super();
	}

	public async ngOnInit() {
		this.setupForm();
		await this.loadData();
	}

	private async loadData() {
		this.userPreferences = await this.userPreferencesDataService.get();

		this.formGroup.patchValue(
			{
				isEnabledStartupPage: this.isEnterprise
					? this.userPreferences.isEnterpriseStartUpPageEnabled
					: this.userPreferences.isProStartUpPageEnabled,
				confidenceId: ''
			},
			{ emitEvent: false }
		);
	}

	private setupForm() {
		this.formGroup = this.fb.group({
			isEnabledStartupPage: ['']
		});

		this.subscriptions.push(
			this.formGroup.controls.isEnabledStartupPage.valueChanges.subscribe((value: boolean) => {
				this.updateUserPreferences(value);
			})
		);
	}

	private async updateUserPreferences(isEnabledStartupPage: boolean) {
		const model = { ...this.userPreferences };

		if (this.isEnterprise) {
			model.isEnterpriseStartUpPageEnabled = isEnabledStartupPage;
		} else {
			model.isProStartUpPageEnabled = isEnabledStartupPage;
		}

		await this.userPreferencesDataService.update(model);
	}
}

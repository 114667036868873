import { TranslationService } from 'src/app/shared/services/translation.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NotificationType } from '../../../server/enums/notification-type.enum';
import { NotificationsViewModel } from '../../../routes/control-analysis/_common/models/notifications.viewmodel';
import { NotificationFilterModel } from '../../../server/models/notification-filter-model.model';
import { TableBase2Component } from '../../../standalone/components/table/table-base2.component';
import { FilterSelectOption, SearchFilter, SelectFilter } from '../../../standalone/components/form-elements/filter/filter.model';
import { TableOutput } from '../../../standalone/components/table/models/table-output.model';
import { TableValue } from '../../../standalone/components/table/models/table-value.model';
import { Customizer } from '../../services/customizer';

@Component({
	selector: 'rq-notification-table',
	templateUrl: './notification-table.component.html',
	styleUrls: ['./notification-table.component.scss']
})
export class NotificationTableComponent
	extends TableBase2Component<NotificationsViewModel, NotificationFilterModel>
	implements OnChanges, OnInit
{
	@Input()
	public isRowSelectable!: boolean;

	@Input()
	public isMarkAllVisible = true;

	@Input()
	public filter?: NotificationFilterModel;

	@Output()
	public readonly rowClicked = new EventEmitter<NotificationsViewModel>();

	@Output()
	public readonly triggerMarkAllAsSeen = new EventEmitter();

	public notificationTypeFilter!: SelectFilter;

	public valueFilter!: SearchFilter;

	public selectedRowIndex?: number;

	constructor(public customizer: Customizer, private translationService: TranslationService) {
		super();
	}

	public async ngOnChanges(changes: SimpleChanges) {
		await super.ngOnChanges(changes);
		const data = changes.data?.currentValue as NotificationsViewModel;
		if (data && this.count !== null && this.count !== undefined) {
			this.setSelectedRow(this.data, this.count);
		}
	}

	public ngOnInit() {
		this.setupFilters();
	}

	public async load(output: TableOutput<NotificationFilterModel>) {
		this.filter = output.filter;
		await this.table?.toggleLoading(true);

		this.loadData.emit(output);
	}

	public triggerRowClicked(tableValue: TableValue<NotificationsViewModel>) {
		this.rowClicked.emit(tableValue.data);
	}

	public newTableTriggerRowClicked(tableItem: NotificationsViewModel) {
		this.rowClicked.emit(tableItem);
	}

	public getBadgeType(value: NotificationType) {
		const values = new Map<NotificationType, string>();
		values.set(NotificationType.Info, 'badge-info');
		values.set(NotificationType.Error, 'badge-danger');
		values.set(NotificationType.Warning, 'badge-warning');
		values.set(NotificationType.Success, 'badge-success');

		const theme = values.get(value);
		if (!theme) {
			throw new Error(`Notification Type ${value} is not handled`);
		}

		return theme;
	}

	public markAllAsSeen() {
		this.triggerMarkAllAsSeen.emit();
		this.triggerPageReset();
	}

	private setupFilters() {
		this.notificationTypeFilter = this.createSelectFilter('global_status_label', this.createStatusFilterGroupOptions());
	}

	private setSelectedRow(values: Array<NotificationsViewModel>, count: number) {
		if (count === 1 && values[0]?.subNotificationsCount > 0) {
			this.selectedRowIndex = 0;
			this.rowClicked.emit(values[0]);
		} else {
			this.selectedRowIndex = undefined;
		}
	}

	private createStatusFilterGroupOptions() {
		const groups = [
			{
				options: [
					new FilterSelectOption(this.translationService.get('global_info_label'), NotificationType.Info),
					new FilterSelectOption(this.translationService.get('global_error_label'), NotificationType.Error),
					new FilterSelectOption(this.translationService.get('global_warning_label'), NotificationType.Warning),
					new FilterSelectOption(this.translationService.get('global_success_label'), NotificationType.Success)
				]
			}
		];

		groups[0].options.sort((a: FilterSelectOption<unknown>, b: FilterSelectOption<unknown>) => a.displayName.localeCompare(b.displayName));
		return groups;
	}
}

import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { LTRecommendationAnalysisViewModel } from '../../../../routes/control-analysis/_common/models/recommendations-analysis-view.viewmodel';
import { EvidencesDetailsModalComponent } from '../../evidences-details-modal/evidences-details-modal.component';
import { ControlFrameworkDescriptionModel } from '../../../../server/models/control-framework-description-model.model';
import { DefensebilityDeltaViewModel } from '../../../../server/models/defensebility-delta-view-model.model';
import { DefensebilityViewModel } from '../../../../server/models/defensebility-view-model.model';

@Component({
	selector: 'rq-step-how',
	templateUrl: './step-how.component.html',
	styleUrls: ['./step-how.component.scss']
})
export class StepHowComponent implements OnChanges {
	@ViewChild(EvidencesDetailsModalComponent, { static: true })
	public currentEvidencesModal!: EvidencesDetailsModalComponent;

	@Input()
	public selectedItem!: LTRecommendationAnalysisViewModel;

	@Input()
	public controlLevelDescriptions!: Array<ControlFrameworkDescriptionModel>;

	@Input()
	public defensebilityDelta!: DefensebilityDeltaViewModel;

	public currentEvidencesData!: DefensebilityViewModel;

	public expectedLossReduction?: number;

	public expectedSLE?: number;

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.selectedItem?.currentValue) {
			const item = changes.selectedItem.currentValue as LTRecommendationAnalysisViewModel;

			this.expectedLossReduction = item.controlImprovements.expectedLossProbabilityReduction;
			this.expectedSLE = item.controlImprovements.averageSLEPercentageDecrease;
		}
	}

	public showEvidences(isFrom: boolean) {
		this.currentEvidencesData = isFrom ? { ...this.defensebilityDelta.from } : { ...this.defensebilityDelta.to };
		this.currentEvidencesModal.show();
	}
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, SimpleChanges } from '@angular/core';
import { TableAbstractComponent } from './table-abstract.component';
import { TableService } from './_common/table.service';
import { TableUiSortFilterService } from './_common/table-ui-sort-filter.service';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { TableDataModel } from './models/table-data.model';
import { TableColumnComponent } from './table-column/table-column.component';
import { FilterOutput } from '../form-elements/filter/filter.model';
import { PaginationState } from '../pager/pager.ui-model';
import { TableSelectOutput } from './models/table-select-output.model';
import { TableSelectType } from './models/table-select.enum';
import { clone } from '../../utils/helpers.utils';

@Component({
	selector: 'rq-table-ui',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TableService, TableUiSortFilterService, TranslatePipe]
})
export class TableUiComponent extends TableAbstractComponent {
	constructor(
		private tableService: TableService,
		private tableUiSortFilterService: TableUiSortFilterService,
		cd: ChangeDetectorRef,
		translatePipe: TranslatePipe
	) {
		super(translatePipe, cd);
	}

	public ngOnChanges(changes: SimpleChanges) {
		super.ngOnChanges(changes);

		// eslint-disable-next-line  @typescript-eslint/no-explicit-any
		const dataSource = changes.dataSource?.currentValue as Array<TableDataModel & Record<any, any>>;

		if (dataSource) {
			this.count = dataSource.length;
			this.tableData = this.getPaginatedValues(dataSource, this.pagerState.skip, this.pagerState.take);
		}
	}

	public triggerSort(column: TableColumnComponent) {
		//Sorting nested objects doesn't work
		this.selectedRowIndex = undefined;
		if (column.isSortable && this.isSortable && !this.isLoading && !this.isFirstLoadingVisible) {
			this.tableService.setSortingState(column.field, this.filterOutput);
			this.tableData = this.tableUiSortFilterService.filterData(this.tableColumns, this.filterOutput, this.dataSource);
			this.tableData = this.tableUiSortFilterService.sortData(this.filterOutput, this.tableData);
			this.tableData = this.getPaginatedValues(this.tableData, this.pagerState.skip, this.pagerState.take);
		}
	}

	public triggerFilterChange(output: FilterOutput) {
		this.selectedRowIndex = undefined;
		this.filterOutput = output;
		if (!this.hasInfiniteScroll) {
			this.pager.reset();
		}

		this.tableData = this.tableUiSortFilterService.filterData(this.tableColumns, this.filterOutput, this.dataSource);
		this.count = this.tableData.length;
		this.tableData = this.getPaginatedValues(this.tableData, this.pagerState.skip, this.pagerState.take);
	}

	public triggerPageChange(state: PaginationState) {
		this.selectedRowIndex = undefined;

		if (!this.hasInfiniteScroll) {
			this.pagerState = state;
		}

		this.tableData = this.tableUiSortFilterService.filterData(this.tableColumns, this.filterOutput, this.dataSource);
		this.tableData = this.tableUiSortFilterService.sortData(this.filterOutput, this.tableData);
		this.tableData = this.getPaginatedValues(this.tableData, this.pagerState.skip, this.pagerState.take);
	}

	public triggerSelectAllChange(target: EventTarget | null) {
		if (target) {
			const input = target as HTMLInputElement;

			this.isAllSelected = input.checked;

			this.dataSource.forEach(data => (data.isSelected = this.isAllSelected));

			this.tableData.forEach(x => (x.isSelected = this.isAllSelected));

			const select = new TableSelectOutput(TableSelectType.All, this.isAllSelected);
			this.selectChange.emit(select);
		}
	}

	public getPaginatedValues(values: Array<TableDataModel>, skip: number, take: number) {
		const clonedValues = clone(values);
		return clonedValues.slice(skip, skip + take);
	}
}

<rq-dialog #deleteDialog
           e2e-test='delete-dialog'
           [theme]="'danger'"
           [options]='dialogOptions'>
  <rq-dialog-title class="dialog-header">
    <span>{{'global_delete_label' | translate}}</span>
  </rq-dialog-title>
  <rq-dialog-body>
    <p class='text-center'
       style="word-break: break-all"
       [innerHTML]="'businessApplication_deleteModal_descriptionName' | translate : {deletedBusinessApplication: '<strong>' +
   name + '</strong>'}">
    </p>
    <p class='text-center'
       *ngIf="customMessage">
      {{ customMessage }}
    </p>
    <p class='text-center'>
      <strong>
        {{ 'global_deleteWarning_label' | translate }}
      </strong>
    </p>
  </rq-dialog-body>
</rq-dialog>

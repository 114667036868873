import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DEFAULT_CURRENCY_CONFIG } from 'src/app/shared/configs/number-formatter.config';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { LossSubTypeTranslate } from 'src/app/shared/pipes/translate-pipes/loss-subtype-label.pipe';
import { StandardBase } from 'src/app/routes/control-analysis/_common/standard.base';
import { AnalysisInfoCardValueModel } from 'src/app/routes/control-analysis/_common/models/analysis-info-card.model';
import { DashboardRisksModel, LossSubTypeCustomViewModel } from 'src/app/routes/control-analysis/_common/models/dashboard-risks.model';
import { ImpactVectorRiskModel } from '../../../server/models/impact-vector-risk-model.model';
import { LossSubTypeModel } from '../../../server/models/loss-sub-type-model.model';
import { AttackViewModel } from '../../../server/models/attack-view-model.model';
import { NumberFormatterService } from '../../services/number-formatter.service';
import { NumberConfig, PercentConfig } from '../../models/number.config';
import { BarChartConfig, BarChartLabel, BarChartSeries } from '../../../standalone/components/charts/_common/models/bar-chart.model';
import { DonutChart } from '../../../standalone/components/charts/donut-chart/donut-chart.model';

@Component({
	selector: 'rq-applications-risks-view',
	templateUrl: './applications-risks-view.component.html',
	styleUrls: ['./applications-risks-view.component.scss']
})
export class ApplicationsRisksViewComponent extends StandardBase implements OnChanges {
	@Input()
	public dataModel!: ImpactVectorRiskModel;

	@Input()
	public attacks!: Array<AttackViewModel>;

	@Input()
	public isForScenario = false;

	public lossSubTypes!: LossSubTypeModel[];

	public data!: DashboardRisksModel;

	private percentFormatConfig = new PercentConfig();

	constructor(
		private numberService: NumberFormatterService,
		private translateService: TranslationService,
		private lossSubTypeTranslate: LossSubTypeTranslate
	) {
		super();
	}

	public getClass(includeInfoClass: boolean, classValue: string, hasPaddingRight = false, isBold = false) {
		let value = includeInfoClass ? classValue : '';

		if (isBold) {
			value = `${value} ${ClassConfig.text.style.bold}`;
		}

		if (hasPaddingRight) {
			value = `${value} ${ClassConfig.text.padding.xs.right}`;
		}

		return value;
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.dataModel.currentValue) {
			this.setupContentData(changes.dataModel.currentValue as ImpactVectorRiskModel);
			this.setupFormatterConfig();
		}
	}

	public getAttackLabel(attackId: string) {
		return this.attacks.find(attack => attack.id === attackId)?.name;
	}

	private setupFormatterConfig() {
		this.percentFormatConfig.decimals = 2;
	}

	private getMostProbableValues(value: string, includeInfoClass = true): AnalysisInfoCardValueModel[] {
		const inputValues = value.split(' ');
		let values: AnalysisInfoCardValueModel[] = [];

		if (inputValues.length === 4) {
			values = values.concat([
				{ value: inputValues[0], classes: this.getClass(includeInfoClass, ClassConfig.text.size.xl, true, true) },
				{ value: inputValues[1], classes: this.getClass(includeInfoClass, ClassConfig.text.size.lg, true) },
				{ value: inputValues[2], classes: this.getClass(includeInfoClass, ClassConfig.text.size.xl, true, true) },
				{ value: inputValues[3], classes: this.getClass(includeInfoClass, ClassConfig.text.size.lg) }
			]);
		} else if (inputValues.length === 3) {
			values = values.concat([
				{ value: inputValues[0], classes: this.getClass(includeInfoClass, ClassConfig.text.size.xl, true, true) },
				{ value: inputValues[1], classes: this.getClass(includeInfoClass, ClassConfig.text.size.lg, true) },
				{ value: inputValues[2], classes: this.getClass(includeInfoClass, ClassConfig.text.size.lg) }
			]);
		} else if (inputValues.length > 4) {
			values = values.concat([{ value, classes: this.getClass(includeInfoClass, ClassConfig.text.size.lg) }]);
		}

		return values;
	}

	private setupContentData(model: ImpactVectorRiskModel) {
		const numFormatterConfig = new NumberConfig();
		numFormatterConfig.isAbbr = true;

		model.lossSubTypes.forEach(lossSubType => {
			let value: string;
			if (lossSubType.value >= 0 && lossSubType.value <= 1) {
				value = lossSubType.value.toString();
			} else {
				value = this.numberService.format(lossSubType.value, DEFAULT_CURRENCY_CONFIG);
			}
			lossSubType.value = this.numberService.parse(value) ?? 0;
		});

		model.lossSubTypes = model.lossSubTypes.filter(l => l.value > 0);

		this.lossSubTypes = model.lossSubTypes.length > 4 ? this.setupLossSubTypes(model.lossSubTypes) : model.lossSubTypes;

		this.data = {
			applicationName: model.businessApplicationName,
			attackId: model.attackId,
			costOfAttack: this.numberService.currency(model.singleLossAmount),
			pos: this.numberService.percent(model.attackSuccessProbability),
			totalRecords: this.numberService.number(model.dataRecords, numFormatterConfig),
			rateOfIncidenceValues: this.getMostProbableValues(model.rateOfIncidence, false),
			donutChartSeries: this.getDonutChartSeries(this.lossSubTypes),
			barChartSeries: this.getBarChartSeries(this.lossSubTypes),
			barChartConfig: <BarChartConfig>{
				isGridVisible: true,
				isVerticalCentered: true,
				barHeight: 7,
				isBarBackgroundVisible: true
			}
		};
	}

	private getBarChartSeries(lossSubTypes: LossSubTypeModel[]): BarChartSeries[] {
		return lossSubTypes.map(x => {
			const model = <LossSubTypeCustomViewModel>x;

			return {
				value: x.value,
				labels: [
					<BarChartLabel>{
						value: this.getLossTypeLabel(model),
						position: 'top',
						align: 'left'
					},
					<BarChartLabel>{
						value: this.numberService.currency(model.value),
						position: 'top',
						align: 'right'
					}
				]
			};
		});
	}

	private getDonutChartSeries(series: LossSubTypeModel[]): DonutChart[] {
		return series.map(item => {
			const model = <LossSubTypeCustomViewModel>item;

			return {
				value: item.value,
				label: this.getLossTypeLabel(model)
			} as DonutChart;
		});
	}

	private getLossTypeLabel(model: LossSubTypeCustomViewModel): string | undefined {
		return model.customType
			? this.translateService.get(model.customType)
			: this.translateService.get(this.lossSubTypeTranslate.transform(model.type));
	}

	private setupLossSubTypes(lossSubTypes: Array<LossSubTypeModel>) {
		const list = lossSubTypes.splice(0, 4);

		const othersLossSubType = new LossSubTypeCustomViewModel();
		othersLossSubType.customType = 'global_other_label';
		othersLossSubType.value = 0;
		lossSubTypes.forEach(lst => (othersLossSubType.value += lst.value));
		list.push(othersLossSubType);

		return list;
	}
}

class ClassConfig {
	public static text = {
		size: {
			xl: 'text-xl',
			lg: 'text-lg'
		},
		style: {
			bold: 'text-bold'
		},
		padding: {
			xs: {
				right: 'p-r-xs'
			}
		}
	};
}

export enum AuditBehaviorType {
	'Archived' = 1,
	'Assign' = 2,
	'ChangePassword' = 3,
	'ChangePasswordFail' = 4,
	'Create' = 5,
	'DeactivateAccount' = 6,
	'Delete' = 7,
	'InitialData' = 8,
	'Login' = 9,
	'LoginFail' = 10,
	'Logout' = 11,
	'ReactivateAccount' = 12,
	'RunRiskAnalysis' = 13,
	'Unarchived' = 14,
	'Unassign' = 15,
	'Update' = 16,
	'ThirdPartyRunAnalysis' = 17
}

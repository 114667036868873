export enum ScenarioType {
	'EnterpriseControl' = 0,
	'RateOfIncidence' = 1,
	'Fair' = 2,
	'SemiFair' = 3,
	'AggregatedFair' = 4,
	'MachineLearning' = 5,
	'MultipleControlProfiles' = 6,
	'MitreCustomAttack' = 7,
	'MitreHistoricalAttack' = 8
}

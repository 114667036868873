<rq-table class="m-b-md m-t-md"
          *ngIf="data"
          [dataSource]="data"
          [pagerState]="{ skip: 0, take: 10 }"
          [hasFilters]="true"
          [count]="count"
          (filterChange)="load($event)"
          (sortChange)="load($event)"
          (pageChange)="load($event)">
  <rq-table-column field="name"
                   filter="string"
                   title="global_fullName_Label"> </rq-table-column>

  <rq-table-column field="email"
                   filter="string"
                   title="settings_user_management_table_email_label"> </rq-table-column>

  <rq-table-column field="role"
                   title="global_role_label"
                   filter="select"
                   [filterSetup]="roleFilterSetup">
    <ng-template tableData
                 let-dataItem="dataItem">
      <rq-expandable-text [wordList]="getRoles(dataItem)"> </rq-expandable-text>
    </ng-template>
  </rq-table-column>

  <rq-table-column *ngIf="isLegalEntityColumnVisible"
                   field="legalEntities"
                   title="global_legalEntity_label"
                   filter="select"
                   [filterSetup]="legalEntityFilterSetup">
    <ng-template tableData
                 let-dataItem="dataItem">
      <ng-container *ngIf="dataItem.isRqEnterpriseAdministrator || dataItem.isRqInstanceManager || dataItem.isRqEnterpriseReadOnly">
        {{ 'global_notApplicable_label' | translate }}
      </ng-container>
      <ng-container *ngIf="!dataItem.isRqEnterpriseAdministrator && !dataItem.isRqInstanceManager && !dataItem.isRqEnterpriseReadOnly">
        <p *ngIf="!getLegalEntities(dataItem).length"
           class="primary-l-20-color">
          {{ 'global_notProvided_label' | translate }}
        </p>
        <ng-container *ngIf="getLegalEntities(dataItem).length">
          <rq-expandable-text [wordList]="getLegalEntities(dataItem)"> </rq-expandable-text>
        </ng-container>
      </ng-container>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="accountStatus"
                   title="settings_user_management_table_user_account_status_label"
                   filter="dropdown"
                   [filterSetup]="statusFilterSetup">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span *ngIf="dataItem.locked">{{ 'global_locked_label' | translate }}</span>
      <ng-container *ngIf="!dataItem.locked">
        <span *ngIf="dataItem.isActive">{{ 'settings_user_management_table_active_label' | translate }}</span>
        <span *ngIf="!dataItem.isActive">{{ 'settings_user_management_table_deactivated_label' | translate }}</span>
      </ng-container>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="actions"
                   [isSortable]="false"
                   [isFilterable]="false">
    <ng-template tableData
                 let-dataItem="dataItem">
      <rq-table-actions [numberOfVisibleActions]="0">
        <ng-template tableAction>
          <rq-table-action-item e2e-test="table-edit-action"
                                rqAuthorize
                                [state]="authorizeState"
                                [class.disabled]="!dataItem.isActive || dataItem.isRqInstanceManager || isLegalEntityArchived"
                                (click)="edit(dataItem)">
            {{'global_edit_label' | translate}}
          </rq-table-action-item>
        </ng-template>

        <ng-template tableAction
                     *ngIf="!dataItem.locked">
          <rq-table-action-item e2e-test="table-edit-action"
                                rqAuthorize
                                [state]="authorizeState"
                                [class.disabled]="isResetPasswordDisabled(dataItem) || isLegalEntityArchived"
                                (click)="resetPassword(dataItem)">
            {{'global_resetPassword_label' | translate}}
          </rq-table-action-item>
        </ng-template>

        <ng-template tableAction
                     *ngIf="dataItem.locked">
          <rq-table-action-item e2e-test="table-edit-action"
                                rqAuthorize
                                [state]="authorizeState"
                                [class.disabled]="isLegalEntityArchived"
                                (click)="unlock(dataItem)">
            {{'global_unlockAccount_label' | translate}}
          </rq-table-action-item>
        </ng-template>

        <ng-template tableAction
                     *ngIf="!dataItem.locked">
          <rq-table-action-item e2e-test="table-edit-action"
                                rqAuthorize
                                [state]="authorizeState"
                                [class.disabled]="isToggleActiveAccountDisabled(dataItem) || isLegalEntityArchived"
                                (click)="toggleStatusAccount(dataItem)">
            <ng-container *ngIf="dataItem.isActive !== undefined">
              <span *ngIf="dataItem.isActive">{{ 'global_deactivatedAccount_label' | translate }}</span>
              <span *ngIf="!dataItem.isActive">{{ 'global_reactivateAccount_label' | translate }}</span>
            </ng-container>
          </rq-table-action-item>
        </ng-template>
      </rq-table-actions>
    </ng-template>
  </rq-table-column>

  <ng-template #rightHeaderTemplate>
    <div class="m-t-md m-b-md">
      <button class="btn-secondary"
              (click)="add()"
              [disabled]="isLegalEntityArchived"
              rqAuthorize
              [state]="authorizeState">
        {{ 'global_create_label' | translate }}
        <rq-icon color="color-primary"
                 href="icon-plus"></rq-icon>
      </button>
    </div>
  </ng-template>
</rq-table>

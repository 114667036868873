export enum CveRecommendationVariationType {
	'CveScoreWeighting' = 0,
	'CveTechnicalWeighting' = 1,
	'CvssScore' = 2,
	'HighestIndividualAle' = 3,
	'AggregatedBusinessApplicationAle' = 4,
	'AggregatedCompanyAle' = 5,
	'AggregatedCompanyAleAOOWeighting' = 6,
	'AggregatedCompanyAleNotAOOWeighting' = 7,
	'AggregatedBusinessApplicationAleWeighting' = 8,
	'HighestIndividualAleWeighting' = 9,
	'CveExploitFoundInTheWildWeighting' = 10,
	'CveExploitNotFoundInTheWildWeighting' = 11
}

<section class="b p-lg accordion-item"
         *ngFor="let item of items; let i = index">
  <header (click)="toggleState(item)">
    <div class="p-r-lg">
      <rq-icon class="icon-arrow"
               [class.hidden]="item.state === 'disabled'"
               [class.icon-arrow-rotate]="item.state === 'visible'"
               [href]="'icon-arrow-chevron-right'">
      </rq-icon>
    </div>
    <h3 *ngIf='!hasTitleTemplate(item)'
        class="m-b-none pointer">{{item.title}}</h3>
    <section class='full-width'
             *ngIf='hasTitleTemplate(item)'>
      <ng-container *ngIf="item.titleTemplate">
        <ng-template *ngTemplateOutlet="item.titleTemplate"></ng-template>
      </ng-container>
    </section>
  </header>
  <article *ngIf="item.templateRef"
           [@slideInOut]="item.state">
    <ng-template *ngTemplateOutlet="item.templateRef"></ng-template>
  </article>
</section>

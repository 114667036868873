import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { LegalEntityDataService } from '../../../server/services/configuration/legal-entity-data.service';
import { Customizer } from '../customizer';

export const legalEntityCurrencyResolver = async (route: ActivatedRouteSnapshot) => {
	const legalEntityDataService = inject(LegalEntityDataService);
	const customizer = inject(Customizer);

	const legalEntityId = route?.params.legalEntityId as string;

	if (legalEntityId) {
		customizer.currency = (await legalEntityDataService.getById(legalEntityId)).currency;
	}

	return customizer.currency;
};

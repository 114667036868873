import { Component, HostBinding, HostListener, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ComparisonCollapsible, ComparisonCollapsibleGroup, ComparisonCollapsibleRow } from './comparison-collapsible.model';
import { ComparisonAbstract } from '../comparison.abstract';
import { ComparisonTemplate } from '../comparison.model';

@Component({
	selector: 'rq-comparison-collapsible',
	templateUrl: './comparison-collapsible.component.html',
	styleUrls: ['./comparison-collapsible.component.scss']
})
export class ComparisonCollapsibleComponent extends ComparisonAbstract implements OnInit, OnDestroy {
	@Input()
	public data = new ComparisonCollapsible([]);

	@Input()
	public valueTemplate!: TemplateRef<undefined>;

	@HostBinding('class.comparison-collapsible')
	public hasDefaultClass = true;

	@HostListener('mouseleave')
	public mouseLeave() {
		this.comparisonService.triggerHover.next();
	}

	public ngOnInit(): void {
		this.subscribeToHoverChange();

		this.subscribeToClick();

		this.subscribeToLoading();
	}

	public ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	public getTemplate(row: ComparisonCollapsibleRow | ComparisonTemplate): TemplateRef<unknown> | null {
		if ('template' in row) {
			return row.template;
		}
		return null;
	}

	public getLabel(row: ComparisonCollapsibleRow | ComparisonTemplate): string | null {
		if ('label' in row) {
			return row.label;
		}
		return null;
	}

	public isGroupVisible(group: ComparisonCollapsibleGroup) {
		return !group.title || group.isVisible;
	}

	public toggleCollapse(groupIndex: number) {
		this.comparisonService.triggerClick.next(groupIndex);
	}

	private subscribeToClick() {
		this.subscriptions.push(
			this.comparisonService.triggerClick.subscribe((index: number) => {
				this.data.groups[index].isVisible = !this.data.groups[index].isVisible;
			})
		);
	}
}

import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AppInjector } from './shared/utils/app-injector.utils';
import { BootstrapService } from './system/services/bootstrap.service';
import { RQRouteReuseStrategyService } from './system/route-reuse-strategy.utils';
import { NgxPendoModule } from 'ngx-pendo';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { FeatureFlagService } from './shared/core/feature-flag/feature-flag.service';
import { CommonModule } from '@angular/common';
import { RqDatePipe } from './standalone/pipes/rq-date.pipe';
import { NotificationComponent } from './standalone/components/notification/notification.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpHeaderInterceptorService } from './system/interceptors/http-header-interceptor.service';
import { AuditInterceptorService } from './system/interceptors/audit-interceptor.service';
import { NavigationInterceptor } from './system/interceptors/navigation.interceptor';
import { ErrorHandlingService } from './shared/core/error-handling/error-handling.service';
import { ServerServicesModule } from './server/services/server-services.module';

export function appInit(bootstrapService: BootstrapService) {
	return () => bootstrapService.load();
}

export function featuresFlagInit(featureFlagService: FeatureFlagService) {
	return () => featureFlagService.load();
}
@NgModule({
	declarations: [AppComponent],
	imports: [
		CommonModule,
		BrowserModule,
		HttpClientModule,
		RouterModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		NotificationComponent,
		ServerServicesModule,
		NgxPendoModule.forRoot({
			pendoApiKey: environment.analyticsId,
			pendoIdFormatter: value => value.toString().toLowerCase()
		})
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: appInit,
			deps: [BootstrapService],
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: featuresFlagInit,
			deps: [FeatureFlagService],
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpHeaderInterceptorService,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuditInterceptorService,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: NavigationInterceptor,
			multi: true
		},
		{
			provide: RouteReuseStrategy,
			useClass: RQRouteReuseStrategyService
		},
		{
			provide: ErrorHandler,
			useClass: ErrorHandlingService
		},
		RqDatePipe
	],
	exports: [],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(injector: Injector) {
		AppInjector.injector = injector;
	}
}

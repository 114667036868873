<h3 *ngIf="title">{{title | translate}}</h3>

<div>
  <div>
    <div class="bar-row"
         *ngFor="let item of data">
      <div class="bar-details">
        <div class="label-name">{{item.label}}</div>
        <div class="progress-bar-label">{{getValue(item)}}</div>
      </div>
      <div class="bar"
           [style.height.px]="16">
        <div class="progress-bar"
             [style.background-color]="item.color ?? colorBrand"
             [style.width.%]="getProgress(item.value)"
             [style.height.px]="16">
        </div>
      </div>
    </div>
  </div>
</div>

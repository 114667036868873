<rq-modal class='modal-xl'>
  <rq-modal-header>
    {{'global_ttps_label' | translate}}
  </rq-modal-header>
  <rq-modal-body>

    <rq-card-layout class='card-layout card-reset'
                    [config]="{ isLoadingActive: true, isHeaderHidden: true }">
      <ng-template [rqCardLayoutItem]>
        <rq-application-details-table [data]="tableData"
                                      [count]="tableCount"
                                      [filterOptions]="tableFilterOptions"
                                      (loadData)='loadApplications($event)'>
        </rq-application-details-table>
      </ng-template>
    </rq-card-layout>
  </rq-modal-body>
  <rq-modal-footer class="buttons">
    <div class="d-flex justify-content-end">
      <button (click)="close()"
              class="btn-secondary">{{'global_label_close' | translate}}
      </button>
    </div>
  </rq-modal-footer>
</rq-modal>

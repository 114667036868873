import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChild,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	TemplateRef,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import { AreaChart } from './models/area-chart.model';
import { AreaChartDatazoomConfig } from './models/area-chart-datazoom-config.model';
import { FormatterType } from '../../../../shared/enums/formatter-type.enum';
import { AreaChartAxisConfig } from './models/area-chart-axis-config.model';
import { DataZoomComponentOption, EChartsType, init } from 'echarts';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { ChartService } from '../chart.service';
import { COLORS } from '../../../utils/colors.utils';

@Component({
	selector: 'rq-area-chart',
	templateUrl: './area-chart.component.html',
	styleUrls: ['./area-chart.component.scss'],
	providers: [ChartService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AreaChartComponent implements OnInit, OnChanges, OnDestroy {
	@Input()
	public data?: AreaChart[];

	@Input('dataZoom.isVisible')
	public dataZoomIsVisible = true;

	@Input('dataZoom.values')
	public dataZoomValues?: { startValue: number; endValue: number };

	@Input()
	public dataZoom: AreaChartDatazoomConfig = {
		isVisible: this.dataZoomIsVisible
	};

	@Input('legend.isVisible')
	public legendIsVisible = true;

	@Input('legend.tooltip.isVisible')
	public legendTooltipIsVisible = false;

	@Input('xAxis.label')
	public xAxisLabel = 'X Axis Label Here';

	@Input('xAxis.interval')
	public xAxisInterval?: number;

	@Input('xAxis.formatter')
	public xAxisFormatter?: FormatterType | string = FormatterType.WholeNumber;

	@Input()
	public xAxis: AreaChartAxisConfig = {
		label: this.xAxisLabel,
		formatter: this.xAxisFormatter
	};

	@Input('yAxis.label')
	public yAxisLabel = 'Y Axis Label Here';

	@Input('yAxis.formatter')
	public yAxisFormatter?: FormatterType | string;

	@Input()
	public yAxis: AreaChartAxisConfig = {
		label: this.yAxisLabel
	};

	@ContentChild('customTooltip')
	public customTooltip?: TemplateRef<unknown>;

	@Output()
	public readonly dataZoomSelected = new EventEmitter<{
		startValue: number;
		endValue: number;
	}>();

	@Output()
	public readonly legendChanged = new EventEmitter<{ [name: string]: boolean }>();

	@ViewChild('chart', { static: true })
	public chartContainer!: ElementRef;

	public totals: number[] = [];

	public colors!: string[];

	private selectedLegend?: { [name: string]: boolean };

	private chart!: EChartsType;

	private resizeObserver!: ResizeObserver;

	private grayDarkColor = getComputedStyle(document.documentElement).getPropertyValue('--background-neutral-contrast');

	private grayLightColor = getComputedStyle(document.documentElement).getPropertyValue('--background-neutral-overlay');

	constructor(private chartService: ChartService, private hostElement: ViewContainerRef, private cdr: ChangeDetectorRef) {}

	@HostListener('window:resize')
	public onResize() {
		if (this.chart) {
			this.chart.resize();
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.xAxisLabel) {
			this.updateXAxisConfiguration({ label: changes.xAxisLabel.currentValue as string } as AreaChartAxisConfig);
		}

		if (changes.xAxisFormatter) {
			this.updateXAxisConfiguration({ formatter: changes.xAxisFormatter.currentValue as string } as AreaChartAxisConfig);
		}

		if (changes.xAxisInterval) {
			this.updateXAxisConfiguration({ interval: changes.xAxisInterval.currentValue as number } as AreaChartAxisConfig);
		}

		if (changes.yAxisLabel) {
			this.updateYAxisConfiguration({ label: changes.yAxisLabel.currentValue as string } as AreaChartAxisConfig);
		}

		if (changes.yAxisFormatter) {
			this.updateYAxisConfiguration({ formatter: changes.yAxisFormatter.currentValue as string } as AreaChartAxisConfig);
		}

		if (changes.dataZoomIsVisible) {
			this.updateDataZoomConfiguration({ isVisible: changes.dataZoomIsVisible.currentValue as boolean } as AreaChartDatazoomConfig);
		}

		if (changes.dataZoomValues) {
			this.updateDataZoomConfiguration({
				values: changes.dataZoomValues.currentValue as { startValue: number; endValue: number }
			} as AreaChartDatazoomConfig);
		}

		if (changes.data) {
			this.setData(changes.data.currentValue as AreaChart[]);
		}

		if (changes.dataZoomValues) {
			this.updateDataZoomValues();
		}
	}

	public ngOnInit(): void {
		this.colors = COLORS.rainbowColorScheme;

		this.setupDataZoomHandler();
		this.setupLegendHandler();

		this.subscribeToResizeLayoutChange();
	}

	public ngOnDestroy() {
		this.resizeObserver.disconnect();
	}

	public isEntryVisible(entry: AreaChart, currentIndex: number) {
		return entry.values[currentIndex].value > 0 && (this.selectedLegend ? this.selectedLegend[entry.name] : true);
	}

	public getFormattedValue(value: number) {
		return this.chartService.getFormatter(this.yAxis.formatter)(value);
	}

	private setData(data: AreaChart[]) {
		if (data) {
			this.selectedLegend = undefined;

			if (!this.chart) {
				this.chart = init(this.chartContainer.nativeElement as HTMLElement);
			} else {
				this.chart?.clear();
			}

			const options = {
				color: COLORS.rainbowColorScheme,
				grid: this.getGridConfig(),
				tooltip: this.getTooltipConfig(),
				legend: this.getLegendConfig(),
				dataZoom: this.getDataZoomConfig(),
				xAxis: this.getXAxisConfig(data),
				yAxis: this.getYAxisConfig(),
				series: this.getSeriesConfig(data)
			};

			this.totals = this.getTotals(data);
			this.chart.setOption(options);
		} else {
			this.chart.dispose();
		}
	}

	private updateDataZoomValues() {
		const maxValue = this.totals.length - 1;
		this.chart.dispatchAction(
			{
				type: 'dataZoom',
				start: ((this.dataZoom.values?.startValue ?? 0) / maxValue) * 100,
				end: ((this.dataZoom.values?.endValue ?? maxValue) / maxValue) * 100
			},
			{ silent: true }
		);
	}

	private setupDataZoomHandler() {
		this.chart.on('datazoom', () => {
			const dataZoomOptions = this.chart.getOption().dataZoom as DataZoomComponentOption[];
			const startValue = Math.trunc(dataZoomOptions[1].startValue as number);
			const endValue = Math.trunc(dataZoomOptions[1].endValue as number);

			if (this.data) {
				this.dataZoomSelected.emit({
					startValue,
					endValue
				});
			}
		});
	}

	private setupLegendHandler() {
		this.chart.on('legendselectchanged', legend => {
			this.selectedLegend = (legend as { selected: { [name: string]: boolean } }).selected;
			if (this.data) {
				this.totals = this.getTotals(this.data);
				this.cdr.detectChanges();
			}

			this.legendChanged.emit(this.selectedLegend);
		});
	}

	private getGridConfig() {
		return {
			top: this.dataZoom.isVisible ? 80 : 20,
			bottom: this.legendIsVisible ? 90 : 60,
			left: 100,
			right: 20
		};
	}

	private getTooltipConfig() {
		return {
			trigger: 'axis',
			formatter: (dataParams: CallbackDataParams[]) => {
				const lineData = dataParams.find(dp => dp.componentSubType === 'line');
				if (lineData) {
					return this.hostElement.element.nativeElement
						.querySelector(`div.tooltip-container [id="${lineData.dataIndex}"]`)
						.cloneNode(true) as Node;
				}

				return '';
			},
			axisPointer: {
				type: 'line',
				axis: 'x',
				snap: true,
				lineStyle: {
					type: 'solid',
					width: 1
				}
			}
		};
	}

	private getLegendConfig() {
		return {
			show: this.legendIsVisible,
			itemWidth: 22,
			itemHeight: 7,
			itemGap: 5,
			bottom: 5,
			icon: 'rect',
			tooltip: {
				show: this.legendTooltipIsVisible,
				formatter: ({ name }: CallbackDataParams) => `${name}`
			}
		};
	}

	private getDataZoomConfig() {
		return [
			{
				type: 'slider',
				show: this.dataZoom.isVisible,
				top: 0,
				realtime: true,
				start: 0,
				end: 100,
				height: 60,
				xAxisIndex: 1,
				fillerColor: `${this.grayLightColor}`,
				borderColor: this.grayDarkColor,
				showDetail: false,
				brushSelect: false,
				moveHandleSize: 0,
				handleSize: '60%',
				handleStyle: {
					borderColor: this.grayDarkColor,
					borderWidth: 0.3
				},
				dataBackground: {
					lineStyle: {
						color: this.grayDarkColor
					},
					areaStyle: {
						color: this.grayLightColor
					}
				},
				selectedDataBackground: {
					lineStyle: {
						color: this.grayLightColor
					},
					areaStyle: {
						color: this.grayDarkColor
					}
				}
			},
			{
				type: 'inside',
				realtime: true,
				start: 0,
				end: 100
			}
		];
	}

	private getXAxisConfig(data: AreaChart[]) {
		const values = data.reduce((acc, element) => acc.concat(element.values.map(el => el.label)), [] as number[]);

		return [
			{
				interval: this.xAxis.interval,
				type: 'value',
				axisTick: { show: false },
				name: this.xAxis.label,
				nameLocation: 'middle',
				nameGap: 30,
				nameTextStyle: {
					color: this.grayDarkColor,
					fontSize: 14,
					fontWeight: 600
				},
				axisLabel: {
					formatter: this.chartService.getFormatter(this.xAxis.formatter)
				},
				min: Math.min(...values),
				max: Math.max(...values),
				axisLine: {
					onZero: false
				}
			},
			{ show: false }
		];
	}

	private getYAxisConfig() {
		return [
			{
				type: 'value',
				axisTick: { show: false },
				name: this.yAxis.label,
				nameLocation: 'middle',
				nameGap: 85,
				nameTextStyle: {
					color: this.grayDarkColor,
					fontSize: 14,
					fontWeight: 600
				},
				axisLabel: {
					formatter: this.chartService.getFormatter(this.yAxis.formatter)
				}
			}
		];
	}

	private getSeriesConfig(data: AreaChart[]) {
		return [
			...data.map(element => ({
				smooth: true,
				type: 'line',
				symbolSize: 1.5,
				name: element.name,
				lineStyle: { width: 1 },
				areaStyle: { opacity: 0.3 },
				data: element.values.map(el => [el.label, el.value])
			})),
			{
				type: 'bar',
				xAxisIndex: 1,
				silent: true,
				itemStyle: { opacity: 0 },
				tooltip: { show: false },
				data: data
					.map(element => element.values.map(el => el.value))
					.reduce((acc, values) => acc.map((el, i) => (el > values[i] ? el : values[i])))
			}
		];
	}

	private getTotals(data: AreaChart[]) {
		return data[0].values.map((_v, i) =>
			data.filter(entry => (this.selectedLegend ? this.selectedLegend[entry.name] : true)).reduce((acc, el) => acc + el.values[i].value, 0)
		);
	}

	private updateXAxisConfiguration(config: AreaChartAxisConfig) {
		this.xAxis = { ...this.xAxis, ...config };
	}

	private updateYAxisConfiguration(config: AreaChartAxisConfig) {
		this.yAxis = { ...this.yAxis, ...config };
	}

	private updateDataZoomConfiguration(config: AreaChartDatazoomConfig) {
		this.dataZoom = { ...this.dataZoom, ...config };
	}

	private subscribeToResizeLayoutChange() {
		this.resizeObserver = new ResizeObserver(() => {
			this.chart?.resize();
		});

		this.resizeObserver.observe(this.hostElement?.element?.nativeElement as Element);
	}
}

import { TranslationService } from 'src/app/shared/services/translation.service';
import { PipeTransform, Pipe } from '@angular/core';
import { LossSubType } from '../../../server/enums/loss-sub-type.enum';

@Pipe({
	name: 'lossSubTypeLabel'
})
export class LossSubTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: LossSubType) {
		const values = new Map<LossSubType, string>();
		values.set(LossSubType.Remediation, 'global_remediation_label');
		values.set(LossSubType.Legal, 'global_legal_label');
		values.set(LossSubType.PerRecordFlatFees, 'global_perRecordFlatFees_label');
		values.set(LossSubType.Revenue, 'global_revenue_label');
		values.set(LossSubType.FineHipaa, 'global_HIPAAFines_label');
		values.set(LossSubType.FineGdpr, 'global_gdprFines_label');
		values.set(LossSubType.BusinessInterruption, 'global_businessInterruption_label');
		values.set(LossSubType.Ransom, 'global_ransom_label');
		values.set(LossSubType.TotalCostsExceptFinesPci, 'lossSubType_label_totalCostsExceptFinesPci');
		values.set(LossSubType.TotalFinesPci, 'global_PCIFines_label');
		values.set(LossSubType.Other, 'global_otherLosses_label');
		values.set(LossSubType.Contract, 'global_otherLosses_label');
		values.set(LossSubType.ContractSlaViolation, 'global_otherLosses_label');
		values.set(LossSubType.CustomLoss, 'lossSubType_label_CustomLoss');
		values.set(LossSubType.Settlement, 'global_settlement_label');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${LossSubType[value]} does not exist`);
		}

		return this.translationService.get(type);
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { CustomVariableType } from '../../../server/enums/custom-variable-type.enum';

@Pipe({
	name: 'customVariableTypeLabel'
})
export class CustomVariableTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: CustomVariableType) {
		const values = new Map<CustomVariableType, string>();
		values.set(CustomVariableType.Number, 'global_number_type_label');
		values.set(CustomVariableType.Currency, 'global_currency_label');
		values.set(CustomVariableType.Percent, 'global_percent_type_label');

		const type = values.get(value);
		if (!type) {
			return '';
		}

		return this.translationService.get(type);
	}
}

import { Component, ViewChild } from '@angular/core';
import { ModalComponent } from '../../../standalone/components/modal/modal.component';

@Component({
	selector: 'rq-reset-password-confirmation-modal',
	templateUrl: './reset-password-confirmation-modal.component.html',
	styleUrls: ['./reset-password-confirmation-modal.component.scss']
})
export class ResetPasswordConfirmationModalComponent {
	@ViewChild(ModalComponent, { static: true })
	public modal!: ModalComponent;

	public show() {
		this.modal.show();
	}

	public closeModal() {
		this.modal.hide();
	}
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { AnalysisLossImpactViewModel } from '../../models/analysis-loss-impact-view-model.model';

@Injectable()
export class AnalysisLossImpactDataService {
	constructor(private httpService: RQHttpService) {}

	public createRange(lossImpacts: AnalysisLossImpactViewModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = lossImpacts;

		return this.httpService.request<void>('post', environment.api + '/api/Analysis/AnalysisLossImpact/CreateRange', options).toPromise();
	}
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { EnterpriseApplicationRiskBaseModel } from '../../models/enterprise-application-risk-base-model.model';
import { Pagination } from '../../models/pagination.model';
import { EnterpriseApplicationRiskModel } from '../../models/enterprise-application-risk-model.model';
import { EnterpriseApplicationRiskFilterModel } from '../../models/enterprise-application-risk-filter-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { EnterpriseApplicationRiskFilterOptionModel } from '../../models/enterprise-application-risk-filter-option-model.model';

@Injectable()
export class EnterpriseAnalysisApplicationDataService {
	constructor(private httpService: RQHttpService) {}

	public getTopByInherentRisk(count: number, options = new HttpOptionsViewModel()): Promise<EnterpriseApplicationRiskBaseModel[]> {
		options.params = this.httpService.getHttpParams({
			count
		});

		return this.httpService
			.request<EnterpriseApplicationRiskBaseModel[]>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseAnalysisApplication/GetTopByInherentRisk',
				options
			)
			.toPromise();
	}

	public getPaginatedRisk(
		filter: EnterpriseApplicationRiskFilterModel,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<EnterpriseApplicationRiskModel>> {
		options.params = this.httpService.getHttpParams({
			filter,
			pagination
		});

		return this.httpService
			.request<Pagination<EnterpriseApplicationRiskModel>>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseAnalysisApplication/GetPaginatedRisk',
				options
			)
			.toPromise();
	}

	public getRiskFilters(options = new HttpOptionsViewModel()): Promise<EnterpriseApplicationRiskFilterOptionModel> {
		return this.httpService
			.request<EnterpriseApplicationRiskFilterOptionModel>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseAnalysisApplication/GetRiskFilters',
				options
			)
			.toPromise();
	}

	public export(options = new HttpOptionsViewModel()): Promise<Blob> {
		options.responseType = options.responseType ? options.responseType : 'blob';
		return this.httpService
			.request<Blob>('get', environment.api + '/api/Enterprise/EnterpriseAnalysisApplication/Export', options)
			.toPromise();
	}
}

import { Component, EventEmitter, Input, OnChanges, Output, QueryList, ViewChildren } from '@angular/core';
import { ComparisonService } from './comparison.service';
import { ComparisonCard, ComparisonIcon, ComparisonType } from './comparison.model';
import { ComparisonAbstract } from './comparison.abstract';
import { ComparisonList } from './comparison-list/comparison-list.model';
import { ComparisonTable } from './comparison-table/comparison-table.model';
import { ComparisonCollapsible } from './comparison-collapsible/comparison-collapsible.model';
import { ThemeColorsType } from '../../types/theme-colors.type';

type ComparisonListType = ComparisonList | ComparisonTable | ComparisonCollapsible;

@Component({
	selector: 'rq-comparison',
	templateUrl: './comparison.component.html',
	styleUrls: ['./comparison.component.scss'],
	providers: [ComparisonService]
})
export class ComparisonComponent implements OnChanges {
	@Input()
	public cards: ComparisonCard[] = [];

	@Output()
	public readonly viewDetails = new EventEmitter<unknown>();

	@ViewChildren('comparisonItem')
	public comparisons!: QueryList<ComparisonAbstract>;

	public type = ComparisonType;

	private spacing = getComputedStyle(document.documentElement).getPropertyValue('--spacer-lg');

	constructor(private comparisonService: ComparisonService) {}

	public get isLoading() {
		let isLoading = false;

		if (this.comparisons && this.comparisons.length > 0) {
			isLoading = this.comparisons
				.toArray()
				.map(x => x.isLoading)
				.reduce((x, y) => x && y, true);
		}
		return isLoading;
	}

	public get cardWidth() {
		const cardMargin = parseFloat(this.spacing.replace('px', '').trim());
		const margin = ((this.cards.length - 1) * cardMargin) / this.cards.length;

		return `calc(${100 / this.cards.length}% - ${margin}px)`;
	}

	public ngOnChanges(): void {
		this.validate();
	}

	public getListValue(value: ComparisonListType) {
		return value as ComparisonList;
	}

	public getCollapsibleValue(value: ComparisonListType) {
		return value as ComparisonCollapsible;
	}

	public getTableValue(value: ComparisonListType) {
		return value as ComparisonTable;
	}

	public viewMoreDetails(data: unknown) {
		this.viewDetails.emit(data);
	}

	public isTitleVisible(card: ComparisonCard) {
		return card?.title;
	}

	public async toggleLoading<T>(
		entity: boolean | (() => Promise<void>) | (() => Promise<T>),
		filter?: (data: T, columnIndex: number) => boolean
	) {
		let result;

		if (typeof entity !== 'boolean') {
			this.triggerLoading(true, filter);

			result = await entity();

			this.triggerLoading(false, undefined);
		} else {
			this.triggerLoading(entity, filter);
		}

		return result as T;
	}

	public getIcon(icon: ComparisonIcon) {
		return icon.name;
	}

	public getColor(theme: ThemeColorsType, type: string) {
		return `${theme}-${type}`;
	}

	public hasIcon(entity: { icon?: ComparisonIcon }) {
		return typeof entity.icon !== 'undefined';
	}

	private validate() {
		this.cards.forEach(card => {
			if (typeof card.value.type === 'undefined') {
				throw new Error('All cards should have a ComparisonType defined');
			}
		});
	}

	private triggerLoading<T>(isLoading: boolean, filter: ((data: T, columnIndex: number) => boolean) | undefined) {
		const param = { isLoading, filter: filter as (data: unknown, columnIndex: number) => boolean };

		this.comparisonService.triggerLoading.next(param);
	}
}

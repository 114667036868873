import { LoadingBoxType } from '../loading-box/loading-box-type.enum';
import { CardLayoutItemDirective } from './card-layout-item.directive';

export class CardLayoutConfig {
	public title?: string;

	public tabs?: CardLayoutTabItemConfig[];

	public menuItems?: CardLayoutMenuItemConfig[];

	public navigateTo?: { label: string; url: string };

	public isLoadingActive?: boolean;

	public loadingBoxType?: LoadingBoxType;

	public isHeaderHidden?: boolean;
}

export class CardLayoutTabItemConfig {
	public isActive?: boolean;

	public isDisabled?: boolean;

	constructor(public tabId: string | number, public title: string) {}
}

export class CardLayoutMenuItemConfig {
	public menuId!: string | number;

	public title!: string;

	public isActive?: boolean;

	public isDisabled?: () => boolean;

	public children?: CardLayoutMenuItemConfig[];
}

export class CardLayoutStateConfig {
	public tabId?: string | number;

	public menuId?: string | number;

	public childMenuId?: string | number;

	public content!: CardLayoutItemDirective;
}

export class CardLayoutChangeConfig extends CardLayoutStateConfig {
	constructor(public changeType: ChartLayoutChangeType) {
		super();
	}
}

export class CardLayoutContentItemConfig {
	public tabId?: string | number;

	public menuItemId!: string | number;

	public subTitles!: string[];

	public title!: string;
}

export enum ChartLayoutChangeType {
	MenuItem = 0,
	Tab = 1
}

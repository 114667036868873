import { TranslationService } from 'src/app/shared/services/translation.service';
import { PipeTransform, Pipe } from '@angular/core';
import { AnalysisSummaryType } from 'src/app/server/enums/analysis-summary-type.enum';

@Pipe({
	name: 'analysisSummaryTypeLabel'
})
export class AnalysisSummaryTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value?: AnalysisSummaryType) {
		switch (value) {
			case AnalysisSummaryType.CoreRQ:
				return this.translationService.get('global_core_rq_label');
			case AnalysisSummaryType.Fair:
				return this.translationService.get('global_manual_fair_label');
			case AnalysisSummaryType.SemiFair:
				return this.translationService.get('global_sa_fair_label');
			default:
				return '';
		}
	}
}

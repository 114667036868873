import { baseVariables } from './base.variables';
import { ThemeKeys } from './theme-keys.enum';
import { IThemeColorVariables, IThemePrimitives, IThemeVariables } from './theme.interface';
import { darkSpecificThemeVariables, darkThemePrimitives } from './themes-colors/dark.theme';
import { lightSpecificThemeVariables, lightThemePrimitives } from './themes-colors/light.theme';

const lightTheme: IThemeVariables = {
	...baseVariables,
	...lightSpecificThemeVariables,
	...generateVariables(lightThemePrimitives)
};

const darkTheme: IThemeVariables = {
	...baseVariables,
	...darkSpecificThemeVariables,
	...generateVariables(darkThemePrimitives)
};

export const THEMES: Record<ThemeKeys, IThemeVariables> = {
	[ThemeKeys.Light]: lightTheme,
	[ThemeKeys.Dark]: darkTheme
};

function generateVariables(colors: IThemePrimitives): IThemeColorVariables {
	const variables: Partial<IThemeColorVariables> = {};

	Object.entries(colors).forEach(([key, color]) => {
		const textKey = `text-${key}` as keyof IThemeColorVariables;
		const backgroundKey = `background-${key}` as keyof IThemeColorVariables;
		const borderKey = `border-${key}` as keyof IThemeColorVariables;

		variables[textKey] = color as string;
		variables[backgroundKey] = color as string;
		variables[borderKey] = color as string;
	});

	return variables as IThemeColorVariables;
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ConfigurationSettingModel } from '../../models/configuration-setting-model.model';
import { Feature } from '../../enums/feature.enum';
import { Section } from '../../enums/section.enum';
import { Control } from '../../enums/control.enum';

@Injectable()
export class ConfigurationDataService {
	constructor(private httpService: RQHttpService) {}

	public getByFeature(legalEntityId: string, feature: Feature, options = new HttpOptionsViewModel()): Promise<ConfigurationSettingModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			feature
		});

		return this.httpService
			.request<ConfigurationSettingModel[]>('get', environment.api + '/api/Settings/Configuration/GetByFeature', options)
			.toPromise();
	}

	public getBySection(legalEntityId: string, section: Section, options = new HttpOptionsViewModel()): Promise<ConfigurationSettingModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			section
		});

		return this.httpService
			.request<ConfigurationSettingModel[]>('get', environment.api + '/api/Settings/Configuration/GetBySection', options)
			.toPromise();
	}

	public getControlValue(
		legalEntityId: string,
		control: Control,
		options = new HttpOptionsViewModel()
	): Promise<ConfigurationSettingModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			control
		});

		return this.httpService
			.request<ConfigurationSettingModel>('get', environment.api + '/api/Settings/Configuration/GetControlValue', options)
			.toPromise();
	}

	public update(configurationSettings: ConfigurationSettingModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = configurationSettings;

		return this.httpService.request<void>('post', environment.api + '/api/Settings/Configuration/Update', options).toPromise();
	}
}

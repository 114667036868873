import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { AnalysisBusinessApplicationUpdateViewModel } from '../../models/analysis-business-application-update-view-model.model';

@Injectable()
export class AnalysisBusinessApplicationDataService {
	constructor(private httpService: RQHttpService) {}

	public updateRange(applications: AnalysisBusinessApplicationUpdateViewModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = applications;

		return this.httpService
			.request<void>('put', environment.api + '/api/Analysis/AnalysisBusinessApplication/UpdateRange', options)
			.toPromise();
	}
}

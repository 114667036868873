import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ModifiedFairInputModel } from '../../models/modified-fair-input-model.model';
import { ModifiedFairCreateInputModel } from '../../models/modified-fair-create-input-model.model';
import { ModifiedFairUpdateInputModel } from '../../models/modified-fair-update-input-model.model';
import { FairValidationError } from '../../models/fair-validation-error.model';
import { SemiFairConfigurationModel } from '../../models/semi-fair-configuration-model.model';

@Injectable()
export class ModifiedFairDataService {
	constructor(private httpService: RQHttpService) {}

	public getByScenarioId(scenarioId: string, options = new HttpOptionsViewModel()): Promise<ModifiedFairInputModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<ModifiedFairInputModel>('get', environment.api + '/api/Fair/ModifiedFair/GetByScenarioId', options)
			.toPromise();
	}

	public create(fairInput: ModifiedFairCreateInputModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = fairInput;

		return this.httpService.request<void>('post', environment.api + '/api/Fair/ModifiedFair/Create', options).toPromise();
	}

	public update(fairInput: ModifiedFairUpdateInputModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = fairInput;

		return this.httpService.request<void>('post', environment.api + '/api/Fair/ModifiedFair/Update', options).toPromise();
	}

	public clone(
		legalEntityId: string,
		scenarioId: string,
		clonedScenarioName: string,
		options = new HttpOptionsViewModel()
	): Promise<string> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			scenarioId,
			clonedScenarioName
		});

		return this.httpService.request<string>('post', environment.api + '/api/Fair/ModifiedFair/Clone', options).toPromise();
	}

	public validate(model: SemiFairConfigurationModel, options = new HttpOptionsViewModel()): Promise<FairValidationError[]> {
		options.body = model;

		return this.httpService
			.request<FairValidationError[]>('post', environment.api + '/api/Fair/ModifiedFair/Validate', options)
			.toPromise();
	}
}

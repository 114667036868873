import { RoleModel } from './role-model.model';

export class CreateUserModel {
	public 'email'!: string;
	public 'name'!: string;
	public 'title'!: string;
	public 'origin'!: string;
	public 'legalEntityId'?: string;
	public 'roles'!: RoleModel[];
}

export enum LossSubType {
	'Remediation' = 0,
	'Legal' = 1,
	'PerRecordFlatFees' = 2,
	'Revenue' = 3,
	'FineHipaa' = 4,
	'Contract' = 5,
	'ContractSlaViolation' = 6,
	'FineGdpr' = 7,
	'BusinessInterruption' = 8,
	'Ransom' = 10,
	'TotalCostsExceptFinesPci' = 11,
	'TotalFinesPci' = 12,
	'Other' = 13,
	'Settlement' = 14,
	'CustomLoss' = 15
}

import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../standalone/components/dialog/dialog.component';
import { DialogOptions } from '../../../standalone/components/dialog/dialog-options.ui-model';
import { DialogResult } from '../../../standalone/components/dialog/dialog-result.enum';

@Component({
	selector: 'rq-delete-dialog',
	templateUrl: './delete-dialog.component.html',
	styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {
	@ViewChild(DialogComponent, { static: true })
	public dialog!: DialogComponent;

	@Output()
	public readonly delete = new EventEmitter<string>();

	public dialogOptions!: DialogOptions;

	public name!: string;

	public customMessage!: string;

	public ngOnInit(): void {
		this.setupDeleteDialog();
	}

	public async show(id: string, name: string, customMessage?: string) {
		this.name = name;
		if (customMessage) {
			this.customMessage = customMessage;
		}
		const dialogResult = await this.dialog.show();
		if (dialogResult === DialogResult.Accept) {
			this.delete.emit(id);
		}
	}

	private setupDeleteDialog() {
		this.dialogOptions = new DialogOptions();
		this.dialogOptions.acceptLabel = 'global_delete_label';
		this.dialogOptions.declineLabel = 'global_label_cancel';
	}
}

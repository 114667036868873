<rq-modal (modalClosed)="onOk()"
          #rqModal>
  <rq-modal-header>
    <ng-content select="rq-alert-title"></ng-content>
  </rq-modal-header>
  <rq-modal-body>
    <ng-content select="rq-alert-body"></ng-content>
  </rq-modal-body>
  <rq-modal-footer>
    <div class="alert-buttons">
      <button (click)="onOk()"
              class="{{ buttonClass }}">{{ "global_label_ok" | translate }}
      </button>
    </div>
  </rq-modal-footer>
</rq-modal>

import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { LoadingInlineConfig } from './models/loading-inline.model';

@Directive({
	standalone: true,
	selector: '[rqLoadingInline]'
})
export class LoadingInlineDirective implements OnInit {
	@Input('rqLoadingInline')
	public config?: LoadingInlineConfig;

	@Input()
	public element!: HTMLElement;

	private icon!: SVGElement;

	private size = 16;

	private observer!: MutationObserver;

	private isHiddenAllowed = false;

	private interval!: number;

	constructor(private elementRef: ElementRef) {}

	public get isVisible() {
		return this.element.classList.contains('ng-pending') && this.element.classList.contains('ng-touched');
	}

	private get elementWidth() {
		const parentElement = <HTMLElement>this.element.parentElement;
		const parentDomRect = parentElement.getBoundingClientRect();
		const elementDomRect = this.element.getBoundingClientRect();
		const totalWidth = this.size * 2 + elementDomRect.width;

		let elementWidth = null;

		if (totalWidth > parentDomRect.width) {
			elementWidth = elementDomRect.width - (totalWidth - parentDomRect.width);
		}

		return elementWidth;
	}

	public ngOnInit(): void {
		this.setupConfig();

		this.setupElement();

		this.createLoading();

		this.setupObserver();
	}

	public async toggleVisibility<T>(value: (() => Promise<T>) | boolean) {
		const isPromiseLoading = typeof value !== 'boolean';

		if (isPromiseLoading) {
			return this.toggleVisibilityByPromise(value as () => Promise<T>);
		} else {
			return this.toggleVisibilityByBool(value);
		}
	}

	public hide() {
		if (this.isHiddenAllowed && !this.isVisible) {
			this.isHiddenAllowed = false;

			window.clearInterval(this.interval);

			this.icon.setAttribute('hidden', '');

			if (!this.config?.isInnerLoading) {
				this.element.style.removeProperty('width');
			}

			if (this.config?.isDisabledOnError) {
				this.element.removeAttribute('disabled');
			}
		}
	}

	public show() {
		this.setHideInterval();

		this.icon.removeAttribute('hidden');

		if (!this.config?.isInnerLoading) {
			this.element.style.width = `${this.elementWidth}px`;
		}

		if (this.config?.isDisabledOnError) {
			this.element.setAttribute('disabled', '');
		}
	}

	private async toggleVisibilityByPromise<T>(promise: () => Promise<T> | boolean) {
		this.toggleState(true);
		this.show();

		const result = await promise();

		this.toggleState(false);

		this.hide();

		return result;
	}

	private toggleVisibilityByBool(isVisible: boolean) {
		if (isVisible) {
			this.toggleState(true);

			this.show();
		} else {
			this.toggleState(false);

			this.hide();
		}
	}

	private toggleState(isVisible: boolean) {
		const pendingClass = 'ng-pending';
		const touchedClass = 'ng-touched';

		if (isVisible) {
			this.element.classList.add(pendingClass);
			this.element.classList.add(touchedClass);
		} else {
			this.element.classList.remove(pendingClass);
			this.element.classList.remove(touchedClass);
		}
	}

	private setupObserver() {
		this.observer = new MutationObserver(() => (this.isVisible ? this.show() : this.hide()));

		this.observer.observe(this.element, {
			attributes: true,
			attributeFilter: ['class'],
			childList: false,
			characterData: false
		});
	}

	private createLoading() {
		const use = document.createElementNS('http://www.w3.org/2000/svg', 'use');
		use.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', './assets/icons/sprites.svg#icon-loading-spinner');

		const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
		svg.classList.add('spinner');
		svg.classList.add('icon');
		svg.setAttribute('width', `${this.size}px`);
		svg.setAttribute('height', `${this.size}px`);
		svg.setAttribute('hidden', '');
		svg.style.marginLeft = `${this.size}px`;

		svg.appendChild(use);

		this.config?.isInnerLoading ? this.element.appendChild(svg) : this.element.after(svg);

		this.icon = svg;
	}

	private setHideInterval() {
		this.interval = window.setInterval(() => {
			this.isHiddenAllowed = true;

			this.hide();
		}, 0);
	}

	private setupElement() {
		this.element = this.element ? this.element : (this.elementRef.nativeElement as HTMLElement);
	}

	private setupConfig() {
		if (!this.config) {
			this.config = new LoadingInlineConfig();
		}
	}
}

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ControlSubType } from 'src/app/server/enums/control-sub-type.enum';
import { ControlFrameworkDescriptionModel } from '../../../../../server/models/control-framework-description-model.model';

@Component({
	selector: 'rq-control-option-content',
	templateUrl: './control-option-content.component.html',
	styleUrls: ['./control-option-content.component.scss']
})
export class ControlOptionContentComponent implements OnChanges {
	@Output()
	public readonly triggerShowCurrentEvidence = new EventEmitter();

	@Input()
	public controlLevelDescriptions!: Array<ControlFrameworkDescriptionModel>;

	@Input()
	public noOfEvidences!: number;

	@Input()
	public controlSubType!: ControlSubType;

	@Input()
	public controlLevel!: number;

	//Check for a solution to can remove oldvalue and proposed value.
	@Input()
	public oldValue!: number;

	@Input()
	public proposedValue!: number;

	@Input()
	public expectedLossReduction?: number;

	@Input()
	public expectedSLE?: number;

	public controlLevelName!: string;

	public shortDescriptions!: string;

	public ngOnChanges() {
		if (
			this.noOfEvidences !== undefined &&
			this.controlLevel !== undefined &&
			this.controlLevelDescriptions !== undefined &&
			this.proposedValue !== undefined
		) {
			this.controlLevelName = this.controlLevelDescriptions[Math.floor(this.controlLevel)].displayName;
			this.shortDescriptions = this.controlLevelDescriptions[Math.floor(this.controlLevel)].shortDescription;
		}
	}

	public showCurrentEvidence() {
		this.triggerShowCurrentEvidence.emit();
	}
}

<div *ngIf="isSearchVisible"
     class="search-container m-l-md">
  <form *ngIf='formGroup'
        [formGroup]='formGroup'>
    <rq-form-control>
      <rq-input type='text'
                placeholder="{{'global_search_label' | translate}} techniques"
                class='form-control'
                formControlName='search'>
      </rq-input>
    </rq-form-control>

  </form>
</div>
<section *ngIf="templateData"
         class="attack-navigator"
         [ngStyle]="{'grid-template-columns': 'repeat(' + templateData.length +', 1fr)'}">
  <ng-container *ngFor="let item of templateData; trackBy: trackByParentId">
    <div class="attack-column">
      <div [title]="item.mitreId"
           class="column-header">

        <div class="d-flex">
          <div class="item-checkbox m-r-xs"
               [class.readonly]="isReadOnly"
               *ngIf="hasCheckboxSelection && !isReadOnly">
            <label>
              <input (ngModelChange)=" triggerSelectAllChange(item, $event)"
                     [(ngModel)]="item.isSelected"
                     type="checkbox" />
              <span></span>
            </label>
          </div>

          <div class="m-b-xs truncate-2 column-name"
               [class.selected]="item.isSelected"
               [title]="item.name">
            <strong>{{item.name}}</strong>
          </div>

        </div>

        <div class="column-nr-of-items">{{item.items?.length}} techniques</div>
      </div>

      <div class="attack-content">
        <rq-attack-navigator-items [items]="item.items"
                                   [showFinancialValue]="showFinancialValue"
                                   [hasCheckboxSelection]="hasCheckboxSelection"
                                   [isReadOnly]="isReadOnly"
                                   (selectChange)="triggerSelectChange($event)">
        </rq-attack-navigator-items>
      </div>

    </div>
  </ng-container>
</section>

<div class="m-l-md"
     *ngIf="!templateData?.length"><span>{{'global_table_noData_with_filter' | translate}}</span></div>

import * as security from '../../routes/security/security-routes.config';
import * as infrastructure from '../../routes/infrastructure/infrastructure-routes.config';
import * as fidelityOne from '../../routes/financial-analysis/financial-analysis-routes.config';
import * as enterprise from '../../routes/enterprise/enterprise-routes.config';
import * as pro from '../../routes/control-analysis/control-analysis-routes.config';

export const RQ_ROUTES = {
	pro: { ...pro.PRO_ROUTE },
	enterprise: { ...enterprise.ENTERPRISE_ROUTE },
	security: { ...security.SECURITY_ROUTE },
	infrastructure: { ...infrastructure.INFRASTRUCTURE_ROUTE },
	fidelityOne: { ...fidelityOne.FIDELITY_ONE_ROUTE },
	noAccess: { path: 'no-access', url: noAccessUrl }
};

export function noAccessUrl() {
	return `${RQ_ROUTES.noAccess.path}`;
}

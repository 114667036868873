import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { MitreQualitativeScaleRiskLevel } from '../../../server/enums/mitre-qualitative-scale-risk-level.enum';

@Pipe({
	name: 'qualitativeScaleRiskLevelLabel'
})
export class QualitativeScaleRiskLevelTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: MitreQualitativeScaleRiskLevel) {
		const values = new Map<MitreQualitativeScaleRiskLevel, string>();
		values.set(MitreQualitativeScaleRiskLevel.VeryLow, 'very_low_label');
		values.set(MitreQualitativeScaleRiskLevel.Low, 'global_low_label');
		values.set(MitreQualitativeScaleRiskLevel.Medium, 'global_medium_label');
		values.set(MitreQualitativeScaleRiskLevel.High, 'global_high_label');
		values.set(MitreQualitativeScaleRiskLevel.VeryHigh, 'very_high_label');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${MitreQualitativeScaleRiskLevel[value]} does not exist`);
		}

		return this.translationService.get(type);
	}
}

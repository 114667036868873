import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { EXPANDABLE_TEXT_CONFIG, ExpandableTextModel, ExpandableTextWordModel } from './expandable-text.ui-model';

@Component({
	selector: 'rq-expandable-text',
	templateUrl: './expandable-text.component.html',
	styleUrls: ['./expandable-text.component.scss']
})
export class ExpandableTextComponent implements OnInit, OnChanges {
	@Input()
	public config!: ExpandableTextModel;

	@Input()
	public wordList!: ExpandableTextWordModel[];

	public wordListFullChunked: ExpandableTextWordModel[][] = [];

	public wordListLimitedChunked: ExpandableTextWordModel[][] = [];

	constructor(private router: Router) {}

	public async navigateTo(url: string) {
		await this.router.navigateByUrl(url);
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.wordList && !changes.wordList.firstChange) {
			this.setupLists();
		}
	}

	public ngOnInit() {
		this.config = Object.assign({}, this.config || EXPANDABLE_TEXT_CONFIG);
		this.setupLists();
	}

	public getText() {
		const showMoreText = this.config?.showMoreText ? this.config.showMoreText : EXPANDABLE_TEXT_CONFIG.showMoreText;
		const showLessText = this.config?.showLessText ? this.config.showLessText : EXPANDABLE_TEXT_CONFIG.showLessText;

		return this.config.expanded ? showLessText : showMoreText;
	}

	private setupLists() {
		if (!this.config.itemsPerLine) {
			throw new Error(`${ExpandableTextComponent.name}: Missing 'itemsPerLine' property from  component config.`);
		}

		this.wordListFullChunked = this.chunkArray(Object.assign([], this.wordList), this.config.itemsPerLine);
		this.wordListLimitedChunked = this.chunkArray(
			Object.assign([], this.wordList.slice(0, this.config.itemsToShow)),
			this.config.itemsPerLine
		);
	}

	private chunkArray(list: ExpandableTextWordModel[], chunkSize?: number) {
		const results = [];

		while (list.length) {
			results.push(list.splice(0, chunkSize));
		}

		return results;
	}
}

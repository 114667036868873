import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { CurrencyModel } from '../../models/currency-model.model';

@Injectable()
export class CurrencyDataService {
	constructor(private httpService: RQHttpService) {}

	public getCurrency(isoCode: string, options = new HttpOptionsViewModel()): Promise<CurrencyModel> {
		options.params = this.httpService.getHttpParams({
			isoCode
		});

		return this.httpService.request<CurrencyModel>('get', environment.api + '/api/Configuration/Currency/GetCurrency', options).toPromise();
	}

	public getAllCurrencies(options = new HttpOptionsViewModel()): Promise<CurrencyModel[]> {
		return this.httpService
			.request<CurrencyModel[]>('get', environment.api + '/api/Configuration/Currency/GetAllCurrencies', options)
			.toPromise();
	}

	public update(currencyToUpdate: CurrencyModel, options = new HttpOptionsViewModel()): Promise<number> {
		options.body = currencyToUpdate;

		return this.httpService.request<number>('put', environment.api + '/api/Configuration/Currency/Update', options).toPromise();
	}

	public insert(currencyToSave: CurrencyModel, options = new HttpOptionsViewModel()): Promise<number> {
		options.body = currencyToSave;

		return this.httpService.request<number>('post', environment.api + '/api/Configuration/Currency/Insert', options).toPromise();
	}

	public delete(isoCode: string, options = new HttpOptionsViewModel()): Promise<number> {
		options.params = this.httpService.getHttpParams({
			isoCode
		});

		return this.httpService.request<number>('delete', environment.api + '/api/Configuration/Currency/Delete', options).toPromise();
	}
}

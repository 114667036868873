import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { BusinessEntityModel } from '../../models/business-entity-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { BusinessEntityFilterModel } from '../../models/business-entity-filter-model.model';
import { BusinessEntityFilterOptionModel } from '../../models/business-entity-filter-option-model.model';
import { BusinessEntityCreateModel } from '../../models/business-entity-create-model.model';
import { BusinessEntityUpdateModel } from '../../models/business-entity-update-model.model';

@Injectable()
export class BusinessEntityDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		pagination: PaginationFilter,
		filter: BusinessEntityFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<BusinessEntityModel>> {
		options.params = this.httpService.getHttpParams({
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<BusinessEntityModel>>('get', environment.api + '/api/Enterprise/BusinessEntity/GetPaginated', options)
			.toPromise();
	}

	public getFilterOptions(options = new HttpOptionsViewModel()): Promise<BusinessEntityFilterOptionModel> {
		return this.httpService
			.request<BusinessEntityFilterOptionModel>('get', environment.api + '/api/Enterprise/BusinessEntity/GetFilterOptions', options)
			.toPromise();
	}

	public getByName(name: string, options = new HttpOptionsViewModel()): Promise<BusinessEntityModel> {
		options.params = this.httpService.getHttpParams({
			name
		});

		return this.httpService
			.request<BusinessEntityModel>('get', environment.api + '/api/Enterprise/BusinessEntity/GetByName', options)
			.toPromise();
	}

	public getById(businessEntityId: string, options = new HttpOptionsViewModel()): Promise<BusinessEntityModel> {
		options.params = this.httpService.getHttpParams({
			businessEntityId
		});

		return this.httpService
			.request<BusinessEntityModel>('get', environment.api + '/api/Enterprise/BusinessEntity/GetById', options)
			.toPromise();
	}

	public create(createModel: BusinessEntityCreateModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = createModel;

		return this.httpService.request<string>('post', environment.api + '/api/Enterprise/BusinessEntity/Create', options).toPromise();
	}

	public update(updateModel: BusinessEntityUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = updateModel;

		return this.httpService.request<void>('post', environment.api + '/api/Enterprise/BusinessEntity/Update', options).toPromise();
	}
}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActorViewModel } from '../../../server/models/actor-view-model.model';
import { AttackViewModel } from '../../../server/models/attack-view-model.model';
import { ImpactVectorFilterOptionsModel } from '../../../server/models/impact-vector-filter-options-model.model';
import { ImpactVectorFilterDefinitionViewModel } from '../../../server/models/impact-vector-filter-definition-view-model.model';
import { ImpactVectorViewModel } from '../../../routes/control-analysis/_common/models/impact-vector.viewmodel';
import { TableBase2Component } from '../../../standalone/components/table/table-base2.component';
import { FormatterType } from '../../enums/formatter-type.enum';
import { FilterSelectOption, RangeFilter, SelectFilter } from '../../../standalone/components/form-elements/filter/filter.model';

@Component({
	selector: 'rq-impact-vectors-table',
	templateUrl: './impact-vectors-table.component.html',
	styleUrls: ['./impact-vectors-table.component.scss']
})
export class ImpactVectorsTableComponent
	extends TableBase2Component<ImpactVectorViewModel, ImpactVectorFilterDefinitionViewModel>
	implements OnChanges
{
	@Input()
	public filterOptions!: ImpactVectorFilterOptionsModel;

	@Input()
	public isLegalEntityVisible = false;

	@Input()
	public actors!: Array<ActorViewModel>;

	@Input()
	public attacks!: Array<AttackViewModel>;

	public actorFilterSetup!: SelectFilter;

	public attackFilterSetup!: SelectFilter;

	public roiFilterSetup!: RangeFilter;

	public attackProbabilityFilterSetup!: RangeFilter;

	public sleFilterSetup!: RangeFilter;

	public aleFilterSetup!: RangeFilter;

	public async ngOnChanges(changes: SimpleChanges) {
		await super.ngOnChanges(changes);
		if (changes?.filterOptions?.currentValue && this.actors && this.attacks) {
			this.setupFilters(changes.filterOptions.currentValue as ImpactVectorFilterOptionsModel);
		}
	}

	public getActorLabel(actorId: string) {
		return this.actors.find(actor => actor.id === actorId)?.name;
	}

	public getAttackLabel(attackId: string) {
		return this.attacks.find(attack => attack.id === attackId)?.name;
	}

	private setupFilters(filterOptions?: ImpactVectorFilterOptionsModel) {
		if (filterOptions) {
			this.actorFilterSetup = this.createSelectFilter('global_actorValue_label', this.createActorGroupOptions());

			this.attackFilterSetup = this.createSelectFilter('global_attack_label', this.createAttackGroupOptions());

			this.roiFilterSetup = this.createRangeFilter(
				'global_annualAttackRateOfIncidence_label',
				filterOptions.rateOfIncident,
				FormatterType.Number
			);

			this.attackProbabilityFilterSetup = this.createRangeFilter(
				'global_probability_label',
				filterOptions.attackSuccessProbability,
				FormatterType.Percent
			);

			this.sleFilterSetup = this.createRangeFilter('global_sle_label', filterOptions.singleLossAmount, FormatterType.Currency);

			this.aleFilterSetup = this.createRangeFilter('global_ale_label', filterOptions.annualizedLossAmount, FormatterType.Currency);
		}
	}

	private createActorGroupOptions() {
		return [{ options: this.actors.map(x => new FilterSelectOption<string>(x.name, x.id)) }];
	}

	private createAttackGroupOptions() {
		return [{ options: this.attacks.map(x => new FilterSelectOption<string>(x.name, x.id)) }];
	}
}

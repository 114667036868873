<section class="card-layout-menu-item">
  <div class="menu-item-label b b-bottom"
       [class.disabled]="isItemDisabled"
       [ngClass]="{'menu-item-label-active': isActiveStateVisible}"
       (click)="triggerMenuItemChange()"
       [title]="label | translate">
    <rq-icon class="icon"
             *ngIf="hasChildren"
             [ngClass]="{'icon-down': !isActive, 'icon-up': isActive}"
             href="icon-arrow-chevron-right"></rq-icon>
    <span class="text-sm">{{label | translate}}</span>
  </div>
  <div class="menu-item-children"
       *ngIf="isActive">
    <ng-content></ng-content>
  </div>
</section>

import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { EndpointScoreWeightingTypeEnum } from 'src/app/routes/control-analysis/_common/enums/endpoint-score-weighting-type.enum';

@Pipe({
	name: 'endpointScoreTypeLabel'
})
export class EndpointScoreTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: EndpointScoreWeightingTypeEnum) {
		const values = new Map<EndpointScoreWeightingTypeEnum, string>();
		values.set(EndpointScoreWeightingTypeEnum.ApplicationSecurity, 'global_applicationSecurity_label');
		values.set(EndpointScoreWeightingTypeEnum.SubnetScore, 'endpointScoreWeighting_subnetScore_label');
		values.set(EndpointScoreWeightingTypeEnum.VulnerabilityScore, 'endpointScoreWeighting_vulnerabilityScore_label');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${value} is not handled`);
		}

		return this.translationService.get(type);
	}
}

<main class="user-menu">
  <section class="user-menu__header">
    <button class="user-menu__avatar">{{ userInitials }}</button>
    <div class="user-menu__details">
      <span class="user-menu__name">{{ userName }}</span>
      <span class="user-menu__role">{{ userRole | translate }}</span>
    </div>
  </section>

  <section class="user-menu__body">
    <button *ngIf="isActivityLogVisible && areActionsVisible"
            class="btn-secondary user-menu__action"
            (click)="navigateToActivityLog()">
      <rq-icon href="icon-clock-time-dashed"
               color="color-secondary"></rq-icon>{{ 'global_activityType_label' | translate }}
    </button>
    <button class="btn-secondary user-menu__action"
            *ngIf="areActionsVisible"
            (click)="navigateToPreferences()">
      <rq-icon href="icon-adjust-horizontal"
               color="color-secondary"></rq-icon>{{ 'settings_preferences_title' | translate }}
    </button>
    <button class="btn-secondary user-menu__action"
            (click)="logout()">
      <rq-icon href="icon-logout"
               color="color-secondary"></rq-icon>{{ 'menu_label_logout' | translate }}
    </button>
  </section>
</main>

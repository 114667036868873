import { Component, ElementRef } from '@angular/core';

@Component({
	selector: 'rq-heading',
	templateUrl: './heading.component.html',
	styleUrls: ['./heading.component.scss']
})
export class HeadingComponent {
	private element: HTMLElement;

	constructor(elementRef: ElementRef) {
		this.element = elementRef.nativeElement as HTMLElement;
	}

	public get isSubTitleVisible() {
		return Boolean(this.element.querySelector('rq-heading-subtitle'));
	}

	public get isTitleVisible() {
		return Boolean(this.element.querySelector('rq-heading-title'));
	}
}

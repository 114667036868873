import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatterService } from '../../shared/services/date-formatter.service';

@Pipe({
	name: 'rqTime',
	standalone: true
})
export class RqTimePipe implements PipeTransform {
	constructor(private dateService: DateFormatterService) {}

	public transform(date: string | Date): string {
		if (date && this.dateService.isValidDate(date)) {
			return this.dateService.format(date, 'HH:mm');
		} else {
			return '';
		}
	}
}

<form [formGroup]="formGroup">
  <rq-form-control class="m-none">
    <rq-dropdown [options]="options"
                 [filter]="true"
                 [searchPlaceholder]="'global_legalEntity_label' | translate"
                 class="dropdown-sm m-none portfolio-dropdown"
                 formControlName="legalEntity"
                 rqAuthorize
                 [state]="authorizeState">
    </rq-dropdown>
  </rq-form-control>
</form>

import { Directive, Input, TemplateRef } from '@angular/core';
import { CardLayoutContentItemConfig } from './card-layout.config.model';

@Directive({
	selector: '[rqCardLayoutItem]'
})
export class CardLayoutItemDirective {
	@Input('rqCardLayoutItem')
	public config?: CardLayoutContentItemConfig;

	@Input()
	public menuItemId?: string | number;

	@Input()
	public tabId?: string | number;

	@Input()
	public subTitles!: string[];

	@Input()
	public title!: string;

	@Input()
	public titleHtml?: TemplateRef<unknown>;

	constructor(public templateRef: TemplateRef<unknown>) {}
}

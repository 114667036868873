import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { AnalysisStatusModel } from '../../models/analysis-status-model.model';
import { RiskAnalysisStartModel } from '../../models/risk-analysis-start-model.model';
import { RiskAnalysisDone } from '../../models/risk-analysis-done.model';

@Injectable()
export class AnalysisRunnerDataService {
	constructor(private httpService: RQHttpService) {}

	public getStatusByAnalysisId(analysisId: string, options = new HttpOptionsViewModel()): Promise<AnalysisStatusModel> {
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService
			.request<AnalysisStatusModel>('get', environment.api + '/api/Analysis/AnalysisRunner/GetStatusByAnalysisId', options)
			.toPromise();
	}

	public getStatus(legalEntityId: string, scenarioId: string, options = new HttpOptionsViewModel()): Promise<AnalysisStatusModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			scenarioId
		});

		return this.httpService
			.request<AnalysisStatusModel>('get', environment.api + '/api/Analysis/AnalysisRunner/GetStatus', options)
			.toPromise();
	}

	public startAnalysis(analysis: RiskAnalysisStartModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = analysis;

		return this.httpService.request<string>('post', environment.api + '/api/Analysis/AnalysisRunner/StartAnalysis', options).toPromise();
	}

	public saveAnalysis(output: RiskAnalysisDone, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = output;

		return this.httpService.request<void>('post', environment.api + '/api/Analysis/AnalysisRunner/SaveAnalysis', options).toPromise();
	}
}

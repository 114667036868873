<div class="dropdown-selected dropdown-item-placeholder"
     (click)="triggerDropdown()">
  <ng-template #selected></ng-template>
  <div *ngIf="isPlaceholderVisible"
       class="dropdown-item placeholder">{{ placeholder }}</div>
</div>

<div class="dropdown-body-hook">
  <div class="dropdown-body m-t-xs b">
    <div class="dropdown-filter p-none b b-bottom"
         *ngIf="filter">
      <div class="d-flex align-items-center">
        <input class="input m-none b b-none"
               [(ngModel)]="filterValue"
               (keyup)="doFilter()"
               placeholder="{{searchPlaceholder}}" />
        <rq-icon class="m-none"
                 color="color-secondary"
                 href="icon-search"></rq-icon>
      </div>
    </div>
    <div class="dropdown-items">
      <ng-template #body></ng-template>
    </div>
  </div>
</div>
<ng-template #default
             let-item>
  <ng-container *ngIf='item'>
    <div class="dropdown-item"
         [ngClass]="{'selected': item.isSelected}"
         [title]="item.displayName"
         (click)="select(item)">{{ item.displayName }}
    </div>
  </ng-container>
</ng-template>

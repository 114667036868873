import { Component, forwardRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FilterAbstractDirective } from '../filter.abstract';
import { FilterSearchBoxConfig, FilterSelectGroup, FilterSelectOption } from '../filter.model';

@Component({
	selector: 'rq-filter-select',
	templateUrl: './filter-select.component.html',
	styleUrls: ['./filter-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FilterSelectComponent),
			multi: true
		}
	]
})
export class FilterSelectComponent extends FilterAbstractDirective<unknown> implements ControlValueAccessor {
	@Input()
	public displayName!: string;

	@Input()
	public placeholder!: string;

	@Input()
	public groups: FilterSelectGroup<unknown>[] = [];

	@Input()
	public isDisabled!: boolean;

	@Input()
	public maxSelected?: number;

	public searchBoxConfig: FilterSearchBoxConfig = {
		searchValue: '',
		trigger: 'change',
		placeholder: 'global_search_label'
	};

	private onChangeCallback!: (_: unknown) => void;

	private onTouchedCallback!: (_: unknown) => void;

	public get isCheckboxDisabled() {
		let isDisabled = Boolean(this.isDisabled);

		if (typeof this.maxSelected === 'number') {
			isDisabled = this.selected.length >= this.maxSelected;
		}

		return isDisabled;
	}

	public get selected() {
		let selectedOptions: FilterSelectOption<unknown>[] = [];

		if (this.groups) {
			this.groups.forEach(x => (selectedOptions = selectedOptions.concat(x.options.filter(y => y.isSelected))));
		}

		return selectedOptions;
	}

	@HostListener('click')
	public setTouchedState() {
		this.value = this.selected.map(x => x.value);

		this.onTouchedCallback(this.value);
	}

	public createPlaceholder() {
		let result = '';

		if (this.selected.length > 0) {
			this.selected.forEach((option, index) => {
				result += index === 0 ? option.displayName : `, ${option.displayName}`;
			});
		} else {
			result = this.placeholder;
		}

		return result;
	}

	public triggerClear() {
		if (this.groups && !this.isDisabled) {
			this.groups.forEach(group => {
				group.options.forEach(option => (option.isSelected = false));
			});

			this.triggerSelectChange();
		}
	}

	public isGroupVisible(group: FilterSelectGroup<unknown>) {
		return group.options.filter(x => !x.isSelected && this.isPartOfSearch(x)).length > 0;
	}

	public isPartOfSearch(option: FilterSelectOption<unknown>) {
		let isPartOfSearch = true;

		if (this.searchBoxConfig.searchValue) {
			isPartOfSearch = option.displayName.toLowerCase().includes(this.searchBoxConfig.searchValue.toLowerCase());
		}

		return isPartOfSearch;
	}

	public triggerSelectChange() {
		Promise.resolve().then(() => {
			this.value = this.selected.map(x => x.value);

			this.onChangeCallback(this.value);

			this.change.next();
		});
	}

	public registerOnChange(fn: (value: unknown) => void) {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: (value: unknown) => void) {
		this.onTouchedCallback = fn;
	}

	public writeValue(values: string) {
		if (!this.groups) {
			return;
		}

		this.groups.forEach(group => {
			if (group.options) {
				group.options.forEach(option => {
					if (values?.includes(option.value as string)) {
						option.isSelected = true;
					}
				});
			}
		});
	}
}

import { Directive } from '@angular/core';

@Directive({ selector: 'rq-modal-body' })
export class ModalBodyDirective {}

@Directive({ selector: 'rq-modal-header' })
export class ModalHeaderDirective {}

@Directive({ selector: 'rq-modal-footer' })
export class ModalFooterDirective {}

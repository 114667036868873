import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { DataProfileType } from 'src/app/server/enums/data-profile-type.enum';

@Pipe({
	name: 'dataProfileTypeLabel'
})
export class DataProfileTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: DataProfileType) {
		const values = new Map<DataProfileType, string>();
		values.set(DataProfileType.PCI, 'global_PCI_label');
		values.set(DataProfileType.PHI, 'global_PHI_label');
		values.set(DataProfileType.PII, 'global_PII_label');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${value} does not exist`);
		}

		return this.translationService.get(type);
	}
}

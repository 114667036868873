import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FairType } from 'src/app/server/enums/fair-type.enum';
import { ScenarioType } from 'src/app/server/enums/scenario-type.enum';
import { FairLabelService } from '../../../routes/control-analysis/_common/services/fair-label.service';
import { ScenarioFairTreeService } from '../../../routes/control-analysis/_common/services/scenario-fair-tree.service';
import { FairAnalysisDetailsModel } from '../../../server/models/fair-analysis-details-model.model';
import { FairTreeModel } from '../../../server/models/fair-tree-model.model';
import { CardTreeConfig, CardTreeNode } from '../../../standalone/components/charts/card-tree/card-tree.model';

@Component({
	selector: 'rq-scenarios-fair-view',
	templateUrl: './scenarios-fair-view.page.html',
	styleUrls: ['./scenarios-fair-view.page.scss']
})
export class ScenariosFairViewComponent implements OnInit, OnChanges {
	@Input()
	public dataModel!: FairTreeModel;

	@Input()
	public scenarioType!: ScenarioType;

	@Input()
	public fairAnalysisDetails!: FairAnalysisDetailsModel;

	public node!: CardTreeNode<FairType, FairTreeModel>;

	public treeConfig!: CardTreeConfig;

	constructor(private fairLabelService: FairLabelService, private scenarioFairTreeService: ScenarioFairTreeService) {}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.dataModel.currentValue) {
			this.node = this.scenarioFairTreeService.createTreeNodes(changes.dataModel.currentValue as FairTreeModel);
		}
	}

	public ngOnInit() {
		this.treeConfig = this.scenarioFairTreeService.createTreeConfig();
	}

	public isConfigured(data: FairTreeModel) {
		return this.scenarioFairTreeService.isNodeConfigured(data);
	}

	public getDataDescription(data: FairTreeModel) {
		return this.scenarioFairTreeService.getDataDescription(data, this.scenarioType);
	}

	public formatValue(number: number, type: FairType) {
		return this.fairLabelService.formatValue(number, type);
	}
}

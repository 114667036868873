import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AnalysisSummaryType } from 'src/app/server/enums/analysis-summary-type.enum';
import { StandardBase } from '../../../../routes/control-analysis/_common/standard.base';
import { AnalysisSummaryCategoryViewModel } from '../../../../routes/control-analysis/_common/models/analysis-summary-category.viewmodel';
import { AnalysisSummaryItemViewModel } from '../../../../routes/control-analysis/_common/models/analysis-summary-item.viewmodel';
import { LoadingBoxType } from '../../../../standalone/components/loading-box/loading-box-type.enum';
import { AnalysisSummaryStatusViewModel } from 'src/app/routes/control-analysis/_common/models/analysis-summary-status.viewmodel';
import { RQAnalysisStatus } from 'src/app/server/enums/rqanalysis-status.enum';
import { AnalysisStatusModel } from 'src/app/server/models/analysis-status-model.model';
import { AnalysisSummaryModel } from 'src/app/server/models/analysis-summary-model.model';
import { AnalysisRunnerDataService } from 'src/app/server/services/analysis/analysis-runner-data.service';
import { AnalysisSummaryDataService } from 'src/app/server/services/analysis/analysis-summary-data.service';
import { AnalysisSummaryCategoryTypeTranslate } from 'src/app/shared/pipes/translate-pipes/analysis-summary-category-type-label.pipe';
import { AnalysisNotificationService } from 'src/app/shared/services/analysis-notification.service';
import { AnalysisSummaryHubService } from 'src/app/shared/signlr-hubs/analysis-summary-hub.service';

@Component({
	selector: 'rq-analysis-summary',
	templateUrl: './analysis-summary.component.html',
	styleUrls: ['./analysis-summary.component.scss']
})
export class AnalysisSummaryComponent extends StandardBase implements OnInit, OnDestroy {
	@Output() public readonly closeAnalysisSummary = new EventEmitter();

	@Output() public readonly navigateToAnalysisGuide = new EventEmitter();

	public currentAnalysisId!: string;

	public analysisSummary: Array<AnalysisSummaryCategoryViewModel> = [];

	public analysisType!: AnalysisSummaryType;

	public loadingPromise!: Promise<void>;

	public loadingType = LoadingBoxType;

	private percentageCompleted!: number;

	constructor(
		private analysisSummaryDataService: AnalysisSummaryDataService,
		private analysisSummaryHubService: AnalysisSummaryHubService,
		private analysisSummaryCategoryTypeTranslate: AnalysisSummaryCategoryTypeTranslate,
		private analysisRunnerDataService: AnalysisRunnerDataService,
		private analysisNotificationService: AnalysisNotificationService
	) {
		super();
	}

	public async ngOnInit() {
		this.loadingPromise = this.loadLastAnalysisSummary();
		this.subscribeToAnalysisNotificationStatus();
		await this.setAnalysisSummaryCurrentStatus();
	}

	public async ngOnDestroy() {
		super.ngOnDestroy();
		await this.analysisSummaryHubService.disconnect();
	}

	public isStepActive(item: AnalysisSummaryItemViewModel) {
		return (
			item.currentPercentageStep >= this.percentageCompleted &&
			item.nextPercentageStep > this.percentageCompleted &&
			item.previousPercentageStep < this.percentageCompleted
		);
	}

	public isStepComplete(item: AnalysisSummaryItemViewModel) {
		return !this.percentageCompleted || this.percentageCompleted >= item.currentPercentageStep;
	}

	public hideAnalysisSummary() {
		this.closeAnalysisSummary.emit();
	}

	public redirectToAnalysisGuide() {
		this.navigateToAnalysisGuide.emit();
	}

	private async setAnalysisSummaryCurrentStatus() {
		await this.analysisSummaryHubService.connect();

		this.analysisSummaryHubService.statusChange((model: AnalysisSummaryStatusViewModel) => {
			if (model.analysisId === this.currentAnalysisId) {
				this.percentageCompleted = model.percentageCompleted;
			}
		});
	}

	private async loadLastAnalysisSummary(status?: AnalysisStatusModel) {
		let currentAnalysisSummaryId = '';

		if (status?.analysisId && status.status === RQAnalysisStatus.Running) {
			currentAnalysisSummaryId = status.analysisId;
		} else {
			const data = await this.analysisRunnerDataService.getStatus(this.legalEntityId, '');

			if (data.analysisId) {
				currentAnalysisSummaryId = data.analysisId;
			}
		}

		this.currentAnalysisId = currentAnalysisSummaryId;

		if (this.currentAnalysisId) {
			await this.getAnalysisSummaryData(this.currentAnalysisId);
		}
	}

	private async getAnalysisSummaryData(analysisId: string) {
		this.analysisType = await this.analysisSummaryDataService.getSummaryTypeByAnalysisId(analysisId);
		const analysisSummaryData = await this.analysisSummaryDataService.getSummaryByAnalysisType(this.analysisType);

		this.setAnalysisSummaryModel(analysisSummaryData);
	}

	private setAnalysisSummaryModel(analysisSummaryData: Array<AnalysisSummaryModel>) {
		if (analysisSummaryData) {
			const uniqueCategories = [...new Set(analysisSummaryData.map(category => category.category))];

			const analysisSummarySteps = analysisSummaryData.map(item => item.visibleFrom).sort((a, b) => a - b);

			this.analysisSummary = uniqueCategories.map(category => {
				const categoryModel: AnalysisSummaryCategoryViewModel = {
					category: this.analysisSummaryCategoryTypeTranslate.transform(category),
					items: analysisSummaryData
						.filter(item => item.category === category)
						.map(x => {
							return <AnalysisSummaryItemViewModel>{
								category: x.category,
								previousPercentageStep: this.extractBeforeAndAfterStep(x.visibleFrom, analysisSummarySteps, true),
								currentPercentageStep: x.visibleFrom,
								nextPercentageStep: this.extractBeforeAndAfterStep(x.visibleFrom, analysisSummarySteps),
								description: x.translationNameKey,
								timeframe: x.timeFrame
							};
						})
				};

				return categoryModel;
			});
		}
	}

	private extractBeforeAndAfterStep(currentStep: number, steps: Array<number>, extractBefore?: boolean) {
		const index = steps.findIndex(step => currentStep === step);

		if (index !== -1) {
			const stepsIndex = extractBefore ? index - 1 : index + 1;
			return steps[stepsIndex];
		}

		return undefined;
	}

	private subscribeToAnalysisNotificationStatus() {
		this.subscriptions.push(
			this.analysisNotificationService.analysisChange.subscribe(async (status: AnalysisStatusModel) => {
				const analysisId = status?.analysisId;

				if (analysisId) {
					await this.getAnalysisSummaryData(analysisId);
				}
			})
		);
	}
}

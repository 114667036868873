import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ControlFrameworkDescriptionModel } from '../../../server/models/control-framework-description-model.model';
import { DefensebilityDeltaViewModel } from '../../../server/models/defensebility-delta-view-model.model';
import { DefensebilityEvidenceViewModel } from '../../../server/models/defensebility-evidence-view-model.model';
import { DefensebilityViewModel } from '../../../server/models/defensebility-view-model.model';
import { ModalComponent } from '../../../standalone/components/modal/modal.component';
import { CardLayoutComponent } from '../../../standalone/components/card-layout/card-layout.component';

@Component({
	selector: 'rq-evidences-details-modal',
	templateUrl: './evidences-details-modal.component.html',
	styleUrls: ['./evidences-details-modal.component.scss']
})
export class EvidencesDetailsModalComponent implements OnChanges {
	@ViewChild(CardLayoutComponent, { static: true })
	public cardLayout!: CardLayoutComponent;

	@ViewChild(ModalComponent, { static: true })
	public modal!: ModalComponent;

	@Input()
	public controlOptionName!: string;

	@Input()
	public multipleData?: DefensebilityDeltaViewModel;

	@Input()
	public singleData?: DefensebilityViewModel;

	@Input()
	public controlLevelDescriptions!: Array<ControlFrameworkDescriptionModel>;

	public evidences!: Array<DefensebilityEvidenceViewModel>;

	public noEvidences!: boolean;

	private get isCompared() {
		return this.multipleData && !this.singleData;
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.multipleData?.currentValue) {
			this.updateMultipleData();
			this.cardLayout.toggleLoadingBoxState(false);
		}

		if (changes.singleData?.currentValue) {
			this.updateSingleData();
			this.cardLayout.toggleLoadingBoxState(false);
		}
	}

	public show() {
		this.modal.show();
	}

	public close() {
		this.modal.hide();
		this.multipleData = undefined;
		this.singleData = undefined;
	}

	public getLevelName(controlLevel: number) {
		return this.controlLevelDescriptions.find(x => x.type === Math.floor(controlLevel))?.displayName ?? '';
	}

	public triggerFilterChange(value: string) {
		if (this.isCompared) {
			this.updateMultipleData(value);
		} else {
			this.updateSingleData(value);
		}
	}

	public updateSingleData(value?: string) {
		if (this.singleData) {
			this.evidences = [...this.singleData.evidences];
			if (value) {
				this.evidences = this.singleData.evidences.filter(x => x?.description?.toLowerCase().trim().includes(value.toLowerCase()));
			}
		}
	}

	private updateMultipleData(filterValue?: string) {
		if (this.multipleData) {
			this.evidences = [];
			let from = this.multipleData.from.evidences;
			let to = this.multipleData.to.evidences;
			let howTo = this.multipleData.howTo.evidences;

			if (filterValue) {
				from = this.multipleData.from.evidences.filter(x => x?.description?.toLowerCase().trim().includes(filterValue.toLowerCase()));
				to = this.multipleData.to.evidences.filter(x => x?.description?.toLowerCase().trim().includes(filterValue.toLowerCase()));
				howTo = this.multipleData.howTo.evidences.filter(x => x?.description?.toLowerCase().trim().includes(filterValue.toLowerCase()));
			}

			for (let i = 0; i < Math.max(from.length, to.length, howTo.length); i++) {
				this.evidences.push(from[i] || {});
				this.evidences.push(to[i] || {});
				this.evidences.push(howTo[i] || {});
			}
		}
	}
}

import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { DefensebilityImplementationState } from '../../../../server/enums/defensebility-implementation-state.enum';
import { DefensebilityEvidenceViewModel } from '../../../../server/models/defensebility-evidence-view-model.model';
import { FilterConfig, SearchFilter } from '../../../../standalone/components/form-elements/filter/filter.model';

@Component({
	selector: 'rq-evidences-list',
	templateUrl: './evidences-list.component.html',
	styleUrls: ['./evidences-list.component.scss']
})
export class EvidencesListComponent implements OnInit {
	@Input()
	public evidences!: DefensebilityEvidenceViewModel[];

	@Input()
	public noOfColumns = 1;

	@Output()
	public readonly filterChange = new EventEmitter<string>();

	public filterConfig = new Array<FilterConfig>();

	public DefensebilityImplementationState = DefensebilityImplementationState;

	public ngOnInit() {
		const filter = new SearchFilter('global_description_label', 'global_description_label');
		this.filterConfig = [new FilterConfig('description', filter)];
	}

	public triggerFilterChange(value: string) {
		this.filterChange.emit(value);
	}
}

<rq-modal>
  <rq-modal-header>
    {{ 'global_resetPassword_label' | translate}}
  </rq-modal-header>
  <rq-modal-body e2e-test="reset-password-modal">
    {{ 'global_resetPassword_message' | translate}}
  </rq-modal-body>
  <rq-modal-footer>
    <div class="d-flex justify-content-end">
      <button (click)="closeModal()"
              e2e-test="reset-password-modal-continue-btn"
              class="text-uppercase btn-primary">
        {{ 'global_label_continue' | translate }}
      </button>
    </div>
  </rq-modal-footer>
</rq-modal>

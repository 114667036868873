import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { LegalEntityPreferenceModel } from '../../models/legal-entity-preference-model.model';

@Injectable()
export class LegalEntityPreferenceDataService {
	constructor(private httpService: RQHttpService) {}

	public get(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<LegalEntityPreferenceModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<LegalEntityPreferenceModel>('get', environment.api + '/api/Settings/LegalEntityPreference/Get', options)
			.toPromise();
	}

	public update(updateModel: LegalEntityPreferenceModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = updateModel;

		return this.httpService.request<void>('post', environment.api + '/api/Settings/LegalEntityPreference/Update', options).toPromise();
	}
}

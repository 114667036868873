import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PagerConfig, PaginationInfo, PaginationState } from './pager.ui-model';
import { DropdownOption } from '../form-elements/dropdown/dropdown.model';
import { Customizer } from '../../../shared/services/customizer';

@Component({
	selector: 'rq-pager',
	templateUrl: './pager.component.html',
	styleUrls: ['./pager.component.scss']
})
export class PagerComponent implements OnInit, OnChanges {
	@Input()
	public state = new PaginationState();

	@Input()
	public count!: number;

	@Input()
	public isDisabled!: boolean;

	@Input()
	public config?: PagerConfig;

	@Output()
	public readonly pageChange = new EventEmitter<PaginationState>();

	public paginationInfo!: PaginationInfo;

	public initialTake!: number;

	public dropdownOptions!: DropdownOption<number>[];

	constructor(private customizer: Customizer) {}

	public get isLeftLabelVisible() {
		return Boolean(this.config?.label);
	}

	public get isPagerContainerVisible() {
		return this.isPaginationVisible || this.isLeftLabelVisible || this.isPageSizerVisible;
	}

	public get isPaginationVisible() {
		return Boolean(this.paginationInfo && this.count && this.count > this.state.take);
	}

	public get isPageSizerVisible() {
		return Boolean(this.paginationInfo && this.count && this.count > this.initialTake && this.state && !this.config?.isPagerSizeHidden);
	}

	public get isLeftBtnDisabled() {
		return this.paginationInfo.currentPage === 1 || this.isDisabled;
	}

	public get isRightBtnDisabled() {
		return this.paginationInfo.currentPage === this.paginationInfo.totalPages || this.isDisabled;
	}

	public ngOnInit(): void {
		if (!this.state) {
			throw new Error(`${PagerComponent.name} requires a config input with a state property`);
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.count !== undefined) {
			this.state.skip = 0;

			this.paginate();
		}

		if (changes.state) {
			this.initPageSizer();
		}
	}

	public goToPage(page: number) {
		if (page > 0 && !this.isDisabled) {
			this.state.skip = page * this.state.take - this.state.take;

			this.paginate();

			this.pageChange.emit(this.state);
		}
	}

	public changePageSize(pageSize: number) {
		if (this.state.take === pageSize) {
			return;
		}
		this.paginationInfo.pageSize = pageSize;
		this.state.take = pageSize;
		this.state.skip = 0;
		this.paginate();
		this.pageChange.emit(this.state);
	}

	public reset() {
		this.state.skip = 0;
		this.paginate();
	}

	public triggerReset() {
		this.reset();
		this.pageChange.emit(this.state);
	}

	private initPageSizer() {
		this.initialTake = this.state.take;

		const pageWord = this.customizer.translate('global_page');

		this.dropdownOptions = [
			new DropdownOption<number>(`${this.state.take} / ${pageWord}`, this.state.take),
			new DropdownOption<number>(`${2 * this.state.take} / ${pageWord}`, 2 * this.state.take),
			new DropdownOption<number>(`${3 * this.state.take} / ${pageWord}`, 3 * this.state.take)
		];
	}

	private paginate(maxPages = 5) {
		if (this.count > this.state.take) {
			const totalPages = Math.ceil(this.count / this.state.take);
			const currentPage = this.getCurrentPage(totalPages);

			let startPage: number, endPage: number;
			if (totalPages <= maxPages) {
				startPage = 1;
				endPage = totalPages;
			} else {
				// total pages more than max so calculate start and end pages
				const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
				const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
				if (currentPage <= maxPagesBeforeCurrentPage) {
					startPage = 1;
					endPage = maxPages;
				} else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
					startPage = totalPages - maxPages + 1;
					endPage = totalPages;
				} else {
					startPage = currentPage - maxPagesBeforeCurrentPage;
					endPage = currentPage + maxPagesAfterCurrentPage;
				}
			}

			const startIndex = (currentPage - 1) * this.state.take;
			const endIndex = Math.min(startIndex + this.state.take - 1, this.count - 1);
			const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(i => startPage + i);

			if (!pages.includes(totalPages)) {
				pages[pages.length - 1] = totalPages;
				pages[pages.length - 2] = -1;
			}

			if (!pages.includes(1)) {
				pages[0] = 1;
				pages[1] = -1;
			}

			this.paginationInfo = <PaginationInfo>{
				totalItems: this.count,
				pageSize: this.state.take,
				currentPage,
				totalPages,
				startPage,
				endPage,
				startIndex,
				endIndex,
				pages
			};
		}
	}

	private getCurrentPage(totalPages: number) {
		let currentPage = Math.floor(this.state.skip / this.state.take) + 1;

		if (currentPage < 1) {
			currentPage = 1;
		} else if (currentPage > totalPages) {
			currentPage = totalPages;
		}

		return currentPage;
	}
}

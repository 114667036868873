import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ScenarioMitreQualitativeScalesModel } from '../../models/scenario-mitre-qualitative-scales-model.model';
import { ScenarioMitreConfigUpdateModel } from '../../models/scenario-mitre-config-update-model.model';

@Injectable()
export class ScenarioMitreQualitativeScaleDataService {
	constructor(private httpService: RQHttpService) {}

	public getByLegalEntityId(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<ScenarioMitreQualitativeScalesModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<ScenarioMitreQualitativeScalesModel>(
				'get',
				environment.api + '/api/Scenario/ScenarioMitreQualitativeScale/GetByLegalEntityId',
				options
			)
			.toPromise();
	}

	public update(updateModel: ScenarioMitreConfigUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = updateModel;

		return this.httpService
			.request<void>('put', environment.api + '/api/Scenario/ScenarioMitreQualitativeScale/Update', options)
			.toPromise();
	}
}

import { StandardBase } from '../../../routes/control-analysis/_common/standard.base';
import { Component, EventEmitter, Input, Output, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { LTRecommendationAnalysisViewModel } from '../../../routes/control-analysis/_common/models/recommendations-analysis-view.viewmodel';
import { AnalysisDetailsFilterModel } from '../../../server/models/analysis-details-filter-model.model';
import { ControlFrameworkDescriptionModel } from '../../../server/models/control-framework-description-model.model';
import { DefensebilityControlFilterViewModel } from '../../../server/models/defensebility-control-filter-view-model.model';
import { DefensebilityDeltaViewModel } from '../../../server/models/defensebility-delta-view-model.model';
import { ControlDefensebilityDataService } from '../../../server/services/configuration/control-defensebility-data.service';
import { ControlFrameworkDescriptionDataService } from '../../../server/services/configuration/control-framework-description-data.service';
import { AttackViewModel } from '../../../server/models/attack-view-model.model';
import { ControlFrameworkDataService } from '../../../server/services/configuration/control-framework-data.service';
import { PagerComponent } from '../../../standalone/components/pager/pager.component';
import { PaginationState } from '../../../standalone/components/pager/pager.ui-model';
import { FormatterType } from '../../enums/formatter-type.enum';
import { DropdownFilter, Filter, FilterConfig, FilterSelectOption } from '../../../standalone/components/form-elements/filter/filter.model';
import { COLORS } from 'src/app/standalone/utils/colors.utils';

@Component({
	selector: 'rq-recommendation-analysis-view',
	templateUrl: './recommendation-analysis-view.component.html',
	styleUrls: ['./recommendation-analysis-view.component.scss']
})
export class RecommendationAnalysisViewComponent extends StandardBase implements OnChanges {
	@ViewChild(PagerComponent)
	public pagerComponent!: PagerComponent;

	@Input()
	public items!: Array<LTRecommendationAnalysisViewModel>;

	@Input()
	public count!: number;

	@Input()
	public hasAnyRecommendation!: boolean;

	@Input()
	public attacks!: Array<AttackViewModel>;

	@Input()
	public initialAttackId?: string;

	@Output()
	public readonly filterOrPageChangeEvent = new EventEmitter<{ pagination: PaginationState; filter: AnalysisDetailsFilterModel }>();

	public paginationState: PaginationState = { skip: 0, take: 5 };

	public filter!: AnalysisDetailsFilterModel;

	public filterConfig = new Array<FilterConfig>();

	public selectedItem?: LTRecommendationAnalysisViewModel;

	public enterpriseControlsGradient!: string;

	public enterpriseLevelDescription!: Array<ControlFrameworkDescriptionModel>;

	public defensebilityDelta!: DefensebilityDeltaViewModel;

	private controlFrameworkId!: string;

	constructor(
		private controlDefensebilityDataService: ControlDefensebilityDataService,
		private controlFrameworkDataService: ControlFrameworkDataService,
		private controlFrameworkDescriptionDataService: ControlFrameworkDescriptionDataService
	) {
		super();
	}

	public get isMessageVisible() {
		return !this.hasAnyRecommendation || !this.count;
	}

	public get formatterType() {
		return FormatterType;
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.initialAttackId?.currentValue && this.attacks) {
			this.setupFilters(changes.initialAttackId?.currentValue as string, this.attacks);
		}
	}

	public pageChange() {
		this.triggerDataChange();
	}

	public filterChange() {
		this.paginationState = { skip: 0, take: 5 };
		if (this.pagerComponent) {
			this.pagerComponent?.triggerReset();
		} else {
			this.triggerDataChange();
		}
	}

	public async selectItem(item: LTRecommendationAnalysisViewModel) {
		this.selectedItem = item;

		await this.getDefensibilityEvidence(this.selectedItem);
		await this.getAllFrameworks();
		this.triggerGradientColorChange(item.controlImprovements.oldValue, item.controlImprovements.proposedValue);
		this.enterpriseLevelDescription = await this.controlFrameworkDescriptionDataService.getByFramework(this.controlFrameworkId);
	}

	private async getAllFrameworks() {
		const enterpriseFrameworkModel = await this.controlFrameworkDataService.getAll();
		const defaultFramework = enterpriseFrameworkModel.find(item => item.isDefault === true);
		if (defaultFramework) {
			this.controlFrameworkId = defaultFramework.id;
		}
	}

	private async getDefensibilityEvidence(item: LTRecommendationAnalysisViewModel) {
		const filter = new DefensebilityControlFilterViewModel();
		filter.controlOptionId = item.controlOptionId;
		filter.fromLevel = parseFloat(item.controlImprovements.oldValue);
		filter.toLevel = parseFloat(item.controlImprovements.proposedValue);

		this.defensebilityDelta = await this.controlDefensebilityDataService.getDelta(filter);
	}

	private triggerGradientColorChange(oldValue: string, proposedValue: string) {
		const gradient = `linear-gradient(to right, ${this.getControlType(parseInt(oldValue.split('.')[0]))}, ${this.getControlType(
			parseInt(proposedValue.split('.')[0])
		)})`;
		this.enterpriseControlsGradient = gradient;
	}

	private getControlType(value: number) {
		const option = new Map<number, string>([
			[0, COLORS.neutralContrastBackground],
			[1, COLORS.dangerBackground],
			[2, COLORS.warningBackground],
			[3, COLORS.royalBackground],
			[4, COLORS.infoBackground],
			[5, COLORS.successBackground]
		]);
		const color = option.get(value);

		if (!color) {
			return COLORS.neutralContrastBackground;
		}

		return color;
	}

	private triggerDataChange() {
		this.selectedItem = undefined;
		this.filterOrPageChangeEvent.emit({ pagination: this.paginationState, filter: this.filter });
	}

	private setupFilters(attackId: string, attacks: Array<AttackViewModel>) {
		this.filter = new AnalysisDetailsFilterModel();
		this.filter.attackId = new Filter<string>(attackId);

		const filter = new DropdownFilter();
		filter.displayName = 'global_attack_label';
		filter.options = attacks.map(x => new FilterSelectOption<string>(x.name, x.id));
		filter.options.forEach(x => (x.isSelected = x.value === this.filter.attackId.value));

		this.filterConfig = [new FilterConfig('attackId', filter)];
	}
}

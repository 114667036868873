import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ScenarioAnalysisStartModel } from '../../models/scenario-analysis-start-model.model';
import { RiskAnalysisDone } from '../../models/risk-analysis-done.model';
import { ScenarioMLConfigDetailsViewModel } from '../../models/scenario-mlconfig-details-view-model.model';
import { ScenarioMLOverviewViewModel } from '../../models/scenario-mloverview-view-model.model';
import { ScenarioMLFinancialDataViewModel } from '../../models/scenario-mlfinancial-data-view-model.model';

@Injectable()
export class ScenarioAnalysisMLDataService {
	constructor(private httpService: RQHttpService) {}

	public startAnalysis(analysis: ScenarioAnalysisStartModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = analysis;

		return this.httpService
			.request<string>('post', environment.api + '/api/Scenario/ScenarioAnalysisML/StartAnalysis', options)
			.toPromise();
	}

	public saveAnalysis(output: RiskAnalysisDone, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = output;

		return this.httpService.request<void>('post', environment.api + '/api/Scenario/ScenarioAnalysisML/SaveAnalysis', options).toPromise();
	}

	public getConfigurationDetails(scenarioId: string, options = new HttpOptionsViewModel()): Promise<ScenarioMLConfigDetailsViewModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<ScenarioMLConfigDetailsViewModel>(
				'get',
				environment.api + '/api/Scenario/ScenarioAnalysisML/GetConfigurationDetails',
				options
			)
			.toPromise();
	}

	public getSummary(scenarioId: string, options = new HttpOptionsViewModel()): Promise<ScenarioMLOverviewViewModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<ScenarioMLOverviewViewModel>('get', environment.api + '/api/Scenario/ScenarioAnalysisML/GetSummary', options)
			.toPromise();
	}

	public getFinancials(scenarioId: string, options = new HttpOptionsViewModel()): Promise<ScenarioMLFinancialDataViewModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<ScenarioMLFinancialDataViewModel>('get', environment.api + '/api/Scenario/ScenarioAnalysisML/GetFinancials', options)
			.toPromise();
	}
}

<rq-modal>
  <rq-modal-header>
    {{'global_clone_label' | translate}}
  </rq-modal-header>

  <rq-modal-body>

    <form *ngIf='form'
          [formGroup]='form'>
      <rq-form-control size='sm'
                       class='m-b-lg'
                       [options]="{validation: {isUnique: 'integration_nameValidation_unique'}}">
        <h3 class='primary-color'>{{'global_name_label' | translate}}</h3>
        <rq-input type='text'
                  e2e-test="clone-modal-input-name"
                  placeholder="{{'global_name_label' | translate}}"
                  class='form-control'
                  formControlName='name'>
        </rq-input>
      </rq-form-control>

    </form>

    <footer class='d-flex justify-content-between modal-footer'>
      <section>
        <button type='button'
                (click)='close()'
                class='btn-secondary'>{{'global_label_cancel' |
                    translate}}</button>
      </section>

      <section class='text-right'>
        <button class='btn-primary'
                #submit
                [rqLoadingInline]='{isDisabledOnError: true, isInnerLoading: true}'
                e2e-test="clone-modal-save-btn"
                type='button'
                (click)='save()'>{{'global_clone_label' | translate}}</button>
      </section>
    </footer>
  </rq-modal-body>
</rq-modal>

import { AfterContentInit, Component, ContentChild, HostBinding, Input, OnDestroy, OnInit, ElementRef } from '@angular/core';
import { FormControlName, ValidationErrors } from '@angular/forms';
import { SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { FormControlConfig } from './form-control.model';
import { TranslateParam } from '../../../pipes/models/translate.model';
import { Customizer } from '../../../../shared/services/customizer';

@Component({
	selector: 'rq-form-control',
	templateUrl: './form-control.component.html',
	styleUrls: ['./form-control.component.scss']
})
export class FormControlComponent implements OnInit, AfterContentInit, OnDestroy {
	@Input()
	public options!: FormControlConfig;

	@Input()
	public size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'xl';

	@ContentChild(FormControlName)
	public control!: FormControlName;

	public error!: SafeHtml;

	private activeSize?: string;

	private observer!: MutationObserver;

	private subscriptions: Subscription[] = [];

	private element: HTMLElement;

	constructor(private customizer: Customizer, public elementRef: ElementRef) {
		this.element = elementRef.nativeElement as HTMLElement;
	}

	@HostBinding('class.ng-touched')
	public get ngClassTouched(): boolean | null {
		if (!this.control) {
			return false;
		}

		if (this.control.touched) {
			this.updateErrorMessage();
		}
		return this.control.touched;
	}

	@HostBinding('class.ng-invalid')
	public get ngClassInvalid(): boolean | null {
		return this.control ? this.control.invalid : false;
	}

	@HostBinding('class.ng-valid')
	public get ngClassValid(): boolean | null {
		return this.control ? this.control.valid : true;
	}

	public ngOnInit(): void {
		this.setupDimension(this.size, 'form-control');
	}

	public ngAfterContentInit(): void {
		this.validate();

		this.handleErrorUpdate();

		this.handleContentChange();
	}

	public ngOnDestroy(): void {
		this.observer.disconnect();

		this.subscriptions.forEach(s => s.unsubscribe());
	}

	private setupDimension(size: string, prefix: string) {
		if (this.activeSize) {
			this.element.classList.remove(`${prefix}-${this.activeSize}`);
		}

		this.activeSize = size;

		this.element.classList.add(`${prefix}-${size}`);
	}

	private handleContentChange() {
		this.observer = new MutationObserver(() => {
			this.setClass('input[type="checkbox"]', 'form-checkbox-label');
			this.setClass('input[type="radio"]', 'form-radio-label');
		});

		this.observer.observe(this.element, {
			attributes: true,
			characterData: false,
			childList: false,
			subtree: false,
			attributeOldValue: false,
			characterDataOldValue: false
		});
	}

	private setClass(selector: string, className: string) {
		const elements = this.element.querySelectorAll(selector);

		if (elements && elements.length > 0) {
			elements.forEach(element => {
				const label = <HTMLLabelElement>element.parentElement;

				label.classList.add(className);
			});
		}
	}

	private handleErrorUpdate() {
		if (this.control.statusChanges !== null) {
			this.subscriptions.push(
				this.control.statusChanges.subscribe(() => {
					this.updateErrorMessage();
				})
			);
		}
	}

	private updateErrorMessage(): void {
		const errors: ValidationErrors | null = this.control.errors;
		if (errors && this.control.touched) {
			const errorKeys = Object.keys(errors);
			if (errorKeys.length > 0) {
				const errorKey = errorKeys[0];

				const translateKey = this.options?.validation?.[errorKey]
					? this.getMessage(this.options.validation[errorKey])
					: `form_validation_${errorKey}`;

				this.error = this.customizer.translate(
					translateKey,
					Object.assign({}, { field: this.control.name }, errors[errorKey]) as TranslateParam
				);
			}
		} else {
			this.error = '';
		}
	}

	private validate() {
		if (!this.control) {
			throw new Error('Attribute form control name not present');
		}
	}

	private getMessage(validationElement: string | (() => string)) {
		return typeof validationElement === 'function' ? validationElement() : validationElement;
	}
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { IntegrationWindowsDefenderSettingsModel } from '../../models/integration-windows-defender-settings-model.model';
import { IntegrationWindowsDefenderSettingsCreateModel } from '../../models/integration-windows-defender-settings-create-model.model';
import { IntegrationWindowsDefenderSettingsUpdateModel } from '../../models/integration-windows-defender-settings-update-model.model';

@Injectable()
export class IntegrationWindowsDefenderSettingsDataService {
	constructor(private httpService: RQHttpService) {}

	public getByIntegrationId(integrationId: string, options = new HttpOptionsViewModel()): Promise<IntegrationWindowsDefenderSettingsModel> {
		options.params = this.httpService.getHttpParams({
			integrationId
		});

		return this.httpService
			.request<IntegrationWindowsDefenderSettingsModel>(
				'get',
				environment.api + '/api/Integration/IntegrationWindowsDefenderSettings/GetByIntegrationId',
				options
			)
			.toPromise();
	}

	public create(createModel: IntegrationWindowsDefenderSettingsCreateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = createModel;

		return this.httpService
			.request<void>('post', environment.api + '/api/Integration/IntegrationWindowsDefenderSettings/Create', options)
			.toPromise();
	}

	public update(updateModel: IntegrationWindowsDefenderSettingsUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = updateModel;

		return this.httpService
			.request<void>('post', environment.api + '/api/Integration/IntegrationWindowsDefenderSettings/Update', options)
			.toPromise();
	}

	public isConnectionValid(settings: IntegrationWindowsDefenderSettingsModel, options = new HttpOptionsViewModel()): Promise<boolean> {
		options.body = settings;

		return this.httpService
			.request<boolean>('post', environment.api + '/api/Integration/IntegrationWindowsDefenderSettings/IsConnectionValid', options)
			.toPromise();
	}
}

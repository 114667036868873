import { animate, state, style, transition, trigger } from '@angular/animations';
import {
	AfterContentInit,
	Component,
	ContentChildren,
	EventEmitter,
	HostBinding,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	TemplateRef
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { AccordionItemConfig } from './accordion-item-config.model';

@Component({
	selector: 'rq-accordion',
	templateUrl: './accordion.component.html',
	styleUrls: ['./accordion.component.scss'],
	animations: [
		trigger('slideInOut', [
			state(
				'visible',
				style({
					overflow: 'visible',
					height: '*'
				})
			),
			state(
				'collapsed',
				style({
					opacity: '0',
					overflow: 'hidden',
					height: '0px'
				})
			),
			transition('visible => collapsed', animate('400ms ease-in-out')),
			transition('collapsed => visible', animate('400ms ease-in-out'))
		])
	]
})
export class AccordionComponent implements OnInit, OnDestroy, OnChanges, AfterContentInit {
	@Input()
	public items: AccordionItemConfig[] = [];

	@Input()
	public change: Subject<boolean> = new Subject();

	@Output()
	public readonly toggleChange = new EventEmitter<AccordionItemConfig>();

	@ContentChildren('accordion')
	public templates!: QueryList<TemplateRef<unknown>>;

	@ContentChildren('title')
	public titleTemplates!: QueryList<TemplateRef<unknown>>;

	@HostBinding('class.accordion')
	public hasContainerClass = true;

	public changeSubscription!: Subscription;

	public ngOnInit(): void {
		this.changeSubscription = this.change.subscribe(_ => this.setup());
	}

	public ngAfterContentInit() {
		this.setup();
	}

	public ngOnChanges() {
		this.setup();
	}

	public ngOnDestroy(): void {
		this.changeSubscription.unsubscribe();
	}

	public toggleState(item: AccordionItemConfig) {
		if (item.state === 'disabled') {
			return;
		}

		item.state = item.state === 'collapsed' ? 'visible' : 'collapsed';

		this.items.forEach(iteratedItem => {
			if (iteratedItem !== item && iteratedItem.state !== 'disabled') {
				iteratedItem.state = 'collapsed';
			}
		});

		this.toggleChange.emit(item);
	}

	public hasTitleTemplate(item: AccordionItemConfig) {
		return item.titleTemplate !== undefined;
	}

	private setup() {
		if (this.templates) {
			const templates = this.templates.toArray();
			const titleTemplates = this.titleTemplates.toArray();

			this.items.forEach((item, index) => {
				item.templateRef = templates[index];
				item.state = item.state ? item.state : 'collapsed';
				if (titleTemplates[index]) {
					item.titleTemplate = titleTemplates[index];
				}
			});
		}
	}
}

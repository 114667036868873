import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FilterOutput } from '../../form-elements/filter/filter.model';

@Injectable()
export class TableService {
	public preselectedChanged$ = new Subject();

	public filterSetupChanged$ = new Subject();

	public setSortingState(name: string, filterOutput: FilterOutput) {
		Object.entries(filterOutput).forEach(entity => {
			const filter = entity[1];
			if (entity[0] !== name) {
				filter.isSortedAscending = undefined;
			} else {
				filter.isSortedAscending = this.getSortState(filter.isSortedAscending);
			}
		});
	}

	private getSortState(isSortedAscending: boolean | undefined) {
		const values = new Map<boolean | undefined, boolean | undefined>();
		values.set(true, false);
		values.set(false, undefined);
		values.set(undefined, true);

		return values.get(isSortedAscending);
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { ProviderType } from 'src/app/server/enums/provider-type.enum';

@Pipe({
	name: 'providerTypeLabel'
})
export class ProviderTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: ProviderType) {
		const values = new Map<ProviderType, string>();
		values.set(ProviderType.RQ, ProviderType[ProviderType.RQ].toString());
		values.set(ProviderType.Snowflake, 'global_snowflake_label');
		values.set(ProviderType.Surecloud, 'integration_createDataProvider_sureCloud');
		values.set(ProviderType.AppSoc, 'global_appsoc_label');
		values.set(ProviderType.TenableApi, 'tenable_api_label');
		values.set(ProviderType.TenableUpload, 'tenable_upload_label');
		values.set(ProviderType.Qualys, 'integration_createDataProvider_qualys');
		values.set(ProviderType.Rapid7, 'integration_createDataProvider_rapid7');
		values.set(ProviderType.Archer, 'integration_createDataProvider_archer');
		values.set(ProviderType.OpenVas, 'integration_createDataProvider_openVas');
		values.set(ProviderType.WindowsDefender, 'global_windows_defender_label');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${value} is not handled`);
		}

		return this.translationService.get(type);
	}
}

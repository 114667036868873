import { TemplateRef } from '@angular/core';
import { ComparisonList } from './comparison-list/comparison-list.model';
import { ComparisonTable } from './comparison-table/comparison-table.model';
import { ComparisonCollapsible } from './comparison-collapsible/comparison-collapsible.model';
import { ThemeColorsType } from '../../types/theme-colors.type';

export class ComparisonCard {
	public title!: ComparisonTitle;

	public value!: ComparisonList | ComparisonTable | ComparisonCollapsible;
}

export class ComparisonTitle {
	constructor(public left?: string, public right?: string) {}
}

export class ComparisonRowThemeConfig {
	public theme?: ThemeColorsType;

	public icon?: ComparisonIcon;
}

export class ComparisonRowThemeBase {
	public icon?: ComparisonIcon;

	public theme?: ThemeColorsType;

	protected setupConfig(config: ComparisonRowThemeConfig | undefined) {
		if (config) {
			this.icon = config.icon;
			this.theme = config.theme;
		}
	}
}

export class ComparisonRowBase extends ComparisonRowThemeBase {
	public isRowHoverDisabled = false;

	public isHighlighted = false;

	public hasMoreDetails = false;

	public isBolded = false;

	protected setupConfig(config: ComparisonRowBaseConfig | undefined) {
		if (config) {
			this.isRowHoverDisabled = typeof config.isRowHoverDisabled !== 'undefined' ? config.isRowHoverDisabled : false;
			this.isHighlighted = typeof config.isHighlighted !== 'undefined' ? config.isHighlighted : false;
			this.hasMoreDetails = typeof config.hasMoreDetails !== 'undefined' ? config.hasMoreDetails : false;
			this.isBolded = typeof config.isBolded !== 'undefined' ? config.isBolded : false;
		}

		super.setupConfig(config);
	}
}

export class ComparisonRowBaseConfig extends ComparisonRowThemeConfig {
	public isRowHoverDisabled?: boolean;

	public isHighlighted?: boolean;

	public isBolded?: boolean;

	public hasMoreDetails?: boolean;
}

export class ComparisonIcon {
	constructor(public name: string) {}
}

export class ComparisonTemplate extends ComparisonRowBase {
	constructor(public template: TemplateRef<unknown>, config?: ComparisonRowBaseConfig) {
		super();

		this.setupConfig(config);
	}
}

export enum ComparisonType {
	List = 1,
	Table = 2,
	Collapsible = 3
}

export class ExpandableTextModel {
	public showMoreText?: string;

	public showLessText?: string;

	public itemsToShow?: number;

	public itemsPerLine?: number;

	public expanded?: boolean;
}

export class ExpandableTextWordModel {
	public displayName?: string;

	public url?: string;

	public delimiter?: string;
}

export const EXPANDABLE_TEXT_CONFIG = {
	showMoreText: 'global_showMore_label',
	showLessText: 'global_showLess_label',
	itemsToShow: 3,
	itemsPerLine: 3,
	expanded: false
};

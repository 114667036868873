<div [ngClass]="isHighlighted ? getControlType(value) : 'control-disabled'">
  <div class="value-container">
    <span class="control-value"
          *ngIf="value !== 0; else showNotApplicable">
      {{ value }}
    </span>
    <ng-template #showNotApplicable>
      <span class="not-applicable-control">{{ 'global_notApplicable_label' | translate }}</span>
    </ng-template>
  </div>
</div>

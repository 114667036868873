import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
	LTRecommendationApplicationFilterConfigViewModel,
	LTRecommendationApplicationFilterViewModel,
	LTRecommendationApplicationViewModel
} from '../../../routes/control-analysis/_common/models/recommendation-application.model';
import { TranslationService } from '../../services/translation.service';
import { AttackViewModel } from '../../../server/models/attack-view-model.model';
import { TableBase2Component } from '../../../standalone/components/table/table-base2.component';
import { CurrencyConfig } from '../../models/number.config';
import { FormatterType } from '../../enums/formatter-type.enum';
import { DropdownFilter, FilterSelectOption, RangeFilter } from '../../../standalone/components/form-elements/filter/filter.model';
import { Customizer } from '../../services/customizer';
import { CustomFilter } from '../../../standalone/components/table/models/filter-setup.model';

@Component({
	selector: 'rq-recommendation-application-table',
	templateUrl: './recommendation-application-table.component.html',
	styleUrls: ['./recommendation-application-table.component.scss']
})
export class RecommendationApplicationTableComponent
	extends TableBase2Component<LTRecommendationApplicationViewModel, LTRecommendationApplicationFilterViewModel>
	implements OnChanges
{
	@Input()
	public isLegalEntityVisible!: boolean;

	@Input()
	public filterOptions!: LTRecommendationApplicationFilterConfigViewModel;

	@Input()
	public hasAnyRecommendation!: boolean;

	@Input()
	public attacks!: Array<AttackViewModel>;

	@Input()
	public initialAttackId!: string;

	public attackFilters!: CustomFilter[];

	public expectedLossReductionFilterSetup!: RangeFilter;

	public expectedProbabilityReductionFilterSetup!: RangeFilter;

	constructor(private customizer: Customizer, private translationService: TranslationService) {
		super();
	}

	public get isMessageVisible() {
		return !this.hasAnyRecommendation || (!this.count && !this.hasFiltersApplied);
	}

	private get currencyConfig(): CurrencyConfig {
		const currencyConfig = new CurrencyConfig();
		currencyConfig.isAbbr = true;
		currencyConfig.decimals = 1;
		currencyConfig.rounding = value => value;
		currencyConfig.symbol = this.customizer.currency;

		return currencyConfig;
	}

	public async ngOnChanges(changes: SimpleChanges) {
		await super.ngOnChanges(changes);

		if (changes.filterOptions?.currentValue) {
			this.setupFilters(changes.filterOptions.currentValue as LTRecommendationApplicationFilterConfigViewModel);
		}

		if (changes.initialAttackId?.currentValue && this.attacks && this.count > 0) {
			this.setupCustomFilters(changes.initialAttackId?.currentValue as string, this.attacks);
		}
	}

	private setupFilters(filterOptions?: LTRecommendationApplicationFilterConfigViewModel) {
		if (filterOptions) {
			this.expectedLossReductionFilterSetup = this.createRangeFilter(
				'recommendation_financialReduction_title',
				filterOptions.expectedLossReduction,
				FormatterType.Currency,
				this.currencyConfig
			);

			this.expectedProbabilityReductionFilterSetup = this.createRangeFilter(
				'recommendation_longTerm_psReduction',
				filterOptions.expectedLossProbabilityReduction,
				FormatterType.Percent,
				this.currencyConfig
			);
		}
	}

	private setupCustomFilters(attackId: string, attacks: Array<AttackViewModel>) {
		const filter = new DropdownFilter();
		filter.options = attacks.map(x => new FilterSelectOption<string>(x.name, x.id));
		filter.value = attackId;
		filter.displayName = this.translationService.get('global_attack_label');

		this.attackFilters = [
			{
				name: 'attackId',
				index: 0,
				filter
			}
		];
	}
}

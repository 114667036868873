export class CalendarDayModel {
	public day: number;

	public year: number;

	public month: number;

	public isToday?: boolean;

	public isAnotherMonth?: boolean;

	public disabled?: boolean;

	constructor(day: number, month: number, year: number) {
		this.day = day;
		this.year = year;
		this.month = month;
	}
}

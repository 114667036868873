import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserAccountStatusType } from '../../server/enums/user-account-status-type.enum';
import { TranslationService } from './translation.service';
import { UserEditViewModel, UserRoleViewModel } from '../models/user-edit-form.model';
import { CreateUserModel } from '../../server/models/create-user-model.model';
import { LegalEntityModel } from '../../server/models/legal-entity-model.model';
import { RoleModel } from '../../server/models/role-model.model';
import { UpdateUserModel } from '../../server/models/update-user-model.model';
import { UserModel } from '../../server/models/user-model.model';
import { UserDataService } from '../../server/services/identity/user-data.service';
import {
	emailCharValidator,
	emailValidator,
	errorValidator,
	noWhiteSpaceAllowedValidator
} from '../../standalone/utils/form-validators.utils';
import { FilterSelectGroup, FilterSelectOption } from '../../standalone/components/form-elements/filter/filter.model';

@Injectable({
	providedIn: 'root'
})
export class UserManagementService {
	constructor(private translationService: TranslationService, private userDataService: UserDataService) {}

	public formControl(email: string) {
		return new UntypedFormControl(
			email,
			[Validators.required, emailValidator(), noWhiteSpaceAllowedValidator(), emailCharValidator(), Validators.maxLength(50)],
			[this.userManagementEmailValidator(email)]
		);
	}

	public userAccountStatusOptions(accountStatuses: UserAccountStatusType[]) {
		const list = [
			<FilterSelectOption<null | UserAccountStatusType>>{
				displayName: this.translationService.get('global_all_label'),
				value: null
			}
		];

		accountStatuses.forEach(status =>
			list.push(<FilterSelectOption<UserAccountStatusType>>{
				displayName: this.userAccountStatusLabel(status),
				value: status
			})
		);

		return list;
	}

	public userRoleOptions(userRoles: RoleModel[]) {
		const list: FilterSelectOption<string>[] = [];

		userRoles.forEach(role =>
			list.push(<FilterSelectOption<string>>{
				displayName: role.displayName,
				value: role.name
			})
		);

		return [<FilterSelectGroup<string>>{ options: list }];
	}

	public userAccountStatusLabel(status: UserAccountStatusType) {
		let statusLabel = '';

		switch (status) {
			case UserAccountStatusType.Active:
				statusLabel = 'settings_user_management_table_active_label';
				break;
			case UserAccountStatusType.Locked:
				statusLabel = 'global_locked_label';
				break;
			case UserAccountStatusType.Deactivated:
				statusLabel = 'settings_user_management_table_deactivated_label';
				break;
			default:
				throw new Error(`${status} is not handled`);
		}

		return this.translationService.get(statusLabel);
	}

	public createUser(user: UserModel, defaultRoles: UserRoleViewModel[]) {
		const userModel = new UserEditViewModel();
		userModel.id = user.id;
		userModel.name = user.name;
		userModel.title = user.title;
		userModel.email = user.email;
		userModel.roles = this.combineUserRoles(defaultRoles, user.roles);
		userModel.providerUserId = user.providerUserId;
		userModel.hasDefaultRoleForSSOLogin = user.hasDefaultRoleForSSOLogin;

		return userModel;
	}

	public createCreateUserModel(user: UserEditViewModel) {
		const newUser: CreateUserModel = new CreateUserModel();

		newUser.name = user.name;
		newUser.title = user.title;
		newUser.email = user.email;
		newUser.roles = user.roles;

		return newUser;
	}

	public createUpdateUserModel(userId: string, userData: UserEditViewModel, isActive: boolean) {
		const updatedUser = new UpdateUserModel();

		updatedUser.id = userId;
		updatedUser.name = userData.name;
		updatedUser.title = userData.title;
		updatedUser.isActive = isActive;
		updatedUser.roles = userData.roles;

		return updatedUser;
	}

	public userLegalEntityOptions(legalEntities: LegalEntityModel[]) {
		const list = [
			<FilterSelectOption<string>>{
				displayName: this.translationService.get('global_notApplicable_label'),
				value: ''
			}
		];

		legalEntities.forEach(le =>
			list.push(<FilterSelectOption<string>>{
				displayName: le.displayName,
				value: le.id
			})
		);

		return [<FilterSelectGroup<string>>{ options: list }];
	}

	private userManagementEmailValidator(existingEmail: string) {
		return (control: AbstractControl) => {
			if (existingEmail && existingEmail === control.value) {
				return of(null);
			}

			return from(this.userDataService.isExistingUser(encodeURIComponent(control.value.trim() as string))).pipe(
				map(user => (!user ? null : { emailNotUnique: true })),
				catchError(errorValidator)
			);
		};
	}

	private combineUserRoles(roles1: UserRoleViewModel[], roles2: RoleModel[]): UserRoleViewModel[] {
		return roles1.map(r1 => {
			const user = Object.assign(new UserRoleViewModel(), r1);
			user.value = Boolean(roles2.find(r2 => r2.name === r1.name));

			return user;
		});
	}
}

<header class="calendar-header">
  <div *ngIf="placeholder"
       class="placeholder b b-bottom">
    <span>
      {{placeholder}}
    </span>
  </div>
  <div class="month-selector">
    <span class="pointer float-left"
          (click)="goPrevMonth()">
      <rq-icon href="icon-arrow-chevron-left"></rq-icon>
    </span>
    <span>{{activeMonth}} {{activeYear}}</span>
    <span class="pointer float-right"
          (click)="goNextMonth()">
      <rq-icon href="icon-arrow-chevron-right"></rq-icon>
    </span>
  </div>
</header>
<article class="calendar-body">
  <header class="calendar-week-days b b-bottom d-flex">
    <div class="calendar-item-container d-flex"
         *ngFor="let weekName of weekDays">
      <div class="calendar-week-days-item d-flex align-items-center justify-content-center">{{weekName}}</div>
    </div>
  </header>
  <section>
    <div class="calendar-row"
         *ngFor="let days of rows">
      <div class="calendar-item-container d-flex align-items-center justify-content-center"
           *ngFor="let day of days">
        <div class="calendar-item d-flex align-items-center justify-content-center w-100"
             (click)="onDateSelection(day)"
             [class.calendar-highlight-item]="isInside(day)"
             [class.hovered]="isHovered(day)"
             [class.calendar-active-item]="isRange(day)"
             [class.another-month]="day.isAnotherMonth"
             [class.today]="day.isToday"
             [class.disabled]="day.disabled"
             (mouseenter)="setHoveredDate(day)"
             (mouseleave)="setHoveredDate(undefined)">
          {{ day.day }}
        </div>
      </div>
    </div>
  </section>
</article>

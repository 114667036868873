<article>
  <div class="notification"
       *ngFor="let notification of notifications">
    <section class="notification-container b">
      <section class="notification-icon error-icon-container m-r-md"
               *ngIf="notification.type === notificationType.Error">
        <rq-icon href="icon-info-circle"
                 color="color-danger"></rq-icon>
      </section>
      <section class="notification-content-wrapper">
        <p class="notification-title text-sm">
          {{ notification.title }}
        </p>
        <p class="notification-content primary-l-10-color"
           [innerHtml]="notification.message"></p>
      </section>
      <rq-icon class="icon-cancel"
               href="icon-close"
               color="color-primary"
               (click)="dismissNotification(notification)"></rq-icon>
    </section>
  </div>
</article>

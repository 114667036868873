import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HomeService } from './shared/services/recheck/home.service';
import { AnalyticsService } from './shared/services/analytics.service';
import { ApplicationInstanceStore } from './shared/core/application.service';

@Component({
	selector: 'rq-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	private subscriptions: Subscription[] = [];

	constructor(
		private router: Router,
		private homeService: HomeService,
		private analyticsService: AnalyticsService,
		private applicationService: ApplicationInstanceStore
	) {}

	public async ngOnInit() {
		this.subscribeToEvents();
		this.applicationService.init();
		await this.analyticsService.setup();
	}

	public ngOnDestroy(): void {
		this.subscriptions.forEach(x => x.unsubscribe());
	}

	private subscribeToEvents() {
		this.subscriptions.push(
			this.router.events.subscribe(event => {
				if (event instanceof NavigationEnd) {
					this.navigateHome(event);
				}
			})
		);
	}

	private navigateHome(event: NavigationEnd) {
		const rootRoute = '/';
		if (event.url === rootRoute) {
			this.homeService.navigateHome();
		}
	}
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoaderType } from './loader-type.enum';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	selector: 'rq-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, LoadingSpinnerComponent]
})
export class LoaderComponent {
	@Input()
	public type?: 'spinner' | 'line' | 'card' = LoaderType.Spinner;

	@Input()
	public lineHeight = '16px';

	public LoaderType = LoaderType;
}

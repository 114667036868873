import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from '../utils/helpers.utils';
import { CurrencyPipeConfig } from './models/currency-pipe.model';
import { NumberFormatterService } from '../../shared/services/number-formatter.service';
import { CurrencyConfig } from '../../shared/models/number.config';

@Pipe({
	name: 'rqCurrency',
	standalone: true
})
export class RqCurrencyPipe implements PipeTransform {
	constructor(private numberFormatter: NumberFormatterService) {}

	public transform(value?: number, config?: CurrencyPipeConfig): string {
		const currencyConfig = Object.assign(<CurrencyConfig>{}, config);

		return isNumber(value) ? this.numberFormatter.currency(<number>value, currencyConfig) : '';
	}
}

import { PipeTransform, Pipe } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { ApplicationLossExposureFilterType } from 'src/app/server/enums/application-loss-exposure-filter-type.enum';

@Pipe({
	name: 'businessAssetStatusTypeLabel'
})
export class BusinessAssetStatusTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: ApplicationLossExposureFilterType) {
		const values = new Map<ApplicationLossExposureFilterType, string>();
		values.set(ApplicationLossExposureFilterType.Provided, 'global_provided_label');
		values.set(ApplicationLossExposureFilterType.NotProvided, 'global_notProvided_label');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${ApplicationLossExposureFilterType[value]} does not exist`);
		}

		return this.translationService.get(type);
	}
}

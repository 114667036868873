<header></header>
<article>
  <div class="y-axis-title"></div>
  <ul class="chart">
    <li>
      <span></span>
      <span></span>
    </li>
    <li>
      <span></span>
      <span></span>
    </li>
    <li>
      <span></span>
      <span></span>
    </li>
    <li>
      <span></span>
      <span></span>
    </li>
    <li>
      <span></span>
      <span></span>
    </li>
  </ul>
</article>
<footer></footer>

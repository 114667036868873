import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { IntegrationType } from '../../../server/enums/integration-type.enum';

@Pipe({
	name: 'integrationTypeLabel'
})
export class IntegrationTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: IntegrationType) {
		const values = new Map<IntegrationType, string>();
		values.set(IntegrationType.Rapid7, 'integration_createDataProvider_rapid7');
		values.set(IntegrationType.Tenable, 'integration_createDataProvider_tenable');
		values.set(IntegrationType.Qualys, 'integration_createDataProvider_qualys');
		values.set(IntegrationType.Archer, 'integration_createDataProvider_archer');
		values.set(IntegrationType.SureCloud, 'integration_createDataProvider_sureCloud');
		values.set(IntegrationType.OpenVAS, 'integration_createDataProvider_openVas');
		values.set(IntegrationType.BeyondTrust, 'integration_createDataProvider_beyondTrust');
		values.set(IntegrationType.SecurityScorecard, 'global_securityScorecard_label');
		values.set(IntegrationType.Snowflake, 'global_snowflake_label');
		values.set(IntegrationType.AppSoc, 'global_appsoc_label');
		values.set(IntegrationType.WindowsDefender, 'global_windows_defender_label');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${value} is not handled`);
		}

		return this.translationService.get(type);
	}
}

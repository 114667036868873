import { AnalysisInfoCardValueModel } from './analysis-info-card.model';
import { LossSubTypeModel } from '../../../../server/models/loss-sub-type-model.model';
import { BarChartConfig, BarChartSeries } from '../../../../standalone/components/charts/_common/models/bar-chart.model';
import { DonutChart } from '../../../../standalone/components/charts/donut-chart/donut-chart.model';

export class DashboardRisksModel {
	public attackId!: string;

	public applicationName!: string;

	public costOfAttack!: string;

	public pos!: string;

	public rateOfIncidenceValues!: AnalysisInfoCardValueModel[];

	public totalRecords?: string;

	public donutChartSeries!: DonutChart[];

	public barChartSeries!: BarChartSeries[];

	public barChartConfig!: BarChartConfig;
}

export class LossSubTypeCustomViewModel extends LossSubTypeModel {
	public customType!: string;
}

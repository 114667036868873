import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { ControlProfileDefinitionModel } from '../../models/control-profile-definition-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { ControlProfileFilterModel } from '../../models/control-profile-filter-model.model';
import { ControlProfileBaseModel } from '../../models/control-profile-base-model.model';
import { ProviderType } from '../../enums/provider-type.enum';
import { ControlProfileCreateModel } from '../../models/control-profile-create-model.model';
import { IActionResult } from '../../models/iaction-result.model';
import { ControlProfileUpdateModel } from '../../models/control-profile-update-model.model';

@Injectable()
export class ControlProfileDataService {
	constructor(private httpService: RQHttpService) {}

	public getControlProfiles(
		legalEntityId: string,
		pagination: PaginationFilter,
		filter: ControlProfileFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<ControlProfileDefinitionModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<ControlProfileDefinitionModel>>(
				'get',
				environment.api + '/api/Configuration/ControlProfile/GetControlProfiles',
				options
			)
			.toPromise();
	}

	public getById(controlProfileId: string, options = new HttpOptionsViewModel()): Promise<ControlProfileBaseModel> {
		options.params = this.httpService.getHttpParams({
			controlProfileId
		});

		return this.httpService
			.request<ControlProfileBaseModel>('get', environment.api + '/api/Configuration/ControlProfile/GetById', options)
			.toPromise();
	}

	public getByName(
		legalEntityId: string,
		controlProfileName: string,
		options = new HttpOptionsViewModel()
	): Promise<ControlProfileBaseModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			controlProfileName
		});

		return this.httpService
			.request<ControlProfileBaseModel>('get', environment.api + '/api/Configuration/ControlProfile/GetByName', options)
			.toPromise();
	}

	public getByProviderId(
		providerId: string,
		providerType: ProviderType,
		options = new HttpOptionsViewModel()
	): Promise<ControlProfileBaseModel> {
		options.params = this.httpService.getHttpParams({
			providerId,
			providerType
		});

		return this.httpService
			.request<ControlProfileBaseModel>('get', environment.api + '/api/Configuration/ControlProfile/GetByProviderId', options)
			.toPromise();
	}

	public create(
		legalEntityId: string,
		controlProfileCreate: ControlProfileCreateModel,
		options = new HttpOptionsViewModel()
	): Promise<string> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		options.body = controlProfileCreate;

		return this.httpService.request<string>('post', environment.api + '/api/Configuration/ControlProfile/Create', options).toPromise();
	}

	public update(controlProfileUpdate: ControlProfileUpdateModel, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.body = controlProfileUpdate;

		return this.httpService
			.request<IActionResult>('put', environment.api + '/api/Configuration/ControlProfile/Update', options)
			.toPromise();
	}

	public updateDefault(legalEntityId: string, controlProfileId: string, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			controlProfileId
		});

		return this.httpService.request<void>('put', environment.api + '/api/Configuration/ControlProfile/UpdateDefault', options).toPromise();
	}

	public delete(controlProfileId: string, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.params = this.httpService.getHttpParams({
			controlProfileId
		});

		return this.httpService
			.request<IActionResult>('delete', environment.api + '/api/Configuration/ControlProfile/Delete', options)
			.toPromise();
	}
}

import { PipeTransform, Pipe } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { ApplicationStatus } from 'src/app/server/enums/application-status.enum';
import { AttackGoalType } from 'src/app/server/enums/attack-goal-type.enum';

@Pipe({
	name: 'attackGoalTypeLabel'
})
export class AttackGoalTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: AttackGoalType) {
		const values = new Map<AttackGoalType, string>();
		values.set(AttackGoalType.Espionage, 'attackGoalType_label_Espionage');
		values.set(AttackGoalType.Financial, 'attackGoalType_label_Financial');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${ApplicationStatus[value]} does not exist`);
		}

		return this.translationService.get(type);
	}
}

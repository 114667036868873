<ng-container *ngIf="type === LoaderType.Spinner">
  <rq-loading-spinner e2e-test="loader-spinner"
                      [size]="'100px'"></rq-loading-spinner>
</ng-container>

<ng-container *ngIf="type === LoaderType.Line">
  <div class="skeleton-line"
       e2e-test="loader-line"
       [style.height]="lineHeight"></div>
</ng-container>

<ng-container *ngIf="type === LoaderType.Card">
  <div class="skeleton-card">
    <div class="skeleton-card__title"></div>
    <div class="skeleton-card__content"></div>
  </div>
</ng-container>

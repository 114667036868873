import { TranslationService } from 'src/app/shared/services/translation.service';
import { PipeTransform, Pipe } from '@angular/core';
import { NotificationType } from '../../../server/enums/notification-type.enum';

@Pipe({
	name: 'notificationType'
})
export class NotificationTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: NotificationType) {
		const values = new Map<NotificationType, string>();
		values.set(NotificationType.Info, 'global_info_label');
		values.set(NotificationType.Error, 'global_error_label');
		values.set(NotificationType.Warning, 'global_warning_label');
		values.set(NotificationType.Success, 'global_success_label');

		const type = values.get(value);
		if (!type) {
			return '';
		}

		return this.translationService.get(type);
	}
}

<header></header>
<article>
  <ul class="table-skeleton-content header-row">
    <li>
      <span></span>
    </li>
    <li>
      <span></span>
    </li>
    <li>
      <span></span>
    </li>
    <li>
      <span></span>
    </li>
  </ul>
  <ul *ngFor="let i of [].constructor(100); let odd=odd;"
      [ngClass]="{ odd: odd }"
      class="table-skeleton-content">
    <li>
      <span></span>
    </li>
    <li>
      <span></span>
    </li>
    <li>
      <span></span>
      <span></span>
      <span></span>
    </li>
    <li>
      <span></span>
    </li>
  </ul>
</article>

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { BaEndpointUpdateModel } from '../../models/ba-endpoint-update-model.model';
import { EndpointFilterModel } from '../../models/endpoint-filter-model.model';
import { BaEndpointAssignationModel } from '../../models/ba-endpoint-assignation-model.model';

@Injectable()
export class BaEndpointDataService {
	constructor(private httpService: RQHttpService) {}

	public update(
		legalEntityId: string,
		assignation: BaEndpointUpdateModel,
		endpointFilterModel: EndpointFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<void> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			endpointFilterModel
		});

		options.body = assignation;

		return this.httpService.request<void>('put', environment.api + '/api/Configuration/BaEndpoint/Update', options).toPromise();
	}

	public updateRange(legalEntityId: string, assignations: BaEndpointUpdateModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		options.body = assignations;

		return this.httpService.request<void>('put', environment.api + '/api/Configuration/BaEndpoint/UpdateRange', options).toPromise();
	}

	public assignEndpoints(
		legalEntityId: string,
		assignations: BaEndpointAssignationModel[],
		options = new HttpOptionsViewModel()
	): Promise<void> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		options.body = assignations;

		return this.httpService.request<void>('put', environment.api + '/api/Configuration/BaEndpoint/AssignEndpoints', options).toPromise();
	}
}

import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { LTRecommendationDetailedApplicationsViewModel } from '../../../../../routes/control-analysis/_common/models/recommendations-detailed-view.viewmodel';
import { RecommendationTTPFilterModel } from '../../../../../server/models/recommendation-ttpfilter-model.model';
import { RecommendationTTPFilterOptionModel } from '../../../../../server/models/recommendation-ttpfilter-option-model.model';
import { TableBase2Component } from '../../../../../standalone/components/table/table-base2.component';
import { FormatterType } from '../../../../enums/formatter-type.enum';
import { RangeFilter } from '../../../../../standalone/components/form-elements/filter/filter.model';

@Component({
	selector: 'rq-application-details-table',
	templateUrl: './application-details-table.component.html',
	styleUrls: ['./application-details-table.component.scss']
})
export class ApplicationDetailsTableComponent
	extends TableBase2Component<LTRecommendationDetailedApplicationsViewModel, RecommendationTTPFilterModel>
	implements OnChanges
{
	@Input()
	public filterOptions!: RecommendationTTPFilterOptionModel;

	public frequencyFilterSetup!: RangeFilter;

	public async ngOnChanges(changes: SimpleChanges) {
		await super.ngOnChanges(changes);
		if (changes?.filterOptions?.currentValue) {
			this.setupFilters(changes.filterOptions.currentValue as RecommendationTTPFilterOptionModel);
		}
	}

	private setupFilters(filterOptions?: RecommendationTTPFilterOptionModel) {
		if (filterOptions) {
			this.frequencyFilterSetup = this.createRangeFilter('global_frequency_label', filterOptions.frequency, FormatterType.Percent);
		}
	}
}

import { Injectable } from '@angular/core';
import { LegalEntityDataService } from '../../../server/services/configuration/legal-entity-data.service';
import { LegalEntityType } from '../../../server/enums/legal-entity-type.enum';
import { LicensePermission } from './license-permission.enum';

@Injectable({
	providedIn: 'root'
})
export class LicenseService {
	private _currentLicense!: LicensePermission;

	constructor(private legalEntityDataService: LegalEntityDataService) {}

	public get currentLicense() {
		return this._currentLicense;
	}

	public load(legalEntityId: string) {
		return new Promise<boolean>(async response => {
			const legalEntity = await this.legalEntityDataService.getById(legalEntityId);
			this._currentLicense = this.getLicenseByLegalEntityType(legalEntity.type);

			response(true);
		});
	}

	public hasLicense(checkedLicense: Array<LicensePermission>) {
		if (!checkedLicense || !this._currentLicense) {
			return true;
		} else {
			return checkedLicense.includes(this._currentLicense);
		}
	}

	private getLicenseByLegalEntityType(type: LegalEntityType): LicensePermission {
		switch (type) {
			case LegalEntityType.ControlsAnalysis:
			case LegalEntityType.FinancialImpacts:
				return LicensePermission.FULL;
			case LegalEntityType.Mitre:
				return LicensePermission.MITRE;
			default:
				return LicensePermission.FULL;
		}
	}
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { CveFinancialWeightingModel } from '../../models/cve-financial-weighting-model.model';

@Injectable()
export class CveFinancialWeightingDataService {
	constructor(private httpService: RQHttpService) {}

	public getByLegalEntity(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<CveFinancialWeightingModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<CveFinancialWeightingModel[]>('get', environment.api + '/api/Settings/CveFinancialWeighting/GetByLegalEntity', options)
			.toPromise();
	}

	public update(financialWeighting: CveFinancialWeightingModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = financialWeighting;

		return this.httpService.request<void>('put', environment.api + '/api/Settings/CveFinancialWeighting/Update', options).toPromise();
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { ExploitabilityType } from 'src/app/server/enums/exploitability-type.enum';

@Pipe({
	name: 'exploitabilityTypeLabel'
})
export class ExploitabilityTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: ExploitabilityType) {
		const values = new Map<ExploitabilityType, string>();
		values.set(ExploitabilityType.CloudCPSM, 'exploitability_type_cloud_cspm_label');
		values.set(ExploitabilityType.Container, ExploitabilityType[ExploitabilityType.Container].toString());
		values.set(ExploitabilityType.DAST, ExploitabilityType[ExploitabilityType.DAST].toString());
		values.set(ExploitabilityType.Endpoint, ExploitabilityType[ExploitabilityType.Endpoint].toString());
		values.set(ExploitabilityType.Manual, ExploitabilityType[ExploitabilityType.Manual].toString());
		values.set(ExploitabilityType.SAST, ExploitabilityType[ExploitabilityType.SAST].toString());
		values.set(ExploitabilityType.SCA, ExploitabilityType[ExploitabilityType.SCA].toString());

		const type = values.get(value);
		if (!type) {
			throw new Error(`${value} is not handled`);
		}

		return this.translationService.get(type);
	}
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { PeerAnalyticsModel } from '../../models/peer-analytics-model.model';
import { PeerAnalyticsFilterModel } from '../../models/peer-analytics-filter-model.model';

@Injectable()
export class PeerAnalyticsDataService {
	constructor(private httpService: RQHttpService) {}

	public getByFilter(
		legalEntityId: string,
		filter: PeerAnalyticsFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<PeerAnalyticsModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			filter
		});

		return this.httpService
			.request<PeerAnalyticsModel[]>('get', environment.api + '/api/FinancialAnalysis/PeerAnalytics/GetByFilter', options)
			.toPromise();
	}
}

import { Component, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { ComparisonItem } from '../comparer.model';

@Component({
	selector: 'rq-comparer-card',
	templateUrl: './comparer-card.component.html',
	styleUrls: ['./comparer-card.component.scss']
})
export class ComparerCardComponent {
	@Output()
	public readonly deleted: EventEmitter<string> = new EventEmitter<string>();

	public isEmpty = false;

	public showDelete = false;

	public value!: ComparisonItem | null;

	public text!: string;

	public id: string | null = null;

	@Input()
	public set item(value: ComparisonItem | null) {
		this.value = value;
		if (value) {
			this.isEmpty = false;
			this.text = value.text;
			this.id = value.id;
		} else {
			this.isEmpty = true;
			this.text = '';
			this.id = null;
		}
	}

	@HostListener('mouseenter')
	public onMouseEnter() {
		this.showDelete = true;
	}

	@HostListener('mouseleave')
	public onMouseLeave() {
		this.showDelete = false;
	}

	public delete($event: Event) {
		$event.stopPropagation();

		if (this.id && this.showDelete) {
			this.deleted.emit(this.id);
		}
	}
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { CveRecommendationModel } from '../../models/cve-recommendation-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { CveRecommendationFilterModel } from '../../models/cve-recommendation-filter-model.model';
import { CveRecommendationFilterOptionModel } from '../../models/cve-recommendation-filter-option-model.model';
import { TopMaxCveScoreByPercentageModel } from '../../models/top-max-cve-score-by-percentage-model.model';
import { TopMaxCveScoreByEffectModel } from '../../models/top-max-cve-score-by-effect-model.model';

@Injectable()
export class CveRecommendationDataService {
	constructor(private httpService: RQHttpService) {}

	public getByAnalysis(
		analysisId: string,
		paginationModel: PaginationFilter,
		filter: CveRecommendationFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<CveRecommendationModel>> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			paginationModel,
			filter
		});

		return this.httpService
			.request<Pagination<CveRecommendationModel>>('get', environment.api + '/api/Analysis/CveRecommendation/GetByAnalysis', options)
			.toPromise();
	}

	public getFilterByAnalysis(analysisId: string, options = new HttpOptionsViewModel()): Promise<CveRecommendationFilterOptionModel> {
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService
			.request<CveRecommendationFilterOptionModel>('get', environment.api + '/api/Analysis/CveRecommendation/GetFilterByAnalysis', options)
			.toPromise();
	}

	public export(analysisId: string, options = new HttpOptionsViewModel()): Promise<Blob> {
		options.responseType = options.responseType ? options.responseType : 'blob';
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService.request<Blob>('get', environment.api + '/api/Analysis/CveRecommendation/Export', options).toPromise();
	}

	public getTopByCveScorePercentage(analysisId: string, options = new HttpOptionsViewModel()): Promise<TopMaxCveScoreByPercentageModel[]> {
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService
			.request<TopMaxCveScoreByPercentageModel[]>(
				'get',
				environment.api + '/api/Analysis/CveRecommendation/GetTopByCveScorePercentage',
				options
			)
			.toPromise();
	}

	public getTopByCveScoreEffect(analysisId: string, options = new HttpOptionsViewModel()): Promise<TopMaxCveScoreByEffectModel[]> {
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService
			.request<TopMaxCveScoreByEffectModel[]>('get', environment.api + '/api/Analysis/CveRecommendation/GetTopByCveScoreEffect', options)
			.toPromise();
	}
}

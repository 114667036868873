import {
	Component,
	ElementRef,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import { PieChart } from './pie-chart.model';
import { EChartsType, init } from 'echarts';
import { COLORS } from '../../../utils/colors.utils';

@Component({
	selector: 'rq-pie-chart',
	templateUrl: './pie-chart.component.html',
	styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnChanges, OnInit, OnDestroy {
	@ViewChild('chart', { static: true })
	public chartContainer!: ElementRef;

	@Input()
	public data!: Array<PieChart>;

	@Input()
	public chartTitle?: string;

	@Input()
	public chartSubtitle?: string;

	@Input()
	public isLabelVisible = false;

	@Input()
	public isTooltipVisible = true;

	private chart?: EChartsType;

	private isResizedForRelativeSizes = false;

	private resizeObserver!: ResizeObserver;

	constructor(private hostElement: ViewContainerRef) {}

	@HostListener('window:resize')
	public onResize() {
		if (this.chart) {
			this.chart.resize();
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.data) {
			this.setData(changes.data.currentValue as Array<PieChart>);
		}
	}

	public ngOnInit() {
		this.subscribeToResizeLayoutChange();
	}

	public ngOnDestroy() {
		this.resizeObserver.disconnect();
	}

	private setData(data: Array<PieChart>) {
		if (data) {
			if (!this.chart) {
				this.chart = init(this.chartContainer.nativeElement as HTMLElement);
			} else {
				this.chart?.clear();
			}

			const options = {
				title: {
					text: this.chartTitle,
					subtext: this.chartSubtitle,
					left: 'center'
				},
				color: COLORS.rainbowColorScheme,
				legend: { show: false },
				tooltip: {
					show: this.isTooltipVisible
				},
				series: this.getSeries(data)
			};

			this.chart.setOption(options);

			this.chart.on('rendered', () => {
				if (!this.isResizedForRelativeSizes) {
					this.isResizedForRelativeSizes = true;
					this.chart?.resize();
				}
			});
		} else {
			this.chart?.dispose();
		}
	}

	private getSeries(data: Array<PieChart>) {
		return {
			type: 'pie',
			radius: '80%',
			label: {
				show: this.isLabelVisible
			},
			data: data.map(x => ({
				name: x.label,
				value: x.value
			})),
			emphasis: {
				itemStyle: {
					shadowBlur: 10,
					shadowOffsetX: 0,
					shadowColor: 'rgba(0, 0, 0, 0.5)'
				}
			}
		};
	}

	private subscribeToResizeLayoutChange() {
		this.resizeObserver = new ResizeObserver(() => {
			this.chart?.resize();
		});

		this.resizeObserver.observe(this.hostElement?.element?.nativeElement as Element);
	}
}

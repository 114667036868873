import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { authenticatedGuard } from './shared/guards/authenticated-guard.service';
import { enterpriseGuard } from './shared/guards/enterprise-guard.service';
import { enterpriseReadOnlyGuard } from './shared/guards/enterprise-read-only-guard.service';
import { fairOnlyGuard } from './shared/guards/fair-only-guard.service';
import { migrationGuard } from './shared/guards/migration-guard.service';
import { termsAndAgreementGuard } from './shared/guards/terms-and-agreement-guard.service';
import { RQ_ROUTES } from './shared/configs/routes.config';
import { PageNotFoundComponent } from './routes/page-not-found/page-not-found.page';
import { fidelityOneGuard } from './shared/guards/fidelity-one.guard';
import { controlAnalysisTypeGuard } from './shared/guards/control-analysis-type.guard';
import { legalEntityCurrencyResolver } from './shared/services/resolvers/le-currency-resolver.service';
import { hasProAccessGuard } from './shared/guards/has-pro-access.guard';
import { inDeletionLeGuard } from './shared/guards/in-deletion-legal-entity.guard copy';
import { NoAccessPageComponent } from './routes/no-access-page/no-access-page.component';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: RQ_ROUTES.security.login.url()
	},
	{
		path: RQ_ROUTES.enterprise.path,
		canActivate: [migrationGuard, authenticatedGuard, enterpriseGuard, termsAndAgreementGuard, fairOnlyGuard],
		loadChildren: () => import('./routes/enterprise/enterprise.module').then(m => m.EnterpriseLayoutModule)
	},
	{
		path: RQ_ROUTES.pro.path,
		canActivate: [
			migrationGuard,
			authenticatedGuard,
			hasProAccessGuard,
			controlAnalysisTypeGuard,
			termsAndAgreementGuard,
			enterpriseReadOnlyGuard,
			inDeletionLeGuard
		],
		resolve: {
			currency: legalEntityCurrencyResolver
		},
		loadChildren: () => import('./routes/control-analysis/control-analysis.module').then(m => m.ControlAnalysisModule)
	},
	{
		path: RQ_ROUTES.fidelityOne.path,
		canActivate: [migrationGuard, authenticatedGuard, fidelityOneGuard, termsAndAgreementGuard, enterpriseReadOnlyGuard, inDeletionLeGuard],
		resolve: {
			currency: legalEntityCurrencyResolver
		},
		loadChildren: () => import('./routes/financial-analysis/financial-analysis.module').then(m => m.FinancialAnalysisModule)
	},
	{
		path: RQ_ROUTES.security.path,
		loadChildren: () => import('./routes/security/security.module').then(m => m.SecurityLayoutModule)
	},
	{
		path: RQ_ROUTES.infrastructure.path,
		loadChildren: () => import('./routes/infrastructure/infrastructure.module').then(m => m.InfrastructureLayoutModule)
	},
	{
		path: RQ_ROUTES.noAccess.path,
		component: NoAccessPageComponent
	},
	{
		path: '**',
		component: PageNotFoundComponent
	}
];

export const routingConfiguration: ExtraOptions = {
	paramsInheritanceStrategy: 'always'
};

@NgModule({
	imports: [RouterModule.forRoot(routes, routingConfiguration)],
	exports: [RouterModule]
})
export class AppRoutingModule {}

<ul e2e-test="notification-popup">
  <div class="notification-header">
    <div class="notification-title">
      <span *ngIf="newNotificationsCount; else noNewNotifications"> {{ newNotificationsCount }} Unread Notifications </span>
      <ng-template #noNewNotifications><span>No Unread Notifications</span></ng-template>
    </div>
    <button class="btn-secondary-sm"
            [disabled]="!newNotificationsCount"
            (click)="markAllAsSeen()">
      {{ 'mark_all_as_read' | translate }}
    </button>
  </div>

  <div rqInfiniteScroll
       (scrolled)="onScroll()"
       style="height: 225px; overflow-y: overlay">
    <li *ngFor="let item of notificationList; let i = index"
        (click)="goToNotification(item)"
        [style.border]="i === notificationList.length - 1 ? 'none' : ''"
        class="notification-row">
      <div class="notification-color">
        <div class="bar"
             [ngClass]="
						!item.isSeen
							? {
									'color-info': item.type === NotificationType.Info,
									'color-danger': item.type === NotificationType.Error,
									'color-warning': item.type === NotificationType.Warning,
									'color-success': item.type === NotificationType.Success
							  }
							: 'color-gray'
					"></div>
      </div>
      <div class="notification-description">
        <span *ngIf="item.legalEntityName"
              class="badge-secondary-sm legal-entity-badge m-b-xs">{{ item.legalEntityName }}</span>
        <p>
          <span *ngIf="item.subNotificationsCount"
                class="counter">{{ item.subNotificationsCount }}</span>
          <span [ngClass]="!item.isSeen ? 'unread-notification' : 'read-notification'">{{ item.value }}</span>
        </p>
      </div>
      <div class="notification-date">
        <p>{{ item.createDate | rqDate : { pattern: customizer.date.format } }}</p>
        <p>{{ item.createDate | rqDate : { pattern: 'hh:mm A' } }}</p>
      </div>
    </li>

    <ng-container *ngIf="isLoading">
      <div class="loading-wrapper"
           [class.first-load]="totalNotifications === undefined">
        <rq-loading-spinner></rq-loading-spinner>
      </div>
    </ng-container>

    <ng-container *ngIf="totalNotifications !== undefined && totalNotifications === 0 && !isLoading">
      <div class="no-data-message">
        <div>{{ 'global_no_notifications_message' | translate }}</div>
      </div>
    </ng-container>

    <div class="load-more"></div>
  </div>

  <div class="notification-footer">
    <button e2e-test="view-all-notifications-link"
            class="btn-secondary"
            (click)="viewAll()">
      {{ 'global_view_all_notifications_label' | translate }}
    </button>
  </div>
</ul>

<rq-table e2e-test="top-app-threats-table"
          class="m-b-md m-t-md"
          *ngIf="data"
          [dataSource]="data"
          [pagerState]="{ skip: 0, take: 5 }"
          [hasFilters]="false"
          [count]="count"
          (filterChange)="load($event)"
          (sortChange)="load($event)"
          (pageChange)="load($event)">
  <rq-table-column *ngIf="isLegalEntityVisible"
                   field="legalEntityName"
                   filter="string"
                   [isSortable]="false"
                   title="global_legalEntity_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <p [rqEllipsis]='dataItem.legalEntityName'
         [charactersCountLimit]='20'></p>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="displayName"
                   filter="string"
                   [isSortable]="false"
                   [title]="entityColumnName">
    <ng-template tableData
                 let-dataItem="dataItem">
      <p [rqEllipsis]='dataItem.displayName'
         [charactersCountLimit]='20'></p>
    </ng-template>
  </rq-table-column>


  <rq-table-column field="singleLossAmount"
                   filter="string"
                   [isSortable]="false"
                   title="global_maxLossSLE_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <p *ngIf="dataItem.singleLossAmount !== null"> {{dataItem.singleLossAmount | rqCurrency}}</p>
      <p *ngIf="dataItem.singleLossAmount === null">
        {{ 'global_notApplicable_label' | translate }}
      </p>
    </ng-template>
  </rq-table-column>


  <rq-table-column field="attackSuccessProbability"
                   filter="string"
                   [isSortable]="false"
                   title="dashboard_topApplications_ps">
    <ng-template tableData
                 let-dataItem="dataItem">
      <p *ngIf="dataItem.attackSuccessProbability !== null"> {{dataItem.attackSuccessProbability | rqPercent}}</p>
      <p *ngIf="dataItem.attackSuccessProbability === null">
        {{ 'global_notApplicable_label' | translate }}
      </p>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="annualizedLossAmount"
                   filter="string"
                   [isSortable]="false"
                   title="dashboard_topApplications_ale">
    <ng-template tableData
                 let-dataItem="dataItem">
      <p *ngIf="dataItem.annualizedLossAmount !== null"> {{dataItem.annualizedLossAmount | rqCurrency}}</p>
      <p *ngIf="dataItem.annualizedLossAmount === null">
        {{ 'global_notApplicable_label' | translate }}
      </p>
    </ng-template>
  </rq-table-column>

  <rq-table-column *ngIf="isActionsVisible"
                   field="actions"
                   [isSortable]="false"
                   [isFilterable]="false">
    <ng-template tableData
                 let-dataItem="dataItem">
      <rq-table-actions [numberOfVisibleActions]="0">
        <ng-template tableAction>
          <rq-table-action-item (click)="triggerViewDetails(dataItem)">
            {{'scenarios_scenariosList_linkViewDetails' | translate}}
          </rq-table-action-item>
        </ng-template>
      </rq-table-actions>
    </ng-template>
  </rq-table-column>
</rq-table>

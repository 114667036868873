<rq-modal class="modal-md">
  <rq-modal-header class="modal-header">
    <span>{{'global_applications_label' | translate}}</span>
    <rq-icon href="icon-close"
             class="icon-cancel white-fill"
             (click)="close()"></rq-icon>
  </rq-modal-header>

  <rq-modal-body>
    <rq-card-layout class='card-layout card-reset'
                    [config]="{ isLoadingActive: true, isHeaderHidden: true }">
      <ng-template [rqCardLayoutItem]>
        <rq-application-list [data]="data"></rq-application-list>
      </ng-template>
    </rq-card-layout>
  </rq-modal-body>

  <rq-modal-footer>
    <section>
      <rq-pager [config]="{isPagerSizeHidden: true}"
                [count]="count"
                [state]="paginationState"
                (pageChange)="pageChange()"></rq-pager>
    </section>
  </rq-modal-footer>
</rq-modal>

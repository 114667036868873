import {
	AfterViewInit,
	Component,
	ElementRef,
	HostBinding,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';
import { RangeChartRenderer } from '../_common/renderers/range-chart.renderer';
import { ChartAbstract } from '../_common/chart.abstract';
import { RangeConfig } from './range-chart.model';

@Component({
	selector: 'rq-range-chart',
	templateUrl: './range-chart.component.html',
	styleUrls: ['./range-chart.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class RangeChartComponent extends ChartAbstract<RangeConfig> implements AfterViewInit, OnChanges, OnDestroy {
	@Input()
	public data!: RangeConfig;

	@HostBinding('class.range-chart')
	public hostClass = true;

	public rangeRenderer!: RangeChartRenderer;

	private subscriptions: Subscription[] = [];

	constructor(elementRef: ElementRef) {
		super(elementRef.nativeElement as HTMLBaseElement);
		this.rangeRenderer = new RangeChartRenderer(this.context);
	}

	public ngAfterViewInit() {
		this.setup(() => this.rangeRenderer.setup(this.data));

		this.subscriptions.push(this.subscribeToResize(() => this.rangeRenderer.resize()));
	}

	public ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	public ngOnChanges(change: SimpleChanges) {
		if (!change.data.firstChange) {
			this.change(() => this.rangeRenderer.redraw(this.data));
		}
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { ImpactVectorRiskType } from '../../../routes/enterprise/_common/models/legal-entity-risk-cards.model';

@Pipe({
	name: 'impactVectorRiskTypeLabel'
})
export class ImpactVectorRiskTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: ImpactVectorRiskType) {
		const values = new Map<ImpactVectorRiskType, string>();
		values.set(ImpactVectorRiskType.MaxSingleLossAmount, 'global_maxLoss_label');
		values.set(ImpactVectorRiskType.MaxAttackSuccessProbability, 'dashboard_risk_card_max_attack_success_probability');
		values.set(ImpactVectorRiskType.MaxRateOfIncidence, 'dashboard_risk_card_max_rate_of_incidence');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${value} is not handled`);
		}

		return this.translationService.get(type);
	}
}

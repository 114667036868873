import { PipeTransform, Pipe } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { ScenarioType } from 'src/app/server/enums/scenario-type.enum';

@Pipe({
	name: 'scenarioTypeLabel'
})
export class ScenarioTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: ScenarioType) {
		const values = new Map<ScenarioType, string>();
		values.set(ScenarioType.EnterpriseControl, 'scenario_editEnterpriseControl_radio');
		values.set(ScenarioType.RateOfIncidence, 'scenario_editAttackRateOfIncidence_radio');
		values.set(ScenarioType.Fair, 'scenario_fair_radio');
		values.set(ScenarioType.SemiFair, 'scenario_modified_fair_radio');
		values.set(ScenarioType.AggregatedFair, 'scenario_aggregated_fair_radio');
		values.set(ScenarioType.MachineLearning, 'global_modelRiskToBusinessAssets_label');
		values.set(ScenarioType.MultipleControlProfiles, 'scenario_multipleLevels_radio');
		values.set(ScenarioType.MitreCustomAttack, 'scenario_mitreCustomAttack_radio');
		values.set(ScenarioType.MitreHistoricalAttack, 'scenario_mitreHistoricalAttack_radio');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${value} does not exist`);
		}

		return this.translationService.get(type);
	}
}

<section class="pager-container"
         *ngIf="isPagerContainerVisible">
  <div *ngIf="isLeftLabelVisible"
       class="pager-label">
    {{ config?.label | translate }}
  </div>
  <div class="pager-items">
    <ng-container *ngIf="isPaginationVisible">
      <button class="btn-icon-sm btn-arrow"
              [disabled]="isLeftBtnDisabled"
              (click)="goToPage(paginationInfo.currentPage - 1)">
        <rq-icon href="icon-arrow-chevron-left"></rq-icon>
      </button>
      <button *ngFor="let page of paginationInfo.pages"
              class="btn-icon-sm btn-marker"
              [disabled]="isDisabled"
              [class.active]="paginationInfo.currentPage === page"
              [class.marker]="page === -1"
              (click)="goToPage(page)">
        {{ page !== -1 ? page : '...' }}
      </button>
      <button class="btn-icon-sm btn-arrow"
              [disabled]="isRightBtnDisabled"
              (click)="goToPage(paginationInfo.currentPage + 1)">
        <rq-icon href="icon-arrow-chevron-right"></rq-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="isPageSizerVisible">
      <rq-dropdown (ngModelChange)="changePageSize($event)"
                   class="dropdown-xs"
                   [class.top-direction]="config?.dropdownDirection === 'top'"
                   [(ngModel)]="state.take"
                   [options]="dropdownOptions">
      </rq-dropdown>
    </ng-container>
  </div>
</section>

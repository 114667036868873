<ng-container *ngIf="!hasNoControls">

  <h4 class="m-b-md primary-l-10-color">
    {{'recommendation_longTerm_percentSubTitle' | translate}}
  </h4>

  <rq-bubble-chart *ngIf="series.length"
                   [legend.isVisible]="true"
                   [yAxis.label]="''"
                   [xAxis.label]="''"
                   [legend.tooltip.isVisible]="true"
                   [data]="series">
  </rq-bubble-chart>
</ng-container>


<ng-container *ngIf="hasNoControls">
  <p>{{'dashboard_control_no_recommendations_message' | translate}}</p>
</ng-container>

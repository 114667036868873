<rq-card [title]="'risk_analysis_application_dashboard_risk_summary_label'"
         [isLoaded]="isComponentLoaded"
         e2e-test="app-risk-summary">
  <ng-template rqCardContent>
    <h4 class="m-b-lg primary-l-10-color">
      {{'application_riskSummary_subTitle' | translate: {count: count} }}
    </h4>

    <rq-bar-chart *ngIf="series?.length"
                  class="chart"
                  yAxis.formatter="currency"
                  bar.formatter="currency"
                  [bar.isVisible]="false"
                  [xAxis.label]="'global_applications_label' | translate"
                  [yAxis.label]="'risk_analysis_application_dashboard_risk_summary_ale_label' | translate"
                  [legend.margin]="80"
                  [legend.isVisible]="true"
                  [data]="series">
    </rq-bar-chart>
  </ng-template>
</rq-card>

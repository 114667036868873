<rq-modal [ngClass]="multipleData ? 'modal-xl' : 'modal-lg'"
          #modal>
  <rq-modal-header class="modal-header">
    <span>{{ 'evidences_details_title' | translate}}</span>
    <rq-icon href="icon-close"
             class="icon-cancel white-fill"
             (click)="close()"></rq-icon>
  </rq-modal-header>

  <rq-modal-body>
    <rq-card-layout class='card-layout card-reset'
                    [config]="{ isLoadingActive: true, isHeaderHidden: true }">
      <ng-template [rqCardLayoutItem]>
        <ng-container *ngIf="controlLevelDescriptions">
          <div class="body-header-wrapper">

            <ng-container *ngIf="singleData">
              <div class="control-option-wrapper">
                <p class="control-option black-color">{{controlOptionName | translate}}</p>
              </div>
              <rq-control-level-info [controlLevelName]="getLevelName(singleData.controlOption.value)"
                                     [controlLevel]="singleData.controlOption.value"
                                     [noOfEvidences]="singleData.count">
              </rq-control-level-info>
            </ng-container>


            <ng-container *ngIf="multipleData">
              <div class="control-option-wrapper">
                <p class="control-option black-color">{{controlOptionName | translate}}</p>
              </div>
              <div>
                <div class="body-multiple-data">
                  <rq-control-level-info [hasNext]="true"
                                         [controlLevelName]="getLevelName(multipleData.from.controlOption.value)"
                                         [controlLevel]="multipleData.from.controlOption.value"
                                         [noOfEvidences]="multipleData.from.count">
                  </rq-control-level-info>
                  <rq-control-level-info [hasNext]="true"
                                         [controlLevelName]="getLevelName(multipleData.to.controlOption.value)"
                                         [controlLevel]="multipleData.to.controlOption.value"
                                         [noOfEvidences]="multipleData.to.count">
                  </rq-control-level-info>
                  <rq-control-level-info [noOfEvidences]="multipleData.howTo.count"
                                         [isDelta]="true">
                  </rq-control-level-info>
                </div>
              </div>
            </ng-container>
          </div>

          <ng-container *ngIf="multipleData">
            <rq-evidences-list [evidences]="evidences"
                               [noOfColumns]="3"
                               (filterChange)="triggerFilterChange($event)"></rq-evidences-list>
          </ng-container>

          <ng-container *ngIf="singleData">
            <rq-evidences-list [evidences]="evidences"
                               (filterChange)="triggerFilterChange($event)"></rq-evidences-list>
          </ng-container>
        </ng-container>
      </ng-template>
    </rq-card-layout>
  </rq-modal-body>
</rq-modal>

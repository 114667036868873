import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProgressBar } from './progress-bar.model';
import { ChartService } from '../chart.service';
import { FormatterType } from '../../../../shared/enums/formatter-type.enum';
import { COLORS } from '../../../utils/colors.utils';

@Component({
	selector: 'rq-progress-bar',
	templateUrl: './progress-bar.component.html',
	styleUrls: ['./progress-bar.component.scss'],
	providers: [ChartService]
})
export class ProgressBarComponent implements OnChanges {
	@Input()
	public maxValue!: number;

	@Input()
	public data!: Array<ProgressBar>;

	@Input()
	public showLabel = true;

	@Input('value.formatter')
	public valueFormatter?: FormatterType;

	@Input()
	public title!: string;

	public colorBrand = getComputedStyle(document.documentElement).getPropertyValue('--background-brand');

	private colorScheme = [...COLORS.rainbowColorScheme];

	constructor(private chartService: ChartService) {}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.data?.currentValue) {
			this.setupDefaultColor(changes.data?.currentValue as Array<ProgressBar>);
		}
	}

	public getProgress(value: number) {
		const percent = (value * 100) / this.maxValue;
		return `${percent}`;
	}

	public getValue(data: ProgressBar) {
		if (data.valueLabel) {
			return data.valueLabel;
		} else {
			return this.chartService.getFormatter(this.valueFormatter)(data.value);
		}
	}

	private setupDefaultColor(data: Array<ProgressBar>) {
		data.forEach((x, i) => {
			if (!x.color) {
				x.color = this.colorScheme[i];
			}
		});
	}
}

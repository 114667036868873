import { PipeTransform, Pipe } from '@angular/core';
import { from } from 'linq-to-typescript';

@Pipe({
	name: 'any'
})
export class AnyPipe implements PipeTransform {
	public transform(items: unknown[], value: unknown): boolean {
		return from(items).any(item => item === value);
	}
}

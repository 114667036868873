<header class="wizzard-header b b-bottom p-l-lg p-r-lg  p-t-md p-b-md">
  <section #wizardSection
           class="wizzard-section"
           routerLinkActive="wizzard-section-selected"
           [routerLinkActiveOptions]="myMatchOptions"
           id="{{i}}"
           [class.wizzard-section-disabled]="isDisabled(i)"
           *ngFor="let item of steps; let i = index">
    <div class="wizzard-line"
         *ngIf="i !== 0"></div>
    <div class="wizzard-item">
      <div class="wizzard-circle"
           title="{{item.title}}"
           (click)="triggerStepChange(i)">
        {{i + 1}}
      </div>
      <div class="wizzard-title m-t-xs text-xs">
        {{item.title}}
      </div>
    </div>
    <a hidden
       [routerLink]="getBaseUrl(item.path)"></a>
  </section>
</header>

<article class="wizzard-content">
  <router-outlet></router-outlet>
</article>

<footer class="wizard-footer p-t-lg">
  <button class="btn-secondary"
          (click)="triggerPrevStep()"
          [rqLoadingInline]="{isDisabledOnError: isFirstStep ? false : true, isInnerLoading: isFirstStep ? false : true}">{{prevStepLabel |
              translate}}</button>

  <button class="btn-primary wizard-submit"
          type="submit"
          (click)="triggerSubmit()"
          #submit
          [rqLoadingInline]="{isDisabledOnError: true, isInnerLoading: true}">
    {{submitLabel | translate}}
  </button>
</footer>

<div class="compare-card m-l-sm m-r-sm p-sm text-xs"
     [ngClass]="{
            'empty': isEmpty,
            'filled b b-none': !isEmpty && !showDelete,
            'delete b b-color-white': !isEmpty && showDelete
          }"
     (click)="delete($event)">
  <div [hidden]="isEmpty || showDelete"
       class="item-text">
    {{ text }}
  </div>

  <div [hidden]="isEmpty || !showDelete">
    <rq-icon href="icon-close"
             class="action-icon"></rq-icon>
  </div>
</div>

<section class="modal-background">
  <article class="modal-container">
    <header class="modal-title"
            [style.color]="this.headerTextColor"
            [hidden]="!isTitleVisible()"
            #divTitle>
      <ng-content select="rq-modal-header">
      </ng-content>
    </header>
    <section class="modal-body"
             [class.scrollable]="!hasFixedContent"
             [hidden]="!isContentVisible()"
             #divContent>
      <ng-content select="rq-modal-body"></ng-content>
    </section>
    <footer class="modal-footer"
            [hidden]="!isFooterVisible()"
            #divFooter>
      <ng-content select="rq-modal-footer"></ng-content>
    </footer>
  </article>
</section>

import { TranslationService } from 'src/app/shared/services/translation.service';
import { PipeTransform, Pipe } from '@angular/core';
import { AnalysisSummaryCategoryType } from 'src/app/server/enums/analysis-summary-category-type.enum';

@Pipe({
	name: 'analysisSummaryCategoryType'
})
export class AnalysisSummaryCategoryTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: AnalysisSummaryCategoryType) {
		switch (value) {
			case AnalysisSummaryCategoryType.GatherInput:
				return this.translationService.get('gathering_input_description');
			case AnalysisSummaryCategoryType.PrepareOutput:
				return this.translationService.get('preparing_output_description');
			case AnalysisSummaryCategoryType.RunAnalysis:
				return this.translationService.get('running_analysis_description');
			default:
				return '';
		}
	}
}

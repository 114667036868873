import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { AnalysisBaEnterpriseControlModel } from '../../models/analysis-ba-enterprise-control-model.model';
import { ScenarioDetailsControlsInputModel } from '../../models/scenario-details-controls-input-model.model';
import { ScenarioDetailsLossBreakdownModel } from '../../models/scenario-details-loss-breakdown-model.model';
import { ScenarioDetailsSummaryInputModel } from '../../models/scenario-details-summary-input-model.model';

@Injectable()
export class ScenarioDetailsControlDataService {
	constructor(private httpService: RQHttpService) {}

	public getDetailsControls(
		legalEntityId: string,
		input: ScenarioDetailsControlsInputModel,
		options = new HttpOptionsViewModel()
	): Promise<AnalysisBaEnterpriseControlModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		options.body = input;

		return this.httpService
			.request<AnalysisBaEnterpriseControlModel[]>(
				'post',
				environment.api + '/api/Scenario/ScenarioDetailsControl/GetDetailsControls',
				options
			)
			.toPromise();
	}

	public getLossBreakdown(
		legalEntityId: string,
		input: ScenarioDetailsSummaryInputModel,
		options = new HttpOptionsViewModel()
	): Promise<ScenarioDetailsLossBreakdownModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		options.body = input;

		return this.httpService
			.request<ScenarioDetailsLossBreakdownModel[]>(
				'post',
				environment.api + '/api/Scenario/ScenarioDetailsControl/GetLossBreakdown',
				options
			)
			.toPromise();
	}
}

<header class="card-title card-layout-title justify-content-between"
        *ngIf="!config.isHeaderHidden">
  <ng-container *ngIf="!isHeaderTemplateConfigured">
    <h3 class="m-b-none p-l-lg p-t-md p-b-md p-r-md"
        *ngIf="config?.title">
      {{ config.title | translate}}
    </h3>
    <div class="header-options">
      <div class="header-tabs"
           *ngIf="config?.tabs">
        <p *ngFor="let tab of config.tabs"
           [class.header-item-label-inactive]="!tab.isActive"
           [class.header-item-label-active]="tab.isActive"
           [class.disabled]="tab.isDisabled"
           (click)="triggerTabChange(tab)"
           class="card-layout-header p-l-lg p-r-lg b b-right">
          <span class="text-sm">{{tab.title | translate}}</span>
        </p>
      </div>
      <div class="card-layout-navigate card-navigate-link"
           *ngIf="config?.navigateTo">
        <a href="#"
           rqPreventDefault
           (click)="navigateTo()"><small>{{config.navigateTo?.label | translate}}</small></a>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isHeaderTemplateConfigured">
    <ng-container *ngTemplateOutlet="cardHeader"></ng-container>
  </ng-container>
</header>
<section class="card-layout-body p-none">
  <ng-container *ngIf="config?.isLoadingActive">
    <rq-loading-box #loadingBox
                    class="card-loading"
                    [type]="config.loadingBoxType">
      <ng-container *ngTemplateOutlet="cardBody"></ng-container>
    </rq-loading-box>
  </ng-container>
  <ng-container *ngIf="!config?.isLoadingActive">
    <ng-container *ngTemplateOutlet="cardBody"></ng-container>
  </ng-container>
</section>

<ng-template #cardBody>
  <aside *ngIf="config?.menuItems"
         class="d-flex flex-column card-layout-aside-menu">
    <rq-card-layout-menu-header-tab *ngIf="contentMenuItemHeader">
      <ng-container *ngTemplateOutlet="contentMenuItemHeader.templateRef"></ng-container>
    </rq-card-layout-menu-header-tab>
    <div class="b b-right d-flex flex-column menu-container menu-container-overflow-y h-100">
      <rq-card-layout-menu-tab *ngFor="let item of config.menuItems"
                               [label]="item.title"
                               [menuId]="item.menuId"
                               [isActive]="item.isActive"
                               [isDisabled]="item.isDisabled"
                               (menuItemChange)="triggerMenuChange($event)">
        <rq-card-layout-menu-tab *ngFor="let childItem of item.children"
                                 [label]="childItem.title"
                                 [menuId]="childItem.menuId"
                                 [parentMenuId]="item.menuId"
                                 [isActive]="childItem.isActive"
                                 [isDisabled]="childItem.isDisabled"
                                 (menuItemChange)="triggerMenuChange($event)">
        </rq-card-layout-menu-tab>
      </rq-card-layout-menu-tab>
    </div>
  </aside>
  <section class="d-flex flex-column"
           [class.card-menu-body]="isMenuConfigured"
           [class.card-inner-body]="!isMenuConfigured"
           *ngIf="state.content">
    <header class="p-md m-b-md p-b-md b b-bottom"
            *ngIf="state.content.title || state.content.subTitles || state.content.titleHtml">
      <div *ngIf="state.content.title || state.content.subTitles">
        <h3 class="m-b-none"
            *ngIf="state.content.title">{{state.content.title | translate}}</h3>
        <h3 class="m-t-md m-b-none text-bold-normal"
            *ngFor="let subTitle of state.content.subTitles">{{subTitle | translate}}
        </h3>
      </div>
      <div *ngIf="state.content.titleHtml">
        <ng-container *ngTemplateOutlet="state.content.titleHtml"></ng-container>
      </div>
    </header>
    <section class="card-body"
             *ngIf="state.content">
      <ng-container *ngTemplateOutlet="state.content.templateRef"></ng-container>
    </section>
  </section>
</ng-template>

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NotificationType } from '../enums/notification-type.enum';
import { NotificationMessageViewModel, NotificationToastViewModel } from '../models/notification.model';
import { AnalysisHubService } from '../signlr-hubs/analysis-hub.service';
import { NotificationService } from './notification.service';
import { RQAnalysisStatus } from '../../server/enums/rqanalysis-status.enum';
import { AnalysisRunnerDataService } from '../../server/services/analysis/analysis-runner-data.service';
import { AnalysisType } from '../../server/enums/analysis-type.enum';
import { TranslationService } from './translation.service';
import { AnalysisScope } from '../../server/enums/analysis-scope.enum';
import { AnalysisStatusModel } from '../../server/models/analysis-status-model.model';
import { RiskAnalysisStartModel } from '../../server/models/risk-analysis-start-model.model';
import { RQ_ROUTES } from '../configs/routes.config';
import { LegalEntityType } from '../../server/enums/legal-entity-type.enum';

@Injectable({
	providedIn: 'root'
})
export class AnalysisNotificationService {
	public analysisChange = new Subject<AnalysisStatusModel>();

	public notification!: NotificationToastViewModel;

	private analysisStatus!: AnalysisStatusModel;

	constructor(
		private analysisRunnerDataService: AnalysisRunnerDataService,
		private analysisHubService: AnalysisHubService,
		private notificationService: NotificationService,
		private translateService: TranslationService
	) {}

	public get isRunAnalysisSuccessful() {
		return (
			this.analysisStatus && this.analysisStatus.status === RQAnalysisStatus.Success && this.analysisStatus.scope === AnalysisScope.Default
		);
	}

	public get isAnalysisRunning() {
		return (
			this.analysisStatus &&
			(this.analysisStatus.scope !== AnalysisScope.Default || this.analysisStatus.status === RQAnalysisStatus.Running)
		);
	}

	public get state() {
		return this.analysisStatus;
	}

	public async connect(legalEntityId: string, scenarioId = '') {
		this.analysisStatus = await this.analysisRunnerDataService.getStatus(legalEntityId, scenarioId);

		await this.analysisHubService.connect();

		this.analysisHubService.statusChange((status: AnalysisStatusModel) => this.triggerStatusChange(status));
	}

	public disconnect() {
		this.analysisHubService.disconnect();
	}

	public async runRiskAnalysis(model: RiskAnalysisStartModel): Promise<void> {
		this.analysisStatus.legalEntityId = model.legalEntityId;
		this.analysisStatus.status = RQAnalysisStatus.Running;

		await this.analysisRunnerDataService.startAnalysis(model);
	}

	private triggerStatusChange(status: AnalysisStatusModel) {
		if (this.analysisStatus.legalEntityId === status.legalEntityId) {
			this.analysisStatus = status;
			this.analysisChange.next(this.analysisStatus);

			this.notify();
		}
	}

	private notify() {
		const isNotificationVisible =
			this.analysisStatus.status !== RQAnalysisStatus.Running &&
			this.analysisStatus.status !== RQAnalysisStatus.Idle &&
			this.analysisStatus.scope === AnalysisScope.Default;

		if (this.notification && !this.notification.dismissed) {
			this.notification.dismissed = true;
			this.notificationService.showToastNotification(this.notification);
		}

		if (isNotificationVisible) {
			this.triggerNotifications();
		}
	}

	private triggerNotifications() {
		this.notification = { analysisId: this.analysisStatus.analysisId, dismissed: false } as NotificationToastViewModel;
		this.notification.timer = 7000;

		const message = this.createMessage();

		switch (this.analysisStatus.status) {
			case RQAnalysisStatus.Success:
				this.notification.type = NotificationType.Info;
				this.notification.title = message.success.title;
				this.notification.message = message.success.message;
				break;
			case RQAnalysisStatus.Failure:
				this.notification.type = NotificationType.Error;
				this.notification.title = message.failure.title;
				this.notification.message = message.failure.message;
				break;
			default:
				throw new Error(`There is no handler for EngineStatus$ ${this.analysisStatus.status}`);
		}

		this.notificationService.showToastNotification(this.notification);
	}

	private createMessage(): NotificationMessageViewModel {
		const typeLabel = this.getType();
		const route = this.getRoute();

		const successTitle = this.translateService.get('analysis_title_success', { type: typeLabel });
		const successMessage = this.translateService.get('analysis_message_success', { type: typeLabel, navRoute: route });

		const errorTitle = this.translateService.get('analysis_title_failure', { type: typeLabel });
		const errorMessage = this.translateService.get('analysis_message_failure', { type: typeLabel, navRoute: route });

		return {
			success: {
				title: successTitle,
				message: successMessage
			},
			failure: {
				title: errorTitle,
				message: errorMessage
			}
		};
	}

	private getType() {
		switch (this.analysisStatus.analysisType) {
			case AnalysisType.Analysis:
				return this.analysisStatus.scenarioId
					? this.translateService.get('Scenarios')
					: this.translateService.get('global_riskAnalysis_label');
			case AnalysisType.Fair:
			case AnalysisType.AggregatedFair:
			case AnalysisType.MachineLearning:
			case AnalysisType.MultipleControlProfiles:
			case AnalysisType.TTP:
				return this.translateService.get('Scenarios');
			case AnalysisType.ThirdParty:
				return this.translateService.get('global_thirdParty_label');
			default:
				throw new Error(`${this.analysisStatus.analysisType} is not handled`);
		}
	}

	private getRoute() {
		switch (this.analysisStatus.analysisType) {
			case AnalysisType.Analysis:
				if (this.analysisStatus.legalEntityType === LegalEntityType.FinancialImpacts) {
					return RQ_ROUTES.fidelityOne.dashboard.url(this.analysisStatus.legalEntityId as string);
				} else {
					return this.analysisStatus.scenarioId
						? RQ_ROUTES.pro.scenarios.url(this.analysisStatus.legalEntityId as string)
						: RQ_ROUTES.pro.dashboard.url(this.analysisStatus.legalEntityId as string);
				}
			case AnalysisType.Fair:
			case AnalysisType.AggregatedFair:
			case AnalysisType.MachineLearning:
			case AnalysisType.MultipleControlProfiles:
				return RQ_ROUTES.pro.scenarios.url(this.analysisStatus.legalEntityId as string);
			case AnalysisType.ThirdParty:
				return RQ_ROUTES.pro.thirdParty.tabs.overview.url(this.analysisStatus.legalEntityId as string);
			default:
				throw new Error(`${this.analysisStatus.analysisType} is not handled`);
		}
	}
}

import { ComparisonRowBase, ComparisonRowBaseConfig, ComparisonTemplate, ComparisonType } from '../comparison.model';

export class ComparisonCollapsible {
	public type: ComparisonType.Collapsible = ComparisonType.Collapsible;

	constructor(public groups: Array<ComparisonCollapsibleGroup>) {}
}

export class ComparisonCollapsibleGroup {
	public isVisible = false;

	constructor(
		public rows: Array<ComparisonCollapsibleRow | ComparisonTemplate>,
		public title?: ComparisonGroupTitleRow,
		public config?: { isVisible?: boolean }
	) {
		this.setup();
	}

	private setup() {
		if (this.config) {
			this.isVisible = typeof this.config.isVisible !== 'undefined' ? this.config.isVisible : false;
		}
	}
}

export class ComparisonCollapsibleRow extends ComparisonRowBase {
	constructor(public label: string, public value: string, config?: ComparisonRowBaseConfig, public data?: unknown) {
		super();
		this.setupConfig(config);
	}
}

export class ComparisonGroupTitleRow extends ComparisonCollapsibleRow {}

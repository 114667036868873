export class ReputationalDamageParameter {
	public id!: string;

	public nameKey!: string;

	public value!: number;

	public isEditing!: boolean;
}

export enum TuningApplicationType {
	OnPrem = 0,
	SaaS
}

import { Component, EventEmitter, Output, QueryList, ViewChild, ViewChildren, OnInit } from '@angular/core';
import { CustomModelType } from '../../../server/enums/custom-model-type.enum';
import { CustomModelsFacade } from '../../facades/custom-models.facade';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomModelCreateViewModel } from '../../../server/models/custom-model-create-view-model.model';
import { noWhiteSpaceValidator } from '../../../standalone/utils/form-validators.utils';
import { ModalComponent } from '../../../standalone/components/modal/modal.component';
import { LoadingInlineDirective } from '../../../standalone/directives/loading-inline.directive';

@Component({
	selector: 'rq-custom-models-create-modal',
	templateUrl: './custom-models-create-modal.component.html',
	styleUrls: ['./custom-models-create-modal.component.scss']
})
export class CustomModelsCreateModalComponent implements OnInit {
	@ViewChild(ModalComponent, { static: true })
	public modal!: ModalComponent;

	@ViewChildren('submit', { read: LoadingInlineDirective })
	public loadingInlineDirectives!: QueryList<LoadingInlineDirective>;

	@Output()
	public readonly resetPage = new EventEmitter();

	@Output()
	public readonly saveNavigateTo = new EventEmitter<string>();

	public form!: UntypedFormGroup;

	public typeOptions = new Array<{ type: CustomModelType; label: string }>();

	public disabledTypes = [CustomModelType.Actor];

	constructor(private customModelsFacade: CustomModelsFacade, private formBuilder: UntypedFormBuilder) {}

	public get isValid() {
		this.form.markAllAsTouched();

		return this.form?.valid;
	}

	public ngOnInit() {
		this.setupForm();

		this.setupOptions();
	}

	public show() {
		this.form.controls.type.setValue(CustomModelType.Loss);
		this.modal.show();
	}

	public closeModal() {
		this.resetForm();

		this.modal.hide();
	}

	public async saveAsDraft() {
		if (this.isValid) {
			await this.create();

			this.resetPage.emit();

			this.closeModal();
		}
	}

	public async save() {
		if (this.isValid) {
			const id = await this.create();
			this.saveNavigateTo.emit(id);
		}
	}

	private resetForm() {
		this.form.controls.displayName.reset();
		this.form.controls.type.setValue(CustomModelType.Loss);
	}

	private create() {
		const model = <CustomModelCreateViewModel>{
			name: this.form.controls.displayName.value as string,
			type: this.form.controls.type.value as CustomModelType
		};

		const promise = this.customModelsFacade.createCustomModel(model);

		this.loadingInlineDirectives.forEach(loader => loader.toggleVisibility(() => promise));

		this.resetForm();

		return promise;
	}

	private setupForm() {
		this.form = this.formBuilder.group({
			displayName: [
				null,
				[Validators.required, Validators.maxLength(100), noWhiteSpaceValidator()],
				[this.customModelsFacade.customModelNameValidator()]
			],
			type: [null, [Validators.required]]
		});
	}

	private setupOptions() {
		this.typeOptions = this.customModelsFacade.createCustomModelTypeOptions();
	}
}

export enum FairType {
	'LossEventFrequency' = 1,
	'ThreatEventFrequency' = 2,
	'Vulnerability' = 3,
	'ContactFrequency' = 4,
	'ProbabilityOfAction' = 5,
	'ThreatCapability' = 6,
	'ResistanceStrength' = 7,
	'PrimaryLossMagnitude' = 8,
	'SecondaryLossEventFrequency' = 9,
	'SecondaryLossMagnitude' = 10,
	'SecondaryRisk' = 11,
	'LossMagnitude' = 12,
	'AnnualLossExposure' = 13
}

import { Directive, EventEmitter, Input, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { TableColumnComponent } from './table-column/table-column.component';
import { TableComponent } from './table.component';
import { BaseComponent } from '../../../shared/components/base.component';
import { TableSelectViewModel } from './models/table-select.viewmodel';
import { AssignationType } from '../../../server/enums/assignation-type.enum';
import { Range } from '../../../server/models/range.model';
import { isEmptyString, isNullOrUndefined } from '../../utils/helpers.utils';
import { NumberConfig } from '../../../shared/models/number.config';
import { FormatterType } from '../../../shared/enums/formatter-type.enum';
import { TableOutput } from './models/table-output.model';
import { TableSelectOutput } from './models/table-select-output.model';
import {
	DropdownFilter,
	Filter,
	FilterSelectGroup,
	FilterSelectOption,
	RangeFilter,
	SelectFilter
} from '../form-elements/filter/filter.model';
import { TableSelectType } from './models/table-select.enum';
import { TableUiComponent } from './table-ui.component';

@Directive()
export class TableBase2Component<Data, FilterData> extends BaseComponent implements OnChanges {
	@Input()
	public count!: number;

	@Input()
	public data!: Array<Data>;

	@Input()
	public isAllSelected!: boolean;

	@Input()
	public rowPopertyIdentifier = 'id';

	@Input()
	public hasFilters = true;

	@Input()
	public isSortable = true;

	@Input()
	public hasActions = true;

	@Input()
	public hasCheckboxSelection = false;

	@Output()
	public readonly loadData = new EventEmitter<TableOutput<FilterData>>();

	@Output()
	public readonly selectionChanged = new EventEmitter<TableSelectViewModel>();

	@ViewChild(TableComponent)
	protected tableBE!: TableComponent;

	@ViewChild(TableUiComponent)
	protected tableUI!: TableUiComponent;

	protected hasFiltersApplied!: boolean;

	protected get table() {
		return this.tableBE ?? this.tableUI;
	}

	public async ngOnChanges(changes: SimpleChanges) {
		if (changes.data?.currentValue && this.count !== null && this.count !== undefined) {
			await this.table?.toggleLoading(false);
		}
	}

	public triggerTableLoading(value: boolean, filter?: (data: Data, columnName: TableColumnComponent[], columnIndex: number) => boolean) {
		this.table?.toggleLoading(value, filter);
	}

	public triggerPageReset() {
		this.table?.pager?.triggerReset();
	}

	public async load(output: TableOutput<FilterData>) {
		this.hasFiltersApplied = this.checkIfHasFiltersApplied(output);

		await this.table?.toggleLoading(true);

		this.loadData.emit(output);
	}

	public select(selection: TableSelectOutput<Data & Record<string, string>>) {
		const entity = new TableSelectViewModel();

		if (selection.type === TableSelectType.All) {
			entity.type = selection.isAllSelected ? AssignationType.AssignAll : AssignationType.RemoveAll;
		} else {
			entity.id = selection.value.data[this.rowPopertyIdentifier];
			entity.type = selection.value.isSelected ? AssignationType.AssignEntity : AssignationType.RemoveEntity;
		}
		this.selectionChanged.emit(entity);
	}

	protected createSelectFilter(displayName: string, groups: FilterSelectGroup<unknown>[], maxSelected?: number) {
		const filter = new SelectFilter();
		filter.displayName = displayName;
		filter.groups = groups;
		if (maxSelected) {
			filter.maxSelected = maxSelected;
		}

		return filter;
	}

	protected createDropdownFilter(displayName: string, options: FilterSelectOption<unknown>[]) {
		const filter = new DropdownFilter();
		filter.displayName = displayName;
		filter.options = options;

		return filter;
	}

	protected createRangeFilter(
		displayName: string,
		range: Range<number>,
		type: FormatterType,
		currencyConfig?: NumberConfig,
		selectGroups?: Array<FilterSelectGroup<unknown>>
	) {
		const filter = new RangeFilter();
		filter.displayName = displayName;
		filter.formatter.type = type;

		if (currencyConfig) {
			filter.formatter.config = currencyConfig;
		}

		if (selectGroups) {
			filter.groups = selectGroups;
		}

		if (range) {
			filter.min = range.min;
			filter.max = range.max;
		}

		return filter;
	}

	private checkIfHasFiltersApplied(output: TableOutput<FilterData>) {
		return (
			Boolean(output) &&
			Object.entries(output.filter as unknown as Record<string, Filter<FilterData>>).filter(
				x => !(isNullOrUndefined(x[1].value) || isEmptyString(x[1].value))
			).length > 0
		);
	}
}

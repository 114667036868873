import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class RQRouteReuseStrategyService implements RouteReuseStrategy {
	public shouldDetach(_route: ActivatedRouteSnapshot): boolean {
		return false;
	}

	public store(_route: ActivatedRouteSnapshot, _detachedTree: DetachedRouteHandle): void {
		/**/
	}

	public shouldAttach(_route: ActivatedRouteSnapshot): boolean {
		return false;
	}

	public retrieve(_route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
		return null;
	}

	public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
		return future.routeConfig === curr.routeConfig;
	}
}

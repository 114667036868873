import { Component, ElementRef, HostBinding, HostListener, Input, OnChanges, OnInit, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateFormatterService } from '../../../../shared/services/date-formatter.service';

@Component({
	selector: 'rq-time-picker',
	templateUrl: './time-picker.component.html',
	styleUrls: ['./time-picker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TimePickerComponent),
			multi: true
		}
	]
})
export class TimePickerComponent implements ControlValueAccessor, OnChanges, OnInit {
	@Input()
	@HostBinding('class.disabled')
	public disabled!: boolean;

	@Input()
	public placeholder = 'HH:mm';

	@Input()
	public minutesStep = 30;

	@Input()
	public timeToStart!: string;

	public form!: UntypedFormGroup;

	public isTimepickerVisible = false;

	public suggestions: Array<Date> = [];

	private localDateResult!: Date | null;

	private element!: HTMLElement;

	private initialLocalDate!: Date;

	private onTouchedCallback?: () => void;

	private onChangeCallback!: (_: unknown) => void;

	constructor(eRef: ElementRef, private dateService: DateFormatterService) {
		this.element = eRef.nativeElement as HTMLElement;
	}

	public get hourInput() {
		return this.form.get('hour');
	}

	public get minutesInput() {
		return this.form.get('minutes');
	}

	public get displayValue() {
		return this.localDateResult ? this.dateService.toLocalDate(this.localDateResult).toISOString() : '';
	}

	@HostListener('document:click', ['$event'])
	public clickout(event: Event) {
		if (!this.element.contains(<Node>event.target)) {
			this.isTimepickerVisible = false;
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.timeToStart?.currentValue) {
			this.setupData(changes.timeToStart?.currentValue as string);
		}

		if (changes.minutesStep?.currentValue && this.timeToStart) {
			this.generateSuggestions(this.initialLocalDate, changes.minutesStep?.currentValue as number);
		}
	}

	public ngOnInit() {
		this.setupForm();
	}

	public registerOnChange(fn: (_: unknown) => void) {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: () => void) {
		this.onTouchedCallback = fn;
	}

	public writeValue(value: string) {
		if (value) {
			this.initialLocalDate = this.dateService.toLocalDate(value);
			this.localDateResult = this.dateService.toLocalDate(value);
		} else {
			this.initialLocalDate = new Date();
			this.localDateResult = null;
		}
	}

	public onChange() {
		const hourValue = this.hourInput?.value as string;
		const minutesValue = this.minutesInput?.value as string;
		if (hourValue && minutesValue) {
			this.hourInput?.setValue(hourValue.padStart(2, '0'));
			this.minutesInput?.setValue(minutesValue.padStart(2, '0'));
			this.localDateResult = new Date();
			this.localDateResult = this.dateService.set(this.localDateResult, Number(hourValue), 'hour');
			this.localDateResult = this.dateService.set(this.localDateResult, Number(minutesValue), 'minutes');
			this.onChangeCallback(this.localDateResult?.toISOString());
			this.isTimepickerVisible = false;
		}
	}

	public isSuggestionSelected(value: Date) {
		return value.getMinutes() === this.localDateResult?.getMinutes() && value.getHours() === this.localDateResult?.getHours();
	}

	public onFocus() {
		if (!this.timeToStart) {
			this.setupData(new Date().toISOString());
		}

		this.isTimepickerVisible = true;

		if (this.onTouchedCallback) {
			this.onTouchedCallback();
		}
	}

	public selectSuggestion(value: Date) {
		this.localDateResult = this.dateService.toLocalDate(value);
		this.hourInput?.setValue(value.getHours().toString().padStart(2, '0'));
		this.minutesInput?.setValue(value.getMinutes().toString().padStart(2, '0'));
		this.onChangeCallback(this.localDateResult?.toISOString());
		this.isTimepickerVisible = false;
	}

	private generateSuggestions(start: Date, step: number) {
		this.suggestions = [];
		const endOfDay = this.dateService.endOf(start, 'day');
		let startOfDay = this.dateService.cloneDate(start);

		if (!this.timeToStart) {
			const roundToNextStep = step - (start.getMinutes() % step);
			startOfDay = this.dateService.add(start, roundToNextStep, 'minutes');
		}

		let toAdd = 0;
		for (let i = startOfDay; i < endOfDay; i = this.dateService.add(i, toAdd, 'minutes')) {
			this.suggestions.push(i);
			toAdd = step - (i.getMinutes() % step);
		}
	}

	private setupData(starTime: string) {
		this.initialLocalDate = this.dateService.toLocalDate(starTime);
		this.generateSuggestions(this.initialLocalDate, this.minutesStep);
	}

	private setupForm() {
		this.form = new UntypedFormGroup({
			hour: new UntypedFormControl(''),
			minutes: new UntypedFormControl('')
		});
	}
}

import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, forwardRef } from '@angular/core';
import { FilterSearchBoxConfig, FilterSelectOption } from '../filter.model';
import { FilterAbstractDirective } from '../filter.abstract';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'rq-filter-dropdown',
	templateUrl: './filter-dropdown.component.html',
	styleUrls: ['./filter-dropdown.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FilterDropdownComponent),
			multi: true
		}
	]
})
export class FilterDropdownComponent extends FilterAbstractDirective<unknown> implements ControlValueAccessor {
	@Input()
	public options?: FilterSelectOption<unknown>[];

	@Input()
	public displayName!: string;

	@Input()
	public placeholder!: string;

	@Input()
	public isDisabled!: boolean;

	@Input()
	public searchBoxConfig!: FilterSearchBoxConfig;

	@HostBinding('class.filter-dropdown-expanded')
	public isExpanded!: boolean;

	@Output()
	public readonly toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

	private element: HTMLElement;

	private onChangeCallback?: (_: unknown) => void;

	private onTouchedCallback?: (_: unknown) => void;

	constructor(elementRef: ElementRef) {
		super();
		this.element = elementRef.nativeElement as HTMLElement;
	}

	@HostListener('document:click', ['$event'])
	public clickOutside(event: Event) {
		if (this.isExpanded && !this.element.contains(<Node>event.target)) {
			this.isExpanded = false;
		}
	}

	@HostListener('click')
	public setTouchedState() {
		if (this.onTouchedCallback) {
			this.onTouchedCallback(this.options);
		}
	}

	public toggleVisibility() {
		if (!this.isDisabled) {
			this.isExpanded = !this.isExpanded;
			this.toggle.emit(this.isExpanded);
		}
	}

	public writeValue(value: unknown): void {
		this.setSelectedOption(value);
	}

	public triggerChange(option: FilterSelectOption<unknown>) {
		this.writeValue(option.value);

		if (this.onChangeCallback) {
			this.onChangeCallback(option.value);
		}

		this.change.next();
	}

	public registerOnChange(fn: (_: unknown) => void): void {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: (_: unknown) => void): void {
		this.onTouchedCallback = fn;
	}

	public isPartOfSearch(option: FilterSelectOption<unknown>) {
		if (this.searchBoxConfig) {
			let isPartOfSearch = true;

			if (this.searchBoxConfig.searchValue) {
				isPartOfSearch = option.displayName.toLowerCase().includes(this.searchBoxConfig.searchValue.toLowerCase());
			}

			return isPartOfSearch;
		}

		return true;
	}

	private setSelectedOption(value: unknown) {
		if (this.options) {
			this.options.forEach(option => {
				option.isSelected = option.value === value;

				if (option.isSelected) {
					this.value = option.value;

					this.placeholder = option.displayName;

					this.isExpanded = false;
				}
			});
		}
	}
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { TPAnalysisModel } from '../../models/tpanalysis-model.model';

@Injectable()
export class TPAnalysisDataService {
	constructor(private httpService: RQHttpService) {}

	public getByVectorId(tpImpactVectorId: string, options = new HttpOptionsViewModel()): Promise<TPAnalysisModel> {
		options.params = this.httpService.getHttpParams({
			tpImpactVectorId
		});

		return this.httpService
			.request<TPAnalysisModel>('get', environment.api + '/api/TPAnalysis/TPAnalysis/GetByVectorId', options)
			.toPromise();
	}
}

import { Component, ContentChild, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { ThreeDotsMenuCustomNameDirective } from '../directives/three-dots-menu-item-custom.directive';

@Component({
	selector: 'rq-three-dots-menu-item',
	template: ''
})
export class ThreeDotsMenuItemComponent {
	@Input()
	public name = '';

	@Input()
	public isDisabled = false;

	@Output()
	public readonly clickAction = new EventEmitter();

	@ContentChild(ThreeDotsMenuCustomNameDirective)
	public customName!: ThreeDotsMenuCustomNameDirective;

	@ContentChildren(ThreeDotsMenuItemComponent)
	public options!: QueryList<ThreeDotsMenuItemComponent>;
}

import { TableService } from '../_common/table.service';
import {
	Component,
	Input,
	QueryList,
	SimpleChanges,
	OnChanges,
	Output,
	EventEmitter,
	OnInit,
	OnDestroy,
	ChangeDetectionStrategy
} from '@angular/core';
import { Subscription } from 'rxjs';
import { TableColumnComponent } from '../table-column/table-column.component';
import { clone, isNullOrUndefined } from '../../../utils/helpers.utils';
import { CustomFilter } from '../models/filter-setup.model';
import {
	CalendarFilter,
	DropdownFilter,
	Filter,
	FilterConfig,
	FilterOutput,
	FilterRange,
	NumericFilter,
	RangeFilter,
	SearchFilter,
	SelectFilter
} from '../../form-elements/filter/filter.model';
import { Customizer } from '../../../../shared/services/customizer';

@Component({
	selector: 'rq-table-filters',
	templateUrl: './table-filters.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableFiltersComponent implements OnChanges, OnInit, OnDestroy {
	@Input()
	public tableColumns!: QueryList<TableColumnComponent>;

	@Input()
	public isDisabled!: boolean;

	@Input()
	public customFilters!: Array<CustomFilter>;

	@Output()
	public readonly filterChange = new EventEmitter<FilterOutput>();

	public filters = {} as { [key: string]: unknown };

	public configs = new Array<FilterConfig>();

	private subscriptions: Subscription[] = [];

	constructor(private customizer: Customizer, private tableService: TableService) {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.tableColumns?.currentValue) {
			this.setupFilters((changes.tableColumns.currentValue as QueryList<TableColumnComponent>).toArray());
		}

		if (changes.customFilters?.currentValue && this.configs) {
			this.setupCustomFilters(changes.customFilters.currentValue as Array<CustomFilter>);
		}
	}

	public ngOnInit() {
		this.subscriptions.push(
			this.tableService.preselectedChanged$.subscribe(() => {
				this.setupFilters(this.tableColumns.toArray());
			}),
			this.tableService.filterSetupChanged$.subscribe(() => {
				this.setupFilters(this.tableColumns.toArray());
			})
		);
	}

	public ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	public triggerFilterChange(filterOutput: FilterOutput) {
		this.filterChange.emit(filterOutput);
	}

	private setupFilters(columns: Array<TableColumnComponent>) {
		this.configs = [];

		columns.forEach(column => {
			if (!column?.filter) {
				return;
			}
			switch (column.filter) {
				case 'string': {
					this.configs.push(this.setupStringFilter(column));
					break;
				}
				case 'numeric': {
					this.configs.push(this.setupNumericFilter(column));
					break;
				}
				case 'range': {
					this.configs.push(this.setupRangeFilter(column));
					break;
				}
				case 'select': {
					this.configs.push(this.setupSelectFilter(column));
					break;
				}
				case 'dropdown': {
					this.configs.push(this.setupDropdownFilter(column));
					break;
				}
				case 'calendar': {
					this.configs.push(this.setupCalendarFilter(column));
					break;
				}
				default: {
					throw new Error('Invalid filter type');
				}
			}

			if (this.customFilters) {
				this.setupCustomFilters(this.customFilters);
			}

			this.setupPreselectedValue(column);
		});
	}

	private setupCustomFilters(customFilters: Array<CustomFilter>) {
		const clonnedConfig = [...this.configs];
		customFilters.forEach(x => {
			if (!clonnedConfig.some(item => item.name === x.name)) {
				const filterConfig = new FilterConfig(x.name, x.filter);
				clonnedConfig.splice(x.index, 0, filterConfig);
			}
		});

		this.configs = [...clonnedConfig];
	}

	private setupStringFilter(column: TableColumnComponent) {
		const filter = new SearchFilter();
		const filterSetup = column.filterSetup as SearchFilter;
		filter.displayName = filterSetup?.displayName ?? column.title;
		filter.placeholder = filterSetup?.placeholder ?? filter.placeholder ?? filter.displayName;

		return new FilterConfig(column.field, filter);
	}

	private setupNumericFilter(column: TableColumnComponent) {
		const filter = new NumericFilter();
		const filterSetup = column.filterSetup as NumericFilter;
		filter.displayName = filterSetup?.displayName ?? column.title;
		filter.suffix = !isNullOrUndefined(filterSetup?.suffix) ? filterSetup?.suffix : filter.suffix;
		filter.allowNegativeNumbers = filterSetup?.allowNegativeNumbers ?? filter.allowNegativeNumbers;

		return new FilterConfig(column.field, filter);
	}

	private setupRangeFilter(column: TableColumnComponent) {
		const filter = new RangeFilter();
		const filterSetup = column.filterSetup as RangeFilter;
		filter.displayName = filterSetup?.displayName ?? column.title;
		filter.isRangeIntervalRounded = filterSetup?.isRangeIntervalRounded ?? filter.isRangeIntervalRounded;
		filter.formatter = filterSetup?.formatter ?? filter.formatter;
		filter.placeholder = filterSetup?.placeholder ?? filter.placeholder;
		filter.groups = clone(filterSetup?.groups) ?? filter.groups;
		filter.min = !isNullOrUndefined(filterSetup?.min) ? filterSetup?.min : filter.min;
		filter.max = !isNullOrUndefined(filterSetup?.max) ? filterSetup?.max : filter.max;

		return new FilterConfig(column.field, filter);
	}

	private setupSelectFilter(column: TableColumnComponent) {
		const filter = new SelectFilter();
		const filterSetup = column.filterSetup as SelectFilter;
		filter.displayName = filterSetup?.displayName ?? column.title;
		filter.maxSelected = filterSetup?.maxSelected;
		filter.groups = clone(filterSetup?.groups) ?? filter.groups;

		return new FilterConfig(column.field, filter);
	}

	private setupDropdownFilter(column: TableColumnComponent) {
		const filter = new DropdownFilter();
		const filterSetup = column.filterSetup as DropdownFilter;
		filter.displayName = filterSetup?.displayName ?? column.title;
		filter.searchBoxConfig = clone(filterSetup?.searchBoxConfig);
		filter.options = clone(filterSetup?.options);

		return new FilterConfig(column.field, filter);
	}

	private setupCalendarFilter(column: TableColumnComponent) {
		const filter = new CalendarFilter();
		const filterSetup = column.filterSetup as CalendarFilter;
		filter.displayName = filterSetup?.displayName ?? column.title;
		filter.datePattern = filterSetup?.datePattern ?? this.customizer.date.format;
		filter.format = filterSetup?.format ?? this.customizer.date.format;
		filter.options = clone(filterSetup?.options);

		if (column.preselectedValue) {
			this.filters[column.field] = new Filter(column.preselectedValue);
		}

		return new FilterConfig(column.field, filter);
	}

	private setupPreselectedValue(column: TableColumnComponent) {
		if (column.preselectedValue) {
			this.checkPreselectedValueToBeValid(column);
			this.filters[column.field] = new Filter(column.preselectedValue);
		}
	}

	private checkPreselectedValueToBeValid(column: TableColumnComponent) {
		switch (column.filter) {
			case 'string': {
				this.checkPreselectedValueForStringFilter(column);
				break;
			}

			case 'numeric': {
				this.checkPreselectedValueForNumericFilter(column);
				break;
			}

			case 'range': {
				this.checkPreselectedValueForRangeFilter(column);
				break;
			}

			case 'calendar': {
				this.checkPreselectedValueForCalendarFilter(column);
				break;
			}

			case 'select': {
				this.checkPreselectedValueForSelectFilter(column);
				break;
			}

			case 'dropdown': {
				this.checkPreselectedValueForDropdownFilter(column);
				break;
			}
			default: {
				break;
			}
		}
	}

	private checkPreselectedValueForSelectFilter(column: TableColumnComponent) {
		const values = column.preselectedValue as Array<unknown>;

		const options: Array<unknown> = [];
		(column.filterSetup as SelectFilter).groups.forEach(g => {
			g.options.forEach(o => {
				options.push(o.value);
			});
		});

		if (!values || !values.every(v => options.includes(v))) {
			throw new Error('For select filter the preselectedValue must be an array of valid options');
		}
	}

	private checkPreselectedValueForRangeFilter(column: TableColumnComponent) {
		const value = column.preselectedValue as FilterRange<unknown>;
		if (value.range?.min === undefined || value.range?.max === undefined) {
			throw new Error('For range filter the preselectedValue must be a FilterRange type');
		}
	}

	private checkPreselectedValueForStringFilter(column: TableColumnComponent) {
		if (typeof column.preselectedValue !== 'string') {
			throw new Error('For text filter the preselectedValue must be a string');
		}
	}

	private checkPreselectedValueForNumericFilter(column: TableColumnComponent) {
		if (typeof column.preselectedValue !== 'number') {
			throw new Error('For numeric filter the preselectedValue must be a number');
		}
	}

	private checkPreselectedValueForDropdownFilter(column: TableColumnComponent) {
		const preselectedOption = (column.filterSetup as DropdownFilter).options?.find(x => x.value === column.preselectedValue);
		if (!preselectedOption) {
			throw new Error('For dropdown filter the preselectedValue must be a valid option');
		}
	}

	private checkPreselectedValueForCalendarFilter(_column: TableColumnComponent) {
		throw new Error('Calendar filter preselectedValue is not supported');
		//There is an issue on calendar filter to update the values.
		// const value = column.preselectedValue as FilterCalendarValue
		// if (value.start === undefined || value.occurrence === undefined) {
		//   throw new Error("For calendar filter the preselectedValue must be a FilterCalendarValue type");
		//}
	}
}

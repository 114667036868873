import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	ElementRef,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	TemplateRef,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import { FormatterType } from '../../../../shared/enums/formatter-type.enum';
import { EChartsType, init } from 'echarts';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { LineChartAxisConfig } from './models/linechart-axis-config.model';
import { LineConfig } from './models/line-config.model';
import { LineChart } from './models/line-chart.model';
import { COLORS } from '../../../utils/colors.utils';
import { ChartService } from '../chart.service';

@Component({
	selector: 'rq-line-chart',
	templateUrl: './line-chart.component.html',
	styleUrls: ['./line-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ChartService]
})
export class LineChartComponent implements OnChanges, OnInit, OnDestroy {
	@Input()
	public data: LineChart[] = [];

	@Input('legend.isVisible')
	public legendIsVisible = true;

	@Input('legend.tooltip.isVisible')
	public legendTooltipIsVisible = false;

	@Input('line.isSmooth')
	public lineIsSmooth = true;

	@Input('line.isThemeColored')
	public lineIsThemeColored = true;

	@Input()
	public line: LineConfig = {
		isSmooth: this.lineIsSmooth,
		isThemeColored: this.lineIsThemeColored
	};

	@Input('xAxis.label')
	public xAxisLabel = 'X Axis Label Here';

	@Input('xAxis.formatter')
	public xAxisFormatter: FormatterType | string = FormatterType.Date;

	@Input()
	public xAxis: LineChartAxisConfig = {
		label: this.xAxisLabel,
		formatter: this.xAxisFormatter
	};

	@Input('yAxis.label')
	public yAxisLabel = 'Y Axis Label Here';

	@Input('yAxis.formatter')
	public yAxisFormatter: FormatterType | string = FormatterType.Currency;

	@Input()
	public yAxis: LineChartAxisConfig = {
		label: this.yAxisLabel,
		formatter: this.yAxisFormatter
	};

	@ContentChild('customTooltip')
	public customTooltip?: TemplateRef<unknown>;

	@ViewChild('chart', { static: true })
	private chartContainer!: ElementRef;

	@ViewChild('tooltipContainer', { static: true, read: ViewContainerRef })
	private tooltipContainer!: ViewContainerRef;

	private chart?: EChartsType;

	private resizeObserver!: ResizeObserver;

	private grayDarkColor = getComputedStyle(document.documentElement).getPropertyValue('--background-neutral-contrast');

	private grayLightColor = getComputedStyle(document.documentElement).getPropertyValue('--background-neutral-overlay');

	constructor(private hostElement: ViewContainerRef, private chartService: ChartService) {}

	@HostListener('window:resize')
	public onResize() {
		if (this.chart) {
			this.chart.resize();
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.xAxisLabel) {
			this.updateXAxisConfiguration({ label: changes.xAxisLabel.currentValue as string } as LineChartAxisConfig);
		}

		if (changes.xAxisFormatter) {
			this.updateXAxisConfiguration({ formatter: changes.xAxisFormatter.currentValue as string } as LineChartAxisConfig);
		}

		if (changes.yAxisLabel) {
			this.updateYAxisConfiguration({ label: changes.yAxisLabel.currentValue as string } as LineChartAxisConfig);
		}

		if (changes.yAxisFormatter) {
			this.updateYAxisConfiguration({ formatter: changes.yAxisFormatter.currentValue as string } as LineChartAxisConfig);
		}

		if (changes.lineIsSmooth) {
			this.updateLineConfiguration({ isSmooth: changes.lineIsSmooth.currentValue as boolean } as LineConfig);
		}

		if (changes.lineIsThemeColored) {
			this.updateLineConfiguration({ isThemeColored: changes.lineIsThemeColored.currentValue as boolean } as LineConfig);
		}

		if (changes.data) {
			this.setData(changes.data.currentValue as LineChart[]);
		}
	}

	public ngOnInit() {
		this.subscribeToResizeLayoutChange();
	}

	public ngOnDestroy() {
		this.resizeObserver.disconnect();
	}

	private setData(data: LineChart[]) {
		if (data) {
			if (!this.chart) {
				this.chart = init(this.chartContainer.nativeElement as HTMLElement);
			} else {
				this.chart?.clear();
			}

			const color = this.line.isThemeColored
				? COLORS.rainbowColorScheme
				: [
						this.grayDarkColor,
						this.grayLightColor,
						this.grayDarkColor,
						this.grayLightColor,
						this.grayDarkColor,
						this.grayLightColor,
						this.grayDarkColor,
						this.grayLightColor,
						this.grayDarkColor
				  ];

			const options = {
				color,
				tooltip: this.getTooltipConfig(),
				grid: this.getGridConfig(),
				legend: this.getLegendConfig(),
				xAxis: this.getXAxisConfig(),
				yAxis: this.getYAxisConfig(),
				series: this.getSeriesConfig(data)
			};

			this.chart.setOption(options);
		} else {
			this.chart?.dispose();
		}
	}

	private getTooltipConfig() {
		return {
			trigger: 'axis',
			formatter: (dataParams: { data: number[]; dataIndex: number }[]) => {
				if (this.customTooltip) {
					const labelValues = dataParams.reduce(
						(acc, { data: [axisLabel, axisValue], dataIndex }) => ({
							index: dataIndex,
							label: axisLabel,
							values: [...(acc.values || []), axisValue]
						}),
						{} as { index: number; label: number; values: number[] }
					);

					this.tooltipContainer.createEmbeddedView(this.customTooltip, { labelValues });
					return this.tooltipContainer.element.nativeElement.parentNode.lastElementChild as HTMLElement;
				}

				return undefined;
			},
			axisPointer: {
				type: 'line',
				axis: 'x',
				snap: true,
				lineStyle: {
					type: 'solid',
					width: 1
				}
			}
		};
	}

	private getGridConfig() {
		return {
			top: 40,
			bottom: this.legendIsVisible ? 80 : 55,
			left: 100,
			right: 40
		};
	}

	private getLegendConfig() {
		return {
			show: this.legendIsVisible,
			itemWidth: 22,
			itemHeight: 7,
			itemGap: 5,
			bottom: 0,
			icon: 'rect',
			tooltip: {
				show: this.legendTooltipIsVisible,
				formatter: ({ name }: CallbackDataParams) => `${name}`
			}
		};
	}

	private getXAxisConfig() {
		return {
			type: 'category',
			axisTick: { show: false },
			name: this.xAxis.label,
			nameLocation: 'middle',
			nameGap: 30,
			nameTextStyle: {
				color: this.grayDarkColor,
				fontSize: 14,
				fontWeight: 600
			},
			axisLine: { show: true, lineStyle: { color: this.grayDarkColor } },
			axisLabel: {
				formatter: this.chartService.getFormatter(this.xAxis.formatter)
			}
		};
	}

	private getYAxisConfig() {
		return [
			{
				type: 'value',
				axisTick: { show: false },
				name: this.yAxis.label,
				nameLocation: 'middle',
				nameGap: 85,
				nameTextStyle: {
					color: this.grayDarkColor,
					fontSize: 14,
					fontWeight: 600
				},
				axisLine: { show: true, lineStyle: { color: this.grayDarkColor } },
				axisLabel: {
					formatter: this.chartService.getFormatter(this.yAxis.formatter)
				}
			}
		];
	}

	private getSeriesConfig(data: LineChart[]) {
		return data.map(element => ({
			smooth: this.line.isSmooth,
			type: 'line',
			symbolSize: 1.5,
			name: element.label,
			lineStyle: { width: 2 },
			data: element.values.map(el => [el.label, el.value])
		}));
	}

	private updateXAxisConfiguration(config: LineChartAxisConfig) {
		this.xAxis = { ...this.xAxis, ...config };
	}

	private updateYAxisConfiguration(config: LineChartAxisConfig) {
		this.yAxis = { ...this.yAxis, ...config };
	}

	private updateLineConfiguration(config: LineConfig) {
		this.line = { ...this.line, ...config };
	}

	private subscribeToResizeLayoutChange() {
		this.resizeObserver = new ResizeObserver(() => {
			this.chart?.resize();
		});

		this.resizeObserver.observe(this.hostElement?.element?.nativeElement as Element);
	}
}

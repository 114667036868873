<article class='card'
         [style.max-width]='cardWidth'
         [style.min-width]='cardWidth'
         *ngFor='let card of cards'>
  <header class='card-title'
          *ngIf='isTitleVisible(card)'>
    <h3 class='title title-left truncate-1'
        [title]='card.title.left'
        *ngIf='card.title.left'>
      {{card.title.left}}
    </h3>
    <h3 class='title title-right truncate-1'
        [title]='card.title.right'
        *ngIf='card.title.right'>
      {{card.title.right}}
    </h3>
  </header>
  <section class='card-body p-none'>
    <ng-container [ngSwitch]='card.value.type'>
      <rq-comparison-list #comparisonItem
                          *ngSwitchCase='type.List'
                          [valueTemplate]='valueTemplate'
                          [data]='getListValue(card.value)'>
      </rq-comparison-list>

      <rq-comparison-table #comparisonItem
                           *ngSwitchCase='type.Table'
                           [valueTemplate]='valueTemplate'
                           [data]='getTableValue(card.value)'>
      </rq-comparison-table>

      <rq-comparison-collapsible #comparisonItem
                                 *ngSwitchCase='type.Collapsible'
                                 [valueTemplate]='valueTemplate'
                                 [data]='getCollapsibleValue(card.value)'>
      </rq-comparison-collapsible>
    </ng-container>
  </section>
</article>
<ng-template #valueTemplate
             let-entity='entity'>
  <span class='comparison-value-container'>
    <ng-container *ngIf='hasIcon(entity)'>
      <span class="m-r-xs"
            *ngIf="entity.hasMoreDetails">
        <rq-icon class="pointer"
                 href="icon-file"
                 (click)="viewMoreDetails(entity.data)"></rq-icon>
      </span>
      <span [attr.title]='entity.value'
            [ngClass]="getColor(entity.theme, 'color')"
            class='comparison-value comparison-value-width-icon'>
        {{entity.value}}
      </span>
      <span>
        <rq-icon [ngClass]="getColor(entity.theme, 'fill')"
                 href='{{getIcon(entity.icon)}}'>
        </rq-icon>
      </span>
    </ng-container>
    <ng-container *ngIf='!hasIcon(entity)'>
      <span [attr.title]='entity.value'
            class='comparison-value'>
        {{entity.value}}
      </span>
    </ng-container>
  </span>
</ng-template>

import { Component, EventEmitter, Input, Output, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { NotificationDetailedModel } from '../../../server/models/notification-detailed-model.model';
import { NotificationFilterModel } from '../../../server/models/notification-filter-model.model';
import { NotificationsViewModel } from '../../../routes/control-analysis/_common/models/notifications.viewmodel';
import { ModalComponent } from '../../../standalone/components/modal/modal.component';
import { TableOutput } from '../../../standalone/components/table/models/table-output.model';

@Component({
	selector: 'rq-notifications-modal',
	templateUrl: './notifications-modal.component.html',
	styleUrls: ['./notifications-modal.component.scss']
})
export class NotificationsModalComponent implements OnChanges {
	@ViewChild(ModalComponent, { static: true })
	public modal!: ModalComponent;

	@Input()
	public data?: NotificationDetailedModel;

	@Input()
	public hasFilters = true;

	@Input()
	public hasActions = true;

	@Output()
	public readonly loadTableData = new EventEmitter<TableOutput<NotificationFilterModel>>();

	public tableCount!: number;

	public tableData!: Array<NotificationsViewModel>;

	public ngOnChanges(changes: SimpleChanges) {
		const data = changes.data?.currentValue as NotificationDetailedModel;
		const isFirstDataChange = changes.data?.isFirstChange();

		if (!isFirstDataChange && data) {
			this.setupTableData(data);
		}
	}

	public show(model?: NotificationDetailedModel) {
		if (model) {
			this.setupTableData(model);
			this.modal.show();
		}
	}

	public closeModal() {
		this.modal.hide();
		this.tableCount = 0;
		this.tableData = [];
	}

	public loadData(data: TableOutput<NotificationFilterModel>) {
		this.loadTableData.emit(data);
	}

	private setupTableData(model: NotificationDetailedModel) {
		this.tableCount = model.subNotifications.count;
		this.tableData = model.subNotifications.values.map(
			x =>
				<NotificationsViewModel>{
					value: x.value,
					createdDate: x.createDate,
					notificationType: x.type
				}
		);
	}
}

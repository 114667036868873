import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { RecordCount } from 'src/app/server/enums/record-count.enum';

@Pipe({
	name: 'recordCountLabel'
})
export class RecordCountTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: RecordCount) {
		const values = new Map<RecordCount, string>();
		values.set(RecordCount.Between_100Mto500M, 'recordCount_Between_100Mto500M');
		values.set(RecordCount.Between_10Mto30M, 'recordCount_Between_10Mto30M');
		values.set(RecordCount.Between_30Mto100M, 'recordCount_Between_30Mto100M');
		values.set(RecordCount.Greater_500M, 'recordCount_Greater_500M');
		values.set(RecordCount.Less_10M, 'recordCount_Less_10M');
		values.set(RecordCount.Invalid, 'recordCount_invalid');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${value} does not exist`);
		}

		return this.translationService.get(type);
	}
}

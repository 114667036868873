import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	TemplateRef
} from '@angular/core';
import { ComparisonTable, ComparisonTableColumn } from './comparison-table.model';
import { ComparisonAbstract } from '../comparison.abstract';
import { ComparisonService } from '../comparison.service';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { ComparisonTemplate } from '../comparison.model';

@Component({
	selector: 'rq-comparison-table',
	templateUrl: './comparison-table.component.html',
	styleUrls: ['./comparison-table.component.scss']
})
export class ComparisonTableComponent extends ComparisonAbstract implements OnInit, AfterViewInit, OnDestroy {
	@Input()
	public data = new ComparisonTable([]);

	@Input()
	public valueTemplate!: TemplateRef<undefined>;

	public ComparisonTableColumn = ComparisonTableColumn;

	private element!: HTMLElement;

	private windowResize = new Subject();

	private tableBodyContainerWidth!: number;

	private columns = new Array<{ percent: number; value: number | null }>();

	constructor(comparisonService: ComparisonService, elementRef: ElementRef, private cd: ChangeDetectorRef) {
		super(comparisonService);

		this.element = elementRef.nativeElement as HTMLElement;
	}

	@HostListener('window:resize')
	public triggerResize() {
		this.windowResize.next();
	}

	@HostListener('mouseleave')
	public mouseLeave() {
		this.comparisonService.triggerHover.next();
	}

	public ngOnInit(): void {
		this.subscribeToHoverChange();

		this.subscribeToWindowResize();

		this.subscribeToLoading();
	}

	public ngAfterViewInit(): void {
		this.calculateMaxPercentWidth();

		this.calculateColumnWidth();
	}

	public ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	public getTemplate(column: ComparisonTableColumn | ComparisonTemplate): TemplateRef<unknown> | null {
		if ('template' in column) {
			return column.template;
		}
		return null;
	}

	public getValue(column: ComparisonTableColumn | ComparisonTemplate): string {
		if ('value' in column) {
			return column.value;
		}
		return '';
	}

	public getTitle(value: string, columnIndex: number) {
		return this.columns[columnIndex]?.value ? value : null;
	}

	public getColumnWidth(index: number) {
		return this.columns[index] ? `${this.columns[index].value}px` : null;
	}

	private subscribeToWindowResize() {
		this.subscriptions.push(
			this.windowResize
				.asObservable()
				.pipe(throttleTime(1000))
				.subscribe(() => this.calculateColumnWidth())
		);
	}

	private calculateColumnWidth() {
		const containerRectWidth = this.element.getBoundingClientRect().width;
		const row = this.element.querySelector('tr');

		if (row) {
			row.querySelectorAll('td').forEach((_, index) => {
				if (this.tableBodyContainerWidth > containerRectWidth) {
					this.columns[index].value = (this.columns[index].percent * containerRectWidth) / 100;
				} else {
					this.columns[index].value = null;
				}
			});
		}

		this.cd.detectChanges();
	}

	private calculateMaxPercentWidth() {
		this.tableBodyContainerWidth = (
			this.element.querySelector<HTMLTableElement>('tbody') as HTMLTableElement
		).getBoundingClientRect().width;

		const row = this.element.querySelector('tr');

		if (row) {
			row.querySelectorAll('td').forEach(column => {
				const columnRect = column.getBoundingClientRect();
				const maxColumnPercentWidth = (columnRect.width * 100) / this.tableBodyContainerWidth;

				this.columns.push({ percent: maxColumnPercentWidth, value: 0 });
			});
		}
	}
}

<rq-card-tree *ngIf="node"
              [node]="node"
              [config]="treeConfig">
  <ng-template let-data="data">
    <ng-container *ngIf="isConfigured(data)">
      <section class="tree-container">
        <section class="tree-content d-flex text-center m-b-md">
          <div class="m-r-xs flex-grow card-item">
            <p>
              <strong>{{'scenarios_semiAutomated_viewDetails_min' | translate }} </strong>
            </p>
            <p class="card-item-value"
               [title]="formatValue(data.minimum, data.type)">
              <strong>{{formatValue(data.minimum, data.type)}}</strong>
            </p>
          </div>
          <div class="flex-grow card-item">
            <p>
              <strong>{{'scenarios_semiAutomated_viewDetails_avg' | translate }}</strong>
            </p>
            <p class="accent-color card-item-value"
               [title]="formatValue(data.mostLikely, data.type)">
              <strong>{{formatValue(data.mostLikely, data.type)}}</strong>
            </p>
          </div>
          <div class="m-l-xs flex-grow card-item">
            <p>
              <strong>{{'scenarios_semiAutomated_viewDetails_max' | translate }}</strong>
            </p>
            <p class="card-item-value"
               [title]="formatValue(data.maximum, data.type)">
              <strong>{{formatValue(data.maximum, data.type)}}</strong>
            </p>
          </div>
        </section>
        <section class="frequency m-b-md text-md"
                 *ngIf="data.frequency">
          <p>{{data.frequency}}</p>
        </section>
        <section class="tree-description">
          <p>{{getDataDescription(data)}}</p>
        </section>
      </section>
    </ng-container>
    <ng-container *ngIf="!isConfigured(data)">
      <p class="text-base">
        <strong>{{getDataDescription(data)}}</strong>
      </p>
    </ng-container>
  </ng-template>
  <ng-template let-data="data"
               #moreDetails>
    <rq-popover>
      <rq-icon href="icon-help-circle"
               class="m-l-sm"
               color="color-light"></rq-icon>
      <div [width]="250"
           class="paragraph more-details"
           rqPopoverContent>
        {{'scenarios_viewDetailsCard_moreDetails' | translate : {magnitudePercentage: data.magnitudePercentage | rqPercent} }}
      </div>
    </rq-popover>
  </ng-template>
</rq-card-tree>

import { PipeTransform, Pipe } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { TargetEndpointStatusType } from 'src/app/server/enums/target-endpoint-status-type.enum';

@Pipe({
	name: 'targetEndpointStatusTypeLabel'
})
export class TargetEndpointStatusTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: TargetEndpointStatusType) {
		const values = new Map<TargetEndpointStatusType, string>();
		values.set(TargetEndpointStatusType.Na, 'global_notApplicable_label');
		values.set(TargetEndpointStatusType.NotProvided, 'global_notProvided_label');
		values.set(TargetEndpointStatusType.Provided, 'global_provided_label');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${value} does not exist`);
		}

		return this.translationService.get(type);
	}
}

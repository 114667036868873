import { ROLE_CONFIG } from '../../../shared/core/roles/role.config';
import { ControlStatus } from '../../../shared/enums/control-status.enum';
import { AuthorizeInputModel } from '../../../shared/core/roles/authorize-input.model';
import { Directive } from '@angular/core';
import { BaseComponent } from '../../../shared/components/base.component';

@Directive()
export class StandardBase extends BaseComponent {
	private authState: AuthorizeInputModel[] = [];

	public get authorizeState() {
		if (this.authState.length === 0) {
			this.authState = this.defaultAuthState();
		}
		return this.authState;
	}

	public get legalEntityId() {
		return (this.params.legalEntityId as string) || '';
	}

	public setAuthorizeState(state: AuthorizeInputModel[]) {
		this.authState = state;
	}

	private defaultAuthState() {
		return [
			{
				role: ROLE_CONFIG.pro.readOnly,
				status: ControlStatus.Disabled
			},
			{
				role: ROLE_CONFIG.pro.fairOnly,
				status: ControlStatus.Disabled
			}
		];
	}
}

import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { LoadingBoxType } from './loading-box-type.enum';

@Component({
	selector: 'rq-loading-box',
	templateUrl: './loading-box.component.html',
	styleUrls: ['./loading-box.component.scss']
})
export class LoadingBoxComponent implements OnChanges, OnDestroy {
	@Input()
	public promise!: Promise<unknown>;

	@Input()
	public type?: LoadingBoxType = LoadingBoxType.Card;

	public loading: boolean;

	// remove this when promise is removed
	public toggleMode: boolean;

	constructor(private changeDetectionRef: ChangeDetectorRef) {
		this.loading = true;
		this.toggleMode = false;
	}

	public toggle(isActive: boolean) {
		this.loading = isActive;
		this.changeDetectionRef.detectChanges();
	}

	public ngOnChanges() {
		if (!this.toggleMode) {
			this.handleLoadingState();
		}
	}

	public ngOnDestroy(): void {
		this.changeDetectionRef.detach();
	}

	public showCardSkeleton(): boolean {
		return this.loading && this.type === LoadingBoxType.Card;
	}

	public showOverviewSkeleton(): boolean {
		return this.loading && this.type === LoadingBoxType.Overview;
	}

	public showChartSkeleton(): boolean {
		return this.loading && this.type === LoadingBoxType.Chart;
	}

	public showTableSkeleton(): boolean {
		return this.loading && this.type === LoadingBoxType.Table;
	}

	private handleLoadingState() {
		this.loading = true;

		if (!this.promise) {
			return;
		}

		if (!this.type) {
			this.type = LoadingBoxType.Card;
		}
		this.promise.then(() => {
			this.loading = false;
		});
	}
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { IntegrationSettingsModel } from '../../models/integration-settings-model.model';
import { IntegrationSettingsCreateModel } from '../../models/integration-settings-create-model.model';
import { Pagination } from '../../models/pagination.model';
import { IntegrationSettingsDefinitionModel } from '../../models/integration-settings-definition-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { IntegrationSettingsFilterModel } from '../../models/integration-settings-filter-model.model';
import { IntegrationSettingsUpdateModel } from '../../models/integration-settings-update-model.model';
import { IntegrationFileModel } from '../../models/integration-file-model.model';
import { IActionResult } from '../../models/iaction-result.model';

@Injectable()
export class IntegrationSettingsDataService {
	constructor(private httpService: RQHttpService) {}

	public create(model: IntegrationSettingsCreateModel, options = new HttpOptionsViewModel()): Promise<IntegrationSettingsModel> {
		options.body = model;

		return this.httpService
			.request<IntegrationSettingsModel>('post', environment.api + '/api/Integration/IntegrationSettings/Create', options)
			.toPromise();
	}

	public getPaginated(
		legalEntityId: string,
		pagination: PaginationFilter,
		filter: IntegrationSettingsFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<IntegrationSettingsDefinitionModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<IntegrationSettingsDefinitionModel>>(
				'get',
				environment.api + '/api/Integration/IntegrationSettings/GetPaginated',
				options
			)
			.toPromise();
	}

	public getById(integrationId: string, options = new HttpOptionsViewModel()): Promise<IntegrationSettingsModel> {
		options.params = this.httpService.getHttpParams({
			integrationId
		});

		return this.httpService
			.request<IntegrationSettingsModel>('get', environment.api + '/api/Integration/IntegrationSettings/GetById', options)
			.toPromise();
	}

	public getByName(legalEntityId: string, name: string, options = new HttpOptionsViewModel()): Promise<IntegrationSettingsModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			name
		});

		return this.httpService
			.request<IntegrationSettingsModel>('get', environment.api + '/api/Integration/IntegrationSettings/GetByName', options)
			.toPromise();
	}

	public update(model: IntegrationSettingsUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = model;

		return this.httpService.request<void>('post', environment.api + '/api/Integration/IntegrationSettings/Update', options).toPromise();
	}

	public download(integrationId: string, options = new HttpOptionsViewModel()): Promise<IntegrationFileModel> {
		options.params = this.httpService.getHttpParams({
			integrationId
		});

		return this.httpService
			.request<IntegrationFileModel>('get', environment.api + '/api/Integration/IntegrationSettings/Download', options)
			.toPromise();
	}

	public delete(integrationId: string, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.params = this.httpService.getHttpParams({
			integrationId
		});

		return this.httpService
			.request<IActionResult>('delete', environment.api + '/api/Integration/IntegrationSettings/Delete', options)
			.toPromise();
	}
}

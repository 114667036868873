<label class='filter-label'><strong>{{displayName}}</strong></label>
<header (click)='toggleVisibility()'
        [class.filter-calendar-disabled]='isDisabled'
        class='filter-calendar-header'>
  <span class='filter-calendar-title'>{{placeholder}}</span>
  <span class='filter-icon'>
    <span class='filter-calendar-icon'></span>
    <span class='filter-calendar-icon'></span>
  </span>
</header>
<section *ngIf='isExpanded'
         class='filter-calendar-body'>
  <div>
    <div *ngIf='isCustomRange'
         class='d-flex justify-content-between b b-bottom p-r-sm'>
      <div class='d-flex flex-row'>
        <div class='p-xs'>
          {{ start ? (start | rqDate:{ pattern: format }) : 'global_start_date_label' | translate}}
        </div>
        <div class='p-xs'>/</div>
        <div class='p-xs'>
          {{ end ? (end | rqDate:{ pattern: format }) : 'global_endDate_label' | translate}}
        </div>
      </div>
      <div class='d-flex align-items-center'>
        <rq-icon (click)='closeFilter()'
                 class='pointer'
                 href='icon-close'></rq-icon>
      </div>
    </div>
    <div class='d-flex flex-row'>
      <div class='filter-range-calendar-options'>
        <p class='p-xs white-bg-color'>
          {{ 'global_occurrence_label' | translate }}
        </p>
        <ng-container *ngFor='let option of options'>
          <p (click)='triggerChange(option)'
             [class.range-calendar-option-active]='option.isSelected'
             class='p-xs range-calendar-option'>
            {{option.displayName | translate}}
          </p>
        </ng-container>
      </div>
      <div *ngIf='isCustomRange'
           class='d-flex b b-left'>
        <div *ngIf='start'>
          <div class='d-flex'>
            <div class='d-flex flex-column p-l-sm p-r-lg'>
              <rq-calendar (endChange)='updateEnd($event)'
                           (startChange)='updateStart($event)'
                           [(ngModel)]='calendarModel.start'
                           [end]='end'
                           [hoveredDate]='hoveredDate'
                           [isInterval]='true'
                           [start]='start'
                           [min]='min'
                           [max]='max'
                           class='b b-none'>
              </rq-calendar>
            </div>
          </div>
          <div class='d-flex justify-content-end p-sm'>
            <button (click)='applySettings()'
                    [class.disabled]='!end'
                    class='button accent'>
              {{ 'global_apply_label' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="point-slider-wrapper">
  <div *ngIf="leftLabel"
       class="m-r-sm point-slider-label">{{leftLabel}}</div>
  <div class="point-slider">
    <ng-container *ngFor="let point of points; let last = last; let index = index">
      <div [ngClass]="{'point': true, 'active': point.value === activePointValue, 'after-active': index > activePointIndex}"
           (click)="onPointClick(point.value, index)"
           (mouseenter)="onMouseEnter(point.value)"
           (mouseleave)="onMouseLeave()">
        <div class="point-name"
             [class.selected]="point.value === activePointValue">
          {{ point.name }}
        </div>
      </div>
      <div *ngIf="!last"
           class="line"
           [class.line-after-active]="index >= activePointIndex"></div>
    </ng-container>
  </div>
  <div *ngIf="rightLabel"
       class="m-l-sm point-slider-label">{{rightLabel}}</div>
</div>

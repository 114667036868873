import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	AbstractControl,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators
} from '@angular/forms';
import { ROLE_CONFIG } from '../../core/roles/role.config';
import { TranslationService } from '../../services/translation.service';
import { UserManagementService } from '../../services/user-management.service';
import {
	RoleAccessRightsViewModel,
	UserEditConfigViewModel,
	UserEditViewModel,
	UserRoleViewModel
} from '../../models/user-edit-form.model';
import { IdentityService } from '../../services/identity.service';
import { from } from 'linq-to-typescript';
import { StandardBase } from '../../../routes/control-analysis/_common/standard.base';
import { noWhiteSpaceValidator } from '../../../standalone/utils/form-validators.utils';

@Component({
	selector: 'rq-user-edit-form',
	templateUrl: './user-edit-form.component.html',
	styleUrls: ['./user-edit-form.component.scss']
})
export class UserEditFormComponent extends StandardBase implements OnInit {
	@Input()
	public user!: UserEditViewModel;

	@Input()
	public config!: UserEditConfigViewModel;

	@Output()
	public readonly triggerSubmit = new EventEmitter<UserEditViewModel>();

	public form!: UntypedFormGroup;

	public validationOpt = {
		validation: {
			emailNotUnique: 'settings_user_management_create_user_email_validator_notUnique',
			emailChar: () => this.form.controls.email.errors?.emailChar.value as string
		}
	};

	private accessRights!: RoleAccessRightsViewModel;

	constructor(
		public userManagementService: UserManagementService,
		private translateService: TranslationService,
		private identityService: IdentityService,
		private formBuilder: UntypedFormBuilder
	) {
		super();
	}

	public get isValid() {
		this.form.markAllAsTouched();

		return this.form.valid;
	}

	public get rolesFormGroup() {
		return this.form.get('roles') as UntypedFormGroup;
	}

	public get isFormDisabled(): boolean {
		return Object.keys(this.form.controls).filter(key => this.form.controls[key].enabled).length === 0;
	}

	private get isEditing() {
		return this.user && typeof this.user.id !== 'undefined';
	}

	private get isRoleConfigured() {
		const rolesFormGroup = this.form.controls.roles as UntypedFormGroup;

		return (
			Object.keys(rolesFormGroup.controls)
				.filter(roleKey => roleKey !== ROLE_CONFIG.pro.exportApi)
				.filter(roleKey => {
					const control = rolesFormGroup.controls[roleKey];

					return control.value;
				}).length > 0
		);
	}

	public ngOnInit() {
		this.setupForm();

		this.setupRoleRules();

		this.setupFormState();
	}

	public submit() {
		this.form.markAllAsTouched();

		if (this.isValid) {
			this.triggerSubmit.emit(this.getUser());
		}
	}

	public getControl(id: string) {
		return this.rolesFormGroup.controls[id] as UntypedFormControl;
	}

	public isRoleVisible(role: string) {
		return role === ROLE_CONFIG.pro.exportApi ? this.config.isExportRoleVisible : true;
	}

	public getUser(): UserEditViewModel {
		const user = new UserEditViewModel();
		user.name = this.form.controls.name.value.trim() as string;
		user.title = this.form.controls.title.value.trim() as string;
		user.email = this.form.controls.email.value.trim() as string;
		user.roles = this.getUserRoles();
		user.id = this.user.id;
		user.providerUserId = this.user.providerUserId;

		return user;
	}

	private requiredGroup(formGroup: UntypedFormGroup): ValidatorFn {
		return (_control: AbstractControl): ValidationErrors | null => {
			const hasAnyRoleSelected = from(Object.keys(formGroup.controls)).any(roleKey => formGroup.controls[roleKey].value as boolean);

			return hasAnyRoleSelected ? null : <ValidationErrors>{ required: true };
		};
	}

	private buildRolesFormGroup(roles: UserRoleViewModel[]): UntypedFormGroup {
		const group = this.formBuilder.group({});

		roles.forEach(role => {
			const control = this.formBuilder.control(role.value);

			const sub = control.valueChanges.subscribe(() => this.toggleEnabledState());

			this.subscriptions.push(sub);

			group.addControl(role.name, control);
		});

		group.setValidators(this.requiredGroup(group));

		return group;
	}

	private setupForm() {
		this.form = this.formBuilder.group({
			name: [this.user.name, [Validators.required, Validators.maxLength(50), noWhiteSpaceValidator()]],
			title: [this.user.title ?? '', [Validators.required, Validators.maxLength(50), noWhiteSpaceValidator()]],
			email: this.userManagementService.formControl(this.user.email),
			roles: this.buildRolesFormGroup(this.user.roles)
		});
	}

	private getUserRoles(): UserRoleViewModel[] {
		const roles = new Array<UserRoleViewModel>();

		for (const key in this.rolesFormGroup.controls) {
			if (this.rolesFormGroup.controls[key].value) {
				roles.push({ name: key, displayName: this.translateService.get(key) } as UserRoleViewModel);
			}
		}

		return roles;
	}

	private setupFormState() {
		if (this.user.providerUserId) {
			this.form.disable({ emitEvent: false });

			if (this.user.hasDefaultRoleForSSOLogin) {
				this.form.controls.title.enable({ emitEvent: false });
				this.toggleEnabledState();
			}
		} else {
			this.toggleEnabledState();
		}

		if (this.isEditing) {
			this.form.controls.email.disable({ emitEvent: false });
		}
	}

	private toggleEnabledState() {
		const formGroup = this.form.controls.roles as UntypedFormGroup;

		const accessRights = from(Object.keys(this.accessRights))
			.where(roleKey => this.identityService.isInRole(roleKey))
			.selectMany(roleKey => this.accessRights[roleKey])
			.distinct();

		Object.keys(formGroup.controls).forEach(roleControlKey => {
			const hasAccessRights = accessRights.any(role => role === roleControlKey);
			const control = formGroup.controls[roleControlKey];

			const isControlEnabled = this.isControlEnabled(hasAccessRights, control, roleControlKey);

			isControlEnabled ? control.enable({ emitEvent: false }) : control.disable({ emitEvent: false });
		});
	}

	private setupRoleRules() {
		this.accessRights = new RoleAccessRightsViewModel();
		this.accessRights[ROLE_CONFIG.pro.readOnly] = [];
		this.accessRights[ROLE_CONFIG.pro.fairOnly] = [];
		this.accessRights[ROLE_CONFIG.pro.exportApi] = [];
		this.accessRights[ROLE_CONFIG.pro.editor] = [ROLE_CONFIG.pro.exportApi];
		this.accessRights[ROLE_CONFIG.pro.administrator] = Object.values(ROLE_CONFIG.pro);

		this.accessRights[ROLE_CONFIG.enterprise.readOnly] = [];
		this.accessRights[ROLE_CONFIG.enterprise.administrator] = Object.values(ROLE_CONFIG.pro).concat(Object.values(ROLE_CONFIG.enterprise));
		this.accessRights[ROLE_CONFIG.instance.administrator] = this.accessRights[ROLE_CONFIG.enterprise.administrator];
	}

	private isControlEnabled(hasAccessRights: boolean, control: AbstractControl, roleControlKey: string) {
		let result = hasAccessRights;

		if (this.isRoleConfigured && roleControlKey !== ROLE_CONFIG.pro.exportApi) {
			result = result && (control.value as boolean);
		}

		return result;
	}
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { IntegrationQualysSettingsModel } from '../../models/integration-qualys-settings-model.model';
import { IntegrationQualysSettingsCreateModel } from '../../models/integration-qualys-settings-create-model.model';
import { IntegrationQualysSettingsUpdateModel } from '../../models/integration-qualys-settings-update-model.model';
import { IntegrationQualysSettingsBaseModel } from '../../models/integration-qualys-settings-base-model.model';

@Injectable()
export class IntegrationQualysSettingsDataService {
	constructor(private httpService: RQHttpService) {}

	public getByIntegrationId(integrationId: string, options = new HttpOptionsViewModel()): Promise<IntegrationQualysSettingsModel> {
		options.params = this.httpService.getHttpParams({
			integrationId
		});

		return this.httpService
			.request<IntegrationQualysSettingsModel>(
				'get',
				environment.api + '/api/Integration/IntegrationQualysSettings/GetByIntegrationId',
				options
			)
			.toPromise();
	}

	public create(createModel: IntegrationQualysSettingsCreateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = createModel;

		return this.httpService
			.request<void>('post', environment.api + '/api/Integration/IntegrationQualysSettings/Create', options)
			.toPromise();
	}

	public update(updateModel: IntegrationQualysSettingsUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = updateModel;

		return this.httpService
			.request<void>('post', environment.api + '/api/Integration/IntegrationQualysSettings/Update', options)
			.toPromise();
	}

	public isConnectionValid(qualysSettings: IntegrationQualysSettingsBaseModel, options = new HttpOptionsViewModel()): Promise<boolean> {
		options.body = qualysSettings;

		return this.httpService
			.request<boolean>('post', environment.api + '/api/Integration/IntegrationQualysSettings/IsConnectionValid', options)
			.toPromise();
	}
}

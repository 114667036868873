import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { LicenseType } from 'src/app/server/enums/license-type.enum';

@Pipe({
	name: 'licenseTypeLabel'
})
export class LicenseTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: LicenseType) {
		const values = new Map<LicenseType, string>();
		values.set(LicenseType.Trial, 'global_RQ_Trial');
		values.set(LicenseType.Pro, 'global_RQ_Pro');
		values.set(LicenseType.Enterprise, 'global_RQ_Enterprise');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${value} does not exist`);
		}

		return this.translationService.get(type);
	}
}

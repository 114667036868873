import { Component, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { ThreeDotsMenuItemComponent } from '../three-dots-menu-item.component/three-dots-menu-item.component';

@Component({
	selector: 'rq-three-dots-menu-items',
	templateUrl: './three-dots-menu-items.component.html',
	styleUrls: ['./three-dots-menu-items.component.scss']
})
export class ThreeDotsMenuItemsComponent {
	@Input()
	public options!: QueryList<ThreeDotsMenuItemComponent>;

	@Output()
	public readonly itemClicked = new EventEmitter();

	public clicked(item?: ThreeDotsMenuItemComponent) {
		this.itemClicked.emit();
		if (item) {
			item.clickAction.emit();
		}
	}
}

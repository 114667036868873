import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ComparisonButtonConfig } from '../comparer.model';

@Component({
	selector: 'rq-comparer-button',
	templateUrl: './comparer-button.component.html',
	styleUrls: ['./comparer-button.component.scss']
})
export class ComparerButtonComponent {
	@Input()
	public isExpanded!: boolean;

	@Input()
	public config!: ComparisonButtonConfig;

	@Input()
	public buttonEnabled = true;

	@Output()
	public readonly selected: EventEmitter<void> = new EventEmitter<void>();

	@Output()
	public readonly comparationRequested: EventEmitter<void> = new EventEmitter<void>();

	public cardClicked() {
		this.selected.emit();
	}

	public compare($event: Event) {
		if (this.buttonEnabled) {
			$event.stopPropagation();

			this.comparationRequested.emit();
		}
	}
}

import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
	standalone: true,
	selector: 'rq-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.scss'],
	imports: [NgIf, NgClass]
})
export class IconComponent {
	@Input()
	public href!: string;

	@Input()
	public color:
		| 'color-primary'
		| 'color-light'
		| 'color-accent'
		| 'color-link'
		| 'color-secondary'
		| 'color-disabled'
		| 'color-danger'
		| 'color-success'
		| 'color-inherit' = 'color-inherit';

	public iconHref!: string;

	public get isInitialized() {
		if (this.href) {
			this.iconHref = `./assets/icons/sprites.svg#${this.href}`;
		}

		return Boolean(this.iconHref);
	}
}

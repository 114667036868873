import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import { AttackNavigatorItemViewModel } from '../../../shared/models/attack-navigator-item.viewmodel';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from '../../../shared/components/base.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { clone } from '../../../standalone/utils/helpers.utils';

@Component({
	selector: 'rq-attack-navigator',
	templateUrl: './attack-navigator.component.html',
	styleUrls: ['./attack-navigator.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttackNavigatorComponent extends BaseComponent implements OnChanges, OnInit {
	@Input()
	public data!: Array<AttackNavigatorItemViewModel>;

	@Input()
	public hasCheckboxSelection = false;

	@Input()
	public isReadOnly = false;

	@Input()
	public showFinancialValue = false;

	@Input()
	public isSearchVisible = false;

	@Output()
	public readonly selectChange = new EventEmitter<Array<AttackNavigatorItemViewModel>>();

	public formGroup!: UntypedFormGroup;

	public templateData!: Array<AttackNavigatorItemViewModel>;

	public fullData!: Array<AttackNavigatorItemViewModel>;

	private searchCache = new Map<string, AttackNavigatorItemViewModel[]>();

	constructor(private cd: ChangeDetectorRef) {
		super();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		const data = changes.data?.currentValue as Array<AttackNavigatorItemViewModel>;
		if (data) {
			this.fullData = clone(data);
			this.templateData = clone(data);
		}
	}

	public ngOnInit() {
		if (this.isSearchVisible) {
			this.setupForm();
		}
	}

	public triggerSelectChange(data: { item: AttackNavigatorItemViewModel; isSelected: boolean }) {
		const coreItem = this.getSelectedItemFromFullData(data.item.mitreId);
		if (coreItem) {
			this.cascadeSelection(coreItem, data.isSelected);
		}
		const root = coreItem ? this.getRootItem(coreItem) : null;

		if (root) {
			root.isSelected = this.getSelectedCheckbox(root);
		}

		this.selectChange.emit(clone(this.fullData));
	}

	public triggerSelectAllChange(item: AttackNavigatorItemViewModel, isSelected: boolean) {
		const coreItem = this.getSelectedItemFromFullData(item.mitreId);
		if (coreItem) {
			this.cascadeSelection(item, isSelected);
			this.cascadeSelection(coreItem, isSelected);
		}

		this.selectChange.emit(clone(this.fullData));
	}

	public trackByParentId(_index: number, item: AttackNavigatorItemViewModel): string {
		return item.mitreId;
	}

	private getSelectedCheckbox(root?: AttackNavigatorItemViewModel): boolean {
		return (
			root?.items?.every(x => {
				if (x.items) {
					x.isSelected = this.getSelectedCheckbox(x);
					return x.isSelected;
				} else {
					return x.isSelected;
				}
			}) ?? false
		);
	}

	private getSelectedItemFromFullData(
		searchId: string,
		array: Array<AttackNavigatorItemViewModel> = this.fullData
	): AttackNavigatorItemViewModel | undefined {
		for (const x of array) {
			if (x.mitreId === searchId) {
				return x;
			}
			if (x.items) {
				const item = this.getSelectedItemFromFullData(searchId, x.items);
				if (item) {
					return item;
				}
			}
		}
		return undefined;
	}

	private cascadeSelection(item: AttackNavigatorItemViewModel, isSelected: boolean) {
		item.isSelected = isSelected;
		item.items?.forEach(child => this.cascadeSelection(child, isSelected));
	}

	private getRootItem(item: AttackNavigatorItemViewModel, data: Array<AttackNavigatorItemViewModel> = this.fullData) {
		function checkItems(items?: Array<AttackNavigatorItemViewModel>): boolean {
			return Boolean(
				items?.some(
					(subItem: AttackNavigatorItemViewModel) => subItem.mitreId === item.mitreId || (subItem.items && checkItems(subItem.items))
				)
			);
		}

		return data.find(x => checkItems(x.items));
	}

	private setupForm() {
		this.formGroup = new UntypedFormGroup({
			search: new UntypedFormControl(null)
		});

		this.subscriptions.push(
			this.formGroup.controls.search.valueChanges.pipe(debounceTime(100), distinctUntilChanged()).subscribe((value: string) => {
				const searchTerm = value ? value.toLowerCase() : '';
				this.templateData = searchTerm ? this.performSearch(searchTerm) : this.fullData;
				this.cd.markForCheck();
			})
		);
	}

	private performSearch(searchTerm: string) {
		if (!this.hasCheckboxSelection && this.searchCache.has(searchTerm)) {
			return this.searchCache.get(searchTerm) ?? [];
		}

		const filteredData = this.fullData.reduce<AttackNavigatorItemViewModel[]>((acc, parent) => {
			const filteredItems =
				parent.items?.filter(item => item.name.toLowerCase().includes(searchTerm) || item.mitreId.toLowerCase().includes(searchTerm)) ?? [];

			if (filteredItems.length) {
				acc.push({
					...parent,
					items: filteredItems
				});
			}

			return acc;
		}, []);

		this.searchCache.set(searchTerm, filteredData);
		return filteredData;
	}
}

import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../../standalone/components/dialog/dialog.component';
import { DialogResult } from '../../../../standalone/components/dialog/dialog-result.enum';

@Component({
	selector: 'rq-logout-confirmation-modal',
	templateUrl: './logout-confirmation-modal.component.html',
	styleUrls: ['./logout-confirmation-modal.component.scss']
})
export class LogoutConfirmationModalComponent {
	@ViewChild('dialog', { static: true })
	public dialog!: DialogComponent;

	@Output()
	public readonly accept = new EventEmitter();

	public async show() {
		this.dialog.show();

		const result = await this.dialog.show();
		if (result === DialogResult.Accept) {
			this.accept.emit();
		}
	}

	public closeDialog() {
		return this.dialog.decline();
	}
}

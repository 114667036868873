import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	SimpleChanges,
	ViewEncapsulation
} from '@angular/core';
import {
	DependencyTreeColumn,
	DependencyTreeConfig,
	DependencyTreeData,
	DependencyTreeInternalData,
	DependencyTreeInternalLink,
	DependencyTreeInternalNode,
	DependencyTreeLink,
	DependencyTreeNode
} from './dependency-tree-config.model';
import { Subscription } from 'rxjs';
import { ChartAbstract } from '../_common/chart.abstract';
import DependencyTreeRenderer from '../_common/renderers/dependency-tree.renderer';
import { COLORS } from '../../../utils/colors.utils';

@Component({
	selector: 'rq-dependency-tree',
	templateUrl: './dependency-tree.component.html',
	styleUrls: ['./dependency-tree.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DependencyTreeComponent extends ChartAbstract<DependencyTreeData> implements AfterViewInit, OnDestroy, OnChanges {
	@HostBinding('class.dependency-tree')
	public hostClass = true;

	@Input()
	public data!: DependencyTreeData;

	@Input()
	public preFilterNodes!: Function;

	@Input()
	public config!: DependencyTreeConfig;

	@Output()
	public readonly selection: EventEmitter<Array<DependencyTreeNode>> = new EventEmitter<Array<DependencyTreeNode>>();

	private subscriptions: Subscription[] = [];

	private dependencyTreeRenderer: DependencyTreeRenderer;

	private colorNeutralContrast = getComputedStyle(document.documentElement).getPropertyValue('--background-neutral-contrast');

	private colorNeutralFill = getComputedStyle(document.documentElement).getPropertyValue('--background-neutral-fill');

	constructor(elementRef: ElementRef) {
		super(elementRef.nativeElement as HTMLBaseElement);
		this.dependencyTreeRenderer = new DependencyTreeRenderer(this.context);
	}

	public ngAfterViewInit() {
		this.mergeConfigOptions();

		const selection = (data: Array<DependencyTreeInternalNode>) => {
			this.selectionCallback(data);
		};

		this.setup(() => this.dependencyTreeRenderer.setup(this.processData(this.data), this.config, selection, this.preFilterNodes));

		this.subscriptions.push(this.subscribeToResize(() => this.dependencyTreeRenderer.resize(this.processData(this.data), this.config)));
	}

	public ngOnChanges(change: SimpleChanges) {
		if (change?.data?.currentValue && !change.data.firstChange) {
			this.change(() => this.dependencyTreeRenderer.change(this.processData(change.data.currentValue as DependencyTreeData), this.config));
		}
	}

	public ngOnDestroy(): void {
		this.subscriptions.forEach(x => x.unsubscribe());
	}

	private selectionCallback(data: Array<DependencyTreeInternalNode>): void {
		if (data) {
			const result = data.reduce((memo: Array<DependencyTreeNode>, item: DependencyTreeInternalNode) => {
				memo.push(item.data as DependencyTreeNode);
				return memo;
			}, []);

			this.selection.emit(result);
		}
	}

	private mergeConfigOptions() {
		this.config.nodeStrokeColor = this.config.nodeStrokeColor ? this.config.nodeStrokeColor : this.colorNeutralFill;

		this.config.nodeLinkColor = this.config.nodeLinkColor ? this.config.nodeLinkColor : this.colorNeutralFill;

		this.config.nodeLockedStrokeColor = this.config.nodeLockedStrokeColor ? this.config.nodeLockedStrokeColor : this.colorNeutralContrast;

		if (!this.config.nodeFillColor) {
			this.config.nodeFillColor = this.colorNeutralFill;
		}

		this.config.nodeHoverFillColor = this.config.nodeHoverFillColor || this.config.nodeFillColor;

		this.config = Object.assign(new DependencyTreeConfig(), this.config, {
			primaryColors: COLORS.primaryColorScheme,
			secondaryColors: COLORS.rainbowColorScheme
		});
	}

	private processData(data: DependencyTreeData): DependencyTreeInternalData {
		const internalData: DependencyTreeInternalData = new DependencyTreeInternalData();
		let node: DependencyTreeInternalNode;
		const nodesMap: { [key: string]: DependencyTreeInternalNode } = {};
		const linksList: Array<Array<string>> = [];
		data.columns.forEach((column: DependencyTreeColumn) => {
			internalData.columns.push(column.name);
			column.nodes.forEach((dataNode: DependencyTreeNode) => {
				node = new DependencyTreeInternalNode();
				node.column = column.name;
				Object.assign(node, { id: dataNode.id, name: dataNode.name }, { data: dataNode });
				nodesMap[dataNode.id] = node;
				internalData.nodes.push(node);
				dataNode.links.forEach((link: DependencyTreeLink) => {
					linksList.push([dataNode.id, link.targetNodeId]);
				});
			});
		});

		linksList.forEach(item => {
			const row: Array<string> = item;
			const [sourceId, targetId] = row;
			const source: DependencyTreeInternalNode = nodesMap[sourceId];
			const target: DependencyTreeInternalNode = nodesMap[targetId];

			if (source && target) {
				internalData.links.push(new DependencyTreeInternalLink(source, target));
			}
		});

		return internalData;
	}
}

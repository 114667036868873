import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { CveRecommendationVariationType } from 'src/app/server/enums/cve-recommendation-variation-type.enum';

@Pipe({
	name: 'cveVariationTypeLabel'
})
export class CveVariationTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: CveRecommendationVariationType) {
		const values = new Map<CveRecommendationVariationType, string>();
		values.set(CveRecommendationVariationType.CveScoreWeighting, 'global_cvssScoreWeighting_label');
		values.set(CveRecommendationVariationType.CveTechnicalWeighting, 'global_otherTechnicalWeighting_label');
		values.set(CveRecommendationVariationType.CvssScore, 'global_cvssScore_label');
		values.set(CveRecommendationVariationType.HighestIndividualAle, 'global_highestIndividualALE_label');
		values.set(CveRecommendationVariationType.AggregatedBusinessApplicationAle, 'global_aggregated_ale');
		values.set(CveRecommendationVariationType.AggregatedCompanyAle, 'global_aggregatedCompanyALE_label');
		values.set(CveRecommendationVariationType.AggregatedCompanyAleAOOWeighting, 'dashboard_aggregatedCompanyAleAOOWeighting_label');
		values.set(CveRecommendationVariationType.AggregatedCompanyAleNotAOOWeighting, 'dashboard_aggregatedCompanyAleNotAOOWeighting_label');
		values.set(
			CveRecommendationVariationType.AggregatedBusinessApplicationAleWeighting,
			'dashboard_aggregatedBusinessApplicationAleWeighting_label'
		);
		values.set(CveRecommendationVariationType.HighestIndividualAleWeighting, 'dashboard_highestIndividualAleWeighting_label');
		values.set(CveRecommendationVariationType.CveExploitFoundInTheWildWeighting, 'dashboard_cveExploitFoundInTheWildWeighting_label');
		values.set(CveRecommendationVariationType.CveExploitNotFoundInTheWildWeighting, 'dashboard_cveExploitNotFoundInTheWildWeighting_label');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${value} is not handled`);
		}

		return this.translationService.get(type);
	}
}

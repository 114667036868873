import { Component, Input } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { CalculationType } from '../../../server/enums/calculation-type.enum';
import { ApplicationRiskSummaryViewModel } from '../../../routes/control-analysis/_common/models/application-summary-bar-chart.model';
import { CalculationTypeTranslate } from '../../pipes/translate-pipes/calculation-type-label.pipe';
import { NumberFormatterService } from '../../services/number-formatter.service';
import { BarChart } from '../../../standalone/components/charts/bar-chart/models/bar-chart.model';
import { StandardBase } from '../../../routes/control-analysis/_common/standard.base';

@Component({
	selector: 'rq-application-summary-bar-chart',
	templateUrl: './application-summary-bar-chart.component.html',
	styleUrls: ['./application-summary-bar-chart.component.scss']
})
export class ApplicationSummaryBarChartComponent extends StandardBase {
	@Input()
	public count!: number;

	public series!: BarChart[];

	constructor(
		public translationService: TranslationService,
		public numberFormatterService: NumberFormatterService,
		private calculationTypeTranslate: CalculationTypeTranslate
	) {
		super();
	}

	public async setData(dataLoader: () => Promise<ApplicationRiskSummaryViewModel[]>) {
		const data = await dataLoader();

		this.series = data.map(
			value =>
				<BarChart>{
					name: value.legalEntityName ? `${value.legalEntityName}\n${value.displayName}` : value.displayName,
					values: [
						{ value: value.inherentRisk, label: this.calculationTypeTranslate.transform(CalculationType.InherentRiskAmount) },
						{ value: value.residualRisk, label: this.calculationTypeTranslate.transform(CalculationType.ResidualRiskAmount) }
					]
				}
		);
		this.isComponentLoaded = true;
	}
}

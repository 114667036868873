import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { BaCustomModelViewModel } from '../../models/ba-custom-model-view-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { BaCustomModelFilterViewModel } from '../../models/ba-custom-model-filter-view-model.model';
import { ActionResult } from '../../models/action-result.model';
import { BaCustomModelUpdateViewModel } from '../../models/ba-custom-model-update-view-model.model';

@Injectable()
export class BaCustomModelDataService {
	constructor(private httpService: RQHttpService) {}

	public getByApplicationId(
		businessApplicationId: string,
		pagination: PaginationFilter,
		filter: BaCustomModelFilterViewModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<BaCustomModelViewModel>> {
		options.params = this.httpService.getHttpParams({
			businessApplicationId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<BaCustomModelViewModel>>('get', environment.api + '/api/Configuration/BaCustomModel/GetByApplicationId', options)
			.toPromise();
	}

	public update(
		assignation: BaCustomModelUpdateViewModel,
		filter: BaCustomModelFilterViewModel,
		options = new HttpOptionsViewModel()
	): Promise<ActionResult> {
		options.params = this.httpService.getHttpParams({
			filter
		});

		options.body = assignation;

		return this.httpService.request<ActionResult>('put', environment.api + '/api/Configuration/BaCustomModel/Update', options).toPromise();
	}
}

import {
	ChangeDetectorRef,
	Component,
	ContentChild,
	ElementRef,
	EmbeddedViewRef,
	HostBinding,
	HostListener,
	Input,
	OnChanges,
	SimpleChanges,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
	forwardRef
} from '@angular/core';
import { DropdownOption } from './dropdown.model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'rq-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DropdownComponent),
			multi: true
		}
	]
})
export class DropdownComponent implements ControlValueAccessor, OnChanges {
	@Input()
	@HostBinding('class.disabled')
	public isDisabled!: boolean;

	@Input()
	public filter!: boolean;

	@Input()
	public options: Array<DropdownOption<unknown>> = [];

	@Input()
	public placeholder!: string;

	@Input()
	public searchPlaceholder!: string;

	@HostBinding('class.dropdown')
	@HostBinding(`class.dropdown-default`)
	public hasDropdownClass = true;

	@HostBinding('class.dropdown-expended')
	public isExpanded!: boolean;

	@ContentChild(TemplateRef)
	public configuredTemplate!: TemplateRef<unknown>;

	@ViewChild('default', { read: TemplateRef, static: true })
	public defaultTemplate!: TemplateRef<unknown>;

	@ViewChild('body', { read: ViewContainerRef, static: true })
	public body!: ViewContainerRef;

	@ViewChild('selected', { read: ViewContainerRef, static: true })
	public selected!: ViewContainerRef;

	public filterValue!: string;

	private innerValue!: unknown;

	private element!: HTMLElement;

	private onTouchedCallback!: () => void;

	private onChangeCallback!: (val: unknown) => void;

	constructor(eRef: ElementRef, private cd: ChangeDetectorRef) {
		this.element = eRef.nativeElement as HTMLElement;
	}

	public get isPlaceholderVisible() {
		return !this.options.some(option => option.isSelected) && this.placeholder;
	}

	public get selectOption() {
		return this.options.find(x => x.isSelected);
	}

	private get template() {
		return this.configuredTemplate ? this.configuredTemplate : this.defaultTemplate;
	}

	@HostListener('document:click', ['$event'])
	public clickout(event: Event) {
		if (this.isExpanded && !this.element.contains(<Node>event.target) && !this.isClickInDropdown(event)) {
			this.toggle();
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		const options = changes.options
			? (changes.options.currentValue as Array<{ displayName: string; value: number; isSelected: boolean }>)
			: undefined;

		if (options) {
			this.triggerOptionsChange(options);
		}
	}

	public doFilter(): void {
		if (this.options) {
			const options = this.options.filter(item => {
				return item.displayName?.toLowerCase().includes(this.filterValue.toLowerCase());
			});

			this.buildOptions(options);
		}
	}

	public select(item: DropdownOption<unknown>): void {
		if (item !== this.selectOption) {
			this.writeValue(item.value);

			this.filterValue = '';

			this.toggle();

			this.buildOptions(this.options);

			this.onChangeCallback(this.innerValue);
		}
	}

	public triggerDropdown() {
		this.toggle();

		this.onTouchedCallback();
	}

	public toggle(): void {
		if (!this.isDisabled) {
			this.isExpanded = !this.isExpanded;
		} else {
			this.isExpanded = false;
		}
	}

	public writeValue(value: unknown) {
		if (this.options) {
			const option = this.options.find(x => x.value === value) as DropdownOption<unknown>;

			this.setValue(option);

			this.setSelected(option);
		}
	}

	public registerOnChange(fn: (_: unknown) => void) {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: () => void) {
		this.onTouchedCallback = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	private buildOptions(options: Array<DropdownOption<unknown>>): void {
		this.body.clear();
		options.forEach(item => {
			const view: EmbeddedViewRef<unknown> = this.body.createEmbeddedView(this.template, { $implicit: item });

			const rootNode = view.rootNodes.find(node => node.tagName) as HTMLElement;

			if (rootNode) {
				const clickBindElement = rootNode.querySelector('[select-element]') ?? rootNode;
				clickBindElement.addEventListener('click', () => {
					this.select(item);
				});
			}
		});
	}

	private setValue(option: DropdownOption<unknown>) {
		const current = this.options.find(opt => opt === option) as DropdownOption<unknown>;

		this.innerValue = current ? current.value : null;

		this.selected.clear();

		this.selected.createEmbeddedView(this.template, {
			$implicit: current
		});
	}

	private setSelected(option: DropdownOption<unknown>) {
		this.options.forEach(opt => {
			opt.isSelected = option === opt;
		});
	}

	private isClickInDropdown(event: Event) {
		const value = (this.element.querySelector('.dropdown-selected') as Element).querySelector('.selected') as HTMLElement;

		return value?.isEqualNode(<Node>event.target);
	}

	private triggerOptionsChange(options: Array<DropdownOption<unknown>>) {
		this.filterValue = '';

		this.buildOptions(options);

		const selected = options.find(x => x.isSelected);
		if (selected) {
			this.writeValue(selected.value);

			this.cd.detectChanges();
		}
	}
}

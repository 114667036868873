import { from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { LegalEntityModel } from '../../server/models/legal-entity-model.model';
import { Injectable } from '@angular/core';
import { LegalEntityDataService } from '../../server/services/configuration/legal-entity-data.service';
import { errorValidator } from '../../standalone/utils/form-validators.utils';

@Injectable()
export class LegalEntityValidatorsService {
	constructor(private legalEntityDataService: LegalEntityDataService) {}

	public nameValidator(name?: string) {
		return (control: AbstractControl) => {
			if (name && name === control.value) {
				return of(null);
			}

			return from(this.legalEntityDataService.getByName(control.value as string)).pipe(
				map((legalEntity: LegalEntityModel) => (legalEntity === null ? null : { legalEntityName: true })),
				catchError(errorValidator)
			);
		};
	}
}

<section class='card-tree-item'
         [class.m-b-xl]='isFirstNode'>
  <section class='card-parent-container d-flex justify-content-center'>
    <hr *ngIf='isHorizontalBorderVisible'
        [class.card-border-horizontal-last]='isLastChildNode'
        [class.card-border-horizontal-first]='isFirstChildNode'
        class='card-border card-border-horizontal' />
    <article class='card {{cardClassName}} card-xs'
             [style.minWidth]='cardMinWidth'
             [style.maxWidth]='cardMaxWidth'
             [class.m-b-none]='!hasChildren'
             [class.card-tree-first]='isFirstNode'>
      <header class='card-title d-flex justify-content-between align-items-center {{bgColorClass}}'>
        <span class='card-title-text'
              title="{{node.title}}"
              [class.card-title-editable]='node.isEditable'>
          {{node.title}}
        </span>
        <rq-icon *ngIf='node.isEditable'
                 (click)='triggerEditEvent(node.id)'
                 href="icon-edit"
                 class="pointer p-xs"
                 color="color-light">
        </rq-icon>
        <ng-container *ngIf="!node.isEditable && node.hasMoreDetails">
          <ng-container *ngTemplateOutlet='moreDetailsTemplate; context: {data: node.data}'></ng-container>
        </ng-container>
      </header>
      <section class='card-body p-sm'>
        <ng-container *ngTemplateOutlet='template; context: {data: node.data}'></ng-container>
      </section>
      <hr *ngIf='!isFirstNode'
          class='card-border card-border-top' />
      <hr *ngIf='hasChildren'
          class='card-border card-border-bottom' />
    </article>
  </section>
  <section class='card-children d-flex justify-content-between'
           *ngIf='hasChildren'>
    <rq-card-tree *ngFor='let treeNode of node.children; let i = index'
                  (triggerEdit)='triggerEditEvent($event)'
                  (triggerCardHeightChange)='setCardHeight($event)'
                  [rowIndex]='childRowIndex'
                  [style.min-width]='minWidth'
                  [template]='template'
                  [moreDetailsTemplate]='moreDetailsTemplate'
                  [class.m-l-none]='i > 0'
                  [class.m-r-none]='i === node.children.length - 1'
                  [node]='treeNode'
                  [isFirstNode]='false'
                  [isOneNode]='node.children.length === 1'
                  [isLastChildNode]='i === node.children.length - 1'
                  [isFirstChildNode]='i === 0'>
      <ng-content></ng-content>
    </rq-card-tree>
  </section>
</section>
<section class='d-flex justify-content-center'
         *ngIf='isLegendVisible'>
  <ul class='d-flex'>
    <li class='d-flex align-items-center'
        *ngFor='let legendItem of config?.legend'>
      <span class='legend-item {{getBgColor(legendItem.theme)}}'></span>
      <strong class='p-l-sm p-r-sm'>{{legendItem.displayName}}</strong>
    </li>
  </ul>
</section>

import { Directive } from '@angular/core';
import { ComparisonTemplate, ComparisonIcon } from './comparison.model';
import { ComparisonService } from './comparison.service';
import { Subscription } from 'rxjs';
import { ComparisonTableColumn } from './comparison-table/comparison-table.model';

@Directive()
export class ComparisonAbstract {
	public activeRowIndex?: number;

	public activeGroupIndex?: number;

	public isLoading = false;

	public subscriptions = new Array<Subscription>();

	public loadingFilter?: (data: unknown, columnIndex: number) => boolean;

	constructor(protected comparisonService: ComparisonService) {}

	public isCustomTemplate(row: ComparisonTableColumn | ComparisonTemplate) {
		const value = row as ComparisonTemplate;

		return typeof value.template !== 'undefined';
	}

	public triggerMouseEnter(row?: { isRowHoverDisabled: boolean }, rowIndex?: number, groupIndex?: number) {
		if (row?.isRowHoverDisabled) {
			this.comparisonService.triggerHover.next({ rowIndex: undefined, groupIndex: undefined });
		} else {
			this.comparisonService.triggerHover.next({ rowIndex, groupIndex });
		}
	}

	public subscribeToHoverChange() {
		this.subscriptions.push(
			this.comparisonService.triggerHover.subscribe(entity => {
				this.activeRowIndex = entity?.rowIndex;
				this.activeGroupIndex = entity?.groupIndex;
			})
		);
	}

	public subscribeToLoading() {
		this.subscriptions.push(
			this.comparisonService.triggerLoading.subscribe(entity => {
				this.isLoading = entity.isLoading;
				/* eslint-disable @typescript-eslint/unbound-method */
				this.loadingFilter = entity.filter;
				/* eslint-enable @typescript-eslint/unbound-method */
			})
		);
	}

	public isRowLoading(data: unknown, rowIndex: number) {
		let isLoading = this.isLoading;

		if (this.loadingFilter) {
			isLoading = isLoading && this.loadingFilter(data, rowIndex);
		}

		return isLoading;
	}

	public isHoverActive(row: { isRowHoverDisabled: boolean } | undefined, rowIndex?: number, groupIndex?: number) {
		if (row?.isRowHoverDisabled) {
			return false;
		}

		return typeof groupIndex === 'number'
			? this.activeRowIndex === rowIndex && this.activeGroupIndex === groupIndex
			: this.activeRowIndex === rowIndex;
	}

	public getIcon(icon: ComparisonIcon) {
		return icon.name;
	}
}

<rq-table class="m-b-md m-t-md"
          *ngIf="data"
          [dataSource]="data"
          [pagerState]="{ skip: 0, take: 10 }"
          [hasFilters]="true"
          [count]="count"
          (filterChange)="load($event)"
          (sortChange)="load($event)"
          (pageChange)="load($event)">
  <rq-table-column field="tacticName"
                   filter="string"
                   title="global_tactic_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span *ngIf='dataItem.tacticName'> {{dataItem.tacticName | translate}}</span>
      <span *ngIf='!dataItem.tacticName'> {{'global_notApplicable_label' | translate}}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="techniqueName"
                   filter="string"
                   title="global_technique_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span *ngIf='dataItem.techniqueName'> {{dataItem.techniqueName | translate}}</span>
      <span *ngIf='!dataItem.techniqueName'> {{'global_notApplicable_label' | translate}}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="frequency"
                   title="global_frequency_label"
                   filter="range"
                   [filterSetup]="frequencyFilterSetup">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{ dataItem.frequency | rqNumber }}</span>
    </ng-template>
  </rq-table-column>
</rq-table>

import { Injectable } from '@angular/core';
import { FairType } from '../../../../server/enums/fair-type.enum';
import { TranslationService } from '../../../../shared/services/translation.service';
import { ScenarioType } from '../../../../server/enums/scenario-type.enum';
import { NumberFormatterService } from '../../../../shared/services/number-formatter.service';

@Injectable({
	providedIn: 'root'
})
export class FairLabelService {
	constructor(private translationService: TranslationService, private numberService: NumberFormatterService) {}

	public getFairLabel(type: FairType) {
		const map = new Map<FairType, string>();
		map.set(FairType.AnnualLossExposure, 'global_annualizedLossExpectencyALE_label');
		map.set(FairType.LossEventFrequency, 'scenarios_fairLEF_title');
		map.set(FairType.ThreatEventFrequency, 'scenarios_fairTef_title');
		map.set(FairType.Vulnerability, 'fair_vulnerability_label');
		map.set(FairType.ContactFrequency, 'scenarios_fairContactFrequency_title');
		map.set(FairType.ProbabilityOfAction, 'scenarios_fairProbabilityOfAction_title');
		map.set(FairType.ThreatCapability, 'global_threatCapability_label');
		map.set(FairType.ResistanceStrength, 'global_resistanceStrength_label');
		map.set(FairType.LossMagnitude, 'scenarios_fairLossMagnitude_title');
		map.set(FairType.PrimaryLossMagnitude, 'scenarios_fairPrimaryLoss_title');
		map.set(FairType.SecondaryRisk, 'scenarios_fairSecondaryRisk_title');
		map.set(FairType.SecondaryLossEventFrequency, 'scenarios_fairSecondaryLEF_title');
		map.set(FairType.SecondaryLossMagnitude, 'scenarios_fairSecondaryLM_title');

		const value = map.get(type);

		if (!value) {
			throw new Error(`${String(type)} is not handled`);
		}

		return this.translationService.get(value);
	}

	public getDescription(type: FairType, scenarioType: ScenarioType, isUserInput?: boolean) {
		const descriptions = new Map<FairType, string>();
		descriptions.set(FairType.AnnualLossExposure, 'scenario_fairAle_description');
		descriptions.set(FairType.LossEventFrequency, 'scenario_fairLef_description');
		descriptions.set(FairType.ThreatEventFrequency, this.getTefDescription(isUserInput));
		descriptions.set(FairType.Vulnerability, this.getVulnerabilityDescription(isUserInput));
		descriptions.set(FairType.ContactFrequency, this.getCFAndPoADescription(isUserInput, scenarioType));
		descriptions.set(FairType.ProbabilityOfAction, this.getCFAndPoADescription(isUserInput, scenarioType));
		descriptions.set(FairType.ThreatCapability, this.getTCAndRSDescription(isUserInput, scenarioType));
		descriptions.set(FairType.ResistanceStrength, this.getTCAndRSDescription(isUserInput, scenarioType));
		descriptions.set(FairType.LossMagnitude, 'scenario_fairLossMagnitude_complete');
		descriptions.set(FairType.SecondaryRisk, 'scenario_fairSecondaryRisk_complete');
		descriptions.set(FairType.PrimaryLossMagnitude, '');
		descriptions.set(FairType.SecondaryLossEventFrequency, 'global_optionalConfiguration_label');
		descriptions.set(FairType.SecondaryLossMagnitude, 'global_optionalConfiguration_label');

		return this.translationService.get(descriptions.get(type) as string);
	}

	public formatValue(number: number, type: FairType) {
		const currencyFields = [
			FairType.LossMagnitude,
			FairType.SecondaryRisk,
			FairType.AnnualLossExposure,
			FairType.PrimaryLossMagnitude,
			FairType.SecondaryLossMagnitude
		];
		const percentageFields = [
			FairType.SecondaryLossEventFrequency,
			FairType.ResistanceStrength,
			FairType.ThreatCapability,
			FairType.ProbabilityOfAction,
			FairType.Vulnerability
		];

		if (currencyFields.includes(type)) {
			return this.numberService.currency(number);
		}
		if (percentageFields.includes(type)) {
			return this.numberService.percent(number);
		}

		return this.numberService.number(number);
	}

	private getTCAndRSDescription(isUserInput: undefined | boolean, scenarioType: ScenarioType) {
		return isUserInput === null && scenarioType === ScenarioType.SemiFair
			? 'global_notApplicable_label'
			: 'scenario_fairTCAndRS_estimationDescription';
	}

	private getCFAndPoADescription(isUserInput: undefined | boolean, scenarioType: ScenarioType) {
		return isUserInput === null && scenarioType === ScenarioType.SemiFair
			? 'global_notApplicable_label'
			: 'scenario_fairCFAndPoA_estimationDescription';
	}

	private getVulnerabilityDescription(isUserInput: undefined | boolean) {
		return isUserInput === null ? 'global_estimationLossEventFrequency_description' : 'scenario_fairVulnerability_derivecDescription';
	}

	private getTefDescription(isUserInput: undefined | boolean) {
		return isUserInput ? 'global_estimationLossEventFrequency_description' : 'scenario_fairTef_derivedDescription';
	}
}

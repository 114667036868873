import { TranslationService } from 'src/app/shared/services/translation.service';
import { PipeTransform, Pipe } from '@angular/core';
import { TPConfigStatusType } from '../../../server/enums/tpconfig-status-type.enum';

@Pipe({
	name: 'thirdPartyConfigStatusLabel'
})
export class ThirdPartyConfigStatusTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: TPConfigStatusType) {
		switch (value) {
			case TPConfigStatusType.Archived:
				return this.translationService.get('global_archived_label');
			case TPConfigStatusType.Draft:
				return this.translationService.get('global_draft_label');
			case TPConfigStatusType.Completed:
				return this.translationService.get('global_complete_label');
			default:
				return '';
		}
	}
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TopThreatsViewModel } from '../models/top-threats.viewmodel';

@Injectable({
	providedIn: 'root'
})
export class ViewScenarioDetailsService {
	public change = new Subject<TopThreatsViewModel>();
}

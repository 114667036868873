import {
	Component,
	ElementRef,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import { EChartsType, init } from 'echarts';
import { GaugeHalfChart } from './gauge-half-chart.model';
import { COLORS } from 'src/app/standalone/utils/colors.utils';

@Component({
	selector: 'rq-gauge-half-chart',
	templateUrl: './gauge-half-chart.component.html',
	styleUrls: ['./gauge-half-chart.component.scss']
})
export class GaugeHalfChartComponent implements OnInit, OnChanges, OnDestroy {
	@ViewChild('chart', { static: true })
	public chartContainer!: ElementRef;

	@Input() public data!: GaugeHalfChart;

	@Input() public showLabels = false;

	@Input() public showCurrentValue = false;

	private chart?: EChartsType;

	private isResizedForRelativeSizes = false;

	private resizeObserver!: ResizeObserver;

	constructor(private hostElement: ViewContainerRef) {}

	@HostListener('window:resize')
	public onResize() {
		if (this.chart) {
			this.chart.resize();
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.data) {
			this.setData(changes.data.currentValue as GaugeHalfChart);
		}
	}

	public ngOnInit() {
		this.subscribeToResizeLayoutChange();
	}

	public ngOnDestroy() {
		this.resizeObserver.disconnect();
	}

	private setData(data: GaugeHalfChart) {
		const colorGrayLight = getComputedStyle(document.documentElement).getPropertyValue('--background-neutral-overlay');
		const colorPrimary = getComputedStyle(document.documentElement).getPropertyValue('--text-neutral-contrast');
		const fontFamilyPrimary = getComputedStyle(document.documentElement).getPropertyValue('--font-family-primary').trim();

		if (data) {
			if (!this.chart) {
				this.chart = init(this.chartContainer.nativeElement as HTMLElement);
			} else {
				this.chart?.clear();
			}

			const options = {
				series: [
					{
						type: 'gauge',
						center: ['50%', '65%'],
						radius: '100%',
						startAngle: 180,
						endAngle: 0,
						min: data.startValue,
						max: data.endValue,
						splitNumber: 5,
						itemStyle: {
							color: colorGrayLight
						},
						progress: {
							roundCap: true,
							itemStyle: {
								color: data.color ? data.color : colorPrimary
							},
							show: true,
							width: 20
						},
						pointer: {
							show: false
						},
						axisLine: {
							roundCap: true,
							lineStyle: {
								width: 20,
								color: [
									[data.currentValue / data.endValue, data.color ? data.color : colorPrimary],
									[1, colorGrayLight]
								]
							}
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: this.showLabels,
							distance: -48,
							length: 4,
							lineStyle: {
								width: 1,
								color: COLORS.secondaryText
							}
						},
						axisLabel: {
							show: this.showLabels,
							fontFamily: fontFamilyPrimary,
							distance: -8,
							color: COLORS.secondaryText,
							fontSize: 12
						},
						anchor: {
							show: false
						},
						title: {
							show: this.showCurrentValue,
							fontFamily: fontFamilyPrimary,
							fontWeight: 500,
							fontSize: 16,
							offsetCenter: [0, -32]
						},
						detail: {
							show: this.showCurrentValue,
							fontFamily: fontFamilyPrimary,
							valueAnimation: true,
							width: '60%',
							lineHeight: 32,
							borderRadius: 8,
							offsetCenter: [0, 0],
							fontSize: 32,
							fontWeight: 500,
							formatter: '{value}',
							color: this.data.color ? this.data.color : colorPrimary
						},
						data: [{ value: data.currentValue, name: data.title }],
						animationEasingUpdate: 'cubicIn'
					}
				]
			};
			this.chart.setOption(options);

			this.chart.on('rendered', () => {
				if (!this.isResizedForRelativeSizes) {
					this.isResizedForRelativeSizes = true;
					this.chart?.resize();
				}
			});
		} else {
			this.chart?.dispose();
		}
	}

	private subscribeToResizeLayoutChange() {
		this.resizeObserver = new ResizeObserver(() => {
			this.chart?.resize();
		});

		this.resizeObserver.observe(this.hostElement?.element?.nativeElement as Element);
	}
}

import { Component, HostListener, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ComparisonList, ComparisonListItem } from './comparison-list.model';
import { ComparisonAbstract } from '../comparison.abstract';
import { ComparisonTemplate } from '../comparison.model';

@Component({
	selector: 'rq-comparison-list',
	templateUrl: './comparison-list.component.html',
	styleUrls: ['./comparison-list.component.scss']
})
export class ComparisonListComponent extends ComparisonAbstract implements OnInit, OnDestroy {
	@Input()
	public data = new ComparisonList([]);

	@Input()
	public valueTemplate!: TemplateRef<undefined>;

	@HostListener('mouseleave')
	public mouseLeave() {
		this.comparisonService.triggerHover.next();
	}

	public ngOnInit(): void {
		this.subscribeToHoverChange();

		this.subscribeToLoading();
	}

	public ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	public getTemplate(row: ComparisonListItem | ComparisonTemplate): TemplateRef<unknown> | null {
		if ('template' in row) {
			return row.template;
		}
		return null;
	}

	public getLabel(row: ComparisonListItem | ComparisonTemplate): string | null {
		if ('label' in row) {
			return row.label;
		}
		return null;
	}
}

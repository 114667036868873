import { Injectable } from '@angular/core';
import { TranslationService } from '../../shared/services/translation.service';
import { ThemeService } from '../../shared/core/theming/theme.service';
import { ThemeKeys } from '../../shared/core/theming/theme-keys.enum';

@Injectable({
	providedIn: 'root'
})
export class BootstrapService {
	constructor(private translationService: TranslationService, private themeService: ThemeService) {}

	public load() {
		return new Promise(async resolve => {
			await Promise.all([this.translationService.load()])
				.then(() => {
					this.themeService.setTheme(ThemeKeys.Light);

					resolve(null);
				})
				// eslint-disable-next-line no-console
				.catch(error => console.error(error));
		});
	}
}

import { Component } from '@angular/core';
import { IdentityService } from '../../shared/services/identity.service';
import { ShareModule } from '../../shared/share.module';

@Component({
	standalone: true,
	selector: 'rq-no-access-page',
	templateUrl: './no-access-page.component.html',
	styleUrls: ['./no-access-page.component.scss'],
	imports: [ShareModule]
})
export class NoAccessPageComponent {
	public cardConfig = {
		isHeaderHidden: true,
		isLoadingActive: false
	};

	constructor(private identityService: IdentityService) {}

	public logout() {
		this.identityService.logout();
	}
}

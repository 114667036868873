import { Injectable, QueryList } from '@angular/core';
import { clone } from '../../../utils/helpers.utils';
import { TableDataModel } from '../models/table-data.model';
import { TableColumnComponent } from '../table-column/table-column.component';
import { Filter, FilterOutput, FilterRange } from '../../form-elements/filter/filter.model';
import { DateFormatterService } from '../../../../shared/services/date-formatter.service';
import { from } from 'linq-to-typescript';

@Injectable()
export class TableUiSortFilterService {
	constructor(private dateFormatterService: DateFormatterService) {}

	public filterData(tableColumns: QueryList<TableColumnComponent>, filterOutput: FilterOutput, values: Array<TableDataModel>) {
		let clonedValues = clone(values);
		Object.entries(filterOutput).forEach(entity => {
			const tableColumn = tableColumns.find(x => x.field === entity[0]);
			if (!tableColumn?.filter) {
				return;
			}

			switch (tableColumn?.filter) {
				case 'string': {
					clonedValues = this.filteStringColumn(clonedValues, entity);
					break;
				}
				case 'numeric': {
					clonedValues = this.filteNumericColumn(clonedValues, entity);
					break;
				}
				case 'range': {
					clonedValues = this.filteRangeColumn(clonedValues, entity as unknown as [string, Filter<FilterRange<unknown>>]);
					break;
				}
				case 'select': {
					clonedValues = this.filteSelectColumn(clonedValues, entity as unknown as [string, Filter<Array<unknown>>]);
					break;
				}
				case 'dropdown': {
					clonedValues = this.filteDropdownColumn(clonedValues, entity);
					break;
				}
				case 'calendar': {
					clonedValues = this.filteCalendarColumn(clonedValues, entity as unknown as [string, Filter<{ start: string; end?: string }>]);
					break;
				}
				default: {
					throw new Error('Invalid filter type');
				}
			}
		});
		return clonedValues;
	}

	public sortData(filterOutput: FilterOutput, values: Array<TableDataModel>) {
		const entry = Object.entries(filterOutput).find(x => x[1].isSortedAscending !== undefined);
		const field = entry?.[0] as string;
		const filter = entry ? entry[1] : undefined;

		const clonedValues = clone(values);

		return filter?.isSortedAscending
			? from(clonedValues)
					.orderBy(data => (data as { [key: string]: unknown })[field])
					.toArray()
			: from(clonedValues)
					.orderByDescending(data => (data as { [key: string]: unknown })[field])
					.toArray();
	}

	private filteStringColumn(values: Array<TableDataModel>, entity: [string, Filter<unknown>]) {
		if (!entity[1].value) {
			return values;
		}

		return values.filter(data => {
			const dataPropValue = (data as { [key: string]: unknown })[entity[0]];
			return (dataPropValue as string).toLowerCase().includes((entity[1].value as string).toLowerCase());
		});
	}

	private filteNumericColumn(values: Array<TableDataModel>, entity: [string, Filter<unknown>]) {
		if (entity[1].value === null || entity[1].value === undefined) {
			return values;
		}
		return values.filter(data => {
			const dataPropValue = (data as { [key: string]: unknown })[entity[0]];
			return dataPropValue === entity[1].value;
		});
	}

	private filteRangeColumn(values: Array<TableDataModel>, entity: [string, Filter<FilterRange<unknown>>]) {
		if (!entity[1].value?.range) {
			return values;
		}
		return values.filter(data => {
			const dataPropValue = (data as { [key: string]: unknown })[entity[0]] as number;
			return dataPropValue >= entity[1].value.range.min && dataPropValue <= entity[1].value.range.max;
		});
	}

	private filteSelectColumn(values: Array<TableDataModel>, entity: [string, Filter<Array<unknown>>]) {
		if (!entity[1].value?.length) {
			return values;
		}
		return values.filter(data => {
			const dataPropValue = (data as { [key: string]: unknown })[entity[0]];
			return entity[1].value.includes(dataPropValue);
		});
	}

	private filteDropdownColumn(values: Array<TableDataModel>, entity: [string, Filter<unknown>]) {
		if (!entity[1].value) {
			return values;
		}
		return values.filter(data => {
			const dataPropValue = (data as { [key: string]: unknown })[entity[0]];
			return entity[1].value === dataPropValue;
		});
	}

	private filteCalendarColumn(values: Array<TableDataModel>, entity: [string, Filter<{ start?: string; end?: string }>]) {
		if (!entity[1].value?.start) {
			return values;
		}
		return values.filter(data => {
			const dataPropValue = (data as { [key: string]: unknown })[entity[0]];
			if (entity[1].value.end) {
				return (
					this.dateFormatterService.isAfter(dataPropValue as string, entity[1].value.start as string, 'day') &&
					this.dateFormatterService.isBefore(dataPropValue as string, entity[1].value.end, 'day')
				);
			} else {
				return this.dateFormatterService.isAfter(dataPropValue as string, entity[1].value.start as string, 'hour');
			}
		});
	}
}

import { Component, OnInit } from '@angular/core';
import { ApplicationInstanceStore } from '../../core/application.service';
import { BaseComponent } from '../../components/base.component';

@Component({
	selector: 'rq-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent implements OnInit {
	public year!: number;

	public version!: string;

	public isBeta!: boolean;

	constructor(public applicationService: ApplicationInstanceStore) {
		super();
	}

	public ngOnInit() {
		this.year = new Date().getFullYear();

		this.subscriptions.push(
			this.applicationService.currentVersion$.subscribe(value => {
				this.version = value;
			})
		);

		this.subscriptions.push(
			this.applicationService.isBeta$.subscribe(value => {
				this.isBeta = value;
			})
		);
	}
}

import { PipeTransform, Pipe } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';

@Pipe({
	name: 'statusLabel'
})
export class StatusTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(isDraft: boolean) {
		if (isDraft) {
			return this.translationService.get('global_draft_label');
		} else {
			return this.translationService.get('global_complete_label');
		}
	}
}

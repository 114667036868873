<form [formGroup]="form">
  <h3 class="black-color m-b-sm">
    {{config.title | translate}}
  </h3>
  <h4 class="primary-l-10-color m-b-sm"
      *ngIf="config.subTitle">
    {{config.subTitle | translate}}
  </h4>

  <div class="exchange-rate-container">
    <div *ngFor="let exchangeRate of exchangeRates"
         class="d-flex form-group justify-content-between">

      <div class="d-flex align-items-center exchange-rate-title">
        {{exchangeRate.name}}
      </div>
      <div>
        <rq-form-control class="m-b-none"
                         [options]='{validation: {pattern: "form_validation_two_decimal_number"}}'>
          <rq-input type="number"
                    e2e-test="{{getGroupId(exchangeRate.isoCode)}}"
                    (blur)="updateExchange(exchangeRate)"
                    (focus)="focus(exchangeRate)"
                    [isEditState]="exchangeRate.isEditState"
                    [state]="authorizeState"
                    size="sm"
                    formControlName="{{getGroupId(exchangeRate.isoCode)}}"
                    rqAuthorize>
            <ng-template let-value="value">
              {{value}}
            </ng-template>
          </rq-input>
        </rq-form-control>
      </div>
    </div>
  </div>
</form>

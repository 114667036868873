import * as d3 from 'd3';
import { ChartConfig, ChartContext } from '../models/chart.model';
import { CHART_CONFIG } from '../chart-config.const';
import { createRect, createSVGNode, updateRect } from '../svg.util';
import { ChartRenderer } from '../models/chart-renderer.abstract';

export class SvgRenderer implements ChartRenderer<HTMLElement, ChartConfig> {
	constructor(private context: ChartContext) {}

	public setup(container: HTMLElement, config?: ChartConfig) {
		const selection = d3.select(container) as d3.Selection<HTMLElement, undefined, d3.BaseType, undefined>;

		this.context.svg.selection = createSVGNode(selection, 'svg', config ? config.svg.padding : {});
		this.context.drawArea.selection = createSVGNode(this.context.svg.selection, 'g', { class: 'chart-inner-container' });

		this.resize(container, config);

		this.context.drawArea.selection.attr('transform', `translate(${this.context.drawArea.rect.left}, ${this.context.drawArea.rect.top})`);
	}

	public resize(container: HTMLElement, config?: ChartConfig) {
		const containerRect = container.getBoundingClientRect();
		if (this.context.svg.rect.width === 0 && this.context.svg.rect.height === 0) {
			this.context.svg.rect = createRect({
				width: containerRect.width,
				height: containerRect.height
			});

			this.context.drawArea.rect = this.createDrawAreaRect(containerRect, config);
		} else {
			updateRect(this.context.svg.rect, {
				width: containerRect.width,
				height: containerRect.height
			});

			updateRect(this.context.drawArea.rect, this.createDrawAreaRect(containerRect, config));
		}
	}

	private createDrawAreaRect(containerRect?: DOMRect, config?: ChartConfig) {
		const paddingModel = this.createPaddingModel(config);
		const rect = createRect(paddingModel);

		if (containerRect) {
			rect.width = containerRect.width - paddingModel.left - paddingModel.right;
			rect.height = containerRect.height - paddingModel.top - paddingModel.bottom;
		}

		return rect;
	}

	private createPaddingModel(config?: ChartConfig) {
		const getValue = (valueConfigAccessor: string, valueDefault: number) =>
			config?.svg?.padding ? config.svg.padding[valueConfigAccessor] : valueDefault;
		return {
			top: getValue('top', CHART_CONFIG.SVG.TOP),
			bottom: getValue('bottom', CHART_CONFIG.SVG.BOTTOM),
			left: getValue('left', CHART_CONFIG.SVG.LEFT),
			right: getValue('right', CHART_CONFIG.SVG.RIGHT)
		};
	}
}

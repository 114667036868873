import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	QueryList,
	ViewChildren,
	OnInit,
	SimpleChanges
} from '@angular/core';
import {
	LTRecommendationFilterConfigViewModel,
	LTRecommendationROIFilterViewModel,
	LTRecommendationROIViewModel
} from '../../../routes/control-analysis/_common/models/recommendation-roi.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ROLE_CONFIG } from '../../core/roles/role.config';
import { ControlStatus } from '../../enums/control-status.enum';
import { AuthorizeInputModel } from '../../core/roles/authorize-input.model';
import { RecommendationConfig } from '../../../server/enums/recommendation-config.enum';
import { TranslationService } from '../../services/translation.service';
import { TableBase2Component } from '../../../standalone/components/table/table-base2.component';
import { CurrencyConfig } from '../../models/number.config';
import { FormatterType } from '../../enums/formatter-type.enum';
import { InputComponent } from '../../../standalone/components/form-elements/input/input.component';
import { FormGroupConfig } from '../../../standalone/components/form-elements/form-group/form-group.model';
import { FilterSelectGroup, FilterSelectOption, RangeFilter } from '../../../standalone/components/form-elements/filter/filter.model';
import { Customizer } from '../../services/customizer';

@Component({
	selector: 'rq-recommendation-roi-calculator-table',
	templateUrl: './recommendation-roi-calculator-table.component.html',
	styleUrls: ['./recommendation-roi-calculator-table.component.scss']
})
export class RecommendationRoiCalculatorTableComponent
	extends TableBase2Component<LTRecommendationROIViewModel, LTRecommendationROIFilterViewModel>
	implements OnChanges, OnInit
{
	@Input()
	public filterOptions!: LTRecommendationFilterConfigViewModel;

	@Input()
	public isLegalEntityVisible!: boolean;

	@Input()
	public noRecommendationMessage = 'dashboard_control_no_recommendations_message';

	@Input()
	public isEnabledForFairUser = false;

	@ViewChildren(InputComponent)
	public inputComponents!: QueryList<InputComponent>;

	@Output()
	public readonly implementationCostChange = new EventEmitter<LTRecommendationROIViewModel>();

	public formGroupOptions: FormGroupConfig = new FormGroupConfig();

	public formGroup!: UntypedFormGroup;

	public authorizeState: AuthorizeInputModel[] = [];

	public expectedLossReductionFilterSetup!: RangeFilter;

	public implementationFilterSetup!: RangeFilter;

	public roiFilterSetup!: RangeFilter;

	public mappedTableData!: Array<LTRecommendationROIViewModel>;

	constructor(private customizer: Customizer, private translationService: TranslationService, private cd: ChangeDetectorRef) {
		super();
	}

	public get isNirvanaMessageVisible() {
		return !this.hasFiltersApplied && this.count === 0;
	}

	private get currencyConfig(): CurrencyConfig {
		const currencyConfig = new CurrencyConfig();
		currencyConfig.isAbbr = true;
		currencyConfig.decimals = 1;
		currencyConfig.rounding = value => value;
		currencyConfig.symbol = this.customizer.currency;

		return currencyConfig;
	}

	public async ngOnChanges(changes: SimpleChanges) {
		await super.ngOnChanges(changes);

		if (changes.filterOptions?.currentValue) {
			this.setupFilters(changes.filterOptions.currentValue as LTRecommendationFilterConfigViewModel);
		}

		if (changes.data?.currentValue && this.count !== null && this.count !== undefined) {
			this.setupData(changes.data?.currentValue as Array<LTRecommendationROIViewModel>);
		}
	}

	public ngOnInit() {
		this.setupAuthorizeState();
	}

	public triggerFocus(data: LTRecommendationROIViewModel) {
		data.isEditState = true;
	}

	public async triggerChange(dataItem: LTRecommendationROIViewModel) {
		if (this.isImplementationValid(dataItem)) {
			dataItem.isEditState = false;
			dataItem.isRoiComputing = true;
			dataItem.cost = this.getFormControlValue<number>(dataItem.recommendationId);

			await this.getInputByFormIndex(dataItem.recommendationId).toggleLoading(true);

			this.implementationCostChange.emit(dataItem);
		}
	}

	private setupData(data: Array<LTRecommendationROIViewModel>) {
		this.setupForm(data);
		this.mappedTableData = data;
	}

	private setupFilters(filterOptions?: LTRecommendationFilterConfigViewModel) {
		if (filterOptions) {
			this.expectedLossReductionFilterSetup = this.createRangeFilter(
				'recommendation_financialReduction_title',
				filterOptions.expectedLossReduction,
				FormatterType.Currency,
				this.currencyConfig
			);

			const implementationSelectGroups = [
				new FilterSelectGroup([
					new FilterSelectOption(this.translationService.get('global_notProvided_label'), RecommendationConfig.NotConfigured),
					new FilterSelectOption(this.translationService.get('global_provided_label'), RecommendationConfig.Configured)
				])
			];

			this.implementationFilterSetup = this.createRangeFilter(
				'recommendation_tableTitle_implementationCost',
				filterOptions.implementation,
				FormatterType.Currency,
				this.currencyConfig,
				implementationSelectGroups
			);

			const roiSelectGroups = [
				new FilterSelectGroup([
					new FilterSelectOption(this.translationService.get('recommendation_tabelFilter_notComputed'), RecommendationConfig.NotConfigured),
					new FilterSelectOption(this.translationService.get('global_computed_label'), RecommendationConfig.Configured)
				])
			];

			this.roiFilterSetup = this.createRangeFilter(
				'recommendation_tableTitle_roi',
				filterOptions.roi,
				FormatterType.Percent,
				this.currencyConfig,
				roiSelectGroups
			);
		}
	}

	private getInputByFormIndex(id: string): InputComponent {
		const inputIndex = Object.keys(this.formGroup.controls).indexOf(id);

		return this.inputComponents.get(inputIndex) as InputComponent;
	}

	private getFormControlValue<T>(id: string) {
		return this.formGroup.controls[id].value as T;
	}

	private isImplementationValid(data: LTRecommendationROIViewModel): boolean {
		const control = this.formGroup.controls[data.recommendationId];
		const value = parseFloat(control.value as string);

		if (control.valid) {
			if (value === data.cost) {
				data.isEditState = false;
				return false;
			} else {
				return true;
			}
		} else {
			return false;
		}
	}

	private setupForm(values: LTRecommendationROIViewModel[]) {
		this.formGroupOptions.validation = {
			pattern: 'form_validation_two_decimal_number'
		};

		this.formGroup = new UntypedFormGroup({});
		values.forEach(recommendation => {
			this.formGroup.addControl(recommendation.recommendationId, new UntypedFormControl(recommendation.cost, [Validators.min(0.01)]));
		});

		this.cd.detectChanges();
	}

	private setupAuthorizeState() {
		this.authorizeState.push(
			{
				role: ROLE_CONFIG.enterprise.readOnly,
				status: ControlStatus.Disabled
			},
			{
				role: ROLE_CONFIG.pro.readOnly,
				status: ControlStatus.Disabled
			}
		);

		if (!this.isEnabledForFairUser) {
			this.authorizeState.push({
				role: ROLE_CONFIG.pro.fairOnly,
				status: ControlStatus.Disabled
			});
		}
	}
}

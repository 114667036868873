  <section *ngIf="hasAnyRecommendation"
           class="p-b-md b b-bottom m-t-md primary-l-10-color responsive">
    <rq-filter (filterChange)="filterChange()"
               [filters]="filter"
               [config]="filterConfig"
               class="p-l-md">
    </rq-filter>
  </section>

  <ng-container *ngIf="count > 0">
    <section class="steps-container">
      <strong class="step-what text-lg p-l-md p-t-sm p-b-sm"
              [innerHTML]="'recommendations_analysisView_step1' | translate"></strong>
      <strong class="step-how text-lg p-l-xl p-t-sm p-b-sm"
              [innerHTML]="'recommendations_analysisView_step2' | translate"></strong>
      <strong class="step-where text-lg p-l-sm p-t-sm p-b-sm"
              [innerHTML]="'recommendations_analysisView_step3' | translate"></strong>
    </section>

    <section *ngIf="count > 0"
             class="horizontal-line"
             [style.background]="enterpriseControlsGradient"></section>

    <section class="steps-container">
      <rq-step-what class="step-what"
                    [items]="items"
                    (itemSelect)="selectItem($event)"></rq-step-what>
      <ng-container *ngIf="selectedItem">
        <rq-step-how class="step-how"
                     [selectedItem]="selectedItem"
                     [controlLevelDescriptions]="enterpriseLevelDescription"
                     [defensebilityDelta]="defensebilityDelta">
        </rq-step-how>
        <rq-step-where class="step-where"
                       [selectedItem]="selectedItem"
                       [controlLevelDescriptions]="enterpriseLevelDescription"
                       [defensebilityDelta]="defensebilityDelta">
        </rq-step-where>
      </ng-container>
    </section>

    <section class="m-md">
      <rq-pager class="m-t-md"
                [config]="{isPagerSizeHidden: true}"
                [count]="count"
                [state]="paginationState"
                (pageChange)="pageChange()"></rq-pager>
    </section>
  </ng-container>

  <ng-container *ngIf='isMessageVisible'>
    <p class='m-l-lg m-b-md m-t-md text-sm primary-l-10-color'>
      <span *ngIf='hasAnyRecommendation && !count'>
        {{'recommendation_tableHeader_noRecommendationsMessage' | translate}}
      </span>
      <span *ngIf='!hasAnyRecommendation'>
        {{'dashboard_control_no_recommendations_message' | translate }}
      </span>
    </p>
  </ng-container>

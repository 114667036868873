import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LTRecommendationAnalysisApplicationViewModel } from '../../../../../routes/control-analysis/_common/models/recommendations-analysis-view.viewmodel';

@Component({
	selector: 'rq-application-list',
	templateUrl: './application-list.component.html',
	styleUrls: ['./application-list.component.scss']
})
export class ApplicationListComponent implements OnChanges {
	@Input()
	public data!: Array<LTRecommendationAnalysisApplicationViewModel>;

	@Input()
	public numberOfItemsToShow = 0;

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.numberOfItemsToShow?.currentValue > 0 && this.data) {
			this.data = this.data.slice(0, changes.numberOfItemsToShow?.currentValue as number);
		}
	}
}

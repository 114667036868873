import { Component, ContentChild, Input, TemplateRef, ViewChild } from '@angular/core';
import { TabContentDirective } from '../tab-content.directive';

@Component({
	selector: 'rq-tab',
	templateUrl: './tab.component.html'
})
export class TabComponent<_T> {
	@ContentChild(TabContentDirective)
	public tabContentDirective!: TabContentDirective;

	@ViewChild(TemplateRef, { static: true })
	public template!: TemplateRef<unknown>;

	@Input()
	public label!: string;

	@Input()
	public isDisabled!: boolean;

	@Input()
	public active!: boolean;

	@Input()
	public tabId!: unknown;
}

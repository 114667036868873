import { Subscription, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SvgRenderer } from './renderers/svg.renderer';
import { ChartConfig, ChartContext, SVGConfig } from './models/chart.model';

export class ChartAbstract<T> {
	public data!: T;

	public element: HTMLBaseElement;

	public context: ChartContext;

	public svgConfig: SVGConfig;

	private svg: SvgRenderer;

	private initialSetupConfig?: ChartConfig;

	private changeListeners: Array<Function> = [];

	constructor(element: HTMLBaseElement) {
		this.element = element;
		this.context = new ChartContext();
		this.svgConfig = new SVGConfig();
		this.svg = new SvgRenderer(this.context);
	}

	public setup(setup: () => void, config?: ChartConfig) {
		this.initialSetupConfig = config;
		this.svg.setup(this.element, config);
		setup();
	}

	public change(change: () => void) {
		change();
		this.changeListeners.forEach(fn => fn() as Function);
	}

	public subscribeToChange(fn: Function) {
		this.changeListeners.push(fn);
	}

	public subscribeToResize(resize: () => void): Subscription {
		return fromEvent(window, 'resize')
			.pipe(debounceTime(100))
			.subscribe(() => {
				this.svg.resize(this.element, this.initialSetupConfig);
				resize();
			});
	}
}

import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'rq-switch',
	templateUrl: './switch.component.html',
	styleUrls: ['./switch.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SwitchComponent),
			multi: true
		}
	]
})
export class SwitchComponent implements ControlValueAccessor {
	@Input()
	public isDisabled!: boolean;

	@Input()
	public hideLeftLabel = false;

	@Input()
	public hideRightLabel = false;

	@Input()
	public leftLabelText = 'OFF';

	@Input()
	public rightLabelText = 'ON';

	private isSwitchOn!: boolean;

	private onTouchedCallback!: (_?: unknown) => void;

	private onChangeCallback!: (_?: unknown) => void;

	public get value(): boolean {
		return this.isSwitchOn;
	}

	@Input()
	public set value(val: boolean) {
		if (val !== this.value) {
			this.isSwitchOn = val;
			this.onChangeCallback(val);
		}
	}

	public triggerTouch() {
		this.onTouchedCallback();
	}

	public writeValue(isSwitchOn: boolean) {
		this.isSwitchOn = isSwitchOn;
	}

	public registerOnChange(fn: (_: unknown) => void) {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: (_: unknown) => void) {
		this.onTouchedCallback = fn;
	}

	public setDisabledState(_isDisabled: boolean): void {
		// eslint-disable-next-line @typescript-eslint/no-empty-function
	}
}

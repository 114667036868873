import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ContentChildren,
	EventEmitter,
	HostBinding,
	Input,
	OnInit,
	Output,
	QueryList
} from '@angular/core';

@Component({
	selector: 'rq-card-layout-menu-tab',
	templateUrl: './card-layout-menu-tab.component.html',
	styleUrls: ['./card-layout-menu-tab.component.scss']
})
export class CardLayoutMenuTabComponent implements OnInit, AfterViewInit {
	@Input()
	public menuId!: string | number;

	@Input()
	public isActive?: boolean;

	@Input()
	public isDisabled?: () => boolean;

	@Input()
	public parentMenuId?: string | number;

	@Input()
	public label!: string;

	@ContentChildren(CardLayoutMenuTabComponent, { descendants: true })
	public children!: QueryList<CardLayoutMenuTabComponent>;

	@HostBinding('class.card-layout-menu')
	public hasDefaultClass = true;

	@Output()
	public readonly menuItemChange = new EventEmitter();

	constructor(private changeDetectorRef: ChangeDetectorRef) {}

	public get isItemDisabled() {
		return this.isDisabled?.();
	}

	public get isActiveStateVisible() {
		return this.isActive && !this.hasChildren;
	}

	public get hasChildren() {
		return this.childrenMenuItems && this.childrenMenuItems.length > 0;
	}

	public get childrenMenuItems() {
		return this.children.toArray().filter(x => x !== this);
	}

	public ngOnInit(): void {
		if (this.menuId === undefined || this.menuId === null || this.menuId === '') {
			throw new Error(`There is no menuId defined for ${CardLayoutMenuTabComponent.name}`);
		}
	}

	public ngAfterViewInit(): void {
		if (this.isActive) {
			this.triggerMenuItemChange();
		}
	}

	public resetTabState(model: { tabId: string | number; childTabId: string | number }) {
		const selectedTabId = model.childTabId ? model.childTabId : model.tabId;

		if (this.hasChildren) {
			const child = this.childrenMenuItems.find(x => x.menuId === selectedTabId);

			if (!child) {
				this.toggleActiveState(false);
			}
		} else if (this.menuId !== selectedTabId) {
			this.toggleActiveState(false);
		}
	}

	public toggleActiveState(isActive: boolean): void {
		this.isActive = isActive;
		this.changeDetectorRef.detectChanges();
	}

	public triggerMenuItemChange() {
		if (this.isItemDisabled) {
			return;
		}

		const menuId = this.parentMenuId ? this.parentMenuId : this.menuId;

		let childMenuId: string | number | null;

		if (!this.parentMenuId && !this.hasChildren) {
			childMenuId = null;
		} else if (!this.parentMenuId && this.hasChildren) {
			childMenuId = this.childrenMenuItems[0].menuId;
		} else {
			childMenuId = this.menuId;
		}

		this.menuItemChange.emit({ menuId, childMenuId });

		this.toggleActiveState(true);

		if (childMenuId) {
			const childTab = <CardLayoutMenuTabComponent>this.children.find(x => x.menuId === childMenuId);

			if (childTab) {
				childTab.toggleActiveState(true);
			}
		}
	}
}

<ul class="list-unstyled actions">
  <ng-container *ngFor="let action of actions; let index = index">
    <ng-container *ngIf="index < numberOfVisibleActions">
      <li [ngClass]="index < actions.length - 1 ? 'm-r-md p-r-md b b-right' : ''">
        <ng-container *ngTemplateOutlet="action.template"></ng-container>
      </li>
    </ng-container>
  </ng-container>

  <li *ngIf="actions.length > numberOfVisibleActions">
    <rq-table-more-actions>
      <ng-container *ngFor="let action of actions; let index = index">
        <ng-container *ngIf="index >= numberOfVisibleActions">
          <ng-template tableAction>
            <ng-container *ngTemplateOutlet="action.template"></ng-container>
          </ng-template>
        </ng-container>
      </ng-container>
    </rq-table-more-actions>
  </li>
</ul>

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CachingService } from '../../system/services/caching.service';

@Injectable({
	providedIn: 'root'
})
export class StartingPageService {
	public showStartupPage = new BehaviorSubject<boolean>(false);

	private readonly shouldShowKey = 'show_starting_page';

	constructor(private cachingService: CachingService) {}

	public setStartingPageStateWhenAppIsSwitched(state: boolean) {
		this.cachingService.setItem(this.shouldShowKey, state.toString(), false);
	}

	public getStartingPageState() {
		const state = this.cachingService.getItem(this.shouldShowKey);
		this.deleteStartingPageState();
		return state;
	}

	public deleteStartingPageState() {
		this.cachingService.removeItem(this.shouldShowKey);
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { FairLossMagnitudeSubType } from 'src/app/server/enums/fair-loss-magnitude-sub-type.enum';
import { FairLossMagnitudeType } from 'src/app/server/enums/fair-loss-magnitude-type.enum';
import { FairLossMagnitudeSubtypeTranslate } from './fair-loss-magnitude-subtype-label.pipe';
import { FairLossMagnitudeTypeTranslate } from './fair-loss-magnitude-type-label.pipe';

@Pipe({
	name: 'lossMagnitudeTypeLabel'
})
export class LossMagnitudeTypeTranslate implements PipeTransform {
	constructor(
		private fairLossMagnitudeSubtypeTranslate: FairLossMagnitudeSubtypeTranslate,
		private fairLossMagnitudeTypeTranslate: FairLossMagnitudeTypeTranslate
	) {}

	public transform(type: FairLossMagnitudeType, subType: FairLossMagnitudeSubType) {
		const typeLabel = this.fairLossMagnitudeTypeTranslate.transform(type);

		return subType !== undefined && subType !== FairLossMagnitudeSubType.Aggregate
			? `${typeLabel} - ${this.fairLossMagnitudeSubtypeTranslate.transform(subType)}`
			: typeLabel;
	}
}

export const CHART_CONFIG = {
	SVG: {
		TOP: 20,
		RIGHT: 20,
		BOTTOM: 20,
		LEFT: 20
	},
	AXIS: {
		LEFT: 15,
		BOTTOM: 15,
		TICKS: 5,
		ITEM_TEXT: {
			LINE_HEIGHT: 1.1,
			WIDTH: 40
		}
	},
	LEGEND: {
		TOP: 5,
		RIGHT: 20,
		PADDING: 10,
		ITEM_RECT: {
			LEFT: 5,
			WIDTH: 22,
			HEIGHT: 7
		},
		ITEM_TEXT: {
			LEFT: 5,
			TOP: 7
		}
	},
	TOOLTIP: {
		ITEM_RECT: {
			LEFT: 10
		}
	},
	COORDS: {
		CIRCLE_RADIUS: 1
	},
	BAR: {
		HEIGHT: 20,
		WIDTH: 35,
		GRID_WIDTH: 30,
		MARGIN_BOTTOM: 10
	}
};

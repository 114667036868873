export class PaginationState {
	[key: string]: unknown;

	constructor(public take = 10, public skip = 0) {
		if (take && skip) {
			// This block ensures 'take' and 'skip' is referenced to fix the sonar issue
		}
	}
}

export class PagerConfig {
	public label?: string;

	public isPagerSizeHidden?: boolean;

	public dropdownDirection?: 'bottom' | 'top';
}

export class PaginationInfo {
	public totalItems!: number;

	public currentPage!: number;

	public pageSize!: number;

	public totalPages!: number;

	public startPage!: number;

	public endPage!: number;

	public startIndex!: number;

	public endIndex!: number;

	public pages!: number[];
}

export class PageSize {
	public value!: number;

	public text!: string;

	constructor(value: number, pageWord: string) {
		this.value = value;
		this.text = `${this.value} / ${pageWord}`;
	}
}

import { PipeTransform, Pipe } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { ApplicationStatus } from 'src/app/server/enums/application-status.enum';

@Pipe({
	name: 'applicationStatusLabel'
})
export class ApplicationStatusTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: ApplicationStatus) {
		const values = new Map<ApplicationStatus, string>();
		values.set(ApplicationStatus.Complete, 'global_complete_label');
		values.set(ApplicationStatus.Draft, 'global_draft_label');
		values.set(ApplicationStatus.Archive, 'global_archived_label');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${ApplicationStatus[value]} does not exist`);
		}

		return this.translationService.get(type);
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { FairType } from 'src/app/server/enums/fair-type.enum';

@Pipe({
	name: 'fairTypeLabel'
})
export class FairTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(type: FairType) {
		const values = new Map<FairType, string>();
		values.set(FairType.LossEventFrequency, 'scenarios_fairLEF_title');
		values.set(FairType.ThreatEventFrequency, 'scenarios_fairTef_title');
		values.set(FairType.Vulnerability, 'fair_vulnerability_label');
		values.set(FairType.ProbabilityOfAction, 'fair_probabilityOfAction_label');
		values.set(FairType.ContactFrequency, 'global_contact_frequency');
		values.set(FairType.ResistanceStrength, 'global_resistanceStrength_label');
		values.set(FairType.LossMagnitude, 'fair_lossMagnitude_label');
		values.set(FairType.SecondaryRisk, 'scenarios_fairSecondaryRisk_title');
		values.set(FairType.SecondaryLossEventFrequency, 'scenarios_fairSecondaryLEF_title');
		values.set(FairType.SecondaryLossMagnitude, 'scenarios_fairSecondaryLM_title');
		values.set(FairType.AnnualLossExposure, 'global_annualizedLossExpectencyALE_label');
		values.set(FairType.PrimaryLossMagnitude, 'fair_primaryLossMagnitude_label');
		values.set(FairType.ThreatCapability, 'global_threatCapability_label');

		const value = values.get(type);
		if (!value) {
			throw new Error(`${type} is not handled`);
		}

		return this.translationService.get(value);
	}
}

import { Injectable } from '@angular/core';
import { TranslationService } from '../../../../shared/services/translation.service';
import { FairType } from '../../../../server/enums/fair-type.enum';
import { FairOutputContextType } from '../../../../server/enums/fair-output-context-type.enum';
import { FairTreePrimaryLossMagnitudeModel } from '../models/fair-tree-primary-loss-magnitude.model';
import { FairLabelService } from './fair-label.service';
import { FairTreeModel } from '../../../../server/models/fair-tree-model.model';
import { CardTreeLegend, CardTreeNode } from '../../../../standalone/components/charts/card-tree/card-tree.model';

@Injectable({
	providedIn: 'root'
})
export class ScenarioFairTreeService {
	constructor(private translationService: TranslationService, private fairLabelService: FairLabelService) {}

	public createTreeConfig() {
		return {
			legend: [
				new CardTreeLegend(this.translationService.get('global_input_label'), 'blue'),
				new CardTreeLegend(this.translationService.get('global_modifiedInput_label'), 'accent'),
				new CardTreeLegend(this.translationService.get('global_computed_label'), 'success'),
				new CardTreeLegend(this.translationService.get('global_disabled_label'), 'disabled')
			]
		};
	}

	public isNodeConfigured(data: FairTreeModel) {
		return typeof data.minimum === 'number' && typeof data.mostLikely === 'number' && typeof data.maximum === 'number';
	}

	public getDataDescription(data: FairTreeModel, scenarioType: number) {
		if (data.isDraft) {
			return this.translationService.get('global_notConfigured_label');
		}

		if (this.isNodeConfigured(data)) {
			const descriptions = this.createDataDescriptions();

			return this.translationService.get(descriptions.get(data.type) as string);
		} else {
			return this.fairLabelService.getDescription(data.type, scenarioType, data.contextType === FairOutputContextType.Input);
		}
	}

	public createTreeNodes(data: FairTreeModel) {
		const isEditable = this.isNodeEditable(data);
		const node = new CardTreeNode<FairType, FairTreeModel>(data.type, this.fairLabelService.getFairLabel(data.type), {
			data,
			hasMoreDetails: this.hasMoreDetails(data),
			isDisabled: this.isNodeDisabled(data),
			theme: this.createNodeTheme(isEditable, data.isDraft, data),
			isEditable: false,
			style: { minWidth: this.getNodeWidth(data), maxWidth: this.getNodeWidth(data) }
		});

		if (data.children) {
			node.children = new Array<CardTreeNode<FairType, FairTreeModel>>();

			data.children.forEach(x => node.children.push(this.createTreeNodes(x)));
		}

		return node;
	}

	private getNodeWidth(data: FairTreeModel) {
		switch (data.type) {
			case FairType.AnnualLossExposure:
			case FairType.LossEventFrequency:
			case FairType.LossMagnitude:
				return 350;
			case FairType.ThreatEventFrequency:
			case FairType.Vulnerability:
			case FairType.PrimaryLossMagnitude:
			case FairType.SecondaryRisk:
				return 250;
			default:
				return 150;
		}
	}

	private createDataDescriptions() {
		const descriptions = new Map<FairType, string>();
		descriptions.set(FairType.LossEventFrequency, 'scenarios_semiAutomated_viewDetails_LEF');
		descriptions.set(FairType.ThreatEventFrequency, 'scenarios_semiAutomated_viewDetails_TEF');
		descriptions.set(FairType.Vulnerability, 'scenarios_semiAutomated_viewDetails_vulnerability');
		descriptions.set(FairType.ContactFrequency, 'scenarios_semiAutomated_viewDetails_contactFrequency');
		descriptions.set(FairType.ProbabilityOfAction, 'scenarios_semiAutomated_viewDetails_probabilityOfAction');
		descriptions.set(FairType.ThreatCapability, 'scenarios_semiAutomated_viewDetails_threatCapability');
		descriptions.set(FairType.ResistanceStrength, 'scenarios_semiAutomated_viewDetails_resistanceStrength');
		descriptions.set(FairType.LossMagnitude, 'scenarios_semiAutomated_viewDetails_lossMagnitude');
		descriptions.set(FairType.PrimaryLossMagnitude, 'scenarios_semiAutomated_viewDetails_primaryLossMagnitude');
		descriptions.set(FairType.SecondaryRisk, 'fair_magnitudeOfSecondaryLoss_label');
		descriptions.set(FairType.SecondaryLossEventFrequency, 'scenarios_semiAutomated_viewDetails_secondaryLossEventFrequency');
		descriptions.set(FairType.SecondaryLossMagnitude, 'fair_magnitudeOfSecondaryLoss_label');
		descriptions.set(FairType.AnnualLossExposure, 'global_annualizedLossExpectencyALE_label');

		return descriptions;
	}

	private createNodeTheme(isEditable: boolean, isDraft: boolean, data: FairTreeModel) {
		if (data.contextType === FairOutputContextType.Computed) {
			return 'accent';
		} else {
			if (isEditable && !isDraft) {
				return 'blue';
			}

			if (isDraft) {
				return 'danger';
			}

			return 'success';
		}
	}

	private isNodeEditable(data: FairTreeModel) {
		if (data.isDraft) {
			return true;
		}

		return Boolean(data.contextType === FairOutputContextType.Input);
	}

	private isNodeDisabled(data: FairTreeModel) {
		if (data.isDraft) {
			return false;
		}

		return data.contextType === null;
	}

	private hasMoreDetails(data: FairTreePrimaryLossMagnitudeModel) {
		return Boolean(data.magnitudePercentage);
	}
}

<form *ngIf="form"
      [formGroup]="form">
  <section>
    <div class="p-b-xl">
      <h3 class="primary-color m-b-none">
        {{ 'global_user_label' | translate }} {{ 'global_role_label' | translate }}*
      </h3>
      <h4 class="primary-l-10-color m-b-none p-b-xs">
        {{ 'settings_user_management_create_user_modal_user_role_info_label' | translate }}*
      </h4>
      <rq-form-group class="m-b-none">
        <div formGroupName="roles">
          <div *ngFor="let item of user.roles; let i = index; last as isLast">
            <label [class.m-b-none]="isLast"
                   *ngIf="isRoleVisible(item.name)">
              <input type="checkbox"
                     e2e-test="user-{{item.displayName}}"
                     [formControl]="getControl(item.name)" />
              <span class="text-sm">{{ item.displayName }}</span>
            </label>
          </div>
        </div>
      </rq-form-group>
    </div>
    <div class="p-b-xl">
      <rq-form-control class="m-b-none"
                       [options]="{validation: {whitespace: 'form_validation_whitespaceNotAllowed'}}">
        <h3 class="primary-color m-b-none">{{ 'global_fullName_Label' | translate }}*</h3>
        <h4 class="primary-l-10-color m-b-none p-b-xs">
          {{ 'settings_user_management_create_user_modal_user_name_info_label' | translate }}
        </h4>
        <label class="m-b-none">
          <rq-input type="text"
                    placeholder="{{ 'settings_user_management_create_user_full_name_placeholder_label' | translate }}"
                    class="user-input form-control m-b-none m-t-none"
                    formControlName="name">
          </rq-input>
        </label>
      </rq-form-control>
    </div>

    <div class="p-b-xl">
      <rq-form-control class="m-b-none"
                       [options]="{validation: {whitespace: 'form_validation_whitespaceNotAllowed'}}">
        <h3 class="primary-color m-b-none">{{ 'global_title_label' | translate }}*</h3>
        <h4 class="primary-l-10-color m-b-none p-b-xs">
          {{ 'settings_user_management_create_user_modal_user_title_info_label' | translate }}
        </h4>
        <label class="m-b-none">
          <rq-input type="text"
                    placeholder="{{ 'settings_user_management_create_user_title_placeholder_label' | translate }}"
                    class="user-input form-control m-b-none m-t-none"
                    formControlName="title">
          </rq-input>
        </label>
      </rq-form-control>
    </div>

    <div class="p-b-xl m-b-md">
      <rq-form-control class="m-b-none"
                       [options]="validationOpt">
        <h3 class="primary-color m-b-none">{{ 'global_email_label' | translate }}*</h3>
        <h4 class="primary-l-10-color m-b-none p-b-xs">
          {{ 'settings_user_management_create_user_modal_user_email_info_label' | translate }}
        </h4>
        <label class="m-b-none">
          <rq-input type="text"
                    placeholder="{{ 'settings_user_management_create_user_email_placeholder_label' | translate }}"
                    class="user-input form-control m-b-none m-t-none"
                    formControlName="email">
          </rq-input>
        </label>
      </rq-form-control>
    </div>
  </section>
  <ng-content></ng-content>
</form>

import { Component, ViewChild } from '@angular/core';
import { AlertComponent } from '../../../standalone/components/alert/alert.component';

@Component({
	selector: 'rq-delete-alert-denied',
	templateUrl: './delete-alert-denied.component.html',
	styleUrls: ['./delete-alert-denied.component.scss']
})
export class DeleteAlertDeniedComponent {
	@ViewChild(AlertComponent, { static: true })
	public alert!: AlertComponent;

	public name!: string;

	public async show(name: string) {
		this.name = name;
		await this.alert.show();
	}
}

<rq-dialog #dialog
           [options]="{acceptLabel: 'menu_label_logout', declineLabel: 'global_label_cancel'}"
           [theme]="'primary'"
           e2e-test="confirm-log-out-modal">
  <rq-dialog-title class="dialog-header">
    {{'logout_title' | translate}}
  </rq-dialog-title>
  <rq-dialog-body>
    <p class="text-center">
      {{'logout_message' | translate }}
    </p>
  </rq-dialog-body>
</rq-dialog>

import { ComparisonRowBase, ComparisonRowBaseConfig, ComparisonTemplate, ComparisonType } from '../comparison.model';

export class ComparisonList {
	public type: ComparisonType.List = ComparisonType.List;

	constructor(public rows: Array<ComparisonListItem | ComparisonTemplate>) {}
}

export class ComparisonListItem extends ComparisonRowBase {
	constructor(public label: string, public value: string, config?: ComparisonRowBaseConfig) {
		super();

		this.setupConfig(config);
	}
}

<article class="risks-dashboard">
  <h2 class="text-lg text-bold m-b-xl">{{'dashboard_risk_control_what_cost_of_attack' | translate}}</h2>
  <div class="d-flex"
       *ngIf="data">
    <div class="p-r-xl b b-right d-flex align-items-center flex-column flex-grow-1">
      <div e2e-test="rq-dashboard-risk-attack-type"
           class="text-md p-b-lg">
        <strong>{{getAttackLabel(data.attackId) | translate}}</strong>

        <ng-container *ngIf="!isForScenario">
          {{'dashboard_risk_control_attack_on' | translate}}
          <strong [rqEllipsis]='data.applicationName'></strong>
        </ng-container>
      </div>

      <div e2e-test="rq-dashboard-risk-attack-cost"
           class="d-flex justify-content-center align-items-center flex-column p-t-lg p-b-lg">
        <strong class="text-xl">{{data.costOfAttack}}</strong>

        <div e2e-test="rq-dashboard-risk-attack-ri"
             class="p-t-sm text-lg">
          <span *ngFor="let value of data.rateOfIncidenceValues"
                [ngClass]="value.classes">{{value.value}}</span>
        </div>

        <div e2e-test="rq-dashboard-risk-attack-ps"
             class="p-t-md text-lg">
          <strong>{{data.pos}}</strong>
          <span class="primary-l-10-color"> {{'global_ProbabilityOfSuccessP(s)_label' | translate}}</span>
        </div>
      </div>

      <div e2e-test="rq-dashboard-risk-total-records"
           class="d-flex justify-content-center align-items-center flex-column p-t-lg"
           *ngIf="data.totalRecords">
        <strong class="text-xl">{{data.totalRecords}}</strong>

        <div class="p-t-md text-lg">
          {{'dashboard_risk_control_records_compromised' | translate}}
        </div>
      </div>
    </div>

    <div e2e-test="rq-dashboard-risk-attack-losses"
         class="p-l-xl flex-grow-1">
      <div class="d-flex justify-content-start">
        <h3 class="p-l-lg"><strong>{{'applications_lossByType_label' | translate}}</strong></h3>
      </div>
      <div class="row charts-container">
        <div class="col-5 chart-item"
             *ngIf="data.donutChartSeries">
          <rq-donut-chart [data]="data.donutChartSeries"
                          value.formatter="currency"
                          [label.visible]="false">
          </rq-donut-chart>
        </div>
        <div class="col-7 chart-item"
             *ngIf="data.barChartSeries">
          <rq-legend-bar-chart class="justify-content-center"
                               [data]="data.donutChartSeries"
                               value.formatter="currency"
                               bar.size="small">
          </rq-legend-bar-chart>
        </div>
      </div>
    </div>
  </div>
</article>

import { TranslationService } from 'src/app/shared/services/translation.service';
import { PipeTransform, Pipe } from '@angular/core';
import { ScenarioConfigStatusType } from '../../../server/enums/scenario-config-status-type.enum';

@Pipe({
	name: 'scenarioConfigStatusLabel'
})
export class ScenarioConfigStatusTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value?: ScenarioConfigStatusType) {
		switch (value) {
			case ScenarioConfigStatusType.Draft:
				return this.translationService.get('global_draft_label');
			case ScenarioConfigStatusType.Completed:
				return this.translationService.get('global_complete_label');
			default:
				throw new Error(`${value} does not exist`);
		}
	}
}

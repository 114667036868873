import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DialogOptions } from './dialog-options.ui-model';
import { ModalComponent } from '../modal/modal.component';
import { DialogResult } from './dialog-result.enum';
import { ThemeColorsType } from '../../types/theme-colors.type';

const btnPrimary = 'btn-primary';

@Component({
	selector: 'rq-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
	@ViewChild('rqModal', { static: true })
	public modal!: ModalComponent;

	@Input()
	public options!: DialogOptions;

	@Input()
	public theme!: ThemeColorsType;

	public buttonClass = btnPrimary;

	public resolve!: (value: DialogResult) => void;

	public ngOnChanges(changes: SimpleChanges) {
		const theme = changes.theme?.currentValue as ThemeColorsType;
		if (theme) {
			this.setThemeColors(theme);
		}
	}

	public ngOnInit(): void {
		this.setupDefaults();
	}

	public show(): Promise<DialogResult> {
		const promise = new Promise<DialogResult>(resolve => {
			this.resolve = resolve;
		});

		this.modal.show();
		return promise;
	}

	public accept(): void {
		this.modal.hide();
		this.resolve(DialogResult.Accept);
	}

	public decline(): void {
		this.modal.hide();
		this.resolve(DialogResult.Decline);
	}

	private setupDefaults(): void {
		if (!this.options) {
			this.options = new DialogOptions();
		}

		if (!this.options.acceptLabel) {
			this.options.acceptLabel = 'global_label_ok';
		}

		if (!this.options.declineLabel) {
			this.options.declineLabel = 'global_label_cancel';
		}
	}

	private setThemeColors(theme: ThemeColorsType) {
		switch (theme) {
			case 'success':
				this.buttonClass = 'btn-secondary';
				break;
			case 'danger':
				this.buttonClass = 'btn-danger';
				break;
			default:
				this.buttonClass = btnPrimary;
		}
	}
}

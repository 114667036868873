import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { TPLossExposureModel } from '../../models/tploss-exposure-model.model';
import { OperationResult } from '../../models/operation-result.model';
import { TPLossExposureUpdateModel } from '../../models/tploss-exposure-update-model.model';
import { TpLossExposureCreateViewModel } from '../../models/tp-loss-exposure-create-view-model.model';

@Injectable()
export class TPLossExposureDataService {
	constructor(private httpService: RQHttpService) {}

	public getByThirdPartyId(thirdPartyId: string, options = new HttpOptionsViewModel()): Promise<TPLossExposureModel> {
		options.params = this.httpService.getHttpParams({
			thirdPartyId
		});

		return this.httpService
			.request<TPLossExposureModel>('get', environment.api + '/api/TPConfig/TPLossExposure/GetByThirdPartyId', options)
			.toPromise();
	}

	public update(model: TPLossExposureUpdateModel, options = new HttpOptionsViewModel()): Promise<OperationResult> {
		options.body = model;

		return this.httpService.request<OperationResult>('post', environment.api + '/api/TPConfig/TPLossExposure/Update', options).toPromise();
	}

	public create(legalEntityId: string, createModel: TpLossExposureCreateViewModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		options.body = createModel;

		return this.httpService.request<string>('post', environment.api + '/api/TPConfig/TPLossExposure/Create', options).toPromise();
	}
}

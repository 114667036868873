import { RecommendationDataService } from '../../../../../server/services/analysis/recommendation-data.service';
import { Component, Input, ViewChild } from '@angular/core';
import {
	LTRecommendationAnalysisApplicationViewModel,
	LTRecommendationAnalysisViewModel
} from '../../../../../routes/control-analysis/_common/models/recommendations-analysis-view.viewmodel';
import { ApplicationInstanceStore } from '../../../../core/application.service';
import { EnterpriseRecommendationDataService } from '../../../../../server/services/enterprise/enterprise-recommendation-data.service';
import { AnalysisDetailsFilterModel } from '../../../../../server/models/analysis-details-filter-model.model';
import { Pagination } from '../../../../../server/models/pagination.model';
import { RecommendationApplicationDetailsModel } from '../../../../../server/models/recommendation-application-details-model.model';
import { RecommendationApplicationInputModel } from '../../../../../server/models/recommendation-application-input-model.model';
import { ModalComponent } from '../../../../../standalone/components/modal/modal.component';
import { PagerComponent } from '../../../../../standalone/components/pager/pager.component';
import { PaginationState } from '../../../../../standalone/components/pager/pager.ui-model';
import { CardLayoutComponent } from '../../../../../standalone/components/card-layout/card-layout.component';
import { Filter } from '../../../../../standalone/components/form-elements/filter/filter.model';

@Component({
	selector: 'rq-application-list-modal',
	templateUrl: './application-list-modal.component.html',
	styleUrls: ['./application-list-modal.component.scss']
})
export class ApplicationListModalComponent {
	@ViewChild(ModalComponent, { static: true })
	public modal!: ModalComponent;

	@ViewChild(CardLayoutComponent, { static: true })
	public card!: CardLayoutComponent;

	@ViewChild(PagerComponent)
	public pagerComponent!: PagerComponent;

	@Input()
	public selectedItem!: LTRecommendationAnalysisViewModel;

	public data!: Array<LTRecommendationAnalysisApplicationViewModel>;

	public count!: number;

	public paginationState: PaginationState = { skip: 0, take: 10 };

	constructor(
		private recommendationDataService: RecommendationDataService,
		private enterpriseRecommendationDataService: EnterpriseRecommendationDataService,
		private applicationService: ApplicationInstanceStore
	) {}

	public async loadData() {
		await this.card.toggleLoadingBox(async () => {
			const filter = new AnalysisDetailsFilterModel();
			filter.attackId = new Filter<string>();
			filter.attackId.value = this.selectedItem.attackId;

			this.applicationService.isEnterprise ? await this.getEnterpriseApplications(filter) : await this.getApplications(filter);
		});
	}

	public async show() {
		this.modal.show();
		this.pagerComponent.triggerReset();
		this.paginationState = { skip: 0, take: 5 };
		await this.loadData();
	}

	public close() {
		this.modal.hide();
	}

	public pageChange() {
		this.loadData();
	}

	private async getEnterpriseApplications(filter: AnalysisDetailsFilterModel) {
		const input = new RecommendationApplicationInputModel();

		input.analysisId = this.selectedItem.analysisId;
		input.controlOptionId = this.selectedItem.controlOptionId;
		input.oldValue = this.selectedItem.controlImprovements.oldValue;
		input.proposedValue = this.selectedItem.controlImprovements.proposedValue;

		const data = await this.enterpriseRecommendationDataService.getApplications(input, filter, this.paginationState);
		this.count = data.count;

		this.data = this.createData(data);
	}

	private async getApplications(filter: AnalysisDetailsFilterModel) {
		const input = new RecommendationApplicationInputModel();

		input.analysisId = this.selectedItem.analysisId;
		input.controlOptionId = this.selectedItem.controlOptionId;
		input.oldValue = this.selectedItem.controlImprovements.oldValue;
		input.proposedValue = this.selectedItem.controlImprovements.proposedValue;

		const data = await this.recommendationDataService.getApplications(input, filter, this.paginationState);
		this.count = data.count;

		this.data = this.createData(data);
	}

	private createData(data: Pagination<RecommendationApplicationDetailsModel>) {
		return data.values.map(item => {
			return <LTRecommendationAnalysisApplicationViewModel>{
				label: item.displayName,
				reduction: item.expectedLossReduction,
				changeInPs: item.expectedLossProbabilityReduction,
				sleReduction: item.expectedSLEReduction
			};
		});
	}
}

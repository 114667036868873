import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { LegalEntityStateModel } from '../../../../server/models/legal-entity-state-model.model';
import { LegalEntityDataService } from '../../../../server/services/configuration/legal-entity-data.service';
import { LegalEntityModel } from '../../../../server/models/legal-entity-model.model';

@Injectable({
	providedIn: 'root'
})
export class LegalEntityStore implements OnDestroy {
	public readonly states$: Observable<LegalEntityStateModel | undefined>;

	private _states$ = new BehaviorSubject<LegalEntityStateModel | undefined>(undefined);

	private subscriptions: Subscription[] = [];

	private legalEntityId!: string;

	private _legalEntity!: LegalEntityModel;

	constructor(private legalEntityDataService: LegalEntityDataService) {
		this.states$ = this._states$.asObservable();
	}

	public get states() {
		return this._states$.getValue();
	}

	public get legalEntity() {
		return this._legalEntity;
	}

	public ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	public async init(legalEntityId: string) {
		this.legalEntityId = legalEntityId;

		await Promise.all([this.getLegalEntity(legalEntityId), this.getLegalEntityState(legalEntityId)]);
	}

	public async refreshLegalEntityState() {
		await this.getLegalEntityState(this.legalEntityId);
	}

	private async getLegalEntityState(legalEntityId: string) {
		const data = await this.legalEntityDataService.getState(legalEntityId);
		this._states$.next(data);
	}

	private async getLegalEntity(legalEntityId: string) {
		const data = await this.legalEntityDataService.getById(legalEntityId);
		this._legalEntity = data;
	}
}

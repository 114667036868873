<rq-modal class="modal-xl"
          [hasFixedContent]="false">
  <rq-modal-header>
    {{'global_notifications_label' | translate}}
  </rq-modal-header>

  <rq-modal-body>
    <rq-notification-table (loadData)='loadData($event)'
                           [isMarkAllVisible]="false"
                           [hasActions]="hasActions"
                           [hasFilters]="hasFilters"
                           [data]="tableData"
                           [count]="tableCount">
    </rq-notification-table>
  </rq-modal-body>

  <rq-modal-footer>
    <section class="d-flex justify-content-end">
      <button type='button'
              e2e-test="notification-modal-close-btn"
              (click)='closeModal()'
              class='btn-secondary'>{{'global_label_close' | translate}}</button>
    </section>
  </rq-modal-footer>
</rq-modal>

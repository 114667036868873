<div class="chart"
     #chart></div>

<ng-content></ng-content>

<ng-template #defaultTooltip
             let-entries="entries"
             let-currentIndex="currentIndex"
             let-total="total">
  <ng-container *ngFor="let entry of entries; let i = index">
    <div *ngIf="isEntryVisible(entry, currentIndex)">
      <span class="marker"
            [ngStyle]="{backgroundColor: colors[i]}"></span>
      <span>{{entry.name}} {{getFormattedValue(entry.values[currentIndex].value)}}</span>
    </div>
  </ng-container>
  <strong>
    {{'global_total_label' | translate}} {{getFormattedValue(total)}}
  </strong>
</ng-template>

<div class="tooltip-container"
     *ngIf="data"
     hidden>
  <ng-container *ngFor="let total of totals; let currentIndex = index">
    <div [id]="currentIndex">
      <ng-container *ngTemplateOutlet="customTooltip || defaultTooltip; context: { entries: data, currentIndex, total }"></ng-container>
    </div>
  </ng-container>
</div>

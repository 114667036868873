<div class="control-level-container p-l-xl p-r-xl p-t-md p-b-md">
  <section class="capabilities-label">
    <p *ngIf="oldValue">
      <span *ngIf="oldValue < proposedValue">
        <strong class="capabilities-description">
          {{ 'capabilities_description_from' | translate : { oldValue: controlLevel } }}
        </strong>
      </span>
      <span *ngIf="oldValue > proposedValue">
        <strong class="capabilities-description">
          {{ 'reduce_capabilities_description_from' | translate : { oldValue: controlLevel } }}
        </strong>
      </span>
    </p>
    <p *ngIf="!oldValue">
      <strong class="capabilities-description">
        {{ 'capabilities_description_to' | translate : { proposedValue: controlLevel } }}
      </strong>
    </p>
  </section>

  <section class="control-level-header">
    <div class="control-level-badge">
      <rq-enterprise-controls-badge class="level-badge"
                                    [isHighlighted]="true"
                                    [value]="controlLevel"> </rq-enterprise-controls-badge>

      <div class="level-title">
        <p class="primary-l-10-color"
           *ngIf="controlSubType">{{ controlSubType | controlSubTypeLabel }}</p>
        <h1>{{ controlLevelName | translate }}</h1>
      </div>

      <div class="level-arrow">
        <rq-icon class="icon-arrow"
                 href="icon-arrow-chevron-right"></rq-icon>
      </div>
    </div>

    <p class="control-description">
      {{ shortDescriptions | translate }}
    </p>

    <ng-container *ngIf="expectedLossReduction">
      <div class="control-space">
        <div class="control-reduction">
          <p>
            <span>
              {{ 'dashboard_reduce_technical_risk_label' | translate }}
            </span>
            <strong class="reduction">{{ expectedLossReduction | rqPercent }} </strong>
          </p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="expectedSLE"
                  class="control-reduction">
      <div class="control-space">
        <div class="control-reduction">
          <p>
            <span>
              {{ 'global_reduce_sle_by_label' | translate }}
            </span>
            <strong class="reduction">{{ expectedSLE | rqPercent }}</strong>
          </p>
        </div>
      </div>
    </ng-container>
  </section>

  <div class="evidence-description">
    <ng-container *ngIf="noOfEvidences !== 0">
      <p class="text-center">
        <strong>{{ 'there_are_label' | translate }}</strong>
        <strong class="accent-color"> {{ noOfEvidences }} </strong>
        <strong>{{ 'evidences_included_description' | translate }}</strong>
      </p>
      <p class="text-center primary-l-10-color">
        {{ 'evidences_current_description' | translate }}
      </p>
    </ng-container>
    <ng-container *ngIf="noOfEvidences === 0">
      <p class="text-center">
        <strong>{{ 'no_evidences_included_label' | translate }}</strong>
      </p>
      <p class="text-center primary-l-10-color">
        {{ 'no_evidences_description' | translate }}
      </p>
    </ng-container>
  </div>

  <button [disabled]="!noOfEvidences"
          (click)="showCurrentEvidence()"
          class="btn-secondary evidence-button">
    {{ 'evidences_current_label' | translate }}
  </button>
</div>

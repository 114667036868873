import { BaseComponent } from '../../components/base.component';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { StartingPageItemViewModel } from '../../models/starting-page-item.viewmodel';
import { ModalComponent } from '../../../standalone/components/modal/modal.component';

@Component({
	selector: 'rq-starting-page',
	templateUrl: './starting-page.component.html',
	styleUrls: ['./starting-page.component.scss']
})
export class StartingPageComponent extends BaseComponent implements OnInit {
	@ViewChild(ModalComponent, { static: true })
	public modal!: ModalComponent;

	@Input()
	public items!: Array<StartingPageItemViewModel>;

	@Output()
	public readonly updateUserPreference = new EventEmitter<boolean>();

	public formGroup!: UntypedFormGroup;

	constructor(private fb: UntypedFormBuilder, private router: Router) {
		super();
	}

	public ngOnInit() {
		this.setupForm();
	}

	public show() {
		this.modal.show();
	}

	public close() {
		this.modal.hide();
	}

	public async continue(item: StartingPageItemViewModel) {
		await this.router.navigateByUrl(item.path);
		this.close();
	}

	private setupForm() {
		this.formGroup = this.fb.group({
			hideStartupPage: ['']
		});

		this.subscriptions.push(
			this.formGroup.controls.hideStartupPage.valueChanges.subscribe((value: boolean) => {
				this.updateUserPreference.emit(value);
			})
		);
	}
}

import { Directive, ElementRef, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[rqInfiniteScroll]'
})
export class InfiniteScrollDirective implements OnInit, OnDestroy {
	@Output()
	public readonly scrolled: EventEmitter<void> = new EventEmitter<void>();

	private observer: IntersectionObserver | undefined;

	constructor(private el: ElementRef) {}

	public ngOnInit(): void {
		this.setupIntersectionObserver();
	}

	public ngOnDestroy(): void {
		if (this.observer) {
			this.observer.disconnect();
		}
	}

	private setupIntersectionObserver(): void {
		this.observer = new IntersectionObserver(
			entries => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						this.scrolled.emit();
					}
				});
			},
			{
				root: this.el.nativeElement as Element,
				rootMargin: '0px',
				threshold: 1.0
			}
		);

		const targetElement = this.el.nativeElement.querySelector('.load-more') as Element;
		if (targetElement) {
			this.observer.observe(targetElement);
		}
	}
}

import { ISpecificThemeVariables, IThemePrimitives } from '../theme.interface';

/* eslint-disable sonarjs/no-duplicate-string */
export const darkThemePrimitives: IThemePrimitives = {
	primary: '#ffffff',
	secondary: '#333333',

	brand: '#ff7a4f',
	'brand-fill': '#FFD8CC',
	'brand-contrast': '#ff5c28',
	'brand-overlay': '#FFF2EE',

	neutral: '#ededed',
	'neutral-fill': '#cccccc',
	'neutral-contrast': '#6c6d7a',
	'neutral-overlay': '#f8f8f8',

	success: '#b0dd7a',
	'success-fill': '#e7f6d9',
	'success-contrast': '#75b259',
	'success-overlay': '#f3faec',

	warning: '#ffca28',
	'warning-fill': '#ffe082',
	'warning-contrast': '#ffb300',
	'warning-overlay': '#fffbf0',

	danger: '#ef5350',
	'danger-fill': '#ef9a9a',
	'danger-overlay': '#fff8f6',
	'danger-contrast': '#ea1915',

	info: '#42a5f5',
	'info-fill': '#90caf9',
	'info-overlay': '#f1f8fe',
	'info-contrast': '#1976d2',

	royal: '#a06cd5',
	'royal-fill': '#d2b7e5',
	'royal-overlay': '#f4effa',
	'royal-contrast': '#6247aa',

	clear: '#97efe2',
	'clear-fill': '#d6f9f3',
	'clear-overlay': '#eafcf9',
	'clear-contrast': '#44e2ca'
};

export const darkSpecificThemeVariables: ISpecificThemeVariables = {
	'sidebar-background': 'var(--background-neutral-overlay)',
	'overlay-background': 'rgba(115, 125, 129, 0.5)',
	'shadow-xs': '',
	'shadow-sm': '',
	'shadow-md': '',
	'shadow-left': '',
	'shadow-right': ''
};

import { Component, ContentChild, Input, TemplateRef, ViewChild, SimpleChanges, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { TableDataDirective } from '../table-data.directive';
import { TableService } from '../_common/table.service';
import {
	CalendarFilter,
	DropdownFilter,
	NumericFilter,
	RangeFilter,
	SearchFilter,
	SelectFilter
} from '../../form-elements/filter/filter.model';

@Component({
	selector: 'rq-table-column',
	templateUrl: './table-column.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableColumnComponent implements OnChanges {
	@ContentChild(TableDataDirective)
	public tableDataDirective!: TableDataDirective;

	@ViewChild(TemplateRef, { static: true })
	public template!: TemplateRef<unknown>;

	@Input()
	public title!: string;

	@Input()
	public field!: string;

	@Input()
	public isHidden!: boolean;

	@Input()
	public width!: string;

	@Input()
	public isFilterable = true;

	@Input()
	public isSortable = true;

	@Input()
	public textAlign: 'left' | 'right' | 'center' = 'left';

	@Input()
	public filter!: 'string' | 'numeric' | 'range' | 'select' | 'dropdown' | 'calendar';

	@Input()
	public filterSetup!: SearchFilter | NumericFilter | RangeFilter | SelectFilter | DropdownFilter | CalendarFilter;

	@Input()
	public preselectedValue!: unknown | Array<unknown>;

	constructor(private tableService: TableService) {}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.preselectedValue?.currentValue) {
			this.tableService.preselectedChanged$.next();
		}

		if (changes.filterSetup?.currentValue) {
			this.tableService.filterSetupChanged$.next();
		}
	}

	public getTableDataValue(obj: Record<string, never>, field: string) {
		if (!field) {
			return obj;
		}

		let prop: string;
		const props = field.split('.');
		let i = 0;
		for (i; i < props.length - 1; i++) {
			prop = props[i];

			const candidate = obj[prop];
			if (candidate !== undefined) {
				obj = candidate;
			} else {
				break;
			}
		}
		return obj[props[i]];
	}
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { FairTEFValueModel } from '../../models/fair-tefvalue-model.model';
import { Pagination } from '../../models/pagination.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { FairTEFValueFilterModel } from '../../models/fair-tefvalue-filter-model.model';
import { FairTEFValueFilterOptionModel } from '../../models/fair-tefvalue-filter-option-model.model';
import { FairTEFValueUpdateModel } from '../../models/fair-tefvalue-update-model.model';
import { FairTEFValueCreateModel } from '../../models/fair-tefvalue-create-model.model';

@Injectable()
export class FairTEFValueDataService {
	constructor(private httpService: RQHttpService) {}

	public getByLegalEntityId(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<FairTEFValueModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<FairTEFValueModel[]>('get', environment.api + '/api/Fair/FairTEFValue/GetByLegalEntityId', options)
			.toPromise();
	}

	public getPaginated(
		legalEntityId: string,
		pagination: PaginationFilter,
		filter: FairTEFValueFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<FairTEFValueModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<FairTEFValueModel>>('get', environment.api + '/api/Fair/FairTEFValue/GetPaginated', options)
			.toPromise();
	}

	public getFilterOptions(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<FairTEFValueFilterOptionModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<FairTEFValueFilterOptionModel>('get', environment.api + '/api/Fair/FairTEFValue/GetFilterOptions', options)
			.toPromise();
	}

	public getByName(legalEntityId: string, name: string, options = new HttpOptionsViewModel()): Promise<FairTEFValueModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			name
		});

		return this.httpService.request<FairTEFValueModel>('get', environment.api + '/api/Fair/FairTEFValue/GetByName', options).toPromise();
	}

	public update(updateModel: FairTEFValueUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = updateModel;

		return this.httpService.request<void>('put', environment.api + '/api/Fair/FairTEFValue/Update', options).toPromise();
	}

	public create(createModel: FairTEFValueCreateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = createModel;

		return this.httpService.request<void>('post', environment.api + '/api/Fair/FairTEFValue/Create', options).toPromise();
	}

	public revertDefaults(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService.request<void>('get', environment.api + '/api/Fair/FairTEFValue/RevertDefaults', options).toPromise();
	}
}

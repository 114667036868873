<div class="compare-item m-r-md">
  <div class="compare-icon">
    <ng-container *ngIf="!isDelta">
      <rq-enterprise-controls-badge class="m-r-md"
                                    [isHighlighted]="true"
                                    [value]="controlLevel"> </rq-enterprise-controls-badge>
    </ng-container>
  </div>
  <div class="compare-content m-r-xs">
    <ng-container *ngIf="!isDelta">
      <span class="control-name black-color"><strong>{{ controlLevelName | translate }}</strong></span>
      <span class="control-description">
        <strong class="black-color">{{ 'there_are_label' | translate }}</strong>
        <strong class="accent-color"> {{ noOfEvidences }} </strong>
        <strong class="black-color">{{ 'evidences_included_description' | translate }}</strong>
      </span>
    </ng-container>

    <ng-container *ngIf="isDelta">
      <span class="control-name black-color"><strong>{{ 'evidences_summary_label' | translate }}</strong></span>
      <span class="control-description">
        <strong class="black-color">{{ 'there_are_label' | translate }}</strong>
        <strong class="accent-color"> {{ noOfEvidences }} </strong>
        <strong class="black-color">{{ 'evidences_total_description' | translate }}</strong>
      </span>
    </ng-container>
  </div>

  <ng-container *ngIf="hasNext">
    <div class="compare-icon">
      <rq-icon class="arrow icon-xl"
               href="icon-arrow-chevron-right"></rq-icon>
    </div>
  </ng-container>
</div>

<rq-alert e2e-test='delete-alert-dialog'
          [theme]="'primary'">
  <rq-alert-title class="dialog-header">
    <span e2e-test="delete-alert-title">{{'global_important_label' | translate }}</span>
  </rq-alert-title>
  <rq-alert-body>
    <p class='text-center'
       style="word-break: break-all"
       [innerHTML]="'businessApplication_alertModal_descriptionName' | translate : {deletedBusinessApplication: '<strong>' +
        name + '</strong>'}">
    </p>
  </rq-alert-body>
</rq-alert>

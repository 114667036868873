import { Directive, HostListener } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[rqPreventDefault]'
})
export class PreventDefaultDirective {
	@HostListener('click', ['$event'])
	public click(event: Event) {
		event.preventDefault();
	}
}

import {
	Component,
	ContentChild,
	ContentChildren,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	QueryList,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { ListHeaderDirective } from './list-header.directive';
import { ListContentDirective } from './list-content.directive';
import { LoadingBoxType } from '../loading-box/loading-box-type.enum';
import { PagerComponent } from '../pager/pager.component';
import { PagerConfig, PaginationState } from '../pager/pager.ui-model';
import { FilterConfig, FilterOutput } from '../form-elements/filter/filter.model';
import { TableOutput } from '../table/models/table-output.model';

@Component({
	selector: 'rq-list-items',
	templateUrl: './list-items.component.html',
	styleUrls: ['./list-items.component.scss']
})
export class ListItemsComponent<T> implements OnChanges {
	@ViewChild(PagerComponent)
	public pager!: PagerComponent;

	@ContentChildren(ListHeaderDirective)
	public listHeaderDirective!: QueryList<ListHeaderDirective>;

	@ContentChild(ListContentDirective)
	public listContentDirective!: ListContentDirective;

	@Input()
	public data!: Array<T>;

	@Input()
	public count!: number;

	@Input()
	public pagerState = new PaginationState();

	@Input()
	public filterConfig!: Array<FilterConfig>;

	@Input()
	public hasFilters = true;

	@Output()
	public readonly pageChange = new EventEmitter();

	@Output()
	public readonly filterChange = new EventEmitter();

	public pagerConfig = new PagerConfig();

	public filterOutput: FilterOutput = {};

	public isLoading = true;

	public LoadingBoxType = LoadingBoxType;

	public get isMessageVisible() {
		return this.data?.length === 0 && !this.isLoading;
	}

	public get hasData() {
		return this.data?.length > 0;
	}

	public get isFirstLoadingVisible() {
		return this.isLoading && (!this.data || this.data?.length === 0);
	}

	public get isPagerConfigured() {
		return Boolean(this.pagerState);
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.data?.currentValue && this.count !== null && this.count !== undefined) {
			this.isLoading = false;
		}
	}

	public triggerPageChange(state: PaginationState) {
		this.isLoading = true;
		this.pagerState = state;
		const tableOutput = new TableOutput<unknown>(this.filterOutput, this.pagerState);
		this.pageChange.emit(tableOutput);
	}

	public triggerFilterChange(output: unknown) {
		this.isLoading = true;
		this.filterOutput = output as FilterOutput;
		this.pager.reset();
		const tableOutput = new TableOutput<unknown>(this.filterOutput, this.pagerState);
		this.filterChange.emit(tableOutput);
	}
}

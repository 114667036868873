<section class="switch-wrapper">
  <label *ngIf="!hideLeftLabel"
         [class.switch-label-disabled]="(value && !hideRightLabel) || isDisabled">{{
        leftLabelText }}</label>
  <label class="switch-container"
         (click)="triggerTouch()"
         [class.disabled]="isDisabled">
    <input type="checkbox"
           [(ngModel)]="value" />
    <div class="switch-item"></div>
  </label>
  <label *ngIf="!hideRightLabel"
         [class.switch-label-disabled]="(!value && !hideLeftLabel) || isDisabled">{{
        rightLabelText }}</label>
</section>

import { Component, ElementRef, Input, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type CustomUnknownType = string | number | null | unknown[];

@Component({
	selector: 'rq-multiple-input',
	templateUrl: './multiple-input.component.html',
	styleUrls: ['./multiple-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => MultipleInputComponent),
			multi: true
		}
	]
})
export class MultipleInputComponent implements ControlValueAccessor {
	@ViewChild('inputControl', { read: ElementRef, static: true })
	public inputControl!: ElementRef;

	@Input()
	public formControlName?: string;

	@Input()
	public placeholder = '';

	@Input()
	public disabled = false;

	public input = '';

	public values: string[] = [];

	public onTouchedCallback!: (value?: CustomUnknownType) => void;

	private onChangeCallback!: (value?: CustomUnknownType) => void;

	public writeValue(values?: string[]): void {
		this.values = values ? values : [];
	}

	public insertValue(keyboardEvent?: KeyboardEvent | Event) {
		keyboardEvent?.stopPropagation();

		if (!this.disabled && this.input) {
			this.values.push(this.input);
			this.input = '';
			this.onChangeCallback(this.values);
		}

		if (keyboardEvent && (keyboardEvent as KeyboardEvent).key !== 'Tab') {
			this.inputControl.nativeElement.getElementsByTagName('input').item(0)?.focus();
		}
	}

	public removeValue(index: number) {
		if (!this.disabled) {
			this.values.splice(index, 1);
			this.onChangeCallback(this.values);
		}
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	public registerOnChange(fn: (value?: CustomUnknownType) => void): void {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: (value?: CustomUnknownType) => void): void {
		this.onTouchedCallback = fn;
	}
}

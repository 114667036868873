import { Component, Input } from '@angular/core';
import { ViewScenarioDetailsService } from '../../../routes/control-analysis/_common/services/view-scenario-details.service';
import { TopThreatsViewModel } from '../../../routes/control-analysis/_common/models/top-threats.viewmodel';
import { TableBase2Component } from '../../../standalone/components/table/table-base2.component';

@Component({
	selector: 'rq-analysis-threats-table',
	templateUrl: './analysis-threats-table.component.html',
	styleUrls: ['./analysis-threats-table.component.scss']
})
export class AnalysisThreatsTableComponent extends TableBase2Component<TopThreatsViewModel, null> {
	@Input()
	public isLegalEntityVisible = false;

	@Input()
	public isActionsVisible = true;

	@Input()
	public entityColumnName = 'global_name_label';

	constructor(private viewScenarioDetailsService: ViewScenarioDetailsService) {
		super();
	}

	public get nameTitle() {
		return this.entityColumnName;
	}

	public triggerViewDetails(data: TopThreatsViewModel) {
		this.viewScenarioDetailsService.change.next(data);
	}
}

import { PipeTransform, Pipe } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { ApplicationEnterpriseProfileType } from 'src/app/server/enums/application-enterprise-profile-type.enum';

@Pipe({
	name: 'applicationEnterpriseProfileTypeLabel'
})
export class ApplicationEnterpriseProfileTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: ApplicationEnterpriseProfileType) {
		const values = new Map<ApplicationEnterpriseProfileType, string>();
		values.set(ApplicationEnterpriseProfileType.Inherent, 'global_inherentRisk_label');
		values.set(ApplicationEnterpriseProfileType.Residual, 'global_residualRisk_label');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${ApplicationEnterpriseProfileType[value]} does not exist`);
		}

		return this.translationService.get(type);
	}
}

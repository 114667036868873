<ul class='list-unstyled '>
  <li class='comparison-row'
      [class.comparison-row-highlighted]='row.isHighlighted'
      *ngFor='let row of data.rows; let i = index'
      (mouseenter)='triggerMouseEnter(row, i)'
      [class.comparison-row-hover]='isHoverActive(row, i)'>
    <ng-container *ngIf='!isCustomTemplate(row) && !isRowLoading(row, i)'>
      <p class='comparison-row-label comparison-row-section'>
        <strong [attr.title]='getLabel(row)'>{{getLabel(row)}}:</strong>
      </p>
      <p class='comparison-value comparison-row-section'>
        <ng-container *ngTemplateOutlet='valueTemplate; context: {entity: row}'></ng-container>
      </p>
    </ng-container>
    <ng-container *ngIf='isCustomTemplate(row) && !isRowLoading(row, i)'>
      <div class='comparison-value-container'>
        <ng-container *ngTemplateOutlet='getTemplate(row); context: {entity: row}'></ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="isRowLoading(row, i)">
      <div class="loading-bar"></div>
    </ng-container>
  </li>
</ul>

<div (click)="toggleState()">
  <ng-container [ngTemplateOutlet]="actionElement?.templateRef || defaultActionElement"></ng-container>
</div>

<section *ngIf="isVisible"
         class="content"
         [style.white-space]="noWrap ? 'nowrap': 'normal'">
  <rq-three-dots-menu-items [options]="items"
                            (itemClicked)="closeMenu()">

  </rq-three-dots-menu-items>

</section>

<ng-template #defaultName
             let-item
             let-index="index">
  <p class="menu-name">{{item?.name}}</p>
</ng-template>

<ng-template #defaultActionElement>
  <rq-icon class="trigger-button"
           href="icon-more-vertical"></rq-icon>
</ng-template>

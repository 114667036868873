import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	ViewChild,
	ViewChildren
} from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { WizardSaveStep, WizardSteps } from './models/wizard-steps.model';
import { WizardService } from './wizard.service';
import { WizardConfigService } from './wizard-config.service';
import { WizardConfig } from './models/wizard-config.model';
import { LoadingInlineDirective } from '../../directives/loading-inline.directive';

@Component({
	selector: 'rq-wizard',
	templateUrl: './wizard.component.html',
	styleUrls: ['./wizard.component.scss'],
	providers: [WizardService, WizardConfigService]
})
export class WizardComponent<T> implements OnInit, AfterViewInit, OnDestroy {
	@HostBinding('class.wizzard')
	public hasDefaultClass = true;

	@ViewChildren('wizardSection')
	public wizardSections!: QueryList<ElementRef<Element>>;

	@ViewChild('submit', { read: LoadingInlineDirective })
	public loadingInlineDirective!: LoadingInlineDirective;

	@Input()
	public config = new WizardConfig();

	@Input()
	public steps: WizardSteps<T>[] = [];

	@Output()
	public readonly exit = new EventEmitter();

	public readonly myMatchOptions: IsActiveMatchOptions = {
		queryParams: 'ignored',
		matrixParams: 'ignored',
		paths: 'subset',
		fragment: 'ignored'
	};

	private subscriptions: Subscription[] = [];

	constructor(private router: Router, public wizardService: WizardService, private configWizardService: WizardConfigService) {}

	public get isLastStep() {
		return Boolean(this.activeStepIndex === this.steps.length - 1);
	}

	public get isFirstStep() {
		return Boolean(this.activeStepIndex === 0);
	}

	public get submitLabel() {
		return this.isLastStep ? this.config.finishButonLabel : this.config.nextButtonLabel;
	}

	public get prevStepLabel() {
		return this.isFirstStep ? this.config.cancelButtonLabel : this.config.previousButtonLabel;
	}

	public get activeStepIndex() {
		const selectedStepElement = this.wizardSections?.find(x => Array.from(x.nativeElement.classList).includes('wizzard-section-selected'));
		return Number(selectedStepElement?.nativeElement.id ?? 0);
	}

	public ngOnInit() {
		this.updateConfigWizardService();

		this.subscribeToChangeStep();
	}

	public ngAfterViewInit() {
		this.subscribeToToggleSubmitLoading();
	}

	public ngOnDestroy() {
		this.subscriptions.forEach(x => x.unsubscribe());
	}

	public getBaseUrl(path: string) {
		return path.split('?')[0];
	}

	public navigateTo(stepIndex: number) {
		const item = this.steps[stepIndex];
		this.router.navigateByUrl(`${item.path}`);
	}

	public isDisabled(stepIndex: number) {
		return this.configWizardService.disabledSteps.includes(stepIndex);
	}

	public triggerStepChange(selectedStepIndex: number) {
		this.wizardService.triggerSaveStep(new WizardSaveStep(selectedStepIndex));
	}

	public triggerPrevStep() {
		if (this.isFirstStep) {
			this.exit.emit();
		} else {
			this.navigateTo(this.activeStepIndex - 1);
		}
	}

	public triggerSubmit() {
		const index = this.isLastStep ? undefined : this.activeStepIndex + 1;
		this.wizardService.triggerSaveStep(new WizardSaveStep(index, true));
	}

	private updateConfigWizardService() {
		this.configWizardService.setActiveStepIndex(this.activeStepIndex);
		this.configWizardService.setIsLastStep(this.isLastStep);
	}

	private subscribeToToggleSubmitLoading() {
		this.subscriptions.push(
			this.configWizardService.toggleSubmitLoading$.subscribe(async value => {
				await this.loadingInlineDirective.toggleVisibility(value);
			})
		);
	}

	private subscribeToChangeStep() {
		this.subscriptions.push(
			this.configWizardService.changeStep$.subscribe((stepIndex: number | undefined) => {
				this.wizardService.toggleSubmitLoading(false);
				if (stepIndex !== undefined) {
					if (!this.isDisabled(stepIndex)) {
						this.navigateTo(stepIndex);
						this.updateConfigWizardService();
					}
				} else {
					this.exit.emit();
				}
			})
		);
	}
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { LookupTableDefinitionViewModel } from '../../models/lookup-table-definition-view-model.model';
import { LookupTableFilterModel } from '../../models/lookup-table-filter-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { LookupTableViewModel } from '../../models/lookup-table-view-model.model';
import { LookupTableCreateViewModel } from '../../models/lookup-table-create-view-model.model';
import { IActionResult } from '../../models/iaction-result.model';
import { LookupTableUpdateViewModel } from '../../models/lookup-table-update-view-model.model';

@Injectable()
export class EnterpriseLookupTableDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		filter: LookupTableFilterModel,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<LookupTableDefinitionViewModel>> {
		options.params = this.httpService.getHttpParams({
			filter,
			pagination
		});

		return this.httpService
			.request<Pagination<LookupTableDefinitionViewModel>>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseLookupTable/GetPaginated',
				options
			)
			.toPromise();
	}

	public getById(tableId: string, options = new HttpOptionsViewModel()): Promise<LookupTableViewModel> {
		options.params = this.httpService.getHttpParams({
			tableId
		});

		return this.httpService
			.request<LookupTableViewModel>('get', environment.api + '/api/Enterprise/EnterpriseLookupTable/GetById', options)
			.toPromise();
	}

	public getAll(options = new HttpOptionsViewModel()): Promise<LookupTableViewModel[]> {
		return this.httpService
			.request<LookupTableViewModel[]>('get', environment.api + '/api/Enterprise/EnterpriseLookupTable/GetAll', options)
			.toPromise();
	}

	public hasUniqueName(modelName: string, options = new HttpOptionsViewModel()): Promise<boolean> {
		options.params = this.httpService.getHttpParams({
			modelName
		});

		return this.httpService
			.request<boolean>('get', environment.api + '/api/Enterprise/EnterpriseLookupTable/HasUniqueName', options)
			.toPromise();
	}

	public create(viewModel: LookupTableCreateViewModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = viewModel;

		return this.httpService.request<string>('post', environment.api + '/api/Enterprise/EnterpriseLookupTable/Create', options).toPromise();
	}

	public update(viewModel: LookupTableUpdateViewModel, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.body = viewModel;

		return this.httpService
			.request<IActionResult>('put', environment.api + '/api/Enterprise/EnterpriseLookupTable/Update', options)
			.toPromise();
	}

	public delete(tableId: string, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.params = this.httpService.getHttpParams({
			tableId
		});

		return this.httpService
			.request<IActionResult>('delete', environment.api + '/api/Enterprise/EnterpriseLookupTable/Delete', options)
			.toPromise();
	}
}

<header></header>
<article>
  <ul *ngFor="let i of [].constructor(10)"
      class="overview-skeleton-content">
    <li>
      <span></span>
      <span></span>
    </li>
    <li>
      <span></span>
      <span></span>
    </li>
    <li>
      <span></span>
      <span></span>
    </li>
  </ul>
</article>

import { Component, ElementRef, HostBinding, HostListener, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MathOperatorEnum } from './math-operator.enum';

@Component({
	selector: 'rq-math-operator-dropdown',
	templateUrl: './math-operator-dropdown.component.html',
	styleUrls: ['./math-operator-dropdown.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => MathOperatorDropdownComponent),
			multi: true
		}
	]
})
export class MathOperatorDropdownComponent implements ControlValueAccessor, OnInit {
	@Input()
	@HostBinding('class.disabled')
	public isDisabled!: boolean;

	@HostBinding('class.dropdown-expended')
	public isExpanded = false;

	public options = [
		MathOperatorEnum.Addition,
		MathOperatorEnum.Subtraction,
		MathOperatorEnum.Multiplication,
		MathOperatorEnum.Division,
		MathOperatorEnum.Modulo
	];

	public selectedOption?: MathOperatorEnum;

	public MathOperatorEnum = MathOperatorEnum;

	private element!: HTMLElement;

	private onTouchedCallback!: () => void;

	private onChangeCallback!: (_: unknown) => void;

	constructor(elementRef: ElementRef) {
		this.element = elementRef.nativeElement as HTMLElement;
	}

	@HostListener('document:click', ['$event'])
	public clickout(event: Event) {
		if (this.isExpanded && !this.element.contains(<Node>event.target) && !this.isClickInDropdown(event)) {
			this.toggle();
		}
	}

	public ngOnInit(): void {
		if (!this.selectedOption) {
			this.writeValue(this.options[0]);
		}
	}

	public registerOnChange(fn: (_: unknown) => void) {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: () => void) {
		this.onTouchedCallback = fn;
	}

	public writeValue(value: MathOperatorEnum) {
		if (this.options) {
			this.selectedOption = this.options.find(x => x === value);
		}
	}

	public triggerDropdown() {
		this.toggle();
		if (this.onTouchedCallback) {
			this.onTouchedCallback();
		}
	}

	public select(operator: MathOperatorEnum) {
		this.selectedOption = operator;
		this.toggle();
		this.onChangeCallback(this.selectedOption);
	}

	public toggle(): void {
		if (!this.isDisabled) {
			this.isExpanded = !this.isExpanded;
		} else {
			this.isExpanded = false;
		}
	}

	public getOptionTitle(option: MathOperatorEnum) {
		switch (option) {
			case MathOperatorEnum.Addition:
				return 'Addition';
			case MathOperatorEnum.Subtraction:
				return 'Subtraction';
			case MathOperatorEnum.Multiplication:
				return 'Multiplication';
			case MathOperatorEnum.Division:
				return 'Division';
			case MathOperatorEnum.Modulo:
				return 'Modulo';
			default:
				return '';
		}
	}

	private isClickInDropdown(event: Event) {
		const value = (this.element.querySelector('.options') as Element).querySelector('.selected') as HTMLElement;

		return value?.isEqualNode(<Node>event.target);
	}
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { BaGeneralControlGroupModel } from '../../models/ba-general-control-group-model.model';
import { BaGeneralControlProfileModel } from '../../models/ba-general-control-profile-model.model';
import { BaGeneralControlUpdateModel } from '../../models/ba-general-control-update-model.model';
import { BaGeneralControlCreateModel } from '../../models/ba-general-control-create-model.model';

@Injectable()
export class BaGeneralControlDataService {
	constructor(private httpService: RQHttpService) {}

	public getByApplicationId(businessApplicationId: string, options = new HttpOptionsViewModel()): Promise<BaGeneralControlGroupModel[]> {
		options.params = this.httpService.getHttpParams({
			businessApplicationId
		});

		return this.httpService
			.request<BaGeneralControlGroupModel[]>('get', environment.api + '/api/Configuration/BaGeneralControl/GetByApplicationId', options)
			.toPromise();
	}

	public getByApplicationIds(
		businessApplicationIds: string[],
		options = new HttpOptionsViewModel()
	): Promise<BaGeneralControlProfileModel[]> {
		options.body = businessApplicationIds;

		return this.httpService
			.request<BaGeneralControlProfileModel[]>('post', environment.api + '/api/Configuration/BaGeneralControl/GetByApplicationIds', options)
			.toPromise();
	}

	public update(baGeneralControl: BaGeneralControlUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = baGeneralControl;

		return this.httpService.request<void>('put', environment.api + '/api/Configuration/BaGeneralControl/Update', options).toPromise();
	}

	public createRange(assignations: BaGeneralControlCreateModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = assignations;

		return this.httpService.request<void>('put', environment.api + '/api/Configuration/BaGeneralControl/CreateRange', options).toPromise();
	}

	public create(baGeneralControl: BaGeneralControlCreateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = baGeneralControl;

		return this.httpService.request<void>('put', environment.api + '/api/Configuration/BaGeneralControl/Create', options).toPromise();
	}
}

import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ThemeKeys } from './theme-keys.enum';
import { THEMES } from './theme.config';
import { IThemeVariables } from './theme.interface';

@Injectable({
	providedIn: 'root'
})
export class ThemeService {
	private _selectedThemeKey!: ThemeKeys;

	constructor(@Inject(DOCUMENT) private document: Document) {}

	public get selectedThemeKey() {
		return this._selectedThemeKey;
	}

	public setTheme(name: ThemeKeys) {
		const theme = THEMES[name];
		this._selectedThemeKey = name;
		Object.keys(theme).forEach(key => {
			const themeProperty = theme[key as keyof IThemeVariables];
			this.document.documentElement.style.setProperty(`--${key}`, themeProperty);
		});
	}
}

export const INFRASTRUCTURE_ROUTE = {
	path: 'infrastructure',
	url: 'infrastructure',
	pageNotFound: {
		path: '404',
		url: '/infrastructure/404'
	},
	migration: {
		path: 'migration',
		url: '/infrastructure/migration'
	},
	termsAndAgreement: {
		path: 'terms-and-agreement',
		url: '/infrastructure/terms-and-agreement'
	}
};

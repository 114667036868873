import {
	AfterContentInit,
	AfterViewChecked,
	ChangeDetectorRef,
	Component,
	ContentChild,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	TemplateRef
} from '@angular/core';
import { CardTreeConfig, CardTreeNode } from './card-tree.model';
import { ThemeColorsType } from '../../../types/theme-colors.type';

@Component({
	selector: 'rq-card-tree',
	templateUrl: './card-tree.component.html',
	styleUrls: ['./card-tree.component.scss']
})
export class CardTreeComponent implements AfterContentInit, AfterViewChecked, OnChanges {
	@Input()
	public node!: CardTreeNode<unknown, unknown>;

	@Input()
	public config?: CardTreeConfig;

	@Input()
	public isFirstNode = true;

	@Input()
	public isLastChildNode = false;

	@Input()
	public isFirstChildNode = false;

	@Input()
	public isOneNode = false;

	@Input()
	public rowIndex = 0;

	@Input()
	public template!: TemplateRef<unknown>;

	@Input()
	public moreDetailsTemplate!: TemplateRef<unknown>;

	@Output()
	public readonly triggerEdit = new EventEmitter();

	@Output()
	public readonly triggerCardHeightChange = new EventEmitter<{ className: string; value: number }>();

	@ContentChild(TemplateRef)
	public content!: TemplateRef<unknown>;

	@ContentChild('moreDetails')
	public moreDetails!: TemplateRef<unknown>;

	public cardHeights = new Map<string, number>();

	private colorGrayDark = getComputedStyle(document.documentElement).getPropertyValue('--background-neutral-contrast');

	private element!: HTMLElement;

	constructor(elementRef: ElementRef, private cd: ChangeDetectorRef) {
		this.element = elementRef.nativeElement as HTMLElement;
	}

	public get hasChildren() {
		return this.node?.children && this.node.children.length > 0;
	}

	public get isHorizontalBorderVisible() {
		return !this.isFirstNode && !this.isOneNode;
	}

	public get minWidth() {
		const value = 100 / this.node.children.length;

		return `${value}%`;
	}

	public get isLegendVisible() {
		return this.isFirstNode && this.config && this.config.legend;
	}

	public get cardMinWidth() {
		return `${this.node.style.minWidth}px`;
	}

	public get cardMaxWidth() {
		return `${this.node.style.maxWidth}px`;
	}

	public get minHeight() {
		const value = this.cardHeights.get(this.cardClassName);

		return typeof value === 'number' ? `${value}px` : 'auto';
	}

	public get bgColorClass() {
		return this.node.isDisabled ? this.colorGrayDark : this.getThemeBgColorClass(this.node.theme);
	}

	public get childRowIndex() {
		return this.rowIndex + 1;
	}

	public get cardClassName() {
		return `card-${this.childRowIndex}`;
	}

	public get createMinHeight() {
		const card = this.element.querySelector(`.${this.cardClassName}`) as Element;

		return card.getBoundingClientRect().height;
	}

	@HostListener('window:resize')
	public onResize() {
		this.resetCardHeights();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.node) {
			this.resetCardHeights();
		}
	}

	public ngAfterViewChecked() {
		this.triggerCardHeightChange.emit({ className: this.cardClassName, value: this.createMinHeight });
	}

	public ngAfterContentInit(): void {
		if (this.moreDetails) {
			this.moreDetailsTemplate = this.moreDetails;
		}

		if (this.content) {
			this.template = this.content;
		}
	}

	public getBgColor(theme: ThemeColorsType) {
		return this.getThemeBgColorClass(theme);
	}

	public triggerEditEvent(id: unknown) {
		this.triggerEdit.emit(id);
	}

	public setCardHeight(input: { className: string; value: number }) {
		if (this.isFirstNode) {
			this.saveHeight(input);

			this.setHeight();
		} else {
			this.triggerCardHeightChange.emit(input);
		}
	}

	private saveHeight(input: { className: string; value: number }) {
		const currentValue = this.cardHeights.get(input.className);

		const classNameValue = currentValue && currentValue > input.value ? currentValue : input.value;

		this.cardHeights.set(input.className, classNameValue);
	}

	private setHeight() {
		this.cardHeights.forEach((value, key) => {
			const cards = Array.from(this.element.querySelectorAll<HTMLElement>(`.${key}`));

			cards.forEach(card => (card.style.minHeight = `${value}px`));
		});
	}

	private resetCardHeights() {
		if (this.isFirstNode) {
			this.cardHeights = new Map<string, number>();

			this.cardHeights.forEach((_, key) => {
				const cards = Array.from(this.element.querySelectorAll<HTMLElement>(`.${key}`));

				cards.forEach(card => (card.style.minHeight = 'auto'));
			});
		}

		this.cd.detectChanges();

		this.triggerCardHeightChange.emit({ className: this.cardClassName, value: this.createMinHeight });
	}

	private getThemeBgColorClass(theme: ThemeColorsType) {
		const themeConfig = {
			default: { value: '#596367' },
			grey: { value: '#bfc9cd' },
			primary: { value: '#596367' },
			accent: { value: '#ff7a4f' },
			success: { value: '#00bfa5' },
			danger: { value: '#fb440e' },
			blue: { value: '#079efa' },
			disabled: { value: '#e8e8e8' }
		};

		const value = themeConfig[theme];

		if (!value) {
			throw new Error(`${theme} is not defined as an rqc theme`);
		}

		return `${theme}-bg-color`;
	}
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { EnterpriseUserLegalEntityModel } from '../../models/enterprise-user-legal-entity-model.model';
import { Pagination } from '../../models/pagination.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { EnterpriseUserFilterModel } from '../../models/enterprise-user-filter-model.model';
import { UserFilterOptionModel } from '../../models/user-filter-option-model.model';
import { LegalEntityModel } from '../../models/legal-entity-model.model';
import { UserLegalEntityBaseModel } from '../../models/user-legal-entity-base-model.model';
import { LegalEntityFilterModel } from '../../models/legal-entity-filter-model.model';
import { UserAssignModel } from '../../models/user-assign-model.model';

@Injectable()
export class EnterpriseUserLegalEntityDataService {
	constructor(private httpService: RQHttpService) {}

	public getByUser(userId: string, options = new HttpOptionsViewModel()): Promise<EnterpriseUserLegalEntityModel> {
		options.params = this.httpService.getHttpParams({
			userId
		});

		return this.httpService
			.request<EnterpriseUserLegalEntityModel>('get', environment.api + '/api/Enterprise/EnterpriseUserLegalEntity/GetByUser', options)
			.toPromise();
	}

	public getPaginated(
		pagination: PaginationFilter,
		userFilterModel: EnterpriseUserFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<EnterpriseUserLegalEntityModel>> {
		options.params = this.httpService.getHttpParams({
			pagination,
			userFilterModel
		});

		return this.httpService
			.request<Pagination<EnterpriseUserLegalEntityModel>>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseUserLegalEntity/GetPaginated',
				options
			)
			.toPromise();
	}

	public getFilterOptions(options = new HttpOptionsViewModel()): Promise<UserFilterOptionModel> {
		return this.httpService
			.request<UserFilterOptionModel>('get', environment.api + '/api/Enterprise/EnterpriseUserLegalEntity/GetFilterOptions', options)
			.toPromise();
	}

	public getAssignedLegalEntities(
		userId: string,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<LegalEntityModel>> {
		options.params = this.httpService.getHttpParams({
			userId,
			pagination
		});

		return this.httpService
			.request<Pagination<LegalEntityModel>>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseUserLegalEntity/GetAssignedLegalEntities',
				options
			)
			.toPromise();
	}

	public getLegalEntities(
		userId: string,
		pagination: PaginationFilter,
		filter: LegalEntityFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<UserLegalEntityBaseModel>> {
		options.params = this.httpService.getHttpParams({
			userId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<UserLegalEntityBaseModel>>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseUserLegalEntity/GetLegalEntities',
				options
			)
			.toPromise();
	}

	public update(assignation: UserAssignModel, filter: LegalEntityFilterModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			filter
		});

		options.body = assignation;

		return this.httpService.request<void>('put', environment.api + '/api/Enterprise/EnterpriseUserLegalEntity/Update', options).toPromise();
	}
}

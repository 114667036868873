import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { CustomModelType } from '../../../server/enums/custom-model-type.enum';

@Pipe({
	name: 'customModelTypeLabel'
})
export class CustomModelTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value?: CustomModelType) {
		if (value === undefined) {
			return '';
		}

		const values = new Map<CustomModelType, string>();
		values.set(CustomModelType.Actor, 'global_actorValue_label');
		values.set(CustomModelType.Loss, 'global_loss_label');

		const type = values.get(value);
		if (!type) {
			return '';
		}

		return this.translationService.get(type);
	}
}

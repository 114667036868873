import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	ViewChild,
	forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FilterAbstractDirective } from '../filter.abstract';
import { FilterRange, FilterSelectOption, RangeFilter, TriggeredBy } from '../filter.model';
import { Subscription } from 'rxjs';
import { NumberFormatterService } from '../../../../../shared/services/number-formatter.service';
import { FormatterType } from '../../../../../shared/enums/formatter-type.enum';
import { CurrencyConfig, PercentConfig } from '../../../../../shared/models/number.config';
import { MAX_NUMBER } from '../../../../utils/constants.utils';
import { Options } from '@angular-slider/ngx-slider';
import { SliderComponent } from '../../slider/slider.component';
import { Customizer } from '../../../../../shared/services/customizer';
import { SliderModel } from '../../slider/slider.model';

@Component({
	selector: 'rq-filter-range',
	templateUrl: './filter-range.component.html',
	styleUrls: ['./filter-range.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FilterRangeComponent),
			multi: true
		}
	]
})
export class FilterRangeComponent
	extends FilterAbstractDirective<FilterRange<unknown>>
	implements ControlValueAccessor, OnDestroy, OnChanges
{
	@Input()
	public isDisabled!: boolean;

	@Input()
	public filter: RangeFilter = new RangeFilter();

	@ViewChild('slider')
	public sliderRefConfig!: SliderComponent;

	public form!: UntypedFormGroup;

	public sliderModel!: SliderModel;

	public sliderOptions!: Options;

	public isSlideVisible = false;

	public placeholder!: string;

	public formatterSymbol = '';

	public subscriptions = new Array<Subscription>();

	public formatter!: (value: number) => string;

	private onChangeCallback!: (_: unknown) => void;

	private onTouchedCallback!: () => void;

	private element: HTMLElement;

	constructor(
		private cd: ChangeDetectorRef,
		private customizer: Customizer,
		private numberFormatterService: NumberFormatterService,
		private formBuilder: UntypedFormBuilder,
		elementRef: ElementRef
	) {
		super();
		this.element = elementRef.nativeElement as HTMLElement;
	}

	private get selected(): Array<FilterSelectOption<unknown>> {
		let selectedOptions: FilterSelectOption<unknown>[] = [];
		this.filter.groups.forEach(x => (selectedOptions = selectedOptions.concat(x.options.filter(y => y.isSelected === true))));
		return selectedOptions;
	}

	private get doesChangeExist(): boolean {
		return this.sliderOptions.floor !== this.sliderModel.min || this.sliderOptions.ceil !== this.sliderModel.max;
	}

	private get doesChangeExistFromPreviousCheck(): boolean {
		return (
			this.form.dirty &&
			((this.inputMinValue !== null && this.inputMinValue !== this.value?.range?.min) ||
				(this.inputMaxValue !== null && this.inputMaxValue !== this.value?.range?.max))
		);
	}

	private get inputMinValue(): number {
		return this.form.get('min')?.value as number;
	}

	private get inputMaxValue(): number {
		return this.form.get('max')?.value as number;
	}

	@HostListener('document:click', ['$event'])
	public clickOutside(event: Event) {
		if (this.isSlideVisible && !this.element.contains(<Node>event.target)) {
			this.onTouchedCallback();
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.filter?.currentValue) {
			this.validateMinMaxValue();

			this.setupSlider(this.filter.min, this.filter.max);

			this.setupForm();

			this.setDropdownPlaceholder();
		}

		if (changes.isDisabled?.currentValue !== undefined && this.sliderOptions) {
			this.updateDisabledState(changes.isDisabled?.currentValue as boolean);
		}
	}

	public ngOnDestroy(): void {
		this.subscriptions.forEach(x => x.unsubscribe());
	}

	public dropDownToggle($event: boolean): void {
		this.isSlideVisible = $event;
	}

	public onSliderChange(): void {
		this.form.get('min')?.setValue(this.sliderModel.min);
		this.form.get('max')?.setValue(this.sliderModel.max);
		this.triggerFilterChange(TriggeredBy.Slider);
	}

	public triggerSelectChange(): void {
		this.triggerFilterChange(TriggeredBy.Checkbox);
	}

	public blur(): void {
		this.cd.detectChanges();
		this.triggerInputChange();
	}

	public triggerInputChange(): void {
		if (!this.form.valid || !this.doesChangeExistFromPreviousCheck) {
			return;
		}

		this.sliderModel.min = this.inputMinValue;
		this.sliderModel.max = this.inputMaxValue;

		this.sliderRefConfig.onChange(this.sliderModel);
	}

	public triggerFilterChange(trigger: TriggeredBy): void {
		Promise.resolve().then(() => {
			const values = this.selected.map(x => x.value);
			const range = {
				min: this.sliderModel?.min,
				max: this.sliderModel?.max
			};

			this.value = <FilterRange<unknown>>{
				values,
				range: this.doesChangeExist || values.length > 0 ? range : null,
				trigger: this.doesChangeExist || values.length > 0 ? trigger : null
			};

			this.onChangeCallback(this.value);
			this.change.next();
		});
	}

	public writeValue(_values: FilterRange<unknown>): void {
		if (_values?.range) {
			this.sliderModel = _values.range;
		}
	}

	public registerOnTouched(fn: () => void): void {
		this.onTouchedCallback = fn;
	}

	public registerOnChange(fn: (value: unknown) => void): void {
		this.onChangeCallback = fn;
	}

	private validateMinMaxValue(): void {
		if (this.filter.min === null || this.filter.min === undefined) {
			this.filter.min = 0;
		}
		if (this.filter.max === null || this.filter.max === undefined) {
			this.filter.max = 1;
		}

		if (this.filter.min > this.filter.max) {
			throw new Error('Minimum value cannot be higher then maximum value');
		}

		if (this.filter.isRangeIntervalRounded) {
			this.filter.min = this.roundValue(this.filter.min, true);
			this.filter.max = this.roundValue(this.filter.max, false);
		}
	}

	private setupSlider(minValue: number, maxValue: number): void {
		const stepSize = this.getStepSize(minValue, maxValue);

		if (minValue === maxValue) {
			maxValue = minValue + stepSize;
			if (this.filter.isRangeIntervalRounded) {
				maxValue = this.roundValue(maxValue, false);
			}
			this.filter.max = maxValue;
		}

		this.sliderOptions = <Options>{
			floor: minValue,
			ceil: maxValue,
			step: stepSize,
			disabled: this.isDisabled ?? false,
			precisionLimit: 100
		};

		this.sliderModel = <SliderModel>{ min: minValue, max: maxValue };

		this.setupSliderFormatter();
	}

	private setupSliderFormatter(): void {
		switch (this.filter.formatter.type) {
			case FormatterType.Currency:
				this.formatter = (value: number) => this.numberFormatterService.currency(value, this.filter.formatter.config as CurrencyConfig);
				this.formatterSymbol = this.customizer.currency;
				break;
			case FormatterType.Percent:
				this.formatter = (value: number) => this.numberFormatterService.percent(value, this.filter.formatter.config as PercentConfig);
				this.formatterSymbol = new PercentConfig().symbol;
				break;
			default:
				this.formatter = (value: number) => this.numberFormatterService.number(value, this.filter.formatter.config);
				break;
		}
	}

	private setupForm() {
		const maxValue = this.filter.max > MAX_NUMBER ? MAX_NUMBER : this.filter.max;

		const validators = [Validators.required, Validators.min(this.filter.min), Validators.max(maxValue)];

		this.form = this.formBuilder.group({
			min: [this.sliderModel.min, validators],
			max: [this.sliderModel.max, validators]
		});
	}

	private setDropdownPlaceholder(): void {
		const defaultPlaceholder = this.filter.placeholder && this.customizer.translate(this.filter.placeholder);
		this.placeholder = defaultPlaceholder;

		this.subscriptions.push(
			this.change.subscribe(() => {
				if (this.doesChangeExist) {
					this.placeholder = `${this.formatter(this.sliderModel.min)} - ${this.formatter(this.sliderModel.max)}`;
				} else {
					this.placeholder = defaultPlaceholder;
				}
			})
		);
	}

	private updateDisabledState(isDisabled: boolean): void {
		this.sliderOptions.disabled = isDisabled;
		if (isDisabled) {
			this.form.get('min')?.disable();
			this.form.get('max')?.disable();
		} else {
			this.form.get('min')?.enable();
			this.form.get('max')?.enable();
		}
	}

	private getStepSize(cleanMinValue: number, cleanMaxValue: number): number {
		let stepSizeMinValue = 1;
		let stepSizeMaxValue = 1;

		if (!Number.isInteger(cleanMinValue)) {
			const digits = cleanMinValue.toString().split('.')[1];
			stepSizeMinValue = 1 / Math.pow(10, digits.length);
		}

		if (!Number.isInteger(cleanMaxValue)) {
			const digits = cleanMaxValue.toString().split('.')[1];
			stepSizeMaxValue = 1 / Math.pow(10, digits.length);
		}

		return stepSizeMinValue < stepSizeMaxValue ? stepSizeMinValue : stepSizeMaxValue;
	}

	private roundValue(value: number, toMin: boolean): number {
		const positionToRound = 2;
		if (toMin) {
			const minLength = Math.abs(Math.floor(value)).toString().length;
			const noOfZeros = Math.pow(10, minLength - positionToRound);
			return Math.floor(Math.floor(value / noOfZeros) * noOfZeros);
		} else {
			const maxLength = Math.abs(Math.ceil(value)).toString().length;
			const noOfZeros = Math.pow(10, maxLength - positionToRound);
			return Math.ceil(Math.ceil(value / noOfZeros) * noOfZeros);
		}
	}
}

<div class="navigation-item__separation"
     [class.collapsed]="!isSidebarExpanded"
     *ngIf="item.separationName">{{ item.separationName }}</div>

<section class="navigation-item pointer"
         [class.disabled]="isDisabled"
         [class.collapsed]="!isSidebarExpanded"
         [routerLink]="item.route"
         routerLinkActive="active"
         [routerLinkActiveOptions]="myMatchOptions"
         (isActiveChange)="updateState($event)"
         [title]="item.name">
  <rq-icon [color]="isDisabled ? 'color-disabled' : item.isSelected ? 'color-accent' : 'color-secondary'"
           href="{{ item.icon }}"></rq-icon>

  <article class="navigation-item__content"
           *ngIf="isSidebarExpanded">
    <span class="navigation-item__route">{{ item.name }}</span>

    <article *ngIf="item.tags && isSidebarExpanded">
      <span class="badge-royal-sm-b">{{ item.tags }}</span>
    </article>

    <article *ngIf="version && isSidebarExpanded"
             class="navigation-item__footer">
      <span class="navigation-item__footer_version">v{{ version }}</span>
      <span *ngIf="isBeta"
            class="badge-secondary-sm-b">{{ 'global_beta_label' | translate }}</span>
    </article>
  </article>


</section>

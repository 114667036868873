import { Component, ViewChild, EventEmitter, Output } from '@angular/core';
import { UsersViewModel } from '../../models/users.viewmodel';
import { ModalComponent } from '../../../standalone/components/modal/modal.component';

@Component({
	selector: 'rq-unlock-user-modal',
	templateUrl: './unlock-user-modal.component.html',
	styleUrls: ['./unlock-user-modal.component.scss']
})
export class UnlockUserModalComponent {
	@ViewChild(ModalComponent, { static: true })
	public modal!: ModalComponent;

	@Output()
	public readonly unlockAccountAction = new EventEmitter<UsersViewModel>();

	public model?: UsersViewModel;

	public show(model: UsersViewModel) {
		this.model = model;
		this.modal.show();
	}

	public closeModal() {
		this.modal.hide();
		if (this.model) {
			this.unlockAccountAction.emit(this.model);
		}
		this.model = undefined;
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { ContractProfileType } from 'src/app/server/enums/contract-profile-type.enum';
import { NumberFormatterService } from '../../services/number-formatter.service';

@Pipe({
	name: 'contractCostLabel'
})
export class ContractCostTranslate implements PipeTransform {
	constructor(private translationService: TranslationService, private numberService: NumberFormatterService) {}

	public transform(data: { type: ContractProfileType; percentageContractRisk: number }) {
		switch (data.type) {
			case ContractProfileType.PercentageOfContractAtRisk: {
				const value = this.numberService.percent(data.percentageContractRisk);
				return this.translationService
					.get('contract_profile_table_PercentageOfContractAtRiskType')
					.replace('{percentage}', value.toString());
			}
			case ContractProfileType.SLAViolationCosts:
				return this.translationService.get('global_fixedLossAmount_label');
			default:
				throw new Error(`${data.type} does not exist`);
		}
	}
}

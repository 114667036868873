import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RQ_ROUTES } from '../configs/routes.config';
import { ApplicationDataService } from '../../server/services/infrastructure/application-data.service';
import { SettingsDataService } from '../../server/services/infrastructure/settings-data.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ApplicationInstanceStore {
	public readonly currentVersion$: Observable<string>;

	public readonly isBeta$: Observable<boolean>;

	private _isBeta$ = new BehaviorSubject<boolean>(false);

	private _currentVersion$ = new BehaviorSubject<string>('');

	constructor(
		private router: Router,
		private applicationDataService: ApplicationDataService,
		private settingsDataService: SettingsDataService
	) {
		this.currentVersion$ = this._currentVersion$.asObservable();
		this.isBeta$ = this._isBeta$.asObservable();
	}

	public get isEnterprise() {
		return this.router.url.includes(`/${RQ_ROUTES.enterprise.path}/`);
	}

	public get isPro() {
		return this.router.url.includes(`/${RQ_ROUTES.pro.mainPath}/`);
	}

	public get isFidelityOne() {
		return this.router.url.includes(`/${RQ_ROUTES.fidelityOne.mainPath}/`);
	}

	public async init() {
		await Promise.all([this.getAppVersion(), this.checkIsBetaVersion()]);
	}

	private async getAppVersion() {
		const data = await this.applicationDataService.get();
		this._currentVersion$.next(data.currentVersion);
	}

	private async checkIsBetaVersion() {
		const data = await this.settingsDataService.isBeta();
		this._isBeta$.next(data);
	}
}

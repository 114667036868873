import { PipeTransform, Pipe } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { TuningApplicationType } from 'src/app/routes/control-analysis/_common/models/reputational-damage-parameters-table.model';

@Pipe({
	name: 'tuningApplicationTypeLabel'
})
export class TuningApplicationTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: TuningApplicationType) {
		const values = new Map<TuningApplicationType, string>();
		values.set(TuningApplicationType.SaaS, 'applicationType_label_saasUnspecified');
		values.set(TuningApplicationType.OnPrem, 'applicationType_label_selfManaged');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${value} does not exist`);
		}

		return this.translationService.get(type);
	}
}

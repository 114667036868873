import { Component, ElementRef, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'rq-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FileUploadComponent),
			multi: true
		}
	]
})
export class FileUploadComponent implements ControlValueAccessor {
	@ViewChild('inputFile')
	public inputFile!: ElementRef;

	public filePath!: string;

	public file!: File;

	public progress!: number;

	private onTouchedCallback!: (_?: unknown) => void;

	private onChangeCallback!: (_?: unknown) => void;

	public writeValue(file: File): void {
		this.file = file;
	}

	public registerOnChange(fn: (_?: unknown) => void) {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: (_?: unknown) => void) {
		this.onTouchedCallback = fn;
	}

	public triggerUpload() {
		const element: HTMLInputElement = this.inputFile.nativeElement as HTMLInputElement;

		element.click();
	}

	public change() {
		const element: HTMLInputElement = this.inputFile.nativeElement as HTMLInputElement;
		const file = element.files ? element.files[0] : null;

		this.file = file as File;

		this.onTouchedCallback();
		this.onChangeCallback(file);
	}

	public setProgress(value: number) {
		this.progress = value;
	}

	public getProgress() {
		return this.progress ? `${this.progress}%` : '0%';
	}

	public update(event: { loaded: number; total: number }) {
		this.progress = (event.loaded / event.total) * 100;
	}
}

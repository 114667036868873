import { Component, Input } from '@angular/core';

@Component({
	standalone: true,
	selector: 'rq-loading-spinner',
	templateUrl: './loading-spinner.component.html',
	styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent {
	@Input()
	public size = '50px';
}

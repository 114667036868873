<ng-container *ngIf="hasData">
  <div class="level">
    <strong>{{'global_recommendation_proposedValueText' | translate: {actualValue: oldValue} }}</strong>
    <rq-icon class='p-l-xs p-r-xs'
             href='icon-arrow-chevron-right'></rq-icon>
    <strong>{{'global_recommendation_proposedValueText' | translate: {actualValue: proposedValue} }}</strong>
  </div>
  <div *ngIf="hasEvidences"><a class="evidences-details"
       (click)="showEvidences()">{{'evidences_details_label' |
      translate }}</a></div>
</ng-container>

<rq-evidences-details-modal [multipleData]="multipleData"
                            [controlOptionName]="controlOptionName"
                            [controlLevelDescriptions]="controlLevelDescriptions"></rq-evidences-details-modal>

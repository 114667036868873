import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class WizardConfigService {
	public activeStepIndex!: number;

	public isLastStep!: boolean;

	public disabledSteps: Array<number> = [];

	public changeStep$ = new Subject<number | undefined>();

	public toggleSubmitLoading$ = new BehaviorSubject<boolean>(false);

	public setActiveStepIndex(stepIndex: number) {
		this.activeStepIndex = stepIndex;
	}

	public setIsLastStep(isLastStep: boolean) {
		this.isLastStep = isLastStep;
	}
}

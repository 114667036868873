import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { EnterpriseLegalEntityViewModel } from '../../models/enterprise-legal-entity-view-model.model';
import { EnterpriseLegalEntityUpdateModel } from '../../models/enterprise-legal-entity-update-model.model';
import { Pagination } from '../../models/pagination.model';
import { LegalEntityDefinitionModel } from '../../models/legal-entity-definition-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { LegalEntityFilterModel } from '../../models/legal-entity-filter-model.model';
import { EnterpriseLegalEntityCreateModel } from '../../models/enterprise-legal-entity-create-model.model';
import { EnterpriseLegalEntityDeleteModel } from '../../models/enterprise-legal-entity-delete-model.model';

@Injectable()
export class EnterpriseLegalEntityDataService {
	constructor(private httpService: RQHttpService) {}

	public getById(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<EnterpriseLegalEntityViewModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<EnterpriseLegalEntityViewModel>('get', environment.api + '/api/Enterprise/EnterpriseLegalEntity/GetById', options)
			.toPromise();
	}

	public update(updatedLegalEntity: EnterpriseLegalEntityUpdateModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = updatedLegalEntity;

		return this.httpService.request<string>('put', environment.api + '/api/Enterprise/EnterpriseLegalEntity/Update', options).toPromise();
	}

	public getPaginated(
		pagination: PaginationFilter,
		filter: LegalEntityFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<LegalEntityDefinitionModel>> {
		options.params = this.httpService.getHttpParams({
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<LegalEntityDefinitionModel>>(
				'get',
				environment.api + '/api/Enterprise/EnterpriseLegalEntity/GetPaginated',
				options
			)
			.toPromise();
	}

	public create(legalEntity: EnterpriseLegalEntityCreateModel, options = new HttpOptionsViewModel()): Promise<string> {
		options.body = legalEntity;

		return this.httpService.request<string>('post', environment.api + '/api/Enterprise/EnterpriseLegalEntity/Create', options).toPromise();
	}

	public delete(deleteModel: EnterpriseLegalEntityDeleteModel, options = new HttpOptionsViewModel()): Promise<boolean> {
		options.body = deleteModel;

		return this.httpService.request<boolean>('post', environment.api + '/api/Enterprise/EnterpriseLegalEntity/Delete', options).toPromise();
	}
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { EndpointProfileModel } from '../../models/endpoint-profile-model.model';
import { EndpointProfileUpdateModel } from '../../models/endpoint-profile-update-model.model';

@Injectable()
export class EndpointProfileDataService {
	constructor(private httpService: RQHttpService) {}

	public getByLegalEntity(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<EndpointProfileModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<EndpointProfileModel>('get', environment.api + '/api/Configuration/EndpointProfile/GetByLegalEntity', options)
			.toPromise();
	}

	public isValidName(
		legalEntityId: string,
		endpointProfileId: string,
		profileName: string,
		options = new HttpOptionsViewModel()
	): Promise<boolean> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			endpointProfileId,
			profileName
		});

		return this.httpService
			.request<boolean>('get', environment.api + '/api/Configuration/EndpointProfile/IsValidName', options)
			.toPromise();
	}

	public update(profile: EndpointProfileUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = profile;

		return this.httpService.request<void>('post', environment.api + '/api/Configuration/EndpointProfile/Update', options).toPromise();
	}

	public getById(endpointProfileId: string, options = new HttpOptionsViewModel()): Promise<EndpointProfileModel> {
		options.params = this.httpService.getHttpParams({
			endpointProfileId
		});

		return this.httpService
			.request<EndpointProfileModel>('get', environment.api + '/api/Configuration/EndpointProfile/GetById', options)
			.toPromise();
	}

	public delete(endpointProfileId: string, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			endpointProfileId
		});

		return this.httpService.request<void>('delete', environment.api + '/api/Configuration/EndpointProfile/Delete', options).toPromise();
	}
}

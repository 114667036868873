export class WizardSteps<T> {
	public id!: T;

	public title?: string;

	public path!: string;
}

export class WizardSaveStep {
	public selectedStepIndex?: number;

	public isSubmit!: boolean;

	constructor(selectedStepIndex?: number, isSubmit = false) {
		this.selectedStepIndex = selectedStepIndex;
		this.isSubmit = isSubmit;
	}
}

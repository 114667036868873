import { ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { TableActionDirective } from '../table-action.directive';

@Component({
	selector: 'rq-table-actions',
	templateUrl: './table-actions.component.html',
	styleUrls: ['./table-actions.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableActionsComponent {
	@Input()
	public numberOfVisibleActions = 1;

	@ContentChildren(TableActionDirective)
	public actions!: QueryList<TableActionDirective>;
}

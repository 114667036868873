import { Filter } from './filter.model';
import { FilterGroupModel } from './filter-group-model.model';

export class AnalysisApplicationRiskFilterModel {
	public 'applicationName'!: Filter<string>;
	public 'controlFrameworkId'!: Filter<string[]>;
	public 'averageControlRating'!: Filter<FilterGroupModel<string, number>>;
	public 'inherentRisk'!: Filter<FilterGroupModel<string, number>>;
	public 'residualRisk'!: Filter<FilterGroupModel<string, number>>;
}

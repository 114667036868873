<label class="filter-label"><strong>{{displayName}}</strong></label>
<rq-filter-dropdown [isDisabled]="isDisabled"
                    [placeholder]="placeholder"
                    (toggle)="dropDownToggle($event)">
  <section class="filter-content">
    <div class="filter-group filter-selected"
         *ngIf="filter.groups?.length">
      <ng-container *ngFor="let group of filter.groups">
        <h4 class="filter-group-title"
            *ngIf="group.title">{{group.title | translate}}</h4>
        <ng-container *ngFor="let option of group.options">
          <div class="filter-item">
            <label>
              <input type="checkbox"
                     [disabled]="isDisabled"
                     (ngModelChange)="triggerSelectChange()"
                     [(ngModel)]="option.isSelected" />
              <span [rqEllipsis]='option.displayName'
                    [charactersCountLimit]='25'></span>
            </label>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <section class="filter-range-content"
             *ngIf="isSlideVisible">
      <rq-slider name="slider-filter"
                 #slider
                 class="filter-range"
                 [options]="sliderOptions"
                 [(ngModel)]="sliderModel"
                 (ngModelChange)="onSliderChange()"
                 [formatter]="formatter">
      </rq-slider>

      <form [formGroup]="form">
        <div class="form-group m-b-none">
          <rq-form-control>
            <label>{{'global_minimum_label' | translate}}</label>
            <rq-input type="number"
                      size='xs'
                      [areNegativeValuesAllowed]="true"
                      [prefix]='formatterSymbol'
                      formControlName="min"
                      (blur)='blur()'
                      (keyup.enter)='triggerInputChange()'>
            </rq-input>
          </rq-form-control>
        </div>
        <div class="form-group m-b-none">
          <rq-form-control>
            <label>{{'global_maximum_label' | translate}}</label>
            <rq-input type="number"
                      size='xs'
                      [areNegativeValuesAllowed]="true"
                      [prefix]='formatterSymbol'
                      formControlName="max"
                      (blur)='blur()'
                      (keyup.enter)='triggerInputChange()'>
            </rq-input>
          </rq-form-control>
        </div>
      </form>
    </section>
  </section>
</rq-filter-dropdown>

import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { UsersViewModel } from '../../models/users.viewmodel';
import { UserActiveModel } from '../../../server/models/user-active-model.model';
import { DialogComponent } from '../../../standalone/components/dialog/dialog.component';
import { DialogOptions } from '../../../standalone/components/dialog/dialog-options.ui-model';
import { DialogResult } from '../../../standalone/components/dialog/dialog-result.enum';

@Component({
	selector: 'rq-deactivate-user-dialog',
	templateUrl: './deactivate-user-dialog.component.html',
	styleUrls: ['./deactivate-user-dialog.component.scss']
})
export class DeactivateUserDialogComponent {
	@ViewChild(DialogComponent, { static: true })
	public dialog!: DialogComponent;

	@Output()
	public readonly changeStatusAction = new EventEmitter<UserActiveModel>();

	public model?: UsersViewModel;

	public dialogOptions!: DialogOptions;

	public async show(model: UsersViewModel) {
		this.model = model;
		this.setupDeactivateDialog(model.isActive);
		const dialogResult = await this.dialog.show();
		if (dialogResult === DialogResult.Accept) {
			const userStatusModel = new UserActiveModel();
			userStatusModel.userId = model.id;
			userStatusModel.activeStatus = !model.isActive;
			this.changeStatusAction.emit(userStatusModel);
		}
	}

	public close() {
		return this.dialog.decline();
	}

	private setupDeactivateDialog(isActive: boolean) {
		this.dialogOptions = new DialogOptions();

		this.dialogOptions.acceptLabel = isActive ? 'global_deactivatedAccount_label' : 'global_reactivateAccount_label';
		this.dialogOptions.declineLabel = 'global_label_cancel';
	}
}

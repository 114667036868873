import { TranslationService } from 'src/app/shared/services/translation.service';
import { PipeTransform, Pipe } from '@angular/core';
import { ApplicationType } from '../../../server/enums/application-type.enum';

@Pipe({
	name: 'applicationTypeLabel'
})
export class ApplicationTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value?: ApplicationType) {
		switch (value) {
			case ApplicationType.Unspecified:
				return this.translationService.get('applicationType_label_unspecified');
			case ApplicationType.SaaS:
				return this.translationService.get('applicationType_label_saaS');
			case ApplicationType.SelfManagedOnPremises:
				return this.translationService.get('applicationType_label_selfHosted');
			case ApplicationType.SelfManagedCloud:
				return this.translationService.get('applicationType_label_selfManagedCloud');
			default:
				return '';
		}
	}
}

import { EnterElement } from 'd3';
import { ChartRect } from './models/chart.model';

type BaseType = Element | EnterElement | Document | Window | null;

export function createSVGNode<Element extends BaseType, Data, PElement extends BaseType, PData>(
	container: d3.Selection<PElement, Data, d3.BaseType, PData>,
	tag: string,
	attr?: { [key: string]: string | number }
): d3.Selection<Element, Data, PElement, PData> {
	const selection = container.append<Element>(tag) as d3.Selection<Element, Data, PElement, PData>;

	if (attr) {
		Object.keys(attr).forEach(key => {
			selection.attr(key, attr[key]);
		});
	}

	return selection;
}

export function createRect(value: ChartRect | {}): ChartRect {
	const rect = new ChartRect();

	updateRect(rect, value);

	return rect;
}

export function updateRect(
	rect: ChartRect,
	value: { [key: string]: number | undefined; left?: number; top?: number; width?: number; height?: number }
) {
	for (const key in value) {
		if (rect[key] !== undefined) {
			rect[key] = value[key] as number;
		}
	}
}

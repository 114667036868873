import { TranslationService } from 'src/app/shared/services/translation.service';
import { PipeTransform, Pipe } from '@angular/core';
import { ScenarioAnalysisStatusType } from '../../../server/enums/scenario-analysis-status-type.enum';

@Pipe({
	name: 'scenarioAnalysisStatusLabel'
})
export class ScenarioAnalysisStatusTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value?: ScenarioAnalysisStatusType) {
		switch (value) {
			case ScenarioAnalysisStatusType.Successful:
				return this.translationService.get('global_successful_label');
			case ScenarioAnalysisStatusType.Running:
				return this.translationService.get('global_running_label');
			case ScenarioAnalysisStatusType.Failed:
				return this.translationService.get('global_unsuccessful_label');
			case ScenarioAnalysisStatusType.None:
				return this.translationService.get('global_none_label');
			default:
				throw new Error(`${value} does not exist`);
		}
	}
}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { RecommendationTopByProbabilityReductionViewModel } from '../../../routes/control-analysis/_common/models/top-controls-reduction-by-percentage.model';
import { NumberFormatterService } from '../../services/number-formatter.service';
import { BubbleChart } from '../../../standalone/components/charts/bubble-chart/models/bubble-chart.model';
import { Customizer } from '../../services/customizer';

@Component({
	selector: 'rq-top-controls-reduction-by-percentage',
	templateUrl: './top-controls-reduction-by-percentage.component.html',
	styleUrls: ['./top-controls-reduction-by-percentage.component.scss']
})
export class TopControlsReductionByPercentageComponent implements OnChanges {
	@Input()
	public data?: Array<RecommendationTopByProbabilityReductionViewModel>;

	public series: BubbleChart[] = [];

	constructor(
		public translationService: TranslationService,
		public customizer: Customizer,
		public numberFormatterService: NumberFormatterService
	) {}

	public get hasNoControls() {
		return this.series && this.series.length === 0;
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes?.data && changes.data.currentValue) {
			this.setupChartSeries(changes.data.currentValue as Array<RecommendationTopByProbabilityReductionViewModel>);
		}
	}

	private setupChartSeries(values: Array<RecommendationTopByProbabilityReductionViewModel>) {
		this.series = values.map(val => ({
			label: this.getLabel(val),
			value: val.expectedAleReduction * -1,
			bubbleValue: val.lossProbabilityReduction * -1
		}));
	}

	private getLabel(value: RecommendationTopByProbabilityReductionViewModel) {
		const title = this.translationService.get(value.titleKey);
		const control = this.translationService.get(value.controlKey);

		const levelLabel = this.translationService.get('global_level_label');
		const level = value.proposedValue ? ` (${levelLabel} ${value.oldValue} -> ${levelLabel} ${value.proposedValue})` : '';

		const label = `${title} - \n${control}${level}`;

		return value.legalEntityName ? `${value.legalEntityName} - \n${label}` : `${label}`;
	}
}

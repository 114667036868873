import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import { TopThreatsViewModel } from '../../../routes/control-analysis/_common/models/top-threats.viewmodel';
import { TableOutput } from '../../../standalone/components/table/models/table-output.model';
import { BarChart } from '../../../standalone/components/charts/bar-chart/models/bar-chart.model';

@Component({
	selector: 'rq-dashboard-attack-content',
	templateUrl: './dashboard-attack-content.component.html',
	styleUrls: ['./dashboard-attack-content.component.scss']
})
export class DashboardAttackContentComponent implements OnChanges {
	@Input()
	public data!: Array<TopThreatsViewModel>;

	@Input()
	public count!: number;

	@Input()
	public hideMoreLink = false;

	@Input()
	public isLegalEntityVisible = false;

	@Output()
	public readonly pageChange = new EventEmitter<TableOutput<null>>();

	@Output()
	public readonly moreLinkClicked = new EventEmitter();

	public barChartData!: BarChart[];

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.data.currentValue) {
			this.setBarChartData(changes.data.currentValue as Array<TopThreatsViewModel>);
		}
	}

	public triggerLoadData(output: TableOutput<null>) {
		this.pageChange.emit(output);
	}

	public navigateTo() {
		this.moreLinkClicked.emit();
	}

	private setBarChartData(data: Array<TopThreatsViewModel>) {
		this.barChartData = data.map(
			x =>
				<BarChart>{
					name: this.isLegalEntityVisible ? `${x.legalEntityName}\n ${x.displayName}` : x.displayName,
					value: x.annualizedLossAmount
				}
		);
	}
}

import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CalendarDayModel } from './calendar.models';
import { DateFormatterService } from '../../../../shared/services/date-formatter.service';

@Component({
	selector: 'rq-calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CalendarComponent),
			multi: true
		}
	]
})
export class CalendarComponent implements ControlValueAccessor, OnChanges {
	@Input()
	public placeholder!: string;

	@Input()
	public start: Date = this.dateService.startOf(new Date(), 'day');

	@Input()
	public end?: Date = undefined;

	@Input()
	public isInterval = false;

	@Input()
	public hoveredDate?: CalendarDayModel = undefined;

	@Input()
	public min!: Date;

	@Input()
	public max!: Date;

	@Output()
	public readonly startChange = new EventEmitter();

	@Output()
	public readonly endChange = new EventEmitter();

	public state!: Date;

	public rows!: (CalendarDayModel[] | undefined)[];

	private onChangeCallback!: (_: unknown) => void;

	private onTouchedCallback!: (_: unknown) => void;

	constructor(private dateService: DateFormatterService) {}

	public get weekDays() {
		return this.dateService.getWeekDays(this.state);
	}

	public get activeMonth() {
		return this.dateService.format(this.state, 'MMMM');
	}

	public get activeYear() {
		return this.state.getFullYear();
	}

	@HostListener('click')
	public setTouchedState() {
		this.onTouchedCallback(this.start.toISOString());
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.start?.currentValue as Date) {
			this.start = this.dateService.startOf(changes.start?.currentValue as Date, 'day');
		}
	}

	public registerOnChange(fn: (value: unknown) => void) {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: (value: unknown) => void) {
		this.onTouchedCallback = fn;
	}

	public writeValue(value: string) {
		let date;
		if (value) {
			date = this.dateService.startOf(value, 'day');
		} else {
			date = this.dateService.startOf(new Date(), 'day');
		}

		this.state = this.dateService.cloneDate(date);

		if (!this.isInterval && value) {
			this.start = date;
		}

		this.setRows();
	}

	public goPrevMonth() {
		this.state = this.dateService.subtract(this.state, 1, 'month');
		this.setRows();
	}

	public goNextMonth() {
		this.state = this.dateService.add(this.state, 1, 'month');
		this.setRows();
	}

	public onDateSelection(cDate: CalendarDayModel) {
		if (!cDate.disabled) {
			const date = this.dateService.startOf(
				this.dateService.setWithObject(new Date(), { year: cDate.year, month: cDate.month, date: cDate.day }),
				'day'
			);

			if (this.isInterval) {
				this.onRangeSelect(date);
			} else {
				this.onSingleSelect(date);
			}
		}
	}

	public setHoveredDate(value: CalendarDayModel | undefined) {
		this.hoveredDate = value;
	}

	public isHovered(cDate: CalendarDayModel) {
		const date = this.cdmToCalendarDate(cDate).valueOf();

		return this.hoveredDate && this.cdmToCalendarDate(this.hoveredDate).valueOf() === date;
	}

	public isInside(cDate: CalendarDayModel) {
		if (this.isInterval) {
			const date = this.cdmToCalendarDate(cDate);

			const isBetween = this.dateService.isAfter(date, this.start, 'day') && this.end && this.dateService.isBefore(date, this.end, 'day');

			return (
				isBetween ||
				(this.start &&
					!this.end &&
					this.hoveredDate &&
					this.dateService.isAfter(date, this.start, 'day') &&
					this.dateService.isBefore(date, this.cdmToCalendarDate(this.hoveredDate), 'day'))
			);
		}

		return false;
	}

	public isRange(cDate: CalendarDayModel) {
		const date = this.cdmToCalendarDate(cDate);
		return this.dateService.isSame(this.start, date, 'day') || (this.end && this.dateService.isSame(this.end, date, 'day'));
	}

	private onRangeSelect(date: Date) {
		if (!this.start && !this.end) {
			this.start = this.dateService.startOf(date, 'day');
			this.startChange.emit(this.start);
		} else if (this.start && !this.end && date > this.start) {
			this.end = this.dateService.endOf(date, 'day');
			this.endChange.emit(this.end);
		} else {
			this.end = undefined;
			this.start = this.dateService.startOf(date, 'day');
			this.endChange.emit(this.end);
			this.startChange.emit(this.start);
		}
	}

	private onSingleSelect(date: Date) {
		this.start = this.dateService.startOf(date, 'day');
		this.startChange.emit(this.start);
		const utc = this.dateService.setUtcIsoString(
			{ year: date.getFullYear(), month: date.getMonth(), date: date.getDate() },
			{ isStartOfDay: true }
		);
		this.onChangeCallback(utc);
	}

	private getDaysBeforeSelectedMonth() {
		const startOfMonthDay = this.dateService.format(this.dateService.startOf(this.state, 'month'), 'dd');
		const daysToAdd: CalendarDayModel[] = [];
		const daysToAddFromPrevMonth = this.weekDays.indexOf(startOfMonthDay);

		for (let i = daysToAddFromPrevMonth; i > 0; i--) {
			const date = this.dateService.subtract(this.dateService.startOf(this.state, 'month'), i, 'd');

			const day = new CalendarDayModel(Number(this.dateService.format(date, 'D')), date.getMonth(), date.getFullYear());
			day.disabled = this.isDayDisabled(day);
			day.isAnotherMonth = this.isAnotherMonth(day);
			daysToAdd.push(day);
		}

		return daysToAdd;
	}

	private getDaysAfterSelectedMonth() {
		const endOfMonthDay = this.dateService.format(this.dateService.endOf(this.state, 'month'), 'dd');
		const daysToAddFromNextMonth = this.weekDays.length - this.weekDays.indexOf(endOfMonthDay) - 1;
		const daysToAdd: CalendarDayModel[] = [];

		for (let i = 1; i <= daysToAddFromNextMonth; i++) {
			const date = this.dateService.add(this.dateService.endOf(this.state, 'month'), i, 'd');

			const day = new CalendarDayModel(Number(this.dateService.format(date, 'D')), date.getMonth(), date.getFullYear());
			day.disabled = this.isDayDisabled(day);
			day.isAnotherMonth = this.isAnotherMonth(day);
			daysToAdd.push(day);
		}

		return daysToAdd;
	}

	private getCalendarDays() {
		const daysInMonth = this.dateService.getDaysInMonth(this.state);
		let startOfMonth = this.dateService.startOf(this.state, 'month');
		const calendarDays: CalendarDayModel[] = [];

		for (let i = 1; i <= daysInMonth; i++) {
			const day = new CalendarDayModel(i, startOfMonth.getMonth(), startOfMonth.getFullYear());
			day.disabled = this.isDayDisabled(day);
			day.isToday = this.isToday(day);
			calendarDays.push(day);
			startOfMonth = this.dateService.add(startOfMonth, 1, 'day');
		}

		return calendarDays;
	}

	private isToday(date: CalendarDayModel) {
		const today = new Date();
		return today.getDate() === date.day && today.getMonth() === date.month && today.getFullYear() === date.year;
	}

	private isAnotherMonth(date: CalendarDayModel) {
		return this.state.getMonth() !== date.month;
	}

	private setRows() {
		const weekLength = 7;
		const days = this.getDaysBeforeSelectedMonth().concat(this.getCalendarDays()).concat(this.getDaysAfterSelectedMonth());

		this.rows = days
			.map((_, index) => {
				return index % weekLength === 0 ? days.slice(index, index + weekLength) : undefined;
			})
			.filter(row => row);
	}

	private cdmToCalendarDate(value: CalendarDayModel) {
		return new Date(value.year, value.month, value.day);
	}

	private isDayDisabled(day: CalendarDayModel) {
		const date = this.cdmToCalendarDate(day);
		if (this.min && this.dateService.isBefore(date, this.min, 'day')) {
			return true;
		}

		return this.max && this.dateService.isAfter(date, this.max, 'day');
	}
}

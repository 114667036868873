<div class='item placeholder'
     [class.active]="isExpanded"
     (click)="toggle()">
  <ng-template *ngTemplateOutlet="defaultTemplate context: { dataItem: selectedOption }"></ng-template>
</div>
<div class="body-hook"
     *ngIf="isExpanded">
  <div class="options">
    <div class="item option"
         [ngClass]="{'selected': option === selectedOption}"
         *ngFor="let option of options"
         title="{{getOptionTitle(option)}}"
         (click)="select(option)">
      <ng-template *ngTemplateOutlet="defaultTemplate context: { dataItem: option }"></ng-template>
    </div>
  </div>
</div>


<ng-template #defaultTemplate
             let-dataItem="dataItem">
  <ng-container [ngSwitch]='dataItem'>
    <div class="sign"
         *ngSwitchCase='MathOperatorEnum.Addition'>
      <rq-icon href="icon-plus"></rq-icon>
    </div>
    <div class="sign"
         *ngSwitchCase='MathOperatorEnum.Subtraction'>
      <rq-icon href="icon-minus"></rq-icon>
    </div>
    <div class="sign"
         *ngSwitchCase='MathOperatorEnum.Multiplication'>
      <rq-icon href="icon-close"></rq-icon>
    </div>
    <div class="sign"
         *ngSwitchCase='MathOperatorEnum.Division'>
      <rq-icon href="icon-divide"></rq-icon>
    </div>
    <div class="sign"
         *ngSwitchCase='MathOperatorEnum.Modulo'>
      <rq-icon href="icon-percentage"></rq-icon>
    </div>
  </ng-container>
</ng-template>

<rq-dialog #deactivateDialog
           [options]="dialogOptions"
           [theme]="'danger'">
  <rq-dialog-title>
    <span *ngIf="model?.isActive">{{ 'global_deactivatedAccount_label' | translate }}</span>
    <span *ngIf="!model?.isActive">{{ 'global_reactivateAccount_label' | translate }}</span>
  </rq-dialog-title>
  <rq-dialog-body>
    <div class='text-center'>
      <span *ngIf="model?.isActive">{{ 'settings_user_management_table_change_deactivate_message_email' | translate : {email: model?.email} }}</span>
      <span *ngIf="!model?.isActive">{{ 'settings_user_management_table_change_reactivate_message_email' | translate : {email: model?.email} }}</span>
    </div>
    <div class='text-center'>
      <span *ngIf="model?.isActive">{{ 'settings_user_management_table_change_deactivate_message_info' | translate }}</span>
      <span *ngIf="!model?.isActive">{{ 'settings_user_management_table_change_reactivate_message_info' | translate }}</span>
    </div>
  </rq-dialog-body>
</rq-dialog>

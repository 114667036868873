<label class="filter-label"><strong>{{displayName}}</strong></label>
<rq-filter-dropdown [placeholder]="createPlaceholder()"
                    [isDisabled]="isDisabled"
                    [searchBoxConfig]="searchBoxConfig">
  <section class="filter-content">
    <div class="filter-group filter-selected"
         *ngIf="selected.length > 0">
      <div class="filter-item">
        <a class="accent-color"
           href="#"
           rqPreventDefault
           (click)="triggerClear()">{{'global_clearSelected_label' | translate}}</a>
      </div>
      <ng-container *ngFor="let option of selected">
        <div class="filter-item">
          <label>
            <input type="checkbox"
                   [disabled]="isDisabled"
                   (ngModelChange)="triggerSelectChange()"
                   [(ngModel)]="option.isSelected" />
            <span [title]="option.displayName"
                  [rqEllipsis]='option.displayName'
                  [charactersCountLimit]='25'></span>
          </label>
        </div>
      </ng-container>
    </div>
    <ng-container *ngFor="let group of groups">
      <div *ngIf="isGroupVisible(group)"
           class="filter-unselected filter-group">
        <h4 class="filter-group-title"
            *ngIf="group.title">{{group.title | translate}}</h4>
        <ng-container *ngFor="let option of group.options">
          <ng-container *ngIf="isPartOfSearch(option)">
            <div class="filter-item"
                 *ngIf="!option.isSelected">
              <label>
                <input type="checkbox"
                       [disabled]="isCheckboxDisabled"
                       (ngModelChange)="triggerSelectChange()"
                       [(ngModel)]="option.isSelected" />
                <span [title]="option.displayName"
                      [rqEllipsis]='option.displayName'
                      [charactersCountLimit]='25'></span>
              </label>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </section>
</rq-filter-dropdown>

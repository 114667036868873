import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { FairCharacteristicOfLossModel } from '../../../server/models/fair-characteristic-of-loss-model.model';
import { TableBase2Component } from '../../../standalone/components/table/table-base2.component';

@Component({
	selector: 'rq-characteristics-of-loss-table',
	templateUrl: './characteristics-of-loss-table.component.html',
	styleUrls: ['./characteristics-of-loss-table.component.scss']
})
export class CharacteristicsOfLossTableComponent extends TableBase2Component<{ name: string; value: number }, null> implements OnChanges {
	@Input()
	public dataModel!: FairCharacteristicOfLossModel;

	constructor(private translateService: TranslationService) {
		super();
	}

	public async ngOnChanges(changes: SimpleChanges) {
		await super.ngOnChanges(changes);
		if (changes.dataModel?.currentValue) {
			this.setTableData(changes.dataModel.currentValue as FairCharacteristicOfLossModel);
			this.triggerTableLoading(false);
		}
	}

	private setTableData(dataModel: FairCharacteristicOfLossModel) {
		const values = dataModel.losses.map(x => {
			return {
				name: `${x.inherentPercentage}%`,
				value: x.inherentLoss
			};
		});

		values.unshift({
			name: this.translateService.get('global_minimum_label'),
			value: dataModel.minimum
		});

		values.push({
			name: this.translateService.get('global_maximum_label'),
			value: dataModel.maximum
		});

		values.push({
			name: this.translateService.get('scenarios_fairDetails_characteristicsOfLoss_average'),
			value: dataModel.average
		});

		values.sort((a, b) => a.value - b.value);

		this.data = [...values];
	}
}

import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
	selector: 'rq-table-action-item',
	templateUrl: './table-action-item.component.html',
	styleUrls: ['./table-action-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableActionItemComponent {
	@HostBinding('class.btn-secondary')
	public isButtonSecondary = true;
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { LossExposureStatus } from 'src/app/server/enums/loss-exposure-status.enum';

@Pipe({
	name: 'otherLossExposureStatusLabel'
})
export class OtherLossExposureStatusTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: LossExposureStatus) {
		const values = new Map<LossExposureStatus, string>();
		values.set(LossExposureStatus.Complete, 'global_complete_label');
		values.set(LossExposureStatus.Draft, 'global_draft_label');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${LossExposureStatus[value]} does not exist`);
		}

		return this.translationService.get(type);
	}
}

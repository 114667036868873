import { AfterViewInit, Directive, ElementRef, Input, Optional } from '@angular/core';
import { AuthorizeInputModel } from './authorize-input.model';
import { IdentityService } from '../../services/identity.service';
import { ControlStatus } from '../../enums/control-status.enum';
import { ROLE_CONFIG } from './role.config';
import { AbstractControl, NgControl } from '@angular/forms';

@Directive({
	selector: '[rqAuthorize]'
})
export class AuthorizeDirective implements AfterViewInit {
	@Input()
	public state: AuthorizeInputModel[] = [];

	constructor(private el: ElementRef, private identityService: IdentityService, @Optional() private formEntity: NgControl) {}

	public ngAfterViewInit() {
		Promise.resolve().then(() => this.applyState());
	}

	private applyState() {
		const state = this.state.find(x => {
			const isValid = x.role === ROLE_CONFIG.all || this.identityService.isInRole(x.role);

			return isValid ? this.isDisabled(x) : false;
		}) as AuthorizeInputModel;

		if (state !== undefined) {
			switch (state.status) {
				case ControlStatus.Disabled:
					this.formEntity
						? (this.formEntity.control as AbstractControl).disable({ emitEvent: false })
						: this.el.nativeElement.classList.add('disabled');
					break;
				case ControlStatus.Hidden:
					this.el.nativeElement.classList.add('hidden');
					break;
				default:
					throw new Error(`${state.status} is not handled`);
			}
		}
	}

	private isDisabled(state: AuthorizeInputModel | undefined) {
		let isDisabled;

		if (state) {
			isDisabled = state.isDisabled ? state.isDisabled() : true;
		}

		return isDisabled;
	}
}

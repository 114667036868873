import { Directive, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export abstract class FilterAbstractDirective<T> {
	@Input()
	public name!: string;

	@Input()
	public displayName!: string;

	public change = new Subject<T>();

	private _value!: T;

	public get value(): T {
		return this._value;
	}

	public set value(value: T) {
		this._value = value;
	}
}

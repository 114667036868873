import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { SurecloudIntegrationType } from '../../../server/enums/surecloud-integration-type.enum';

@Pipe({
	name: 'surecloudTypeLabel'
})
export class SurecloudTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value?: SurecloudIntegrationType) {
		if (value === undefined) {
			return '';
		}

		const values = new Map<SurecloudIntegrationType, string>();
		values.set(SurecloudIntegrationType.Applications, 'global_applications_label');
		values.set(SurecloudIntegrationType.ThirdParty, 'global_thirdParty_label');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${value} is not handled`);
		}

		return this.translationService.get(type);
	}
}

export class DependencyTreeInternalData {
	public columns: Array<string> = [];

	public nodes: Array<DependencyTreeInternalNode> = [];

	public selectedNode?: DependencyTreeInternalNode;

	public links: Array<DependencyTreeInternalLink> = [];
}

export class DependencyTreeInternalNode {
	public column!: string;

	public name!: string;

	public data!: unknown;

	public targets: Array<DependencyTreeInternalNode> = [];

	public sources: Array<DependencyTreeInternalNode> = [];

	public yPos!: number;

	public x!: number;

	public y!: number;

	public lastVisitValue!: number;

	public selected = false;

	public partOfPath = false;

	public locking = false;
}

export class DependencyTreeInternalLink {
	public opacity = 1;

	public source!: DependencyTreeInternalNode;

	public target!: DependencyTreeInternalNode;

	constructor(source: DependencyTreeInternalNode, target: DependencyTreeInternalNode) {
		this.source = source;
		this.target = target;
		this.source.targets.push(target);
		this.target.sources.push(source);
	}
}

export class DependencyTreeConfig {
	[dynamicProp: string]: string | number;

	public nodeStrokeColor!: string;

	public nodeLockedStrokeColor!: string;

	public nodeHoverFillColor!: string;

	public nodeFillColor!: string;

	public nodeLinkColor!: string;

	public leftPadding = 50;

	public minColumnSpacing = 140;

	public radius = 5;

	public yPadding = 60;

	public nodeTextPadding = 10;

	public linkRounding = 100;

	public transitionDuration = 200;

	public selectedLinkStrokeWidth = 2;

	public unselectedLinkStrokeWidth = 1;

	public normalLinkStrokeWidth = 1.5;
}

export class DependencyTreeData {
	public columns!: Array<DependencyTreeColumn>;
}

export class DependencyTreeColumn {
	public name!: string;

	public nodes!: DependencyTreeNode[];
}

export class DependencyTreeNode {
	[dynamicProp: string]: string | number | boolean | DependencyTreeLink[];

	public id!: string;

	public name!: string;

	public links!: DependencyTreeLink[];
}

export class DependencyTreeLink {
	public targetNodeId!: string;
}

/* eslint-disable @typescript-eslint/unbound-method */
import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	ElementRef,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	TemplateRef,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import { BarChart } from './models/bar-chart.model';
import { BarChartLegendConfig } from './models/barchart-legend-config.model';
import { FormatterType } from '../../../../shared/enums/formatter-type.enum';
import { BarChartAxisConfig } from './models/barchart-axis-config.model';
import { BarchartBarConfig } from './models/barchart-bar-config.model';
import { EChartsType, init } from 'echarts';
import { ChartService } from '../chart.service';
import { CallbackDataParams, ECBasicOption } from 'echarts/types/dist/shared';
import { COLORS } from '../../../utils/colors.utils';

@Component({
	selector: 'rq-bar-chart',
	templateUrl: './bar-chart.component.html',
	styleUrls: ['./bar-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ChartService]
})
export class BarChartComponent implements OnChanges, OnInit, OnDestroy {
	@Input()
	public isStacked = false;

	@Input('legend.total')
	public legendIsTotalVisible = false;

	@Input('legend.isVisible')
	public legendIsVisible = true;

	@Input('legend.tooltip.isVisible')
	public legendTooltipIsVisible = false;

	@Input('legend.margin')
	public legendMargin?: number;

	@Input('legend')
	public legend: BarChartLegendConfig = {
		isVisible: this.legendIsVisible
	};

	@Input()
	public isTooltipVisible = true;

	@Input()
	public orientation: 'HORIZONTAL' | 'VERTICAL' = 'VERTICAL';

	@Input()
	public data: BarChart[] = [];

	@Input('xAxis.label')
	public xAxisLabel = 'X Axis Label Here';

	@Input('xAxis.offset')
	public xAxisOffset?: number;

	@Input('xAxis.formatter')
	public xAxisFormatter?: FormatterType | string;

	@Input('xAxis.customFormatter')
	public xAxisCustomFormatter?: (value: unknown) => string;

	@Input()
	public xAxis: BarChartAxisConfig = {
		label: this.xAxisLabel
	};

	@Input('yAxis.label')
	public yAxisLabel = 'Y Axis Label Here';

	@Input('yAxis.offset')
	public yAxisOffset?: number;

	@Input('yAxis.formatter')
	public yAxisFormatter?: FormatterType | string;

	@Input('yAxis.customFormatter')
	public yAxisCustomFormatter?: (value: unknown) => string;

	@Input()
	public yAxis: BarChartAxisConfig = {
		label: this.yAxisLabel
	};

	@Input('bar.isVisible')
	public barIsVisible = true;

	@Input('bar.formatter')
	public barFormatter?: FormatterType | string;

	@Input('bar.customFormatter')
	public barCustomFormatter?: (value: unknown) => string;

	@Input()
	public bar: BarchartBarConfig = {
		isVisible: this.barIsVisible
	};

	@ContentChild('customTooltip')
	public customTooltip?: TemplateRef<unknown>;

	@ViewChild('chart', { static: true })
	private chartContainer!: ElementRef;

	private barChart?: EChartsType;

	private xAxisOptions?: object;

	private yAxisOptions?: object;

	private resizeObserver!: ResizeObserver;

	private grayDarkColor = getComputedStyle(document.documentElement).getPropertyValue('--background-neutral-contrast');

	constructor(private hostElement: ViewContainerRef, private chartService: ChartService) {}

	@HostListener('window:resize')
	public onResize() {
		if (this.barChart) {
			this.barChart.resize();

			this.barChart.setOption(
				{
					xAxis: this.orientation === 'VERTICAL' ? this.xAxisOptions : this.yAxisOptions,
					yAxis: this.orientation === 'VERTICAL' ? this.yAxisOptions : this.xAxisOptions
				},
				{
					silent: true
				}
			);
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		this.handleXAxisChanges(changes);
		this.handleYAxisChanges(changes);
		this.handleBarChanges(changes);
		this.handleLegendChanges(changes);

		if (changes.data) {
			this.setData(changes.data.currentValue as BarChart[]);
		}
	}

	public ngOnInit() {
		this.subscribeToResizeLayoutChange();
	}

	public ngOnDestroy() {
		this.resizeObserver.disconnect();
	}

	public getDefaultTooltipLabel(name: string, label: string, value: number) {
		return (label ? label : name) + (value ? ' - ' + this.getBarFormatter()(value) : '');
	}

	private handleXAxisChanges(changes: SimpleChanges) {
		if (changes.xAxisLabel) {
			this.updateXAxisConfiguration({ label: changes.xAxisLabel.currentValue as string } as BarChartAxisConfig);
		}

		if (changes.xAxisOffset) {
			this.updateXAxisConfiguration({ offset: changes.xAxisOffset.currentValue as number } as BarChartAxisConfig);
		}

		if (changes.xAxisFormatter) {
			this.updateXAxisConfiguration({ formatter: changes.xAxisFormatter.currentValue as string } as BarChartAxisConfig);
		}

		if (changes.xAxisCustomFormatter) {
			this.updateXAxisConfiguration({
				customFormatter: changes.xAxisCustomFormatter.currentValue as (value: unknown) => string
			} as BarChartAxisConfig);
		}
	}

	private handleYAxisChanges(changes: SimpleChanges) {
		if (changes.yAxisLabel) {
			this.updateYAxisConfiguration({ label: changes.yAxisLabel.currentValue as string } as BarChartAxisConfig);
		}

		if (changes.yAxisOffset) {
			this.updateYAxisConfiguration({ offset: changes.yAxisOffset.currentValue as number } as BarChartAxisConfig);
		}

		if (changes.yAxisFormatter) {
			this.updateYAxisConfiguration({ formatter: changes.yAxisFormatter.currentValue as string } as BarChartAxisConfig);
		}

		if (changes.yAxisCustomFormatter) {
			this.updateYAxisConfiguration({
				customFormatter: changes.yAxisCustomFormatter.currentValue as (value: unknown) => string
			} as BarChartAxisConfig);
		}
	}

	private handleBarChanges(changes: SimpleChanges) {
		if (changes.barIsVisible) {
			this.updateBarConfiguration({ isVisible: changes.barIsVisible.currentValue as boolean });
		}
		if (changes.barFormatter) {
			this.updateBarConfiguration({ formatter: changes.barFormatter.currentValue as string } as BarchartBarConfig);
		}
		if (changes.barCustomFormatter) {
			this.updateBarConfiguration({
				customFormatter: changes.barCustomFormatter.currentValue as (value: unknown) => string
			} as BarchartBarConfig);
		}
	}

	private handleLegendChanges(changes: SimpleChanges) {
		if (changes.legendIsTotalVisible) {
			this.updateLegendConfiguration({ isTotalVisible: changes.legendIsTotalVisible.currentValue as boolean } as BarChartLegendConfig);
		}
		if (changes.legendIsVisible) {
			this.updateLegendConfiguration({ isVisible: changes.legendIsVisible.currentValue as boolean });
		}
		if (changes.legendMargin) {
			this.updateLegendConfiguration({ margin: changes.legendMargin.currentValue as number } as BarChartLegendConfig);
		}
	}

	private getBarFormatter = () => {
		return this.bar?.customFormatter ?? this.chartService.getFormatter(this.bar?.formatter);
	};

	private getSeries(models: BarChart[]) {
		return models[0].values
			? models
					.map(m => m.values ?? [])
					.reduce((acc, model) => acc.concat(model), [])
					.reduce(
						(acc, model) => {
							const existingModel = acc.find(x => x.label === model.label);

							if (existingModel) {
								existingModel.values = [...existingModel.values, model.value];
							} else {
								acc.push({
									label: model.label,
									values: [model.value]
								});
							}
							return acc;
						},
						[] as {
							label: string;
							values: number[];
						}[]
					)
					.map((model, index, array) => ({
						type: 'bar',
						stack: this.isStacked ? 'stack' : undefined,
						name: model.label,
						data: model.values,
						barGap: 0,
						barCategoryGap: 0,
						barMaxWidth: 35,
						label: this.getBarLabelConfig(index === array.length - 1)
					}))
			: models.map((model, index) => ({
					type: 'bar',
					stack: 'stack',
					name: model.name,
					data: [...Array<number>(index), model.value],
					barCategoryGap: 0,
					barMaxWidth: 35,
					label: this.getBarLabelConfig()
			  }));
	}

	private getBarLabelConfig(show = false) {
		return {
			show: this.isStacked && this.bar.isVisible ? show : this.bar.isVisible,
			position: this.orientation === 'VERTICAL' ? 'top' : 'right',
			color: this.grayDarkColor,
			fontSize: 10,
			fontWeight: 600,
			formatter: this.isStacked
				? ({ dataIndex }: { dataIndex: number }) =>
						this.getBarFormatter()((this.data[dataIndex].values ?? []).reduce((acc, value) => acc + value.value, 0))
				: ({ value }: { value: number }) => this.getBarFormatter()(value)
		};
	}

	private getGridConfig() {
		return this.orientation === 'VERTICAL'
			? {
					left: this.yAxis.offset ?? 100,
					right: 20,
					top: 20,
					bottom: this.legend.margin ?? (this.legend.isVisible ? 20 * this.data.length : 60)
			  }
			: {
					left: this.yAxis.offset ?? 150,
					right: 40,
					top: 10,
					bottom: this.legend.margin ?? (this.legend.isVisible ? 20 * this.data.length : 20)
			  };
	}

	private getLegendConfig(models: BarChart[]) {
		return {
			bottom: 0,
			show: this.legend.isVisible,
			padding: [0, this.hostElement.element.nativeElement.offsetWidth * 0.1, 0],
			itemWidth: 22,
			itemHeight: 7,
			itemGap: 5,
			textStyle: {
				width: (this.hostElement.element.nativeElement.scrollWidth / models.length) * (80 / 100),
				overflow: 'truncate'
			},
			formatter: (value: string) => {
				const label = value.replace('\n', '');

				const total = this.data.reduce((acc, entry) => {
					if (entry.values) {
						return acc + (entry.values.find(val => val.label === value)?.value ?? 0);
					} else {
						return acc + (entry.value ?? 0);
					}
				}, 0);

				return this.legendIsTotalVisible ? `${label} - ${this.getBarFormatter()(total)}` : label;
			},
			tooltip: {
				show: this.legendTooltipIsVisible,
				formatter: ({ name }: CallbackDataParams) => `${name}`
			}
		};
	}

	private getAxisLabel(axis: 'X' | 'Y') {
		if (axis === 'X') {
			if (this.orientation === 'VERTICAL') {
				return this.xAxis?.label;
			} else {
				return this.yAxis?.label;
			}
		} else {
			if (this.orientation === 'VERTICAL') {
				return this.yAxis?.label;
			} else {
				return this.xAxis?.label;
			}
		}
	}

	private getAxisFormatter(axis: 'X' | 'Y') {
		let formatter;

		if (axis === 'X') {
			if (this.orientation === 'VERTICAL') {
				formatter = this.xAxis?.customFormatter ?? this.chartService.getFormatter(this.xAxis?.formatter);
			} else {
				formatter = this.yAxis?.customFormatter ?? this.chartService.getFormatter(this.yAxis?.formatter);
			}
		} else {
			if (this.orientation === 'VERTICAL') {
				formatter = this.yAxis?.customFormatter ?? this.chartService.getFormatter(this.yAxis?.formatter);
			} else {
				formatter = this.xAxis?.customFormatter ?? this.chartService.getFormatter(this.xAxis?.formatter);
			}
		}

		return formatter;
	}

	private setData(models: BarChart[]) {
		if (models) {
			if (!this.barChart) {
				this.barChart = init(this.chartContainer.nativeElement as HTMLElement);
			}

			this.setXAxisOptions(models);
			this.setYAxisOptions();
			this.setBarchartOptions(models);
		} else {
			this.barChart?.dispose();
		}
	}

	private setBarchartOptions(models: BarChart[]) {
		this.barChart?.clear();

		const options: ECBasicOption = {
			grid: this.getGridConfig(),
			color: COLORS.rainbowColorScheme,
			tooltip: {
				show: this.isTooltipVisible,
				formatter: (dataParams: CallbackDataParams) => {
					const id =
						dataParams.seriesName !== dataParams.name ? `${dataParams.dataIndex}-${dataParams.seriesIndex}` : `${dataParams.seriesIndex}`;
					return this.hostElement.element.nativeElement.querySelector(`div.tooltip-container [id="${id}"]`).cloneNode(true) as Node;
				}
			},
			legend: this.getLegendConfig(models),
			xAxis: this.orientation === 'VERTICAL' ? this.xAxisOptions : this.yAxisOptions,
			yAxis: this.orientation === 'VERTICAL' ? this.yAxisOptions : this.xAxisOptions,
			series: this.getSeries(models)
		} as ECBasicOption;

		this.barChart?.setOption(options);
	}

	private setXAxisOptions(models: BarChart[]) {
		this.xAxisOptions = {
			inverse: this.orientation === 'HORIZONTAL',
			type: 'category',
			data: models.map(model => model.name),
			name: this.getAxisLabel('X'),
			nameLocation: 'middle',
			nameTextStyle: {
				padding: this.orientation === 'VERTICAL' ? 25 : 110,
				color: this.grayDarkColor,
				fontSize: 14,
				fontWeight: 600
			},
			axisTick: { show: false },
			axisLabel: {
				interval: 0,
				hideOverlap: true,
				width: this.orientation === 'VERTICAL' ? this.hostElement.element.nativeElement.offsetWidth / (models.length + 2) : 110,
				overflow: 'truncate',
				formatter: this.getAxisFormatter('X')
			},
			axisLine: {
				lineStyle: { color: this.grayDarkColor }
			}
		};
	}

	private setYAxisOptions() {
		this.yAxisOptions = {
			type: 'value',
			name: this.getAxisLabel('Y'),
			nameLocation: 'middle',
			nameTextStyle: {
				padding: this.orientation === 'VERTICAL' ? 60 : 25,
				color: this.grayDarkColor,
				fontSize: 14,
				fontWeight: 600
			},
			axisLine: { show: true, lineStyle: { color: this.grayDarkColor } },
			axisLabel: {
				formatter: this.getAxisFormatter('Y')
			}
		};
	}

	private updateXAxisConfiguration(config: BarChartAxisConfig) {
		this.xAxis = { ...this.xAxis, ...config };
	}

	private updateYAxisConfiguration(config: BarChartAxisConfig) {
		this.yAxis = { ...this.yAxis, ...config };
	}

	private updateBarConfiguration(config: BarchartBarConfig) {
		this.bar = { ...this.bar, ...config };
	}

	private updateLegendConfiguration(config: BarChartLegendConfig) {
		this.legend = { ...this.legend, ...config };
	}

	private subscribeToResizeLayoutChange() {
		this.resizeObserver = new ResizeObserver(() => {
			this.barChart?.resize();
		});

		this.resizeObserver.observe(this.hostElement?.element?.nativeElement as Element);
	}
}

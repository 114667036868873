import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import { StandardBase } from '../../../../routes/control-analysis/_common/standard.base';
import { LTRecommendationAnalysisViewModel } from '../../../../routes/control-analysis/_common/models/recommendations-analysis-view.viewmodel';

@Component({
	selector: 'rq-step-what',
	templateUrl: './step-what.component.html',
	styleUrls: ['./step-what.component.scss']
})
export class StepWhatComponent extends StandardBase implements OnChanges {
	@Input()
	public items!: Array<LTRecommendationAnalysisViewModel>;

	@Output()
	public readonly itemSelect = new EventEmitter<LTRecommendationAnalysisViewModel>();

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.items?.currentValue) {
			const items = changes.items?.currentValue as Array<LTRecommendationAnalysisViewModel>;
			if (items.length > 0) {
				this.select(items[0]);
			}
		}
	}

	public select(item: LTRecommendationAnalysisViewModel) {
		const dataItem = this.items.find(
			x =>
				x.controlOptionId === item.controlOptionId &&
				x.controlImprovements.proposedValue === item.controlImprovements.proposedValue &&
				x.controlImprovements.oldValue === item.controlImprovements.oldValue &&
				x.legalEntityName === item.legalEntityName
		);
		if (!dataItem) {
			return;
		}
		this.items.forEach(x => (x.isSelected = false));
		dataItem.isSelected = true;
		this.itemSelect.emit(dataItem);
	}
}

import { TranslationService } from 'src/app/shared/services/translation.service';
import { PipeTransform, Pipe } from '@angular/core';
import { RQAnalysisStatus } from '../../../server/enums/rqanalysis-status.enum';

@Pipe({
	name: 'thirdPartyAnalysisStatusLabel'
})
export class ThirdPartyAnalysisStatusTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: RQAnalysisStatus) {
		switch (value) {
			case RQAnalysisStatus.Success:
				return this.translationService.get('global_successful_label');
			case RQAnalysisStatus.Failure:
				return this.translationService.get('global_unsuccessful_label');
			case RQAnalysisStatus.Running:
				return this.translationService.get('global_running_label');
			default:
				return '';
		}
	}
}

import * as d3 from 'd3';
import { ChartContext } from '../models/chart.model';
import { SVGAttributes } from '../models/svg-attributes.model';
import { ChartRenderer } from '../models/chart-renderer.abstract';
import {
	DependencyTreeConfig,
	DependencyTreeInternalData,
	DependencyTreeInternalLink,
	DependencyTreeInternalNode
} from '../../dependency-tree/dependency-tree-config.model';

export default class DependencyTreeRenderer implements ChartRenderer<DependencyTreeInternalData, DependencyTreeConfig> {
	private columnConfig!: { [key: string]: { x: number; y: number } };

	private links!: d3.Selection<SVGPathElement, DependencyTreeInternalLink, SVGGElement, undefined>;

	private nodes!: d3.Selection<SVGGElement, DependencyTreeInternalNode, SVGGElement, undefined>;

	private columns!: d3.Selection<SVGTextElement, string, SVGGElement, undefined>;

	private locked = false;

	private preFilterNodes?: Function;

	constructor(private context: ChartContext) {}

	public setup(
		data: DependencyTreeInternalData,
		config: DependencyTreeConfig,
		selectionCallback?: Function,
		preFilterNodes?: Function
	): void {
		data.nodes.forEach(node => {
			Object.assign(node, { x: 0, y: 0 });
		});
		this.preFilterNodes = preFilterNodes;
		const svg = this.context.drawArea.selection;
		this.recalculateColumns(data, config);
		this.prepareColumns(data, svg);
		this.prepareLinks(data, svg, config);

		this.nodes = svg
			.selectAll('.node')
			.data(data.nodes)
			.enter()
			.append<SVGGElement>(SVGAttributes.group.tag)
			.attr(SVGAttributes.group.class, 'node')
			.attr(SVGAttributes.group.transform, d => `translate(${this.getPosition(d, config)[0]}, ${this.getPosition(d, config)[1]})`)
			.on(SVGAttributes.events.mouseClick, (_d, item) => {
				this.mouseClick(item, data, config, selectionCallback);
			})
			.on(SVGAttributes.events.mouseEnter, (_d, item) => {
				this.mouseEnter(item, data, config);
			})
			.on(SVGAttributes.events.mouseLeave, (_d, item) => {
				this.mouseLeave(item, data, config);
			});

		this.nodes
			.append('circle')
			.attr(SVGAttributes.circle.fill, 'transparent')
			.attr(SVGAttributes.circle.stroke, config.nodeStrokeColor)
			.attr(SVGAttributes.circle.strokeWidth, config.selectedLinkStrokeWidth)
			.attr(SVGAttributes.circle.radius, config.radius)
			.attr(SVGAttributes.circle.x, '0')
			.attr(SVGAttributes.circle.y, '0');

		this.nodes
			.append(SVGAttributes.text.tag)
			.attr(SVGAttributes.text.textAnchor, 'middle')
			.text(d => {
				if (d.name.length > 30) {
					return d.name.slice(0, 15).concat('...');
				}
				return d.name;
			})
			.attr(SVGAttributes.text.transform, () => `translate(0, ${-config.radius - config.nodeTextPadding})`)
			.append('title')
			.text(d => {
				return d.name;
			});
	}

	public resize(data: DependencyTreeInternalData, config: DependencyTreeConfig): void {
		this.recalculateColumns(data, config);
		this.reposition(config);
	}

	public change(data: DependencyTreeInternalData, config: DependencyTreeConfig): void {
		this.links.remove();
		this.nodes.remove();
		this.columns.remove();
		this.setup(data, config);
	}

	private getUpdateLinkPosition(config: DependencyTreeConfig) {
		return (d: DependencyTreeInternalLink) => {
			const source = d.target,
				target = d.source;

			const [fx, fy] = this.getPosition(source, config);
			const [tx, ty] = this.getPosition(target, config);

			return `M${fx - config.radius} ${fy} C${fx - config.linkRounding} ${fy} ${tx + config.linkRounding} ${ty} ${
				tx + config.radius
			} ${ty}`;
		};
	}

	private prepareColumns(data: DependencyTreeInternalData, svg: d3.Selection<SVGGElement, undefined, SVGElement, undefined>): void {
		this.columns = svg
			.selectAll('text')
			.data(data.columns)
			.enter()
			.append<SVGTextElement>(SVGAttributes.text.tag)
			.attr('class', 'title')
			.attr(SVGAttributes.text.textAnchor, 'middle')
			.text(d => d)
			.attr(SVGAttributes.text.transform, d => `translate(${this.columnConfig[d].x}, 0)`);
	}

	private prepareLinks(
		data: DependencyTreeInternalData,
		svg: d3.Selection<SVGGElement, undefined, SVGElement, undefined>,
		config: DependencyTreeConfig
	): void {
		this.links = svg
			.selectAll('.link')
			.data(data.links)
			.enter()
			.append<SVGPathElement>(SVGAttributes.path.tag)
			.attr(SVGAttributes.path.fill, 'none')
			.attr(SVGAttributes.path.stroke, () => config.nodeLinkColor)
			.attr(SVGAttributes.path.opacity, d => d.opacity)
			.attr(SVGAttributes.path.strokeWidth, config.normalLinkStrokeWidth)
			.attr(SVGAttributes.path.d, this.getUpdateLinkPosition(config));
	}

	private mouseClick(
		d: DependencyTreeInternalNode,
		data: DependencyTreeInternalData,
		config: DependencyTreeConfig,
		selectionCallback?: Function
	) {
		this.locked = !(this.locked && d.locking);

		data.nodes.forEach(node => {
			node.locking = this.locked && d === node;
		});

		this.highlightPathOf(d, data, config);

		if (selectionCallback) {
			if (this.locked) {
				selectionCallback(this.nodes.filter(node => node.partOfPath).data());
			} else {
				selectionCallback(this.nodes.data());
			}
		}
	}

	private mouseLeave(d: DependencyTreeInternalNode, data: DependencyTreeInternalData, config: DependencyTreeConfig) {
		if (!this.locked) {
			d.selected = false;
			data.links.forEach((link: DependencyTreeInternalLink) => (link.opacity = 1));
			this.links
				.transition()
				.duration(150)
				.attr(SVGAttributes.line.opacity, (link: DependencyTreeInternalLink) => link.opacity)
				.attr(SVGAttributes.line.strokeWidth, config.normalLinkStrokeWidth);
			this.nodes.select(SVGAttributes.circle.tag).attr(SVGAttributes.circle.fill, 'transparent');
			this.nodes.transition().duration(150).attr(SVGAttributes.circle.opacity, 1);
		}
	}

	private highlightPathOf(d: DependencyTreeInternalNode, data: DependencyTreeInternalData, config: DependencyTreeConfig) {
		data.nodes.forEach(node => (node.selected = false));
		const visitKey = Math.random();

		data.nodes.forEach(node => (node.partOfPath = false));
		this.selectNode(d, visitKey);

		const selected = data.nodes.filter(node => node.partOfPath);

		if (this.preFilterNodes) {
			this.preFilterNodes(selected);
		}

		d.selected = true;
		data.links.forEach((link: DependencyTreeInternalLink) => (link.opacity = link.source.partOfPath && link.target.partOfPath ? 1 : 0.3));
		this.links
			.transition()
			.duration(150)
			.attr(SVGAttributes.line.opacity, (link: DependencyTreeInternalLink) => link.opacity)
			.attr(SVGAttributes.line.strokeWidth, (link: DependencyTreeInternalLink) =>
				link.opacity === 1 ? config.selectedLinkStrokeWidth : config.unselectedLinkStrokeWidth
			);
		this.nodes
			.select(SVGAttributes.circle.tag)
			.transition()
			.duration(150)
			.attr(SVGAttributes.path.fill, (circle: DependencyTreeInternalNode) => (circle.selected ? config.nodeHoverFillColor : 'transparent'));
		this.nodes
			.transition()
			.duration(150)
			.attr('opacity', (circle: DependencyTreeInternalNode) => (circle.partOfPath ? 1 : 0.3));
	}

	private mouseEnter(d: DependencyTreeInternalNode, data: DependencyTreeInternalData, config: DependencyTreeConfig) {
		if (!this.locked) {
			this.highlightPathOf(d, data, config);
		}
	}

	private selectNode(node: DependencyTreeInternalNode, visited = Math.random(), direction = 0): void {
		if (node.lastVisitValue !== visited) {
			node.lastVisitValue = visited;
			node.partOfPath = true;
			if (direction <= 0) {
				node.sources.forEach(linked => {
					this.selectNode(linked, visited, -1);
				});
			}
			if (direction >= 0) {
				node.targets.forEach(linked => {
					this.selectNode(linked, visited, 1);
				});
			}
		}
	}

	private reposition(config: DependencyTreeConfig): void {
		this.columns
			.transition()
			.duration(config.transitionDuration)
			.attr(SVGAttributes.group.transform, (column: string) => `translate(${this.columnConfig[column].x}, 0)`);

		this.nodes
			.transition()
			.duration(config.transitionDuration)
			.attr(SVGAttributes.group.transform, (d: DependencyTreeInternalNode) => {
				d.y = 0;
				return `translate(${this.getPosition(d, config)[0]}, ${this.getPosition(d, config)[1]})`;
			});

		this.links.transition().duration(config.transitionDuration).attr(SVGAttributes.path.d, this.getUpdateLinkPosition(config));
	}

	private recalculateColumns(data: DependencyTreeInternalData, config: DependencyTreeConfig): void {
		const width = this.context.drawArea.rect.width;
		const columnSpacing = Math.max(config.minColumnSpacing, width / data.columns.length);
		this.columnConfig = data.columns.reduce((memo: { [key: string]: { x: number; y: number } }, item, index: number) => {
			memo[item] = {
				x: config.leftPadding + index * columnSpacing,
				y: 0
			};
			return memo;
		}, {});
	}

	private getPosition(node: DependencyTreeInternalNode, config: DependencyTreeConfig): Array<number> {
		if (!node.y) {
			const conf = this.columnConfig[node.column];
			conf.y += 1;
			node.y = conf.y * config.yPadding;
			node.x = conf.x;
		}

		return [node.x, node.y];
	}
}

import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagService } from './feature-flag.service';
import { EFeatureFlag } from '../../../server/enums/efeature-flag.enum';

@Directive({
	selector: '[rqHasFeature]'
})
export class HasFeatureDirective {
	private featureFlag!: EFeatureFlag | string;

	constructor(
		private templateRef: TemplateRef<unknown>,
		private viewContainerRef: ViewContainerRef,
		private hasFeatureService: FeatureFlagService
	) {}

	@Input()
	public set rqHasFeature(val: EFeatureFlag | string) {
		this.featureFlag = val;
		this.updateView();
	}

	@Input()
	public set rqHasFeatureIsOldVersion(value: boolean) {
		if (value !== undefined) {
			this.updateViewForOldVersion(value);
		}
	}

	private updateViewForOldVersion(showOldVersion: boolean) {
		const isFeatureEnabled = this.hasFeatureService.isEnabled(this.featureFlag);
		if (!isFeatureEnabled && showOldVersion) {
			this.showComponent();
		} else {
			this.removeComponent();
		}
	}

	private updateView() {
		if (this.hasFeatureService.isEnabled(this.featureFlag)) {
			this.showComponent();
		} else {
			this.removeComponent();
		}
	}

	private removeComponent(): void {
		this.viewContainerRef.clear();
	}

	private showComponent(): void {
		this.viewContainerRef.clear();
		this.viewContainerRef.createEmbeddedView(this.templateRef);
	}
}

import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { COLORS } from '../../utils/colors.utils';
import { ThemeColorsType } from '../../types/theme-colors.type';

@Component({
	selector: 'rq-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
	@Output()
	public readonly modalClosed = new EventEmitter<void>();

	@Input()
	public isVisible = false;

	@Input()
	public hasFixedContent = true;

	@Input()
	public theme!: ThemeColorsType;

	@ViewChild('divTitle', { static: true })
	public divTitle!: ElementRef;

	@ViewChild('divContent', { static: true })
	public divContent!: ElementRef;

	@ViewChild('divFooter', { static: true })
	public divFooter!: ElementRef;

	public data!: unknown;

	public headerTextColor = COLORS.primaryText;

	@HostBinding('attr.hidden')
	public get isModalHidden() {
		return this.isVisible ? null : 'disabled';
	}

	public ngOnChanges(changes: SimpleChanges) {
		const theme = changes.theme?.currentValue as ThemeColorsType;
		if (theme) {
			this.setThemeColors(theme);
		}
	}

	public show(): void {
		this.isVisible = true;
	}

	public hide(): void {
		this.isVisible = false;
	}

	public isTitleVisible(): boolean {
		return this.hasContent(this.divTitle);
	}

	public isContentVisible(): boolean {
		return this.hasContent(this.divContent);
	}

	public isFooterVisible(): boolean {
		return this.hasContent(this.divFooter);
	}

	private hasContent(htmlElement: ElementRef): boolean {
		if (!htmlElement) {
			return false;
		}

		if (!htmlElement.nativeElement.firstChild) {
			return false;
		}

		if (htmlElement.nativeElement.firstChild.innerHTML.trim() === '') {
			return false;
		}

		return true;
	}

	private setThemeColors(theme: ThemeColorsType) {
		switch (theme) {
			case 'success':
				this.headerTextColor = COLORS.primaryText;
				break;
			case 'danger':
				this.headerTextColor = COLORS.dangerText;
				break;
			default:
				this.headerTextColor = COLORS.primaryText;
		}
	}
}

<rq-table class="m-b-md"
          *ngIf="data"
          [dataSource]="data"
          [pagerState]="{ skip: 0, take: 99 }"
          [hasFilters]="false"
          [count]="data.length">
  <rq-table-column field="name"
                   [isSortable]="false"
                   title="whatIf_fairDetails_riskCharacteristics_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{ dataItem.name }}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="value"
                   [isSortable]="false"
                   title="risk_analysis_application_dashboard_risk_summary_ale_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{ dataItem.value | rqCurrency }}</span>
    </ng-template>
  </rq-table-column>
</rq-table>

<section *ngIf="!count"
         class="p-lg">
  <p>{{'global_noData_available' | translate}}</p>
</section>

<section *ngIf="count"
         e2e-test="rq-dashboard-attack-container"
         class="row">
  <div class="col-12 col-xl-6">
    <rq-dashboard-top-threats class="top-threats"
                              [barChartData]="barChartData"
                              [xAxisLabel]="'global_scenarios_label'"
                              [yAxisLabel]="'dashboard_topApplications_ale'">
    </rq-dashboard-top-threats>
  </div>

  <div class="col-12 col-xl-6 application-threats-container">
    <rq-analysis-threats-table (loadData)='triggerLoadData($event)'
                               [data]="data"
                               [count]="count"
                               [isLegalEntityVisible]="isLegalEntityVisible"
                               [entityColumnName]="'global_scenarios_label'">
    </rq-analysis-threats-table>

    <div class="see-more-link m-l-md m-r-md m-b-lg"
         [attr.hidden]="hideMoreLink ?  'hidden' : null">
      <a rqPreventDefault
         (click)="navigateTo()"
         href="#">{{ 'see_more_details' | translate}}</a>
    </div>
  </div>


</section>

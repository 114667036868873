export class TableValue<T> {
	public isSelected = false;

	constructor(public data: T, config?: { isSelected?: boolean }) {
		this.setup(config);
	}

	private setup(config: { isSelected?: boolean } | undefined) {
		if (config) {
			this.isSelected = typeof config.isSelected === 'boolean' ? config.isSelected : false;
		}
	}
}

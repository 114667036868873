import { trigger, transition, style, animate } from '@angular/animations';

export const slideInFromRightAnimation = trigger('slideInFromRightAnimation', [
	transition(':enter', [
		style({
			transform: 'translateX(100%)'
		}),
		animate(
			'300ms ease-out',
			style({
				transform: 'translateX(0)'
			})
		)
	]),
	transition(':leave', [
		animate(
			'200ms ease-in',
			style({
				transform: 'translateX(100%)'
			})
		)
	])
]);

export const slideInFromLeftAnimation = trigger('slideInFromLeftAnimation', [
	transition(':enter', [
		style({
			transform: 'translateX(-100%)'
		}),
		animate(
			'300ms ease-out',
			style({
				transform: 'translateX(0)'
			})
		)
	]),
	transition(':leave', [
		animate(
			'200ms ease-in',
			style({
				transform: 'translateX(-100%)'
			})
		)
	])
]);

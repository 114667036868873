import { TranslationService } from 'src/app/shared/services/translation.service';
import { PipeTransform, Pipe } from '@angular/core';
import { TPLossExposureType } from '../../../server/enums/tploss-exposure-type.enum';

@Pipe({
	name: 'thirdPartyLossExposureLabel'
})
export class ThirdPartyLossExposureTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: TPLossExposureType) {
		switch (value) {
			case TPLossExposureType.Data:
				return this.translationService.get('global_dataRecords_label');
			case TPLossExposureType.IPTheft:
				return this.translationService.get('configure_thirdParty_lossExposure_ipTheft_type');
			case TPLossExposureType.IndirectOperation:
				return this.translationService.get('configure_thirdParty_lossExposure_indirectOperations_type');
			case TPLossExposureType.Reputation:
				return this.translationService.get('configure_thirdParty_lossExposure_reputational_type');
			case TPLossExposureType.DirectOperations:
				return this.translationService.get('configure_thirdParty_lossExposure_directOperations_type');
			default:
				return '';
		}
	}
}

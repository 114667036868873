import { Component, ContentChild, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { PopoverContentDirective } from './popover.directive';

@Component({
	selector: 'rq-popover',
	templateUrl: './popover.component.html',
	styleUrls: ['./popover.component.scss']
})
export class PopoverComponent {
	@Input()
	public isVisibleOnClick = false;

	@Output()
	public readonly visibilityChange = new EventEmitter<boolean>();

	@ContentChild(PopoverContentDirective)
	public popoverContent!: PopoverContentDirective;

	private isContentVisible = false;

	private showTimeout = 300;

	private timer?: number;

	constructor(private el: ElementRef) {
		el.nativeElement.classList.add('popover');
	}

	@HostListener('document:click', ['$event'])
	public documentClick(event: MouseEvent) {
		const isClickedOutside = this.el.nativeElement.contains(event.target) as boolean;

		if (this.isVisibleOnClick && this.isContentVisible && !isClickedOutside) {
			this.hide();
		}
	}

	@HostListener('click')
	public click() {
		if (this.isVisibleOnClick) {
			this.isContentVisible ? this.hide() : this.show();
		}
	}

	@HostListener('focusin')
	@HostListener('mouseenter')
	public onHoverIn() {
		if (!this.isVisibleOnClick) {
			this.showOnHover();
		}
	}

	@HostListener('focusout', ['$event'])
	@HostListener('mouseout', ['$event'])
	public onHoverOut($event: MouseEvent) {
		if (!this.isVisibleOnClick) {
			this.hideOnHover($event);
		}
	}

	private hideOnHover($event: MouseEvent) {
		if (!this.el.nativeElement.contains($event.relatedTarget) && this.popoverContent) {
			window.clearTimeout(this.timer);

			this.hide();

			this.timer = undefined;
		}
	}

	private showOnHover() {
		if (!this.timer && this.popoverContent) {
			this.timer = window.setTimeout(() => {
				this.show();
			}, this.showTimeout);
		}
	}

	private show() {
		this.popoverContent.show(this.el.nativeElement as HTMLElement);

		this.isContentVisible = true;

		this.visibilityChange.emit(this.isContentVisible);
	}

	private hide() {
		this.popoverContent.hide();

		this.isContentVisible = false;

		this.visibilityChange.emit(this.isContentVisible);
	}
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { LegalEntitySummaryModel } from '../../models/legal-entity-summary-model.model';
import { HighestLossBreakdownByEntity } from '../../models/highest-loss-breakdown-by-entity.model';
import { FilterTimeIntervalModel } from '../../models/filter-time-interval-model.model';
import { LossOverTimeAnalysisModel } from '../../models/loss-over-time-analysis-model.model';
import { TimeInterval } from '../../enums/time-interval.enum';
import { LossDistributionOverTimeByTypeModel } from '../../models/loss-distribution-over-time-by-type-model.model';
import { LossDistributionOverTimeFilterModel } from '../../models/loss-distribution-over-time-filter-model.model';
import { Pagination } from '../../models/pagination.model';
import { BusinessApplicationLossBreakdownModel } from '../../models/business-application-loss-breakdown-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { LossBreakdownFilterModel } from '../../models/loss-breakdown-filter-model.model';

@Injectable()
export class LegalEntityLossDataService {
	constructor(private httpService: RQHttpService) {}

	public getSummary(analysisId: string, options = new HttpOptionsViewModel()): Promise<LegalEntitySummaryModel> {
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService
			.request<LegalEntitySummaryModel>('get', environment.api + '/api/Analysis/LegalEntityLoss/GetSummary', options)
			.toPromise();
	}

	public getBreakdownByEntity(analysisId: string, options = new HttpOptionsViewModel()): Promise<HighestLossBreakdownByEntity> {
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService
			.request<HighestLossBreakdownByEntity>('get', environment.api + '/api/Analysis/LegalEntityLoss/GetBreakdownByEntity', options)
			.toPromise();
	}

	public getLossOverTimeFilters(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<FilterTimeIntervalModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<FilterTimeIntervalModel[]>('get', environment.api + '/api/Analysis/LegalEntityLoss/GetLossOverTimeFilters', options)
			.toPromise();
	}

	public getLossOverTime(
		legalEntityId: string,
		filter: TimeInterval,
		count: number,
		options = new HttpOptionsViewModel()
	): Promise<LossOverTimeAnalysisModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			filter,
			count
		});

		return this.httpService
			.request<LossOverTimeAnalysisModel[]>('get', environment.api + '/api/Analysis/LegalEntityLoss/GetLossOverTime', options)
			.toPromise();
	}

	public getLossDistributionOverTime(
		analysisId: string,
		filter: LossDistributionOverTimeFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<LossDistributionOverTimeByTypeModel[]> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			filter
		});

		return this.httpService
			.request<LossDistributionOverTimeByTypeModel[]>(
				'get',
				environment.api + '/api/Analysis/LegalEntityLoss/GetLossDistributionOverTime',
				options
			)
			.toPromise();
	}

	public getLossBreakdownBySubType(
		analysisId: string,
		pagination: PaginationFilter,
		filter: LossBreakdownFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<BusinessApplicationLossBreakdownModel>> {
		options.params = this.httpService.getHttpParams({
			analysisId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<BusinessApplicationLossBreakdownModel>>(
				'get',
				environment.api + '/api/Analysis/LegalEntityLoss/GetLossBreakdownBySubType',
				options
			)
			.toPromise();
	}
}

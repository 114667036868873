import {
	AfterContentInit,
	Component,
	ContentChild,
	ContentChildren,
	EventEmitter,
	HostBinding,
	Input,
	OnDestroy,
	Output,
	QueryList,
	TemplateRef,
	ViewChild,
	ViewChildren
} from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from '../../utils/helpers.utils';
import {
	CardLayoutChangeConfig,
	CardLayoutConfig,
	CardLayoutStateConfig,
	CardLayoutTabItemConfig,
	ChartLayoutChangeType
} from './card-layout.config.model';
import { LoadingBoxType } from '../loading-box/loading-box-type.enum';
import { LoadingBoxComponent } from '../loading-box/loading-box.component';
import { CardLayoutItemTitleDirective } from './card-layout-item-title.directive';
import { CardLayoutItemDirective } from './card-layout-item.directive';
import { CardLayoutItemHeaderDirective } from './card-layout-item-header.directive';
import { CardLayoutMenuTabComponent } from './card-layout-menu-tab/card-layout-menu-tab.component';

@Component({
	selector: 'rq-card-layout',
	templateUrl: './card-layout.component.html',
	styleUrls: ['./card-layout.component.scss']
})
export class CardLayoutComponent implements AfterContentInit, OnDestroy {
	@Input()
	public config!: CardLayoutConfig;

	@HostBinding('class.card-layout')
	@HostBinding('class.card')
	public hasDefaultClasses = true;

	@ViewChildren(CardLayoutMenuTabComponent)
	public menuItems!: QueryList<CardLayoutMenuTabComponent>;

	@ContentChild(CardLayoutItemHeaderDirective)
	public contentMenuItemHeader!: CardLayoutItemHeaderDirective;

	@ContentChildren(CardLayoutItemTitleDirective)
	public contentItemsTitles!: QueryList<CardLayoutItemTitleDirective>;

	@ContentChildren(CardLayoutItemDirective)
	public contentItems!: QueryList<CardLayoutItemDirective>;

	@ContentChild('cardLayoutHeader', { read: TemplateRef })
	public cardHeader!: TemplateRef<unknown>;

	@Output()
	public readonly triggerChange = new EventEmitter<CardLayoutChangeConfig>();

	@Output()
	public readonly triggerBeforeChange = new EventEmitter<CardLayoutChangeConfig>();

	@ViewChild('loadingBox')
	public loadingBox!: LoadingBoxComponent;

	public state = new CardLayoutStateConfig();

	public isLoadingActive!: boolean;

	private isDestroyed!: boolean;

	constructor(private router: Router) {}

	public get isHeaderTemplateConfigured() {
		return Boolean(this.cardHeader);
	}

	public get isTabsConfigured() {
		return this.config.tabs && this.config.tabs.length > 0;
	}

	public get isMenuConfigured() {
		return this.config.menuItems && this.config.menuItems.length > 0;
	}

	public get isTitleContentConfigured() {
		return this.contentItemsTitles && this.contentItemsTitles.length > 0;
	}

	public ngAfterContentInit() {
		this.setupLoadingBox();

		this.setStateConfig();

		this.setActiveContent();

		this.setActiveTab();
	}

	public ngOnDestroy(): void {
		this.isDestroyed = true;
	}

	public navigateTo() {
		if (!this.config.navigateTo) {
			return;
		}

		this.router.navigateByUrl(this.config.navigateTo.url);
	}

	public triggerTabChange(tab: CardLayoutTabItemConfig) {
		if (!tab.isDisabled && tab.tabId !== this.state.tabId) {
			const beforeChange = Object.assign(new CardLayoutChangeConfig(ChartLayoutChangeType.Tab), this.state);

			this.triggerBeforeChange.emit(beforeChange);

			this.state.tabId = tab.tabId;

			this.setActiveTab();

			this.setActiveContent();

			const change = Object.assign(new CardLayoutChangeConfig(ChartLayoutChangeType.Tab), this.state);

			this.triggerChange.emit(change);
		}
	}

	public triggerMenuChange(menuChange: { menuId: string | number; childMenuId: string | number }) {
		const beforeChange = Object.assign(new CardLayoutChangeConfig(ChartLayoutChangeType.Tab), this.state);

		this.triggerBeforeChange.emit(beforeChange);

		this.state.menuId = menuChange.menuId;
		this.state.childMenuId = menuChange.childMenuId;

		this.triggerReset();

		this.setActiveContent();

		const change = Object.assign(new CardLayoutChangeConfig(ChartLayoutChangeType.MenuItem), this.state);

		this.triggerChange.emit(change);
	}

	public setActiveContent() {
		let items: CardLayoutItemDirective[] = this.contentItems.toArray();

		if (this.isTitleContentConfigured) {
			this.setContentTiles(items);
		}

		if (this.isTabsConfigured) {
			items = items.filter(x => x.tabId === this.state.tabId);
		}

		if (this.isMenuConfigured) {
			items = this.state.childMenuId
				? items.filter(x => x.menuItemId === this.state.childMenuId)
				: items.filter(x => x.menuItemId === this.state.menuId);
		}

		if (!this.isTabsConfigured && !this.isMenuConfigured && this.isContentConfigured()) {
			items = [this.contentItems.toArray()[0]];
		}

		if (items.length === 0) {
			throw new Error(`There is no template configured with tabId ${this.state.tabId} and menuItemId ${this.state.menuId} `);
		}

		if (items.length > 1) {
			throw new Error(`There is more then one template configured with tabId ${this.state.tabId} and menuItemId ${this.state.menuId} `);
		}

		this.state.content = items[0];
	}

	public isContentConfigured() {
		return this.contentItems && this.contentItems.length > 0;
	}

	public async toggleLoadingBox<T>(dataLoaded: () => Promise<T>) {
		this.toggleLoadingBoxState(true);

		const data = await dataLoaded();

		this.toggleLoadingBoxState(false);

		return data;
	}

	public toggleLoadingBoxState(isActive: boolean) {
		if (this.isDestroyed) {
			return;
		}

		this.isLoadingActive = isActive;

		if (!this.loadingBox) {
			return;
		}

		this.loadingBox.toggle(this.isLoadingActive);
	}

	private setupLoadingBox() {
		if (isNullOrUndefined(this.config.loadingBoxType)) {
			this.config.loadingBoxType = LoadingBoxType.Card;
		}

		if (!isNullOrUndefined(this.isLoadingActive)) {
			this.toggleLoadingBoxState(this.isLoadingActive);
		}
	}

	private triggerReset() {
		if (!this.menuItems) {
			return;
		}

		this.menuItems.forEach(x => {
			if (x.menuId !== this.state.menuId) {
				x.toggleActiveState(false);
			}
		});
	}

	private setActiveTab() {
		if (!this.config.tabs) {
			return;
		}

		this.config.tabs.forEach(tab => (tab.isActive = false));

		const activeTab = <CardLayoutTabItemConfig>this.config.tabs.find(x => x.tabId === this.state.tabId);

		activeTab.isActive = true;
	}

	private setStateConfig() {
		if (this.config.tabs && this.config.tabs.length > 0) {
			const tabs = this.config.tabs.filter(x => x.isActive);

			if (!tabs || tabs.length > 1) {
				throw new Error('There should always be only one tab set to isActive');
			}

			this.state.tabId = tabs[0].tabId;
		}

		if (this.config.menuItems && this.config.menuItems.length > 0) {
			const menuItems = this.config.menuItems.filter(x => x.isActive);

			if (!menuItems || menuItems.length > 1) {
				throw new Error('There should always be only one tab set to isActive');
			}

			this.state.menuId = menuItems[0].menuId;
		}

		if (typeof this.config.isLoadingActive !== 'boolean') {
			this.config.isLoadingActive = true;
		}
	}

	private setContentTiles(items: CardLayoutItemDirective[]) {
		const titles = this.contentItemsTitles.toArray();

		for (let i = 0; i < items.length; i++) {
			items[i].titleHtml = titles[i].templateRef;
		}
	}
}

import { TranslationService } from 'src/app/shared/services/translation.service';
import { PipeTransform, Pipe } from '@angular/core';
import { ApplicationEnterpriseProfileType } from '../../../server/enums/application-enterprise-profile-type.enum';

@Pipe({
	name: 'applicationProfileTypeLabel'
})
export class ApplicationProfileTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value?: ApplicationEnterpriseProfileType) {
		switch (value) {
			case ApplicationEnterpriseProfileType.Inherent:
				return this.translationService.get('global_inherentRisk_label');
			case ApplicationEnterpriseProfileType.Residual:
				return this.translationService.get('global_residualRisk_label');
			default:
				throw new Error(`${value} does not exist`);
		}
	}
}

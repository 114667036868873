import { AfterContentInit, Component, ContentChild, ElementRef, HostBinding, Input, OnDestroy } from '@angular/core';
import { FormGroupName, ValidationErrors } from '@angular/forms';
import { SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { FormGroupConfig } from './form-group.model';
import { Customizer } from '../../../../shared/services/customizer';
import { TranslateParam } from '../../../pipes/models/translate.model';

@Component({
	selector: 'rq-form-group',
	templateUrl: './form-group.component.html',
	styleUrls: ['./form-group.component.scss']
})
export class FormGroupComponent implements AfterContentInit, OnDestroy {
	@Input()
	public options!: FormGroupConfig;

	@ContentChild(FormGroupName)
	public control!: FormGroupName;

	public error!: SafeHtml;

	private observer!: MutationObserver;

	private element!: HTMLElement;

	private subscriptions: Subscription[] = [];

	constructor(private customizer: Customizer, elementRef: ElementRef) {
		this.element = elementRef.nativeElement as HTMLElement;
	}

	@HostBinding('class.ng-touched')
	public get ngClassTouched(): boolean | null {
		if (!this.control) {
			return false;
		}
		if (this.control.touched) {
			this.updateErrorMessage();
		}
		return this.control.touched;
	}

	@HostBinding('class.ng-invalid')
	public get ngClassInvalid(): boolean | null {
		return this.control ? this.control.invalid : false;
	}

	@HostBinding('class.ng-valid')
	public get ngClassValid(): boolean | null {
		return this.control ? this.control.valid : true;
	}

	public ngAfterContentInit(): void {
		this.handleErrorUpdate();

		this.handleContentChange();
	}

	public ngOnDestroy(): void {
		this.observer.disconnect();
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	private handleContentChange() {
		this.observer = new MutationObserver(() => {
			this.setClass('input[type="checkbox"]', 'form-checkbox-label');
			this.setClass('input[type="radio"]', 'form-radio-label');
		});

		this.observer.observe(this.element, {
			attributes: true,
			characterData: false,
			childList: false,
			subtree: false,
			attributeOldValue: false,
			characterDataOldValue: false
		});
	}

	private setClass(selector: string, className: string) {
		const elements = this.element.querySelectorAll(selector);

		if (elements && elements.length > 0) {
			elements.forEach(element => {
				const label = <HTMLLabelElement>element.parentElement;

				label.classList.add(className);
			});
		}
	}

	private handleErrorUpdate() {
		if (this.control?.statusChanges) {
			const sub = this.control.statusChanges.subscribe(() => {
				this.updateErrorMessage();
			});

			this.subscriptions.push(sub);
		}
	}

	private updateErrorMessage(): void {
		const errors: ValidationErrors | null = this.control.errors;
		if (errors && this.control.touched) {
			const errorKeys = Object.keys(errors);
			if (errorKeys.length > 0) {
				const errorKey = errorKeys[0];

				const translateKey = this.options?.validation?.[errorKey] ? this.options.validation[errorKey] : `form_validation_${errorKey}`;

				this.error = this.customizer.translate(
					translateKey,
					Object.assign({}, { field: this.control.name }, errors[errorKey]) as TranslateParam
				);
			}
		} else {
			this.error = '';
		}
	}
}

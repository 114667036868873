import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { LossImpactAnalysisModel } from '../../models/loss-impact-analysis-model.model';
import { AnalysisLossBoundModel } from '../../models/analysis-loss-bound-model.model';

@Injectable()
export class AnalysisResultsDataService {
	constructor(private httpService: RQHttpService) {}

	public getLastAnalysis(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<LossImpactAnalysisModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<LossImpactAnalysisModel>('get', environment.api + '/api/Analysis/AnalysisResults/GetLastAnalysis', options)
			.toPromise();
	}

	public getLossBounds(analysisId: string, options = new HttpOptionsViewModel()): Promise<AnalysisLossBoundModel[]> {
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService
			.request<AnalysisLossBoundModel[]>('get', environment.api + '/api/Analysis/AnalysisResults/GetLossBounds', options)
			.toPromise();
	}
}

import * as d3 from 'd3';
import { EnterElement } from 'd3';

type BaseType = Element | EnterElement | Document | Window | null;

export class ChartRect {
	[name: string]: number;

	public left = 0;

	public top = 0;

	public width = 0;

	public height = 0;
}

export class ChartEvents {
	private subscriptions: { [key: string]: Function[] } = {};

	public on(event: string, callback: Function) {
		let eventArr: Array<Function> = this.subscriptions[event];
		if (!eventArr) {
			eventArr = this.subscriptions[event] = [];
		}

		eventArr.push(callback);

		return this;
	}

	public emit<T>(event: string, data: T) {
		const eventArr: Array<Function> = this.subscriptions[event];

		if (eventArr) {
			eventArr.forEach((func: Function) => {
				func(data);
			});
		}
	}
}

export class ChartCanvas<Element extends BaseType, Data, PElement extends BaseType, PData> {
	public selection!: d3.Selection<Element, Data, PElement, PData>;

	public rect = new ChartRect();
}

export class ChartLegendDefinitionModel {
	public titles: Array<string> = [];

	public values: Array<number> = [];
}

export class ChartContext {
	public svg = new ChartCanvas<SVGElement, undefined, HTMLElement, undefined>();

	public drawArea = new ChartCanvas<SVGGElement, undefined, SVGElement, undefined>();

	public events = new ChartEvents();

	public colors!: Array<string>;

	public isDebug!: boolean;
}

export class ChartConfig {
	public svg!: SVGConfig;
}

export class SVGConfig {
	public padding?: { [key: string]: number };
}

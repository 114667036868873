<ng-container *ngIf='isMessageVisible'>
  <p class='m-l-lg m-b-md m-t-md text-sm primary-l-10-color'>
    <span *ngIf='hasAnyRecommendation && !count'>
      {{'recommendation_tableHeader_noRecommendationsMessage' | translate}}
    </span>
    <span *ngIf='!hasAnyRecommendation'>
      {{'dashboard_control_no_recommendations_message' | translate }}
    </span>
  </p>
</ng-container>

<rq-table class="m-b-md m-t-md"
          *ngIf="data"
          [attr.hidden]="!hasAnyRecommendation ? 'hidden' : null"
          [dataSource]="data"
          [pagerState]="{ skip: 0, take: 5 }"
          [customFilters]="attackFilters"
          [hasFilters]="true"
          [count]="count"
          (filterChange)="load($event)"
          (sortChange)="load($event)"
          (pageChange)="load($event)">
  <rq-table-column *ngIf="isLegalEntityVisible"
                   field="legalEntityName"
                   filter="string"
                   title="global_legalEntity_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{dataItem.legalEntityName}}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="applicationNames"
                   filter="string"
                   title="businessApplications_applicationName_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span> {{dataItem.applicationDisplayName | translate}}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="recommendationNames"
                   filter="string"
                   [isSortable]="false"
                   title="global_controlsToImprove_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <p><strong>{{dataItem.titleDisplayName | translate}}</strong></p>
      <p> {{dataItem.recommendationDisplayName | translate}}</p>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="controlSubType"
                   [isFilterable]="false"
                   [isSortable]="false"
                   title="global_type_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <p *ngIf='!dataItem.controlSubType'> {{'global_notApplicable_label' | translate}}</p>
      <p *ngIf='dataItem.controlSubType'>{{dataItem.controlSubType | controlSubTypeLabel}}</p>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="upgrade"
                   [isFilterable]="false"
                   [isSortable]="false"
                   title="global_upgrade_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <rq-analysis-recommendation-level *ngIf='dataItem.proposedValue'
                                        [proposedValue]='dataItem.proposedValue'
                                        [hasEvidences]="dataItem.hasEvidences"
                                        [controlOptionId]='dataItem.controlOptionId'
                                        [oldValue]="dataItem.oldValue"
                                        [controlOptionName]="dataItem.recommendationDisplayName">
      </rq-analysis-recommendation-level>
      <p *ngIf='!dataItem.proposedValue'> {{'global_notApplicable_label' | translate}}</p>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="expectedLossReduction"
                   filter="range"
                   [isSortable]="false"
                   [filterSetup]="expectedLossReductionFilterSetup"
                   title="recommendation_financialReduction_title">
    <ng-template tableData
                 let-dataItem="dataItem">
      <p *ngIf='dataItem.expectedLossReduction !== null'> {{dataItem.expectedLossReduction | rqCurrency}}</p>
      <p *ngIf='dataItem.expectedLossReduction === null'> {{'global_notApplicable_label' | translate}}</p>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="expectedProbabilityReduction"
                   filter="range"
                   [isSortable]="false"
                   [filterSetup]="expectedProbabilityReductionFilterSetup"
                   title="recommendation_longTerm_psReduction">
    <ng-template tableData
                 let-dataItem="dataItem">
      <p *ngIf='dataItem.expectedProbabilityReduction !== null'> {{dataItem.expectedProbabilityReduction | rqPercent}}</p>
      <p *ngIf='dataItem.expectedProbabilityReduction === null'> {{'global_notApplicable_label' | translate}}</p>
    </ng-template>
  </rq-table-column>
</rq-table>

<section *ngIf="!count"
         class="p-lg">
  <p>{{'global_noData_available' | translate}}</p>
</section>

<section *ngIf="count"
         e2e-test="rq-dashboard-attack-container"
         class="row-content">
  <div>
    <rq-dashboard-top-threats class="top-threats"
                              [barChartData]="barChartData"
                              [xAxisLabel]="xAxisLabel"
                              [yAxisLabel]="'dashboard_topApplications_ale'">
    </rq-dashboard-top-threats>
  </div>
  <div class="application-threats-container">
    <rq-analysis-threats-table (loadData)='triggerLoadData($event)'
                               [data]="data"
                               [isLegalEntityVisible]="isLegalEntityVisible"
                               [count]="count">
    </rq-analysis-threats-table>
  </div>
</section>

import { AnalysisApplicationRiskFilterModel } from '../../../../server/models/analysis-application-risk-filter-model.model';
import { AnalysisApplicationRiskModel } from '../../../../server/models/analysis-application-risk-model.model';
import { Filter } from '../../../../standalone/components/form-elements/filter/filter.model';

export class ApplicationRiskDetailsTableViewModel extends AnalysisApplicationRiskModel {
	public 'legalEntityName'!: string;
}

export class ApplicationRiskDetailsFilterViewModel extends AnalysisApplicationRiskFilterModel {
	public 'legalEntityName'!: Filter<string>;
}

export class ApplicationRiskDetailsTableFilterOptionViewModel {
	public 'averageControlRatingMin'!: number;

	public 'averageControlRatingMax'!: number;

	public 'inherentRiskMin'!: number;

	public 'inherentRiskMax'!: number;

	public 'residualRiskMin'!: number;

	public 'residualRiskMax'!: number;
}

export class ApplicationRiskDetailsTableConfig {
	public isLegalEntityVisible?: boolean;

	public paginationCount?: number;

	public areFiltersHidden?: boolean;

	public isColumnSortingHidden?: boolean;
}

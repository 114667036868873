import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { FairLossMagnitudeType } from 'src/app/server/enums/fair-loss-magnitude-type.enum';

@Pipe({
	name: 'fairLossMagnitudeTypeLabel'
})
export class FairLossMagnitudeTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(type: FairLossMagnitudeType) {
		const values = new Map<FairLossMagnitudeType, string>();
		values.set(FairLossMagnitudeType.CompetitiveAdvantage, 'global_competitive_advantage');
		values.set(FairLossMagnitudeType.Replacement, 'global_replacement_label');
		values.set(FairLossMagnitudeType.Productivity, 'global_productivity_label');
		values.set(FairLossMagnitudeType.Response, 'global_response_label');
		values.set(FairLossMagnitudeType.FinesAndJudgement, 'fair_finesAndJudgement_label');
		values.set(FairLossMagnitudeType.Reputation, 'global_reputation_label');

		const value = values.get(type);
		if (!value) {
			throw new Error(`${type} is not defined`);
		}

		return this.translationService.get(value);
	}
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { IntegrationFileSettingsModel } from '../../models/integration-file-settings-model.model';
import { IntegrationFileSettingsCreateModel } from '../../models/integration-file-settings-create-model.model';
import { IntegrationFileSettingsUpdateModel } from '../../models/integration-file-settings-update-model.model';

@Injectable()
export class IntegrationFileSettingsDataService {
	constructor(private httpService: RQHttpService) {}

	public getByIntegrationSettingId(integrationId: string, options = new HttpOptionsViewModel()): Promise<IntegrationFileSettingsModel> {
		options.params = this.httpService.getHttpParams({
			integrationId
		});

		return this.httpService
			.request<IntegrationFileSettingsModel>(
				'get',
				environment.api + '/api/Integration/IntegrationFileSettings/GetByIntegrationSettingId',
				options
			)
			.toPromise();
	}

	public create(createModel: IntegrationFileSettingsCreateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = createModel;

		return this.httpService.request<void>('post', environment.api + '/api/Integration/IntegrationFileSettings/Create', options).toPromise();
	}

	public update(updateModel: IntegrationFileSettingsUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = updateModel;

		return this.httpService.request<void>('post', environment.api + '/api/Integration/IntegrationFileSettings/Update', options).toPromise();
	}
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CachingService } from 'src/app/system/services/caching.service';

const SIDEBAR_EXPANDED_KEY = 'sidebar-expanded';

@Injectable({
	providedIn: 'root'
})
export class SidebarStateStore {
	public readonly isSidebarExtended$: Observable<boolean>;

	private _isSidebarExtended$ = new BehaviorSubject<boolean>(false);

	constructor(private cachingService: CachingService) {
		const savedState = this.cachingService.getItem<boolean>(SIDEBAR_EXPANDED_KEY);
		if (savedState !== null) {
			this._isSidebarExtended$.next(savedState);
		} else {
			this.cachingService.setItem(SIDEBAR_EXPANDED_KEY, true, true);
		}

		this.isSidebarExtended$ = this._isSidebarExtended$.asObservable();
	}

	public get state() {
		return this._isSidebarExtended$.getValue();
	}

	public setSidebarState(isExpanded: boolean) {
		this._isSidebarExtended$.next(isExpanded);
		this.cachingService.setItem(SIDEBAR_EXPANDED_KEY, isExpanded, true);
	}
}

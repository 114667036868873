export enum IntegrationType {
	'OpenVAS' = 1,
	'Rapid7' = 2,
	'Tenable' = 3,
	'BeyondTrust' = 6,
	'Qualys' = 7,
	'Archer' = 8,
	'SureCloud' = 9,
	'SecurityScorecard' = 10,
	'Snowflake' = 11,
	'AppSoc' = 12,
	'WindowsDefender' = 13
}

import { PipeTransform, Pipe } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { CalculationType } from 'src/app/server/enums/calculation-type.enum';

@Pipe({
	name: 'calculationTypeLabel'
})
export class CalculationTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: CalculationType) {
		const values = new Map<CalculationType, string>();
		values.set(CalculationType.ResidualRiskAmount, 'global_residualRisk_label');
		values.set(CalculationType.InherentRiskAmount, 'global_inherentRisk_label');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${CalculationType[value]} does not exist`);
		}

		return this.translationService.get(type);
	}
}

<section class='input-container'
         (click)='triggerFocus()'
         [class.input-focusing]='isFocusing'
         [class.input-danger]='isDangerState'
         *ngIf='isEditState'>
  <ng-container *ngIf='isEditState'>
    <span class='prefix d-flex align-items-center'
          *ngIf='hasPrefix'>
      {{prefix}}
    </span>
    <input *ngIf='!isFormattedInputNumber'
           class='input-{{size}}'
           [type]='showPassword ? "text" : type'
           [class.input-prefix]='hasPrefix'
           [class.input-suffix]='hasSuffix'
           [placeholder]='placeholder'
           [disabled]='isDisabled'
           [(ngModel)]='innerValue'
           (ngModelChange)='valueChange()'
           (keyup.enter)='triggerEnter()'
           (blur)='triggerBlur()' />
    <input *ngIf='isFormattedInputNumber'
           type='text'
           mask="separator"
           thousandSeparator=","
           class='input-{{size}}'
           [allowNegativeNumbers]='areNegativeValuesAllowed'
           [dropSpecialCharacters]="false"
           [class.input-prefix]='hasPrefix'
           [class.input-suffix]='hasSuffix'
           [disabled]='isDisabled'
           [placeholder]='placeholder'
           [(ngModel)]='innerValue'
           (ngModelChange)='valueChange()'
           (keyup.enter)='triggerEnter()'
           (keydown.space)='$event.preventDefault()'
           (blur)='triggerBlur()' />
    <span class='suffix  d-flex align-items-center'
          *ngIf='hasSuffix'>
      <ng-container *ngIf="!isShowPasswordButtonVisible">
        {{suffix}}
      </ng-container>

      <ng-container *ngIf="isShowPasswordButtonVisible">
        <rq-icon class="pointer"
                 [href]="showPassword ? 'icon-eye-password' : 'icon-eye-password-off'"
                 (click)="showPasswordAction()"></rq-icon>
      </ng-container>
    </span>
  </ng-container>
</section>
<section class='input-edit-container'
         [class.input-right-align]="isEditValueRightAlign"
         [class.input-edit-danger]='isDangerState'
         *ngIf='!isEditState'>
  <span class='input-value d-flex align-items-center justify-content-end'>
    <ng-content></ng-content>
    <ng-container *ngTemplateOutlet="editTemplate; context: {value: value}"></ng-container>
  </span>
  <span *ngIf='!isLoadingVisible'
        class="icon input-edit-icon"
        [class.pointer]='!isDisabled'
        (click)='toggleEditState()'>
    <rq-icon href="icon-edit">
    </rq-icon>
  </span>
</section>
<rq-input-spinner class='input-spinner-container'
                  [isVisible]='isLoadingVisible'>
</rq-input-spinner>

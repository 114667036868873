import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CACHE_KEY } from '../../shared/configs/cache.config';
import { CachingService } from '../services/caching.service';

@Injectable({
	providedIn: 'root'
})
export class HttpHeaderInterceptorService implements HttpInterceptor {
	constructor(private cachingService: CachingService) {}

	public intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<string>> {
		if (this.isSecurityScorecardUrl(req)) {
			req = this.setDefaultHeaders(req);
		} else if (this.isApiUrl(req)) {
			req = this.setDefaultHeaders(req);
			req = req.clone({ headers: req.headers.set('Authorization', `Bearer ${this.cachingService.getItem<string>(CACHE_KEY.Token)}`) });
		}

		return next.handle(req);
	}

	private isApiUrl(req: HttpRequest<string>) {
		return req.url.includes('/api/') || req.url.toLocaleLowerCase().includes('/identity/');
	}

	private isSecurityScorecardUrl(req: HttpRequest<string>) {
		return req.url.includes(environment.thirdPartyApi.securityScorecard);
	}

	private setDefaultHeaders(req: HttpRequest<string>) {
		req = req.clone({ headers: req.headers.set('Cache-Control', 'no-cache') });
		req = req.clone({ headers: req.headers.set('Pragma', 'no-cache') });
		return req;
	}
}

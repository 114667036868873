import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { ControlProfileType } from 'src/app/server/enums/control-profile-type.enum';

@Pipe({
	name: 'controlProfileTypeLabel'
})
export class ControlProfileTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: ControlProfileType) {
		const values = new Map<ControlProfileType, string>();
		values.set(ControlProfileType.General, 'global_general_label');
		values.set(ControlProfileType.Application, 'global_application_label');
		values.set(ControlProfileType.Enterprise, 'global_enterprise_label');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${ControlProfileType[value]} does not exist`);
		}

		return this.translationService.get(type);
	}
}

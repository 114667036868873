import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../services/translation.service';

@Pipe({
	name: 'draftLabel'
})
export class DraftLabelTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: boolean) {
		const values = new Map<boolean, string>();
		values.set(true, 'global_draft_label');
		values.set(false, 'global_complete_label');

		const type = values.get(value);
		if (!type) {
			return '';
		}

		return this.translationService.get(type);
	}
}

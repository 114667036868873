<rq-table class="p-b-md p-t-md"
          *ngIf="data"
          [dataSource]="data"
          [pagerState]="{ skip: 0, take: 10 }"
          [hasFilters]="hasFilters"
          [count]="count"
          [isRowSelectable]="isRowSelectable"
          [selectedRowIndex]="selectedRowIndex"
          (rowClicked)="newTableTriggerRowClicked($event)"
          (filterChange)="load($event)"
          (sortChange)="load($event)"
          (pageChange)="load($event)">
  <rq-table-column field="value"
                   title="global_name_label"
                   [isSortable]="hasFilters"
                   [preselectedValue]="filter?.value?.value"
                   filter="string">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span *ngIf="dataItem.legalEntityName"
            class="badge-secondary-sm legal-entity-badge m-b-xs">{{ dataItem.legalEntityName }}</span>
      <div>
        <span *ngIf="dataItem.subNotificationsCount"
              class="counter">{{ dataItem.subNotificationsCount }}</span>
        <span e2e-test="notification-table-name"
              [ngClass]="hasActions && dataItem.isSeen === false ? 'unread-notification' : 'read-notification'">{{
					dataItem.value
				}}</span>
      </div>
    </ng-template>
  </rq-table-column>
  <rq-table-column field="createdDate"
                   [isSortable]="hasFilters"
                   title="global_created_label"
                   filter="calendar">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span [ngClass]="hasActions && dataItem.isSeen === false ? 'unread-notification' : 'read-notification'">{{
				dataItem.createdDate | rqDate : { pattern: customizer.dateTime.format }
			}}</span>
    </ng-template>
  </rq-table-column>
  <rq-table-column field="notificationType"
                   [isSortable]="hasFilters"
                   title="global_status_label"
                   filter="select"
                   [preselectedValue]="filter?.notificationType?.value"
                   [filterSetup]="notificationTypeFilter">
    <ng-template tableData
                 let-dataItem="dataItem">
      <div e2e-test="notification-table-status"
           [ngClass]="getBadgeType(dataItem.notificationType)">
        {{ dataItem.notificationType | notificationType }}
      </div>
    </ng-template>
  </rq-table-column>

  <ng-container *ngIf="isMarkAllVisible">
    <ng-template #rightHeaderTemplate>
      <div class="m-t-md m-b-md m-r-md">
        <button class="btn-secondary"
                (click)="markAllAsSeen()">
          {{ 'mark_all_as_read' | translate }}
        </button>
      </div>
    </ng-template>
  </ng-container>
</rq-table>

import {
	Component,
	ContentChild,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	TemplateRef,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import { EChartsType, init } from 'echarts';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { DonutChart } from './donut-chart.model';
import { FormatterType } from '../../../../shared/enums/formatter-type.enum';
import { ChartService } from '../chart.service';
import { COLORS } from '../../../utils/colors.utils';

@Component({
	selector: 'rq-donut-chart',
	templateUrl: './donut-chart.component.html',
	styleUrls: ['./donut-chart.component.scss'],
	providers: [ChartService]
})
export class DonutChartComponent implements OnChanges, OnInit, OnDestroy {
	@Input()
	public data!: DonutChart[];

	@Input('label.visible')
	public isLabelVisible = true;

	@Input('value.formatter')
	public valueFormatter?: FormatterType | string;

	@Input('chartTitle')
	public chartTitle?: string;

	@Input('chartSubtitle')
	public chartSubtitle?: string;

	@Input('selectedItem')
	public selectedItem?: string;

	@Input()
	public showLegend = false;

	@Input()
	public centerUpperText!: string;

	@Input()
	public centerLowerText!: string;

	@Output()
	public readonly sliceSelected = new EventEmitter<DonutChart>();

	@ContentChild('customTooltip')
	public customTooltip?: TemplateRef<{ marker: string; percent: number; value: number; label: string }>;

	@ViewChild('chart', { static: true })
	private chartContainer!: ElementRef;

	@ViewChild('tooltipContainer', { static: true, read: ViewContainerRef })
	private tooltipContainer!: ViewContainerRef;

	private chart?: EChartsType;

	private isResizedForRelativeSizes = false;

	private resizeObserver!: ResizeObserver;

	constructor(private hostElement: ViewContainerRef, private chartService: ChartService) {}

	@HostListener('window:resize')
	public onResize() {
		if (this.chart) {
			this.chart.resize();
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.data) {
			this.setData(changes.data.currentValue as DonutChart[]);
		}

		if (changes.selectedItem && this.data) {
			this.chart?.setOption({ animation: false });
			this.chart?.setOption({ series: this.getSeries(this.data) });
			this.chart?.setOption({ animation: true });
		}
	}

	public ngOnInit() {
		this.subscribeToResizeLayoutChange();
	}

	public ngOnDestroy() {
		this.resizeObserver.disconnect();
	}

	private setData(data: DonutChart[]) {
		if (data) {
			if (!this.chart) {
				this.chart = init(this.chartContainer.nativeElement as HTMLElement);
			} else {
				this.chart?.clear();
			}

			const options = this.getChartOptions(data);

			this.chart.setOption(options);

			this.chart.on('rendered', () => {
				if (!this.isResizedForRelativeSizes) {
					this.isResizedForRelativeSizes = true;
					this.chart?.resize();
				}
			});
			this.chart.on('click', params => {
				const item = this.data[params.dataIndex];
				this.sliceSelected.emit(item);
			});
		} else {
			this.chart?.dispose();
		}
	}

	private getChartOptions(data: DonutChart[]) {
		const fontSizeLG = getComputedStyle(document.documentElement).getPropertyValue('--font-size-lg');
		const truncateCenterText = this.chartContainer.nativeElement.offsetWidth / 10 - 8;

		return {
			title: {
				text: this.chartTitle,
				subtext: this.chartSubtitle,
				left: 'center'
			},
			color: COLORS.rainbowColorScheme,
			grid: this.getGridConfig(),
			legend: {
				show: this.showLegend,
				selectedMode: false,
				bottom: '5%'
			},
			tooltip: {
				show: true,
				confine: true,
				formatter: ({ marker, percent, name, value }: CallbackDataParams) => {
					if (this.customTooltip) {
						this.tooltipContainer.createEmbeddedView(this.customTooltip, { marker, percent, value, label: name });
						return this.tooltipContainer.element.nativeElement.parentNode.lastElementChild as HTMLElement;
					}

					return `${marker} ${name} - ${this.chartService.getFormatter(this.valueFormatter)(value as number)}`;
				}
			},
			series: this.getSeries(data),
			graphic: {
				elements: [
					{
						type: 'text',
						left: 'center',
						top: '46%',
						z: 999,
						silent: true,
						style: {
							oppacity: this.centerUpperText ? 100 : 0,
							fill: COLORS.primaryText,
							font: 'Roboto',
							text: this.centerUpperText,
							textAlign: 'center',
							fontWeight: 500,
							fontSize: fontSizeLG
						}
					},
					{
						type: 'text',
						left: 'center',
						top: '52%',
						silent: true,
						z: 999,
						style: {
							oppacity: this.centerLowerText ? 100 : 0,
							fill: COLORS.secondaryText,
							font: 'Roboto',
							text: this.centerLowerText ? this.truncateText(this.centerLowerText, truncateCenterText) : '',
							textAlign: 'center',
							fontSize: 14
						}
					}
				]
			}
		};
	}

	private truncateText(text: string, ellipsis: number) {
		const truncate = '...';

		if (text.length > ellipsis) {
			return text.slice(0, ellipsis - truncate.length).concat(truncate);
		} else {
			return text;
		}
	}

	private getSeries(data: DonutChart[]) {
		return {
			type: 'pie',
			radius: ['50%', '70%'],
			center: ['50%', '50%'],
			height: '90%',
			width: '100%',
			top: 'center',
			left: 'center',
			label: {
				show: this.isLabelVisible,
				alignTo: 'edge',
				minMargin: 5,
				edgeDistance: 0,
				width: 64,
				verticalAlign: 'top',
				overflow: 'break'
			},
			data: data.map((x, i) => ({
				name: x.label,
				value: x.value,
				itemStyle: {
					color: x?.color ?? this.getItemColor(i),
					borderWidth: 2.5,
					borderColor: 'white',
					borderRadius: '3%',
					opacity: this.getOpacity(x.isSelected)
				}
			}))
		};
	}

	private getOpacity(isSelected?: boolean) {
		let opacity;

		if (this.selectedItem) {
			opacity = isSelected ? 1 : 0.4;
		} else {
			opacity = 1;
		}

		return opacity;
	}

	private getGridConfig() {
		return {
			top: 40,
			bottom: 55,
			left: 70,
			right: 20
		};
	}

	private getItemColor(index: number) {
		if (index >= COLORS.rainbowColorScheme.length) {
			return 'black';
		} else {
			return COLORS.rainbowColorScheme[index];
		}
	}

	private subscribeToResizeLayoutChange() {
		this.resizeObserver = new ResizeObserver(() => {
			this.chart?.resize();
		});

		this.resizeObserver.observe(this.hostElement?.element?.nativeElement as Element);
	}
}

import { Component, Input } from '@angular/core';
import { BarChart } from '../../../standalone/components/charts/bar-chart/models/bar-chart.model';

@Component({
	selector: 'rq-dashboard-top-threats',
	templateUrl: './dashboard-top-threats.component.html',
	styleUrls: ['./dashboard-top-threats.component.scss']
})
export class DashboardTopThreatsComponent {
	@Input()
	public barChartData: BarChart[] = [];

	@Input()
	public xAxisLabel!: string;

	@Input()
	public yAxisLabel!: string;
}

<div class="comparer"
     [ngClass]="{'comparer-expanded': isExpanded}">
  <ng-container *ngIf="isExpanded">
    <div class="expanded-wrapper">
      <ng-container *ngFor="let item of createRange(); let i = index">
        <rq-comparer-card [item]="getItemToCompare(i)"
                          (deleted)="remove($event)"></rq-comparer-card>
      </ng-container>
      <span class="b b-right m-bottom m-top m-xs"></span>
    </div>

  </ng-container>

  <rq-comparer-button [isExpanded]="isExpanded"
                      (comparationRequested)="compare()"
                      [config]="buttonConfig"
                      [buttonEnabled]="itemsToCompare.length > 0">
  </rq-comparer-button>
</div>

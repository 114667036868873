<header></header>
<article>
  <ul *ngFor="let i of [].constructor(10)"
      class="items">
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </ul>
</article>
<footer></footer>

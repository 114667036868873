<ng-container *ngIf='isMessageVisible'>
  <p class='m-l-lg m-b-md m-t-md text-sm primary-l-10-color'>
    {{ noRecommendationMessage | translate }}
  </p>
</ng-container>

<rq-table class="m-b-md m-t-md"
          *ngIf="data"
          [attr.hidden]="isMessageVisible ? 'hidden' : null"
          [dataSource]="data"
          [pagerState]="{ skip: 0, take: 5 }"
          [hasFilters]="hasFilters"
          [count]="count"
          (filterChange)="load($event)"
          (sortChange)="load($event)"
          (pageChange)="load($event)">

  <rq-table-column *ngIf="isLegalEntityVisible"
                   [isSortable]="isSortable"
                   field="legalEntityName"
                   filter="string"
                   title="global_legalEntity_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{dataItem.legalEntityName}}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="name"
                   [isSortable]="isSortable"
                   filter="string"
                   title="global_controls_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <p><strong>{{dataItem.title | translate}}</strong></p>
      <p> {{dataItem.displayName | translate}}</p>
    </ng-template>
  </rq-table-column>

  <rq-table-column *ngIf="isImprovementVisible"
                   [isSortable]="isSortable"
                   field="improvements"
                   filter="string"
                   title="global_improves_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <p *ngIf='dataItem.improvement'> {{dataItem.improvement?.displayName }}</p>
      <p *ngIf='!dataItem.improvement'> {{'global_notApplicable_label' | translate}}</p>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="controlSubType"
                   [isFilterable]="false"
                   [isSortable]="false"
                   title="global_type_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <p *ngIf='!dataItem.controlSubType'> {{'global_notApplicable_label' | translate}}</p>
      <p *ngIf='dataItem.controlSubType'>{{dataItem.controlSubType | controlSubTypeLabel}}</p>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="proposedValue"
                   [isFilterable]="false"
                   [isSortable]="false"
                   title="global_upgrade_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <rq-analysis-recommendation-level *ngIf='dataItem.proposedValue'
                                        [proposedValue]='dataItem.proposedValue'
                                        [oldValue]="dataItem.oldValue"
                                        [hasEvidences]="dataItem.hasEvidences"
                                        [controlOptionId]="dataItem.controlOptionId"
                                        [controlOptionName]="dataItem.displayName">
      </rq-analysis-recommendation-level>
      <p *ngIf='!dataItem.proposedValue'> {{'global_notApplicable_label' | translate}}</p>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="expectedLossReduction"
                   filter="range"
                   [isSortable]="isSortable"
                   [filterSetup]="expectedLossReductionFilterSetup"
                   title="recommendation_financialReduction_title">
    <ng-template tableData
                 let-dataItem="dataItem">
      <p *ngIf='dataItem.expectedLossReduction'> {{dataItem.expectedLossReduction | rqCurrency}}</p>
      <p *ngIf='!dataItem.expectedLossReduction'> {{'global_notApplicable_label' | translate}}</p>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="expectedLossProbabilityReduction"
                   filter="range"
                   [isSortable]="isSortable"
                   [filterSetup]="expectedProbabilityReductionFilterSetup"
                   title="recommendation_longTerm_psReduction">
    <ng-template tableData
                 let-dataItem="dataItem">
      <p *ngIf='dataItem.expectedLossProbabilityReduction !== null'> {{dataItem.expectedLossProbabilityReduction | rqPercent}}</p>
      <p *ngIf='dataItem.expectedLossProbabilityReduction === null'> {{'global_notApplicable_label' | translate}}</p>
    </ng-template>
  </rq-table-column>

  <rq-table-column *ngIf="isActionsColumnVisible"
                   field="actions"
                   [isSortable]="false"
                   [isFilterable]="false">
    <ng-template tableData
                 let-dataItem="dataItem">
      <rq-table-actions [numberOfVisibleActions]="0">
        <ng-template tableAction>
          <rq-table-action-item [class.disabled]="!dataItem.hasTTPs"
                                (click)="triggerViewDetails(dataItem)">
            {{'see_more_details' | translate}}
          </rq-table-action-item>
        </ng-template>
      </rq-table-actions>
    </ng-template>
  </rq-table-column>
</rq-table>

<rq-applications-modal></rq-applications-modal>

<ng-container *ngIf='isNirvanaMessageVisible'>
  <p class='m-l-lg m-b-md m-t-md text-sm primary-l-10-color'>
    {{ noRecommendationMessage | translate }}
  </p>
</ng-container>

<rq-table class="m-b-md m-t-md"
          *ngIf="mappedTableData"
          [attr.hidden]="isNirvanaMessageVisible ? 'hidden' : null"
          [dataSource]="mappedTableData"
          [pagerState]="{ skip: 0, take: 5 }"
          [hasFilters]="true"
          [count]="count"
          (filterChange)="load($event)"
          (sortChange)="load($event)"
          (pageChange)="load($event)">
  <rq-table-column *ngIf="isLegalEntityVisible"
                   field="legalEntityName"
                   filter="string"
                   title="global_legalEntity_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{dataItem.legalEntityName}}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="name"
                   filter="string"
                   title="global_controls_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <p><strong>{{dataItem.title | translate}}</strong></p>
      <p> {{dataItem.displayName | translate}}</p>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="expectedLossReduction"
                   filter="range"
                   [filterSetup]="expectedLossReductionFilterSetup"
                   title="recommendation_financialReduction_title">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{dataItem.expectedLossReduction | rqCurrency}}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="implementation"
                   filter="range"
                   [textAlign]="'right'"
                   [filterSetup]="implementationFilterSetup"
                   title="recommendation_tableTitle_implementationCost">
    <ng-template tableData
                 let-dataItem="dataItem">
      <div class='form-group'
           (click)='$event.stopPropagation()'>
        <form class='implementation-cost-form'
              *ngIf="formGroup"
              [formGroup]='formGroup'>
          <rq-form-control class='m-b-none m-b-none'
                           [options]='formGroupOptions'>
            <div class="implementation-cost-edit">
              <rq-input type='number'
                        size='sm'
                        class='m-none'
                        [class.cost-edit]='!dataItem.isEditState'
                        [isEditState]='dataItem.isEditState'
                        rqAuthorize
                        [state]="authorizeState"
                        (focus)='triggerFocus(dataItem)'
                        (blur)='triggerChange(dataItem)'
                        formControlName='{{dataItem.recommendationId}}'>
                <ng-template let-value="value">
                  {{value | rqCurrency}}
                </ng-template>
              </rq-input>
            </div>
          </rq-form-control>
        </form>
      </div>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="roi"
                   filter="range"
                   [filterSetup]="roiFilterSetup"
                   title="recommendation_tableTitle_roi">
    <ng-template tableData
                 let-dataItem="dataItem">
      <p *ngIf='dataItem.isRoiComputing'>
        <span>{{ 'global_Calculating' | translate }}...</span>
      </p>
      <p *ngIf='!dataItem.isRoiComputing '>
        {{dataItem.roi | rqPercent}}
      </p>
      <p *ngIf='!dataItem.isRoiComputing && !dataItem.cost && dataItem.cost!==0'>
        {{'recommendation_tableRoiColumn_provideCost' | translate}}
      </p>
    </ng-template>
  </rq-table-column>
</rq-table>

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ReportModel } from '../../models/report-model.model';

@Injectable()
export class ReportDataService {
	constructor(private httpService: RQHttpService) {}

	public generate(analysisId: string, model: ReportModel, options = new HttpOptionsViewModel()): Promise<Blob> {
		options.responseType = options.responseType ? options.responseType : 'blob';
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		options.body = model;

		return this.httpService.request<Blob>('post', environment.api + '/api/Reporting/Report/Generate', options).toPromise();
	}
}

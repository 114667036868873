import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
	CalendarFilter,
	DropdownFilter,
	Filter,
	FilterBase,
	FilterCalendarValue,
	FilterConfig,
	FilterOutput,
	FilterType,
	NumericFilter,
	RangeFilter,
	SelectFilter
} from './filter.model';

@Component({
	selector: 'rq-filter',
	templateUrl: './filter.component.html',
	styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnChanges {
	@Input()
	public config: FilterConfig[] = [];

	@Input()
	public filters = {} as unknown;

	@Input()
	public label = 'global_filterBy_label';

	@Input()
	public isDisabled = false;

	@Output()
	public readonly filterChange = new EventEmitter();

	public filterType = FilterType;

	public get isLabelVisible() {
		return Boolean(this.label) && this.config.length > 0;
	}

	public get filterOutput() {
		return this.filters as FilterOutput;
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.config?.currentValue) {
			this.setupFilter();
		}
	}

	public triggerValueChange() {
		this.config.forEach(config => {
			config.filter.value = this.filterOutput[config.name].value;
		});

		this.filterChange.emit(this.filterOutput);
	}

	public selectFilter(value: FilterBase) {
		return value as SelectFilter;
	}

	public rangeFilter(value: FilterBase) {
		return value as RangeFilter;
	}

	public dropdownFilter(value: FilterBase) {
		return value as DropdownFilter;
	}

	public calendarFilter(value: FilterBase) {
		return value as CalendarFilter;
	}

	public numberFilter(value: FilterBase) {
		return value as NumericFilter;
	}

	private setupFilter() {
		this.config.forEach(config => {
			if (!this.filterOutput[config.name]) {
				this.filterOutput[config.name] = new Filter<unknown>();
				this.filterOutput[config.name].value = config.filter.value;
			}

			if (config.filter) {
				const defaultPlaceholder = config.filter.placeholder ? config.filter.placeholder : 'global_all_label';
				config.filter.placeholder =
					config.filter.type === FilterType.Search && !config.filter.placeholder ? config.filter.displayName : defaultPlaceholder;

				this.filterOutput[config.name].value =
					config.filter.type === FilterType.Calendar && !this.filterOutput[config.name].value
						? new FilterCalendarValue()
						: this.filterOutput[config.name].value;
			}
		});
	}
}

import { PipeTransform, Pipe } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { TechnicalRiskScaleType } from 'src/app/server/enums/technical-risk-scale-type.enum';

@Pipe({
	name: 'technicalRiskScoreTypeLabel'
})
export class TechnicalRiskScoreTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: TechnicalRiskScaleType) {
		const values = new Map<TechnicalRiskScaleType, string>();
		values.set(TechnicalRiskScaleType.Low, 'global_low_risk_label');
		values.set(TechnicalRiskScaleType.Medium, 'global_medium_risk_label');
		values.set(TechnicalRiskScaleType.High, 'global_high_risk_label');
		values.set(TechnicalRiskScaleType.Critical, 'global_critical_risk_label');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${value} does not exist`);
		}

		return this.translationService.get(type);
	}
}

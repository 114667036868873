import { Component, Input } from '@angular/core';

@Component({
	selector: 'rq-summary-count',
	templateUrl: './summary-count.component.html',
	styleUrls: ['./summary-count.component.scss']
})
export class SummaryCountComponent {
	@Input() public value = 0;

	@Input() public title!: string;
}

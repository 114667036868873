import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { LossExposureType } from 'src/app/server/enums/loss-exposure-type.enum';

@Pipe({
	name: 'lossExposureTypeLabel'
})
export class LossExposureTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: LossExposureType) {
		const values = new Map<LossExposureType, string>();
		values.set(LossExposureType.Contract, 'global_custom_label');
		values.set(LossExposureType.Revenue, 'global_revenue_label');
		values.set(LossExposureType.IPTheft, 'contractProfileDropdown_IPTheft_label');
		values.set(LossExposureType.Reputation, 'contractProfileDropdown_reputation_label');
		values.set(LossExposureType.IndirectOperation, 'contractProfileDropdown_indirectOperational_label');
		values.set(LossExposureType.TPData, 'contractProfileDropdown_data_label');
		values.set(LossExposureType.DataRecords, 'global_dataRecords_label');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${LossExposureType[value]} does not exist`);
		}

		return this.translationService.get(type);
	}
}

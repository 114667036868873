import { ChangeDetectionStrategy, Component, ContentChild, HostBinding, Input, TemplateRef } from '@angular/core';
import { PageTitleActionDirective } from './page-title-action-directive';

@Component({
	selector: 'rq-page-title',
	templateUrl: './page-title.component.html',
	styleUrls: ['./page-title.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTitleComponent {
	@HostBinding('class.page-header')
	public hasDefaultClass = true;

	@ContentChild(TemplateRef)
	public customTemplate!: TemplateRef<unknown>;

	@ContentChild(PageTitleActionDirective)
	public pageTitleActionDirective!: PageTitleActionDirective;

	@Input()
	public title!: string;

	@Input()
	public isLoading = false;
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { RQ_ROUTES } from '../../../configs/routes.config';
import { UserViewModel } from '../../../models/user-edit-form.model';
import { ApplicationInstanceStore } from 'src/app/shared/core/application.service';
import { LegalEntityStore } from 'src/app/routes/control-analysis/_common/store/legal-entity.store';
import { IdentityService } from '../../../services/identity.service';

@Component({
	selector: 'rq-user-popup',
	templateUrl: './user-popup.component.html',
	styleUrls: ['./user-popup.component.scss']
})
export class UserPopupComponent implements OnChanges, OnInit {
	@Output()
	public readonly logoutAction = new EventEmitter();

	@Output()
	public readonly closeModal = new EventEmitter();

	@Input()
	public user!: UserViewModel;

	@Input()
	public userInitials!: string;

	public userName!: string;

	public userRole!: string;

	public areActionsVisible!: boolean;

	public isActivityLogVisible!: boolean;

	constructor(
		private applicationInstanceStore: ApplicationInstanceStore,
		private router: Router,
		private legalEntityStore: LegalEntityStore,
		private identityService: IdentityService
	) {}

	public ngOnChanges(changes: SimpleChanges) {
		const user = changes.user?.currentValue as UserViewModel;

		if (user) {
			this.userName = user.name;
			this.userRole = user.roles.map(x => x.name).join(', ');
		}
	}

	public ngOnInit() {
		this.areActionsVisible = this.applicationInstanceStore.isEnterprise || this.applicationInstanceStore.isPro;
		this.isActivityLogVisible = !this.identityService.isProReadOnly || !this.identityService.isReadOnly;
	}

	public logout() {
		this.logoutAction.emit();
	}

	public navigateToActivityLog() {
		if (this.applicationInstanceStore.isEnterprise) {
			this.router.navigateByUrl(RQ_ROUTES.enterprise.user.activityLog.url());
		} else if (this.applicationInstanceStore.isPro) {
			this.router.navigateByUrl(RQ_ROUTES.pro.user.activityLog.url(this.legalEntityStore.legalEntity?.id));
		}

		this.closeModal.emit();
	}

	public navigateToPreferences() {
		if (this.applicationInstanceStore.isEnterprise) {
			this.router.navigateByUrl(RQ_ROUTES.enterprise.user.preferences.url());
		} else if (this.applicationInstanceStore.isPro) {
			this.router.navigateByUrl(RQ_ROUTES.pro.user.preferences.url(this.legalEntityStore.legalEntity?.id));
		}

		this.closeModal.emit();
	}
}

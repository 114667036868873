/* eslint-disable */
import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[e2e-test]'
})
export class E2eDirective {
	@Input('e2e-test')
	public set e2eValue(value: string) {
		this.el.nativeElement.setAttribute('data-e2e-test', value);
	}

	constructor(private el: ElementRef) {}
}

<rq-filter *ngIf="filterConfig && hasFilters"
           class="filters p-l-lg p-t-sm p-b-sm"
           [config]="filterConfig"
           [isDisabled]="isLoading"
           (filterChange)="triggerFilterChange($event)">
</rq-filter>

<div class="list-container m-b-md">
  <div class="header-row">
    <div *ngFor="let header of listHeaderDirective"
         class="header-column"
         [class.small-header-column]="header.isSmallHeader">
      <ng-template [ngTemplateOutlet]="header.template">
      </ng-template>
    </div>
  </div>

  <ng-container *ngIf="hasData">
    <div class="list-row"
         *ngFor="let item of data">
      <ng-container *ngIf="!isLoading">
        <ng-template [ngTemplateOutlet]="listContentDirective.template"
                     [ngTemplateOutletContext]="{ dataItem: item }">
        </ng-template>
      </ng-container>

      <ng-container *ngIf="isLoading">
        <rq-loading-box class="loader-container"
                        [type]="LoadingBoxType.Card">
        </rq-loading-box>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="isFirstLoadingVisible">
    <ng-template *ngTemplateOutlet="firstLoadingTemplate"></ng-template>
  </ng-container>

  <ng-container *ngIf="isMessageVisible">
    <ng-template *ngTemplateOutlet="emptyListTemplate"></ng-template>
  </ng-container>
</div>

<section class="m-b-md"
         *ngIf="isPagerConfigured">
  <rq-pager (pageChange)="triggerPageChange($event)"
            [config]="pagerConfig"
            [count]="count"
            [state]="pagerState">
  </rq-pager>
</section>


<ng-template #firstLoadingTemplate>
  <div class="list-row"
       *ngFor="let item of [].constructor(5)">
    <rq-loading-box class="loader-container"
                    [type]="LoadingBoxType.Card">
    </rq-loading-box>
  </div>
</ng-template>

<ng-template #emptyListTemplate>
  <div class="list-row">
    <span>{{'global_table_noData_with_filter' | translate}}</span>
  </div>
</ng-template>

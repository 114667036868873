import { ChangeDetectionStrategy, Component, ContentChildren, QueryList } from '@angular/core';
import { TableActionDirective } from '../../table-action.directive';

@Component({
	selector: 'rq-table-more-actions',
	templateUrl: './table-more-actions.component.html',
	styleUrls: ['./table-more-actions.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableMoreActionsComponent {
	@ContentChildren(TableActionDirective)
	public moreActions!: QueryList<TableActionDirective>;
}

<label class="filter-label"><strong>{{displayName}}</strong></label>
<header class="filter-dropdown-header"
        [class.filter-dropdown-disabled]="isDisabled"
        (click)="toggleVisibility()">
  <span class="filter-dropdown-title">{{placeholder}}</span>
  <span class="filter-icon">
    <span class="filter-dropdown-icon"></span>
    <span class="filter-dropdown-icon"></span>
  </span>
</header>
<section class="filter-dropdown-options"
         *ngIf="isExpanded">
  <rq-filter-search *ngIf="searchBoxConfig"
                    class="search-wrapper"
                    [(ngModel)]="searchBoxConfig.searchValue"
                    [isDisabled]="isDisabled"
                    [trigger]="searchBoxConfig.trigger"
                    placeholder="{{searchBoxConfig.placeholder | translate}}">
  </rq-filter-search>
  <ng-content *ngIf="!options"></ng-content>
  <ng-container *ngIf="options">
    <ng-container *ngFor="let option of options">
      <ng-container *ngIf="isPartOfSearch(option)">
        <p class="p-xs dropdown-option"
           [class.dropdown-option-active]="option.isSelected"
           (click)="triggerChange(option)"
           [rqEllipsis]='option.displayName'
           [charactersCountLimit]='25'>
        </p>
      </ng-container>
    </ng-container>
  </ng-container>
</section>

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';

@Injectable()
export class UserRolesDataService {
	constructor(private httpService: RQHttpService) {}

	public getByUser(options = new HttpOptionsViewModel()): Promise<string[]> {
		return this.httpService.request<string[]>('get', environment.api + '/api/Identity/UserRoles/GetByUser', options).toPromise();
	}
}

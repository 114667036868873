import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { FairThreatCapabilityType } from 'src/app/server/enums/fair-threat-capability-type.enum';

@Pipe({
	name: 'threatCapabilityTypeLabel'
})
export class ThreatCapabilityTypeLabelTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(type: FairThreatCapabilityType) {
		const values = new Map<FairThreatCapabilityType, string>();
		values.set(FairThreatCapabilityType.Moderate, 'threatCapability_moderate_label');
		values.set(FairThreatCapabilityType.Minimal, 'threatCapability_minimal_label');
		values.set(FairThreatCapabilityType.Advanced, 'threatCapability_advanced_label');

		const value = values.get(type);
		if (!value) {
			throw new Error(`${type} is not defined`);
		}

		return this.translationService.get(value);
	}
}

import { StandardBase } from '../../../../routes/control-analysis/_common/standard.base';
import { Component, Input, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import {
	LTRecommendationAnalysisApplicationViewModel,
	LTRecommendationAnalysisViewModel
} from '../../../../routes/control-analysis/_common/models/recommendations-analysis-view.viewmodel';
import { ApplicationListModalComponent } from './application-list-modal/application-list-modal.component';
import { EvidencesDetailsModalComponent } from '../../evidences-details-modal/evidences-details-modal.component';
import { ControlFrameworkDescriptionModel } from '../../../../server/models/control-framework-description-model.model';
import { DefensebilityDeltaViewModel } from '../../../../server/models/defensebility-delta-view-model.model';

@Component({
	selector: 'rq-step-where',
	templateUrl: './step-where.component.html',
	styleUrls: ['./step-where.component.scss']
})
export class StepWhereComponent extends StandardBase implements OnChanges {
	@ViewChild(ApplicationListModalComponent, { static: true })
	public applicationListModal!: ApplicationListModalComponent;

	@ViewChild(EvidencesDetailsModalComponent, { static: true })
	public evidencesDetailsModal!: EvidencesDetailsModalComponent;

	@Input()
	public selectedItem!: LTRecommendationAnalysisViewModel;

	@Input()
	public controlLevelDescriptions!: Array<ControlFrameworkDescriptionModel>;

	@Input()
	public defensebilityDelta?: DefensebilityDeltaViewModel;

	public applications!: Array<LTRecommendationAnalysisApplicationViewModel>;

	public multipleData!: DefensebilityDeltaViewModel;

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.selectedItem?.currentValue) {
			this.mapData(changes.selectedItem?.currentValue as LTRecommendationAnalysisViewModel);
		}
	}

	public showMore() {
		this.applicationListModal.show();
	}

	public showDetails() {
		if (this.defensebilityDelta) {
			this.multipleData = { ...this.defensebilityDelta };
		}
		this.evidencesDetailsModal.show();
	}

	private mapData(selectedItem: LTRecommendationAnalysisViewModel) {
		this.applications = selectedItem.appplications.map(item => {
			return <LTRecommendationAnalysisApplicationViewModel>{
				label: item.displayName,
				reduction: item.expectedLossReduction,
				changeInPs: item.expectedLossProbabilityReduction,
				sleReduction: item.expectedSLEReduction
			};
		});
	}
}

import { ChangeDetectionStrategy, Component, ContentChild, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CardHeaderDirective } from './card-header.directive';
import { CardTitleDirective } from './card-title.directive';
import { WidgetContentDirective } from './card-content.directive';
import { CardActionDirective } from './card-action.directive';
import { CardNoDataContentDirective } from './card-no-data-content.directive';

@Component({
	selector: 'rq-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent implements OnChanges {
	@HostBinding('class.page-full-h')
	@Input()
	public isPage = false;

	@Input()
	public isContent = false;

	@Input()
	public minLoadingHeight = '500px';

	@Input()
	public title!: string;

	@Input()
	public isLoaded!: unknown;

	@Input()
	public isNoDataVisible = false;

	@ContentChild(CardHeaderDirective)
	public customHeader?: CardHeaderDirective;

	@ContentChild(CardTitleDirective)
	public customTitle?: CardTitleDirective;

	@ContentChild(WidgetContentDirective)
	public widgetContent?: WidgetContentDirective;

	@ContentChild(CardNoDataContentDirective)
	public noDataContent?: CardNoDataContentDirective;

	@ContentChild(CardActionDirective)
	public customAction?: CardActionDirective;

	public isLoading = true;

	@HostBinding('class.bordered')
	public get isBordered() {
		return !this.isPage && !this.isContent;
	}

	@HostBinding('class.flex-grow')
	public get hasFlexHeight() {
		return this.isPage || this.isContent;
	}

	@HostBinding('style.min-height')
	public get hostMinHeight(): string {
		return this.isLoading ? this.minLoadingHeight : '50px';
	}

	public ngOnChanges(changes: SimpleChanges) {
		const isLoaded = changes.isLoaded?.currentValue as unknown;
		if (isLoaded !== undefined) {
			if (typeof isLoaded === 'boolean') {
				this.isLoading = !isLoaded;
			} else if (isLoaded === null) {
				this.isLoading = true;
			} else {
				this.isLoading = false;
			}
		}
	}
}

import { AfterContentInit, Component, ContentChildren, EventEmitter, HostBinding, Input, Output, QueryList } from '@angular/core';
import { TabComponent } from './tab/tab.component';
import { BaseComponent } from '../../../shared/components/base.component';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'rq-tabs',
	templateUrl: './tabs.component.html',
	styleUrls: ['./tabs.component.scss']
})
export class TabsComponent<T> extends BaseComponent implements AfterContentInit {
	@Output()
	public readonly tabChange = new EventEmitter<unknown | null>();

	@ContentChildren(TabComponent)
	public tabs!: QueryList<TabComponent<T>>;

	@Input()
	public scope: 'tab' | 'filter' | 'page' = 'tab';

	@Input()
	public keepTabContent = true;

	@Input()
	public displayType: 'inline' | 'block' = 'inline';

	private isInternalNavigation = false;

	constructor(private router: Router) {
		super();

		this.subscriptions.push(
			this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
				if (!this.isInternalNavigation) {
					this.tabChange.emit(null);
				}
				this.isInternalNavigation = false;
			})
		);
	}

	@HostBinding('class.flex-height')
	public get hasFlexHeight() {
		return this.scope !== 'filter';
	}

	public ngAfterContentInit(): void {
		const activeTabs = this.tabs.filter(tab => tab.active);
		if (activeTabs.length === 0 && this.tabs.length > 0) {
			this.tabs.first.active = true;
		}
	}

	public selectTab(selectedTab: TabComponent<T>): void {
		if (!selectedTab.isDisabled) {
			this.tabs.forEach(tab => {
				tab.active = selectedTab === tab;
			});

			this.triggerTabChangeEvent();
		}
	}

	private triggerTabChangeEvent() {
		const activeTab = this.tabs.find(x => x.active);
		this.isInternalNavigation = true;

		if (activeTab) {
			this.tabChange.emit(activeTab.tabId);
		}
	}
}

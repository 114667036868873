import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ComparisonService {
	public triggerHover = new Subject<{ rowIndex?: number; groupIndex?: number }>();

	public triggerClick = new Subject<number>();

	public triggerLoading = new Subject<{ isLoading: boolean; filter?(data: unknown, columnIndex: number): boolean }>();
}

import { Pipe, PipeTransform } from '@angular/core';
import { PercentPipeConfig } from './models/percent-pipe.model';
import { isNumber } from '../utils/helpers.utils';
import { NumberFormatterService } from '../../shared/services/number-formatter.service';
import { PercentConfig } from '../../shared/models/number.config';

@Pipe({
	name: 'rqPercent',
	standalone: true
})
export class RqPercentPipe implements PipeTransform {
	constructor(private numberFormatter: NumberFormatterService) {}

	public transform(value?: number, config?: PercentPipeConfig): string {
		const percentConfig = Object.assign(<PercentConfig>{}, config);

		return isNumber(value) ? this.numberFormatter.percent(<number>value, percentConfig) : '';
	}
}

import { TranslationService } from 'src/app/shared/services/translation.service';
import { PipeTransform, Pipe } from '@angular/core';
import { ControlSubType } from '../../../server/enums/control-sub-type.enum';

@Pipe({
	name: 'controlSubTypeLabel'
})
export class ControlSubTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: ControlSubType) {
		switch (value) {
			case ControlSubType.Amplification:
				return this.translationService.get('configuration_controlOptionSubType_amplification');
			case ControlSubType.Technical:
				return this.translationService.get('configuration_controlOptionSubType_technical');
			case ControlSubType.Loss:
				return this.translationService.get('configuration_controlOptionSubType_loss');
			default:
				return '';
		}
	}
}

<button (click)="triggerUpload()"
        class="btn-secondary"
        type="button">
  <rq-icon href="icon-upload"></rq-icon>
  <span>Select File </span>
</button>
<p *ngIf="file"
   class="file-description m-t-xs">
  <span>
    <rq-icon class="m-r-xs"
             href="icon-file-empty"
             color="color-secondary"></rq-icon>
  </span>
  <span class="file-description-inner">
    <span>{{file.name}}</span>
    <span class="file-upload-progress">
      <span [style.width]="getProgress()"
            class="file-upload-progress-inner"></span>
    </span>
  </span>
</p>
<input #inputFile
       (ngModelChange)="
       change()"
       [(ngModel)]="filePath"
       class="file-upload"
       type="file" />

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { AuditDefinitionModel } from '../../models/audit-definition-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { AuditFilterModel } from '../../models/audit-filter-model.model';
import { AuditDefinitionFilterModel } from '../../models/audit-definition-filter-model.model';

@Injectable()
export class AuditDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		legalEntityId: string,
		pagination: PaginationFilter,
		filter: AuditFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<AuditDefinitionModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<AuditDefinitionModel>>('get', environment.api + '/api/Infrastructure/Audit/GetPaginated', options)
			.toPromise();
	}

	public getEnterprisePaginated(
		pagination: PaginationFilter,
		filter: AuditFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<AuditDefinitionModel>> {
		options.params = this.httpService.getHttpParams({
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<AuditDefinitionModel>>('get', environment.api + '/api/Infrastructure/Audit/GetEnterprisePaginated', options)
			.toPromise();
	}

	public getEnterpriseFilters(options = new HttpOptionsViewModel()): Promise<AuditDefinitionFilterModel> {
		return this.httpService
			.request<AuditDefinitionFilterModel>('get', environment.api + '/api/Infrastructure/Audit/GetEnterpriseFilters', options)
			.toPromise();
	}

	public getFilters(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<AuditDefinitionFilterModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<AuditDefinitionFilterModel>('get', environment.api + '/api/Infrastructure/Audit/GetFilters', options)
			.toPromise();
	}
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { TPRecommendationModel } from '../../models/tprecommendation-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { TPRecommendationFilterModel } from '../../models/tprecommendation-filter-model.model';
import { TPRecommendationFilterOptionsModel } from '../../models/tprecommendation-filter-options-model.model';

@Injectable()
export class TPRecommendationDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		legalEntityId: string,
		pagination: PaginationFilter,
		filter: TPRecommendationFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<TPRecommendationModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<TPRecommendationModel>>('get', environment.api + '/api/TPAnalysis/TPRecommendation/GetPaginated', options)
			.toPromise();
	}

	public getTopByLossProbabilityReduction(
		legalEntityId: string,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<TPRecommendationModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			pagination
		});

		return this.httpService
			.request<Pagination<TPRecommendationModel>>(
				'get',
				environment.api + '/api/TPAnalysis/TPRecommendation/GetTopByLossProbabilityReduction',
				options
			)
			.toPromise();
	}

	public getFilters(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<TPRecommendationFilterOptionsModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<TPRecommendationFilterOptionsModel>('get', environment.api + '/api/TPAnalysis/TPRecommendation/GetFilters', options)
			.toPromise();
	}
}

<rq-popover [isVisibleOnClick]="true">
  <ng-container *ngTemplateOutlet="moreTemplate"></ng-container>
  <div rqPopoverContent
       [width]="200">
    <div class="actions__body">
      <ng-container *ngFor="let action of moreActions">
        <ng-container *ngTemplateOutlet="action.template"></ng-container>
      </ng-container>
    </div>
  </div>
</rq-popover>

<ng-template #moreTemplate>
  <div class="action-row">
    <button class="btn-icon">
      <rq-icon title="{{ 'Actions' | translate }}"
               href="icon-more-vertical"></rq-icon>
    </button>
  </div>
</ng-template>

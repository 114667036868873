import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { FairTreeModel } from '../../models/fair-tree-model.model';
import { FairAnalysisDetailsModel } from '../../models/fair-analysis-details-model.model';
import { FairLossExceedingCurveModel } from '../../models/fair-loss-exceeding-curve-model.model';
import { FairCharacteristicOfLossModel } from '../../models/fair-characteristic-of-loss-model.model';

@Injectable()
export class FairAnalysisDetailsDataService {
	constructor(private httpService: RQHttpService) {}

	public getOutputs(scenarioId: string, options = new HttpOptionsViewModel()): Promise<FairTreeModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<FairTreeModel>('get', environment.api + '/api/Fair/FairAnalysisDetails/GetOutputs', options)
			.toPromise();
	}

	public getDetails(scenarioId: string, options = new HttpOptionsViewModel()): Promise<FairAnalysisDetailsModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<FairAnalysisDetailsModel>('get', environment.api + '/api/Fair/FairAnalysisDetails/GetDetails', options)
			.toPromise();
	}

	public getLossExceedingCurve(scenarioId: string, options = new HttpOptionsViewModel()): Promise<FairLossExceedingCurveModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<FairLossExceedingCurveModel>('get', environment.api + '/api/Fair/FairAnalysisDetails/GetLossExceedingCurve', options)
			.toPromise();
	}

	public getCharacterisicOfLoss(scenarioid: string, options = new HttpOptionsViewModel()): Promise<FairCharacteristicOfLossModel> {
		options.params = this.httpService.getHttpParams({
			scenarioid
		});

		return this.httpService
			.request<FairCharacteristicOfLossModel>('get', environment.api + '/api/Fair/FairAnalysisDetails/GetCharacterisicOfLoss', options)
			.toPromise();
	}
}

<rq-modal>
  <rq-modal-header>
    {{'global_create_label' | translate}} {{'custom_model_label' | translate}}
  </rq-modal-header>

  <rq-modal-body>
    <form [formGroup]="form">
      <rq-form-control size="sm"
                       class="m-b-lg"
                       [options]="{validation: {isUnique: 'integration_nameValidation_unique', whitespace: 'form_validation_whitespaceNotAllowed'}}">
        <h3 class="primary-color"> {{'global_name_label' | translate}}* </h3>

        <rq-input type="text"
                  e2e-test="create-modal-input-name"
                  formControlName="displayName"
                  placeholder="{{'global_name_label' | translate}}"
                  class="form-control">
        </rq-input>
      </rq-form-control>

      <rq-form-control size="md"
                       class="m-b-lg">
        <h3 class="primary-color m-b-xs">{{'global_modelType_label' | translate}}</h3>
        <h4 class="primary-l-10-color">{{'type_explore_description' | translate}}</h4>

        <p *ngFor="let option of typeOptions"
           e2e-test="create-modal-custom-model-types">
          <label class="m-b-xs"
                 [class.disabled]="(disabledTypes | any:option.type) ? true : null">
            <input type="radio"
                   e2e-test="create-modal-input-type-{{option.type}}"
                   [value]="option.type"
                   formControlName="type">
            <span [class.disabled]="(disabledTypes | any:option.type) ? true : null"
                  class="text-sm">{{option.label}}</span>
          </label>
        </p>
      </rq-form-control>
    </form>
  </rq-modal-body>

  <rq-modal-footer class="d-flex justify-content-between">
    <section>
      <button type='button'
              e2e-test="create-modal-cancel-btn"
              (click)='closeModal()'
              class='btn-secondary'>{{'global_label_cancel' | translate}}</button>
    </section>

    <section class='d-flex'>
      <button type='button'
              #submit
              e2e-test="create-modal-save-as-draft-btn"
              [rqLoadingInline]='{isDisabledOnError: true, isInnerLoading: true}'
              (click)='saveAsDraft()'
              class='btn-secondary'>{{'global_saveAsDraft_label' | translate}}</button>

      <button class='btn-primary m-l-md'
              #submit
              e2e-test="create-modal-continue-btn"
              [rqLoadingInline]='{isDisabledOnError: true, isInnerLoading: true}'
              type='button'
              (click)='save()'>{{'global_continueConfig_label' | translate}}</button>
    </section>
  </rq-modal-footer>
</rq-modal>

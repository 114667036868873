import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
	ScenariosAnalysisSummaryFilterViewModel,
	ScenariosAnalysisSummaryFilterOptionViewModel
} from '../../../routes/control-analysis/_common/models/scenarios-analysis-summary.model';
import { ScenarioDetailsSummaryInputModel } from '../../../server/models/scenario-details-summary-input-model.model';
import { ScenarioDetailsSummaryModel } from '../../../server/models/scenario-details-summary-model.model';
import { AttackViewModel } from '../../../server/models/attack-view-model.model';
import { ActorViewModel } from '../../../server/models/actor-view-model.model';
import { ComparisonComponent } from '../../../standalone/components/comparison/comparison.component';
import { ComparisonCard } from '../../../standalone/components/comparison/comparison.model';
import { isNullOrUndefined } from '../../../standalone/utils/helpers.utils';
import { Filter, FilterSelectOption } from '../../../standalone/components/form-elements/filter/filter.model';

@Component({
	selector: 'rq-scenarios-enterprise-view',
	templateUrl: './scenarios-enterprise-view.page.html',
	styleUrls: ['./scenarios-enterprise-view.page.scss']
})
export class ScenariosEnterpriseViewComponent implements AfterViewInit {
	@Input()
	public scenarioAnalysisApplicationId?: string;

	@Input()
	public scenarioControl!: ComparisonCard[];

	@Input()
	public attacks!: Array<AttackViewModel>;

	@Input()
	public actors!: Array<ActorViewModel>;

	@Output()
	public readonly getInputData = new EventEmitter<ScenarioDetailsSummaryInputModel>();

	@Output()
	public readonly getScenarioData = new EventEmitter<ScenarioDetailsSummaryModel[]>();

	@ViewChild(ComparisonComponent)
	public comparison!: ComparisonComponent;

	public filter!: ScenariosAnalysisSummaryFilterViewModel;

	public data!: ScenarioDetailsSummaryModel[];

	private dataResolver!: (data: ScenarioDetailsSummaryModel[]) => void;

	constructor(private changeDetector: ChangeDetectorRef) {}

	public async ngAfterViewInit() {
		await this.loadData();

		this.setupFilters();

		this.changeDetector.detectChanges();
	}

	public setData(data: ScenarioDetailsSummaryModel[]) {
		this.dataResolver(data);
	}

	private setupFilters() {
		this.filter = <ScenariosAnalysisSummaryFilterViewModel>{
			analysisApplicationId: this.scenarioAnalysisApplicationId,
			actorId: <Filter<string>>{ value: this.data[0].actorId },
			attackId: <Filter<string>>{ value: this.data[0].attackId },
			options: <ScenariosAnalysisSummaryFilterOptionViewModel>{
				actorIds: this.actors.map(x => new FilterSelectOption<string>(x.name, x.id)),
				attackIds: this.attacks.map(x => new FilterSelectOption<string>(x.name, x.id))
			}
		};
	}

	private getFiltersInput() {
		if (isNullOrUndefined(this.filter)) {
			return <ScenarioDetailsSummaryInputModel>{
				analysisApplicationId: this.scenarioAnalysisApplicationId
			};
		} else {
			return <ScenarioDetailsSummaryInputModel>{
				analysisApplicationId: this.scenarioAnalysisApplicationId,
				actorId: this.filter.actorId,
				attackId: this.filter.attackId
			};
		}
	}

	private async loadData() {
		const input = this.getFiltersInput();

		this.getInputData.emit(input);

		const promise = new Promise<ScenarioDetailsSummaryModel[]>(res => {
			this.dataResolver = res;
		});
		this.data = await promise;

		this.getScenarioData.emit(this.data);
	}
}

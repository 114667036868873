import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { SecurityScorecardSettingsCreateModel } from '../../models/security-scorecard-settings-create-model.model';
import { SecurityScorecardSettingsModel } from '../../models/security-scorecard-settings-model.model';
import { SecurityScorecardTokenModel } from '../../models/security-scorecard-token-model.model';

@Injectable()
export class SecurityScorecardSettingsDataService {
	constructor(private httpService: RQHttpService) {}

	public create(model: SecurityScorecardSettingsCreateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = model;

		return this.httpService
			.request<void>('post', environment.api + '/api/Integration/SecurityScorecardSettings/Create', options)
			.toPromise();
	}

	public update(model: SecurityScorecardSettingsModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = model;

		return this.httpService
			.request<void>('put', environment.api + '/api/Integration/SecurityScorecardSettings/Update', options)
			.toPromise();
	}

	public getByIntegrationSettingId(integrationId: string, options = new HttpOptionsViewModel()): Promise<SecurityScorecardSettingsModel> {
		options.params = this.httpService.getHttpParams({
			integrationId
		});

		return this.httpService
			.request<SecurityScorecardSettingsModel>(
				'get',
				environment.api + '/api/Integration/SecurityScorecardSettings/GetByIntegrationSettingId',
				options
			)
			.toPromise();
	}

	public isAnyScorecardSetting(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<boolean> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<boolean>('get', environment.api + '/api/Integration/SecurityScorecardSettings/IsAnyScorecardSetting', options)
			.toPromise();
	}

	public getTokenByLegalEntityId(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<SecurityScorecardTokenModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<SecurityScorecardTokenModel>(
				'get',
				environment.api + '/api/Integration/SecurityScorecardSettings/GetTokenByLegalEntityId',
				options
			)
			.toPromise();
	}

	public getTokenValidity(token: string, integrationId: string, options = new HttpOptionsViewModel()): Promise<boolean> {
		options.params = this.httpService.getHttpParams({
			token,
			integrationId
		});

		return this.httpService
			.request<boolean>('get', environment.api + '/api/Integration/SecurityScorecardSettings/GetTokenValidity', options)
			.toPromise();
	}
}

import { Injectable } from '@angular/core';
import { BaseHubService } from './_common/base-hub.service';
import { HUB } from './_common/hub.config';
import { AnalysisSummaryStatusViewModel } from 'src/app/routes/control-analysis/_common/models/analysis-summary-status.viewmodel';
import { ConsoleLoggerService } from '../services/console-logger.service';

@Injectable({
	providedIn: 'root'
})
export class AnalysisSummaryHubService extends BaseHubService {
	constructor(loggerService: ConsoleLoggerService) {
		super(HUB.analysisSummary.path, loggerService);
	}

	public statusChange(handler: (status: AnalysisSummaryStatusViewModel) => void) {
		this.connection?.on('statusChange', handler);
	}
}

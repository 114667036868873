/* eslint-disable lines-between-class-members */

export class COLORS {
	public static get dangerContrastText() {
		return this.getColor('--text-danger-contrast');
	}
	public static get warningText() {
		return this.getColor('--text-warning');
	}
	public static get successText() {
		return this.getColor('--text-success');
	}
	public static get primaryText() {
		return this.getColor('--text-secondary');
	}
	public static get secondaryText() {
		return this.getColor('--text-neutral-contrast');
	}
	public static get infoText() {
		return this.getColor('--text-info');
	}
	public static get dangerText() {
		return this.getColor('--text-danger');
	}
	public static get primaryBackground() {
		return this.getColor('--background-primary');
	}
	public static get neutralOverlayBackground() {
		return this.getColor('--background-neutral-overlay');
	}
	public static get neutralContrastBackground() {
		return this.getColor('--background-neutral-contrast');
	}
	public static get dangerBackground() {
		return this.getColor('--background-danger');
	}
	public static get dangerDarkBackground() {
		return this.getColor('--background-danger-contrast');
	}
	public static get warningBackground() {
		return this.getColor('--background-warning');
	}
	public static get warningDarkBackground() {
		return this.getColor('--background-warning-contrast');
	}
	public static get infoBackground() {
		return this.getColor('--background-info');
	}
	public static get infoDarkBackground() {
		return this.getColor('--background-info-contrast');
	}
	public static get royalBackground() {
		return this.getColor('--background-royal');
	}
	public static get royalDarkBackground() {
		return this.getColor('--background-royal-contrast');
	}
	public static get successBackground() {
		return this.getColor('--background-success');
	}
	public static get successDarkBackground() {
		return this.getColor('--background-success-contrast');
	}

	public static get lightText() {
		return this.getColor('--text-primary');
	}

	public static get primaryColorScheme() {
		return [
			this.getColor('--background-neutral-contrast'),
			this.getColor('--background-neutral'),
			this.getColor('--background-neutral-overlay')
		];
	}

	public static get rainbowColorScheme() {
		return [
			this.getColor('--background-brand'),
			this.getColor('--background-info'),
			this.getColor('--background-success'),
			this.getColor('--background-warning'),
			this.getColor('--background-danger'),
			this.getColor('--background-royal'),
			this.getColor('--background-clear'),
			this.getColor('--background-brand-fill'),
			this.getColor('--background-info-fill'),
			this.getColor('--background-success-fill'),
			this.getColor('--background-warning-fill'),
			this.getColor('--background-royal-fill'),
			this.getColor('--background-clear-fill')
		];
	}

	private static getColor(variable: string) {
		return getComputedStyle(document.documentElement).getPropertyValue(variable);
	}
}

import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SideBarNavigationService } from '../../shared/core/navigation-menu/refresh-navigation-states.service';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class NavigationInterceptor implements HttpInterceptor {
	constructor(private navigationService: SideBarNavigationService) {}

	public intercept(req: HttpRequest<string>, next: HttpHandler) {
		this.navigationService.refreshStates(req.url, true);
		return next.handle(req).pipe(
			map(httpEvent => {
				if (httpEvent instanceof HttpResponse) {
					this.navigationService.refreshStates(req.url, false);
				}
				return httpEvent;
			}),
			catchError(error => {
				this.navigationService.refreshStates(req.url, false);
				return throwError(error);
			})
		);
	}
}

<div class="bar-row"
     [style.width]="barLength">
  <div class="bar-details">
    <div *ngIf="label"
         class="label-name">{{label}}</div>
  </div>

  <div class="bar-wrapper">
    <div class="bar"
         [style.height.px]="8">
      <div class="progress-bar"
           [style.background-color]="color"
           [style.width.%]="(value * 100) / this.maxValue"
           [style.height.px]="8">
      </div>
    </div>

    <div *ngIf="valueFormatter === 'percent'"
         class="progress-bar-label">{{value | rqPercent}}</div>
    <div *ngIf="valueFormatter === 'currency'"
         class="progress-bar-label">{{value | rqCurrency}}</div>
    <div *ngIf="valueFormatter === 'number'"
         class="progress-bar-label">{{value | rqNumber}}</div>
  </div>
</div>

<div class="chart"
     #chart></div>

<ng-content></ng-content>

<ng-template #defaultTooltip
             let-name="name"
             let-label="label"
             let-value="value">
  <p class="default-tooltip">{{getDefaultTooltipLabel(name, label, value)}}</p>
</ng-template>

<div class="tooltip-container"
     *ngIf="data"
     hidden>
  <ng-container *ngFor="let entry of data; let index = index">
    <div [id]="index">
      <ng-container *ngTemplateOutlet="customTooltip || defaultTooltip; context: { name: entry.name, value: entry.value }"></ng-container>

      <ng-container *ngFor="let groupValue of entry.values; let groupIndex = index">
        <div [id]="index + '-' + groupIndex">
          <ng-container *ngTemplateOutlet="customTooltip || defaultTooltip; context: { name: entry.name, label: groupValue.label, value: groupValue.value }"></ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

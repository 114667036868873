import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { FairLossModel } from '../../models/fair-loss-model.model';
import { FairType } from '../../enums/fair-type.enum';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { FairLossFilterModel } from '../../models/fair-loss-filter-model.model';
import { FairLossFilterOptionModel } from '../../models/fair-loss-filter-option-model.model';
import { OperationResult } from '../../models/operation-result.model';
import { FairLossCreateModel } from '../../models/fair-loss-create-model.model';
import { FairLossUpdateModel } from '../../models/fair-loss-update-model.model';
import { CalculationInputModel } from '../../models/calculation-input-model.model';

@Injectable()
export class FairLossDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		scenarioId: string,
		type: FairType,
		pagination: PaginationFilter,
		filter: FairLossFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<FairLossModel>> {
		options.params = this.httpService.getHttpParams({
			scenarioId,
			type,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<FairLossModel>>('get', environment.api + '/api/Scenario/FairLoss/GetPaginated', options)
			.toPromise();
	}

	public count(scenarioId: string, type: FairType, options = new HttpOptionsViewModel()): Promise<number> {
		options.params = this.httpService.getHttpParams({
			scenarioId,
			type
		});

		return this.httpService.request<number>('get', environment.api + '/api/Scenario/FairLoss/Count', options).toPromise();
	}

	public getFilterOptions(scenarioId: string, type: FairType, options = new HttpOptionsViewModel()): Promise<FairLossFilterOptionModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId,
			type
		});

		return this.httpService
			.request<FairLossFilterOptionModel>('get', environment.api + '/api/Scenario/FairLoss/GetFilterOptions', options)
			.toPromise();
	}

	public getById(fairLossId: string, options = new HttpOptionsViewModel()): Promise<FairLossModel> {
		options.params = this.httpService.getHttpParams({
			fairLossId
		});

		return this.httpService.request<FairLossModel>('get', environment.api + '/api/Scenario/FairLoss/GetById', options).toPromise();
	}

	public getByScenarioId(scenarioId: string, options = new HttpOptionsViewModel()): Promise<FairLossModel[]> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<FairLossModel[]>('get', environment.api + '/api/Scenario/FairLoss/GetByScenarioId', options)
			.toPromise();
	}

	public getInvalidFairLosses(scenarioId: string, options = new HttpOptionsViewModel()): Promise<FairLossModel[]> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<FairLossModel[]>('get', environment.api + '/api/Scenario/FairLoss/GetInvalidFairLosses', options)
			.toPromise();
	}

	public create(fairLoss: FairLossCreateModel, options = new HttpOptionsViewModel()): Promise<OperationResult> {
		options.body = fairLoss;

		return this.httpService.request<OperationResult>('post', environment.api + '/api/Scenario/FairLoss/Create', options).toPromise();
	}

	public createRange(fairLosses: FairLossCreateModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = fairLosses;

		return this.httpService.request<void>('post', environment.api + '/api/Scenario/FairLoss/CreateRange', options).toPromise();
	}

	public update(fairLoss: FairLossUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = fairLoss;

		return this.httpService.request<void>('post', environment.api + '/api/Scenario/FairLoss/Update', options).toPromise();
	}

	public updateRange(fairModels: FairLossUpdateModel[], options = new HttpOptionsViewModel()): Promise<void> {
		options.body = fairModels;

		return this.httpService.request<void>('post', environment.api + '/api/Scenario/FairLoss/UpdateRange', options).toPromise();
	}

	public delete(fairLossId: string, options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			fairLossId
		});

		return this.httpService.request<void>('delete', environment.api + '/api/Scenario/FairLoss/Delete', options).toPromise();
	}

	public deleteRange(fairLossIds: string[], options = new HttpOptionsViewModel()): Promise<void> {
		options.params = this.httpService.getHttpParams({
			fairLossIds
		});

		return this.httpService.request<void>('delete', environment.api + '/api/Scenario/FairLoss/DeleteRange', options).toPromise();
	}

	public hasUniqueName(scenarioId: string, name: string, type: FairType, options = new HttpOptionsViewModel()): Promise<boolean> {
		options.params = this.httpService.getHttpParams({
			scenarioId,
			name,
			type
		});

		return this.httpService.request<boolean>('get', environment.api + '/api/Scenario/FairLoss/HasUniqueName', options).toPromise();
	}

	public getValue(input: CalculationInputModel, options = new HttpOptionsViewModel()): Promise<number> {
		options.body = input;

		return this.httpService.request<number>('post', environment.api + '/api/Scenario/FairLoss/GetValue', options).toPromise();
	}
}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AuditFormatType } from '../../../server/enums/audit-format-type.enum';
import { FINANCIAL_CURRENCY_CONFIG } from '../../configs/number-formatter.config';
import { AuditBehaviorType } from '../../../server/enums/audit-behavior-type.enum';
import { TranslationService } from '../../services/translation.service';
import { AuditFilterConfigViewModel, AuditFilterViewModel } from '../../models/audit-table.model';
import { AuditType } from '../../../server/enums/audit-type.enum';
import { AuditDefinitionModel } from '../../../server/models/audit-definition-model.model';
import { AuditBehaviorTypeTranslate } from '../../pipes/translate-pipes/audit-behavior-type-label.pipe';
import { TableBase2Component } from '../../../standalone/components/table/table-base2.component';
import { DateFormatterService } from '../../services/date-formatter.service';
import { NumberFormatterService } from '../../services/number-formatter.service';
import { Customizer } from '../../services/customizer';
import { FilterSelectGroup, FilterSelectOption, SelectFilter } from '../../../standalone/components/form-elements/filter/filter.model';

@Component({
	selector: 'rq-audit-table-new',
	templateUrl: './audit-table-new.component.html',
	styleUrls: ['./audit-table-new.component.scss']
})
export class AuditTableNewComponent extends TableBase2Component<AuditDefinitionModel, AuditFilterViewModel> implements OnChanges {
	@Input()
	public hasLegalEntityColumn!: boolean;

	@Input()
	public filterOptions!: AuditFilterConfigViewModel;

	public typeFilterSetup!: SelectFilter;

	public behaviourTypeFilterSetup!: SelectFilter;

	public updatedVariableFilterSetup!: SelectFilter;

	constructor(
		private customizer: Customizer,
		private numberFormatterService: NumberFormatterService,
		private dateFormatterService: DateFormatterService,
		private translateService: TranslationService,
		private auditBehaviorTypeTranslate: AuditBehaviorTypeTranslate
	) {
		super();
	}

	public get dateTimePattern() {
		return this.customizer.dateTime.format;
	}

	public async ngOnChanges(changes: SimpleChanges) {
		await super.ngOnChanges(changes);

		if (changes?.filterOptions?.currentValue) {
			this.setupFilters(changes.filterOptions.currentValue as AuditFilterConfigViewModel);
		}
	}

	public getValue(value: string, formatType?: AuditFormatType) {
		if (!value) {
			return '-';
		}

		return formatType ? this.getFormattedValue(value, formatType) : value;
	}

	private setupFilters(filterOptions?: AuditFilterConfigViewModel) {
		this.behaviourTypeFilterSetup = this.createSelectFilter('audit_actionType_label', this.createBehaviorTypeOptions());
		this.typeFilterSetup = this.createSelectFilter('global_type_label', this.createTypeOptions());

		if (filterOptions) {
			this.updatedVariableFilterSetup = this.createSelectFilter(
				'audit_updatedVariable_label',
				this.createUpdateVariablepOptions(filterOptions),
				5
			);
		}
	}

	private createUpdateVariablepOptions(filterOptions: AuditFilterConfigViewModel) {
		const groups = [
			{
				options: filterOptions.updatedVariables.map(x => new FilterSelectOption(this.translateService.get(x), x))
			}
		];

		groups[0].options.sort((a, b) => a.displayName.localeCompare(b.displayName));

		return groups;
	}

	private getType(type: AuditType): string {
		const map = new Map<AuditType, string>();
		map.set(AuditType.BusinessApplication, 'global_application_label');
		map.set(AuditType.LegalEntity, 'global_legalEntity_label');
		map.set(AuditType.BaGeneralControl, 'audits_applicationGeneralInfo_displayName');
		map.set(AuditType.BaEnterpriseProfile, 'audits_applicationEnterpriseProfile_displayName');
		map.set(AuditType.BaApplicationProfile, 'audits_applicationApplicationProfile_displayName');
		map.set(AuditType.BaEndpointProfile, 'audits_applicationEndpointProfile_displayName');
		map.set(AuditType.BaEndpoint, 'audits_applicationEndpoints_displayName');
		map.set(AuditType.EnterpriseProfile, 'global_enterpriseControlProfile_label');
		map.set(AuditType.DataProfile, 'audits_dataRecordProfile_displayName');
		map.set(AuditType.CveTechnicalWeighting, 'global_otherTechnicalWeighting_label');
		map.set(AuditType.EndpointProfile, 'global_endpointProfile_label');
		map.set(AuditType.RunAnalysis, 'global_runRiskAnalysis_label');
		map.set(AuditType.User, 'global_user_label');
		map.set(AuditType.Account, 'global_account_Label');
		map.set(AuditType.LossValue, 'loss_variable_type_Title');
		map.set(AuditType.ReputationDamageParameter, 'reputation_damage_parameters_Title');
		map.set(AuditType.AttackRateOfIncidence, 'global_annualAttackRateOfIncidence_label');
		map.set(AuditType.Configuration, 'settings_section_enterprise_management');
		map.set(AuditType.ExchangeRate, 'settings_preferences_currency_conversions_label');
		map.set(AuditType.LegalEntityPreference, 'audit_legalEntityPreferences_label');
		map.set(AuditType.CveFinancialWeighting, 'settings_cveFinancialWeightings_title');
		map.set(AuditType.CveExploitabilityWeighting, 'global_exploitabilityWeighting_label');
		map.set(AuditType.CveScoreWeighting, 'global_cvssScoreWeighting_label');
		map.set(AuditType.FileUpload, 'global_fileUpload_label');
		map.set(AuditType.FairMagnitudeValues as AuditType, 'audit_fairMagnitudeValues_label');
		map.set(AuditType.IntegrationSettings, 'global_integrations_label');
		map.set(AuditType.FairScenario, 'scenario_fair_radio');
		map.set(AuditType.ModifiedFairScenario, 'scenario_modified_fair_radio');
		map.set(AuditType.AggregatedFairScenario, 'scenario_aggregated_fair_radio');
		map.set(AuditType.MachineLearningScenario, 'global_modelRiskToBusinessAssets_label');
		map.set(AuditType.ScenarioEnterprise, 'scenario_editEnterpriseControl_radio');
		map.set(AuditType.ScenarioRateOfIncidence, 'scenario_editAttackRateOfIncidence_radio');
		map.set(AuditType.LossExposure, 'global_BusinessAssets_label');
		map.set(AuditType.TPRunAnalysis, 'audit_TpAnalysis');
		map.set(AuditType.ThirdParty, 'global_thirdParty_label');
		map.set(AuditType.LossLimit, 'global_lossLimits_label');
		map.set(AuditType.SecurityScorecard, 'global_securityScorecard_label');
		map.set(AuditType.BaLossValues, 'ba_loss_variable_type_Title');
		map.set(AuditType.FairTEFValues, 'audit_semiFairTEF_label');
		map.set(AuditType.BusinessEntity, 'global_businessEntity_label');
		map.set(AuditType.ApplicationCharacteristic, 'global_applicationCharacteristics_label');
		map.set(AuditType.ApplicationType, 'global_applicationType_label');
		map.set(AuditType.UserPreferences, 'preferences_userPreferences_label');
		map.set(AuditType.RecommendationImplementationCost, 'recommendation_implementationCost_label');
		map.set(AuditType.MultipleControlProfilesScenario, 'scenario_multipleLevels_radio');
		map.set(AuditType.CustomVariable, 'audit_customVariable_auditType_label');
		map.set(AuditType.CustomModel, 'custom_model_label');
		map.set(AuditType.EndpointScoreWeighting, 'global_endpoint_score_weighting_label');
		map.set(AuditType.LookupTable, 'customTables_label');
		map.set(AuditType.Mitre, 'mitre_analysis_label');
		map.set(AuditType.QuantitativeScales, 'global_qualitative_scale_label');
		map.set(AuditType.FeatureFlag, 'global_enterprisePreferences_label');

		if (!map.has(type)) {
			throw new Error(`Type ${type} is not handled`);
		}

		return this.translateService.get(map.get(type) as string);
	}

	private createTypeOptions() {
		const groups = [
			<FilterSelectGroup<AuditType>>{
				options: [
					new FilterSelectOption(this.getType(AuditType.BusinessApplication), AuditType.BusinessApplication),
					new FilterSelectOption(this.getType(AuditType.LegalEntity), AuditType.LegalEntity),
					new FilterSelectOption(this.getType(AuditType.BaGeneralControl), AuditType.BaGeneralControl),
					new FilterSelectOption(this.getType(AuditType.BaApplicationProfile), AuditType.BaApplicationProfile),
					new FilterSelectOption(this.getType(AuditType.BaEnterpriseProfile), AuditType.BaEnterpriseProfile),
					new FilterSelectOption(this.getType(AuditType.BaEndpointProfile), AuditType.BaEndpointProfile),
					new FilterSelectOption(this.getType(AuditType.BaEndpoint), AuditType.BaEndpoint),
					new FilterSelectOption(this.getType(AuditType.EnterpriseProfile), AuditType.EnterpriseProfile),
					new FilterSelectOption(this.getType(AuditType.DataProfile), AuditType.DataProfile),
					new FilterSelectOption(this.getType(AuditType.EndpointProfile), AuditType.EndpointProfile),
					new FilterSelectOption(this.getType(AuditType.CveTechnicalWeighting), AuditType.CveTechnicalWeighting),
					new FilterSelectOption(this.getType(AuditType.RunAnalysis), AuditType.RunAnalysis),
					new FilterSelectOption(this.getType(AuditType.User), AuditType.User),
					new FilterSelectOption(this.getType(AuditType.Account), AuditType.Account),
					new FilterSelectOption(this.getType(AuditType.LossValue), AuditType.LossValue),
					new FilterSelectOption(this.getType(AuditType.ReputationDamageParameter), AuditType.ReputationDamageParameter),
					new FilterSelectOption(this.getType(AuditType.AttackRateOfIncidence), AuditType.AttackRateOfIncidence),
					new FilterSelectOption(this.getType(AuditType.Configuration), AuditType.Configuration),
					new FilterSelectOption(this.getType(AuditType.ExchangeRate), AuditType.ExchangeRate),
					new FilterSelectOption(this.getType(AuditType.LegalEntityPreference), AuditType.LegalEntityPreference),
					new FilterSelectOption(this.getType(AuditType.FileUpload), AuditType.FileUpload),
					new FilterSelectOption(this.getType(AuditType.CveFinancialWeighting), AuditType.CveFinancialWeighting),
					new FilterSelectOption(this.getType(AuditType.CveExploitabilityWeighting), AuditType.CveExploitabilityWeighting),
					new FilterSelectOption(this.getType(AuditType.CveScoreWeighting), AuditType.CveScoreWeighting),
					new FilterSelectOption(this.getType(AuditType.IntegrationSettings), AuditType.IntegrationSettings),
					new FilterSelectOption(this.getType(AuditType.FairScenario), AuditType.FairScenario),
					new FilterSelectOption(this.getType(AuditType.ModifiedFairScenario), AuditType.ModifiedFairScenario),
					new FilterSelectOption(this.getType(AuditType.AggregatedFairScenario), AuditType.AggregatedFairScenario),
					new FilterSelectOption(this.getType(AuditType.MachineLearningScenario), AuditType.MachineLearningScenario),
					new FilterSelectOption(this.getType(AuditType.ScenarioEnterprise), AuditType.ScenarioEnterprise),
					new FilterSelectOption(this.getType(AuditType.ScenarioRateOfIncidence), AuditType.ScenarioRateOfIncidence),
					new FilterSelectOption(this.getType(AuditType.LossExposure), AuditType.LossExposure),
					new FilterSelectOption(this.getType(AuditType.ThirdParty), AuditType.ThirdParty),
					new FilterSelectOption(this.getType(AuditType.TPRunAnalysis), AuditType.TPRunAnalysis),
					new FilterSelectOption(this.getType(AuditType.LossLimit), AuditType.LossLimit),
					new FilterSelectOption(this.getType(AuditType.FairMagnitudeValues as AuditType), AuditType.FairMagnitudeValues),
					new FilterSelectOption(this.getType(AuditType.BaLossValues as AuditType), AuditType.BaLossValues),
					new FilterSelectOption(this.getType(AuditType.FairTEFValues as AuditType), AuditType.FairTEFValues),
					new FilterSelectOption(this.getType(AuditType.BusinessEntity as AuditType), AuditType.BusinessEntity),
					new FilterSelectOption(this.getType(AuditType.ApplicationCharacteristic as AuditType), AuditType.ApplicationCharacteristic),
					new FilterSelectOption(this.getType(AuditType.ApplicationType as AuditType), AuditType.ApplicationType),
					new FilterSelectOption(this.getType(AuditType.SecurityScorecard as AuditType), AuditType.SecurityScorecard),
					new FilterSelectOption(this.getType(AuditType.UserPreferences as AuditType), AuditType.UserPreferences),
					new FilterSelectOption(
						this.getType(AuditType.RecommendationImplementationCost as AuditType),
						AuditType.RecommendationImplementationCost
					),
					new FilterSelectOption(this.getType(AuditType.MultipleControlProfilesScenario), AuditType.MultipleControlProfilesScenario),
					new FilterSelectOption(this.getType(AuditType.CustomVariable), AuditType.CustomVariable),
					new FilterSelectOption(this.getType(AuditType.CustomModel), AuditType.CustomModel),
					new FilterSelectOption(this.getType(AuditType.EndpointScoreWeighting as AuditType), AuditType.EndpointScoreWeighting),
					new FilterSelectOption(this.getType(AuditType.LookupTable), AuditType.LookupTable),
					new FilterSelectOption(this.getType(AuditType.QuantitativeScales), AuditType.QuantitativeScales),
					new FilterSelectOption(this.getType(AuditType.Mitre), AuditType.Mitre),
					new FilterSelectOption(this.getType(AuditType.FeatureFlag), AuditType.FeatureFlag)
				]
			}
		];

		groups[0].options.sort((a: FilterSelectOption<unknown>, b: FilterSelectOption<unknown>) => a.displayName.localeCompare(b.displayName));

		return groups;
	}

	private createBehaviorTypeOptions() {
		const groups = [
			{
				options: [
					new FilterSelectOption(this.auditBehaviorTypeTranslate.transform(AuditBehaviorType.Create), AuditBehaviorType.Create),
					new FilterSelectOption(this.auditBehaviorTypeTranslate.transform(AuditBehaviorType.Update), AuditBehaviorType.Update),
					new FilterSelectOption(this.auditBehaviorTypeTranslate.transform(AuditBehaviorType.Delete), AuditBehaviorType.Delete),
					new FilterSelectOption(this.auditBehaviorTypeTranslate.transform(AuditBehaviorType.Unarchived), AuditBehaviorType.Unarchived),
					new FilterSelectOption(this.auditBehaviorTypeTranslate.transform(AuditBehaviorType.Archived), AuditBehaviorType.Archived),
					new FilterSelectOption(this.auditBehaviorTypeTranslate.transform(AuditBehaviorType.Assign), AuditBehaviorType.Assign),
					new FilterSelectOption(this.auditBehaviorTypeTranslate.transform(AuditBehaviorType.Unassign), AuditBehaviorType.Unassign),
					new FilterSelectOption(this.auditBehaviorTypeTranslate.transform(AuditBehaviorType.Login), AuditBehaviorType.Login),
					new FilterSelectOption(this.auditBehaviorTypeTranslate.transform(AuditBehaviorType.Logout), AuditBehaviorType.Logout),
					new FilterSelectOption(
						this.auditBehaviorTypeTranslate.transform(AuditBehaviorType.ChangePassword),
						AuditBehaviorType.ChangePassword
					),
					new FilterSelectOption(
						this.auditBehaviorTypeTranslate.transform(AuditBehaviorType.ChangePasswordFail),
						AuditBehaviorType.ChangePasswordFail
					),
					new FilterSelectOption(
						this.auditBehaviorTypeTranslate.transform(AuditBehaviorType.DeactivateAccount),
						AuditBehaviorType.DeactivateAccount
					),
					new FilterSelectOption(
						this.auditBehaviorTypeTranslate.transform(AuditBehaviorType.ReactivateAccount),
						AuditBehaviorType.ReactivateAccount
					),
					new FilterSelectOption(
						this.auditBehaviorTypeTranslate.transform(AuditBehaviorType.RunRiskAnalysis),
						AuditBehaviorType.RunRiskAnalysis
					),
					new FilterSelectOption(this.auditBehaviorTypeTranslate.transform(AuditBehaviorType.InitialData), AuditBehaviorType.InitialData),
					new FilterSelectOption(
						this.auditBehaviorTypeTranslate.transform(AuditBehaviorType.ThirdPartyRunAnalysis),
						AuditBehaviorType.ThirdPartyRunAnalysis
					)
				]
			}
		];

		groups[0].options.sort((a: FilterSelectOption<unknown>, b: FilterSelectOption<unknown>) => a.displayName.localeCompare(b.displayName));

		return groups;
	}

	private getFormattedValue(value: string, formatType: AuditFormatType) {
		switch (formatType) {
			case AuditFormatType.Currency:
				return this.numberFormatterService.currency(Number(value), FINANCIAL_CURRENCY_CONFIG);
			case AuditFormatType.Number:
				return this.numberFormatterService.number(Number(value));
			case AuditFormatType.Percent:
				return this.numberFormatterService.percent(Number(value));
			case AuditFormatType.Date:
				return this.dateFormatterService.format(value);
			case AuditFormatType.DateTime:
				return this.dateFormatterService.format(value, this.dateTimePattern);
			default:
				throw new Error(`Formatter with value ${formatType} is not supported`);
		}
	}
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { MitreTacticFinancialModel } from '../../models/mitre-tactic-financial-model.model';
import { MitreLossSummaryModel } from '../../models/mitre-loss-summary-model.model';

@Injectable()
export class MitreAnalysisTechniqueDataService {
	constructor(private httpService: RQHttpService) {}

	public getFinancialLosses(scenarioId: string, options = new HttpOptionsViewModel()): Promise<MitreTacticFinancialModel[]> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<MitreTacticFinancialModel[]>('get', environment.api + '/api/Scenario/MitreAnalysisTechnique/GetFinancialLosses', options)
			.toPromise();
	}

	public getHighest(scenarioId: string, options = new HttpOptionsViewModel()): Promise<MitreLossSummaryModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<MitreLossSummaryModel>('get', environment.api + '/api/Scenario/MitreAnalysisTechnique/GetHighest', options)
			.toPromise();
	}
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { ActorRoiDefinitionModel } from '../../models/actor-roi-definition-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { ActorRoiFilterModel } from '../../models/actor-roi-filter-model.model';
import { ActorRateOfIncidenceModel } from '../../models/actor-rate-of-incidence-model.model';
import { ActorRateOfIncidenceUpdateModel } from '../../models/actor-rate-of-incidence-update-model.model';
import { ActorRateOfIncidenceCreateModel } from '../../models/actor-rate-of-incidence-create-model.model';
import { ActorRoiFilterOptionModel } from '../../models/actor-roi-filter-option-model.model';

@Injectable()
export class ActorRateOfIncidenceDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		legalEntityId: string,
		pagination: PaginationFilter,
		filterModel: ActorRoiFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<ActorRoiDefinitionModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			pagination,
			filterModel
		});

		return this.httpService
			.request<Pagination<ActorRoiDefinitionModel>>('get', environment.api + '/api/Settings/ActorRateOfIncidence/GetPaginated', options)
			.toPromise();
	}

	public getById(actorId: string, options = new HttpOptionsViewModel()): Promise<ActorRateOfIncidenceModel> {
		options.params = this.httpService.getHttpParams({
			actorId
		});

		return this.httpService
			.request<ActorRateOfIncidenceModel>('get', environment.api + '/api/Settings/ActorRateOfIncidence/GetById', options)
			.toPromise();
	}

	public update(model: ActorRateOfIncidenceUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = model;

		return this.httpService.request<void>('put', environment.api + '/api/Settings/ActorRateOfIncidence/Update', options).toPromise();
	}

	public create(model: ActorRateOfIncidenceCreateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = model;

		return this.httpService.request<void>('post', environment.api + '/api/Settings/ActorRateOfIncidence/Create', options).toPromise();
	}

	public getFilterOptions(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<ActorRoiFilterOptionModel> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<ActorRoiFilterOptionModel>('get', environment.api + '/api/Settings/ActorRateOfIncidence/GetFilterOptions', options)
			.toPromise();
	}
}

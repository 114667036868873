import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { LegalEntityUserModel } from '../../models/legal-entity-user-model.model';
import { LegalEntityModel } from '../../models/legal-entity-model.model';

@Injectable()
export class LegalEntityUserDataService {
	constructor(private httpService: RQHttpService) {}

	public getByLegalEntity(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<LegalEntityUserModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<LegalEntityUserModel[]>('get', environment.api + '/api/Configuration/LegalEntityUser/GetByLegalEntity', options)
			.toPromise();
	}

	public getByUser(options = new HttpOptionsViewModel()): Promise<LegalEntityModel[]> {
		return this.httpService
			.request<LegalEntityModel[]>('get', environment.api + '/api/Configuration/LegalEntityUser/GetByUser', options)
			.toPromise();
	}

	public getByUserId(userId: string, options = new HttpOptionsViewModel()): Promise<LegalEntityModel[]> {
		options.params = this.httpService.getHttpParams({
			userId
		});

		return this.httpService
			.request<LegalEntityModel[]>('get', environment.api + '/api/Configuration/LegalEntityUser/GetByUserId', options)
			.toPromise();
	}
}

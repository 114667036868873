import { Component, ContentChild, ContentChildren, ElementRef, HostListener, Input, QueryList } from '@angular/core';
import { ThreeDotsMenuItemComponent } from './three-dots-menu-item.component/three-dots-menu-item.component';
import { ThreeDotsMenuElementDirective } from './directives/three-dots-menu-element.directive';

@Component({
	selector: 'rq-three-dots-menu',
	templateUrl: './three-dots-menu.component.html',
	styleUrls: ['./three-dots-menu.component.scss']
})
export class ThreeDotsMenuComponent {
	@ContentChildren(ThreeDotsMenuItemComponent)
	public items!: QueryList<ThreeDotsMenuItemComponent>;

	@ContentChild(ThreeDotsMenuElementDirective)
	public actionElement?: ThreeDotsMenuElementDirective;

	@Input()
	public noWrap = true;

	public isVisible = false;

	constructor(private elementRef: ElementRef<HTMLElement>) {}

	@HostListener('document:click', ['$event', '$event.target'])
	public onClick(_event: MouseEvent, targetElement: HTMLElement): void {
		if (!targetElement) {
			return;
		}
		const clickedInside = this.elementRef.nativeElement.contains(targetElement);
		if (!clickedInside && this.isVisible) {
			this.closeMenu();
		}
	}

	public toggleState(): void {
		this.isVisible = !this.isVisible;
	}

	public closeMenu() {
		if (this.isVisible) {
			this.isVisible = false;
		}
	}
}

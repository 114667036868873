<rq-modal (modalClosed)="closeModal()">
  <rq-modal-header>
    {{ 'global_unlockAccount_label' | translate }}
  </rq-modal-header>
  <rq-modal-body>
    <div class='text-center'>
      {{ 'settings_user_management_table_unlock_account_message_email' | translate : {email: model?.email} }}
    </div>
    <div class='text-center'>
      {{ 'settings_user_management_table_unlock_account_message_info' | translate }}
    </div>
  </rq-modal-body>
  <rq-modal-footer class="d-flex justify-content-end">
    <button (click)="closeModal()"
            class="text-uppercase btn-primary">
      {{ 'global_label_ok' | translate }}
    </button>
  </rq-modal-footer>
</rq-modal>

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import {
	ApplicationRiskDetailsFilterViewModel,
	ApplicationRiskDetailsTableConfig,
	ApplicationRiskDetailsTableViewModel
} from '../../../routes/control-analysis/_common/models/application-risk-details-table.model';
import { EnterpriseValue } from '../../models/enterprise-value.model';
import { AnalysisApplicationRiskFilterOptionModel } from '../../../server/models/analysis-application-risk-filter-option-model.model';
import { ControlFrameworkModel } from '../../../server/models/control-framework-model.model';
import { AnalysisApplicationRiskModel } from '../../../server/models/analysis-application-risk-model.model';
import { TableBase2Component } from '../../../standalone/components/table/table-base2.component';
import { FormatterType } from '../../enums/formatter-type.enum';
import { TableOutput } from '../../../standalone/components/table/models/table-output.model';
import { FilterSelectOption, RangeFilter, SelectFilter } from '../../../standalone/components/form-elements/filter/filter.model';

@Component({
	selector: 'rq-application-risk-details-table',
	templateUrl: './application-risk-details-table.component.html',
	styleUrls: ['./application-risk-details-table.component.scss']
})
export class ApplicationRiskDetailsTableComponent
	extends TableBase2Component<ApplicationRiskDetailsTableViewModel | AnalysisApplicationRiskModel, ApplicationRiskDetailsFilterViewModel>
	implements OnChanges
{
	@Input()
	public filterOptions!: AnalysisApplicationRiskFilterOptionModel;

	@Input()
	public controlFrameworksOptions!: ControlFrameworkModel[];

	@Input()
	public config = new ApplicationRiskDetailsTableConfig();

	@Input()
	public isLegalEntityVisible = true;

	@Output()
	public readonly pageChange = new EventEmitter<TableOutput<ApplicationRiskDetailsFilterViewModel>>();

	public controlFrameworkFilterSetup!: SelectFilter;

	public caverageControlRatingFilterSetup!: RangeFilter;

	public inherentRiskFilterSetup!: RangeFilter;

	public residualRiskFilterSetup!: RangeFilter;

	constructor(private translateService: TranslationService) {
		super();
	}

	public async ngOnChanges(changes: SimpleChanges) {
		await super.ngOnChanges(changes);
		if (changes?.filterOptions?.currentValue) {
			this.setupFilters(changes.filterOptions.currentValue as AnalysisApplicationRiskFilterOptionModel);
		}

		if (changes?.controlFrameworksOptions?.currentValue) {
			this.setupControlOptionsFilters(changes.controlFrameworksOptions.currentValue as ControlFrameworkModel[]);
		}
	}

	public getLevel(level: number): string {
		return level === EnterpriseValue.NotApplicable ? this.translateService.get('global_notApplicable_label') : `${level}`;
	}

	private setupFilters(filterOptions?: AnalysisApplicationRiskFilterOptionModel) {
		if (filterOptions) {
			this.caverageControlRatingFilterSetup = this.createRangeFilter(
				'global_averageControlRating_label',
				{ min: Math.floor(this.filterOptions.averageControlRatingMin), max: Math.ceil(this.filterOptions.averageControlRatingMax) },
				FormatterType.Number
			);

			this.inherentRiskFilterSetup = this.createRangeFilter(
				'global_inherentRisk_label',
				{ min: Math.floor(this.filterOptions.inherentRiskMin), max: Math.ceil(this.filterOptions.inherentRiskMax) },
				FormatterType.Currency
			);

			this.residualRiskFilterSetup = this.createRangeFilter(
				'global_residualRisk_label',
				{ min: Math.floor(this.filterOptions.residualRiskMin), max: Math.ceil(this.filterOptions.residualRiskMax) },
				FormatterType.Currency
			);
		}
	}

	private setupControlOptionsFilters(controlFrameworks: ControlFrameworkModel[]) {
		this.controlFrameworkFilterSetup = this.createSelectFilter(
			'global_controlFramework_label',
			this.createControlFrameworSelectGroup(controlFrameworks)
		);
	}

	private createControlFrameworSelectGroup(controlFrameworks: ControlFrameworkModel[]) {
		return [
			{
				options: controlFrameworks.map(
					x =>
						<FilterSelectOption<string>>{
							displayName: this.translateService.get(x.displayName),
							value: x.id
						}
				)
			}
		];
	}
}

<ng-template #template
             let-dataItem="dataItem">
  <ng-container *ngIf="!tableDataDirective">
    <ng-template *ngTemplateOutlet="defaultTemplate context: { dataItem: dataItem }"></ng-template>
  </ng-container>

  <ng-container *ngIf="tableDataDirective">
    <ng-template [ngTemplateOutlet]="tableDataDirective.template"
                 [ngTemplateOutletContext]="{ dataItem: dataItem }">
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #defaultTemplate
             let-dataItem="dataItem">
  {{getTableDataValue(dataItem, field)}}
</ng-template>

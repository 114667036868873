import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
	selector: '[rqClickOutside]'
})
export class ClickOutsideDirective {
	@Output()
	public readonly rqClickOutside = new EventEmitter<MouseEvent>();

	@Input()
	public excludeElement!: HTMLElement;

	constructor(private elementRef: ElementRef<HTMLElement>) {}

	@HostListener('document:click', ['$event', '$event.target'])
	public onClick(event: MouseEvent, targetElement: HTMLElement): void {
		if (!targetElement) {
			return;
		}
		if (!this.isClickInside(targetElement)) {
			this.rqClickOutside.emit(event);
		}
	}

	private isClickInside(targetElement: HTMLElement) {
		if (this.elementRef.nativeElement.contains(targetElement)) {
			return true;
		} else {
			return this.excludeElement?.contains(targetElement) || false;
		}
	}
}

<rq-filter class="filters p-l-lg p-t-sm p-b-sm"
           (filterChange)="triggerFilterChange($event.description.value)"
           [config]="filterConfig">
</rq-filter>

<div class="evidences-wrapper"
     [ngStyle]="{'grid-template-columns': 'repeat(' + noOfColumns +', 1fr)'}"
     *ngIf="evidences.length > 0">
  <ng-container *ngFor="let item of evidences; let i = index">
    <div [ngClass]="item?.description ? 'evidence-item' : 'empty-item'">
      <ng-container *ngIf="item?.description">
        <ng-container *ngIf="(noOfColumns === 3) && ((i+1) % 3 === 0)">
          <span title="{{'evidences_subsumed_label' | translate}}"
                *ngIf="item.implementationState === DefensebilityImplementationState.Subsumed"
                class="bullet subsumed-bullet">
          </span>
          <span title="{{'evidences_both_label' | translate}}"
                *ngIf="item.implementationState === DefensebilityImplementationState.Both"
                class="bullet both-bullet"></span>
          <span title="{{'evidences_new_label' | translate}}"
                *ngIf="item.implementationState === DefensebilityImplementationState.New"
                class="bullet new-bullet"></span>
        </ng-container>
        <span>{{item.description}}</span>
      </ng-container>
    </div>
  </ng-container>
</div>

<div class="no-results"
     *ngIf="evidences.length === 0">
  <p class="m-l-md m-b-md m-t-md text-sm primary-l-10-color">{{'global_table_noData_with_filter' | translate}}</p>
</div>


<div class="bullet-legend">
  <ng-container *ngIf="noOfColumns === 3">
    <p class="m-r-md">
      <span class="bullet both-bullet"></span>
      <span>{{'evidences_both_description' | translate}}</span>
    </p>

    <p class="m-r-md">
      <span class="bullet new-bullet"></span>
      <span>{{'evidences_new_description' | translate}}</span>
    </p>

    <p class="m-r-md">
      <span class="bullet subsumed-bullet"></span>
      <span>{{'evidences_subsumed_description' | translate}}</span>
    </p>
  </ng-container>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { AuditBehaviorType } from '../../../server/enums/audit-behavior-type.enum';
import { TranslationService } from '../../services/translation.service';

@Pipe({
	name: 'auditBehaviorTypeLabel'
})
export class AuditBehaviorTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: AuditBehaviorType) {
		const map = new Map<AuditBehaviorType, string>();
		map.set(AuditBehaviorType.Create, 'global_create_label');
		map.set(AuditBehaviorType.Delete, 'global_delete_label');
		map.set(AuditBehaviorType.ChangePasswordFail, 'global_changePasswordFail_label');
		map.set(AuditBehaviorType.Update, 'global_update_label');
		map.set(AuditBehaviorType.Archived, 'global_archived_label');
		map.set(AuditBehaviorType.Unarchived, 'global_unarchived_label');
		map.set(AuditBehaviorType.Assign, 'global_assign_label');
		map.set(AuditBehaviorType.Unassign, 'global_unassign_label');
		map.set(AuditBehaviorType.ChangePassword, 'global_changePassword_label');
		map.set(AuditBehaviorType.DeactivateAccount, 'global_deactivatedAccount_label');
		map.set(AuditBehaviorType.ReactivateAccount, 'global_reactivateAccount_label');
		map.set(AuditBehaviorType.Login, 'global_login_label');
		map.set(AuditBehaviorType.Logout, 'global_logout_label');
		map.set(AuditBehaviorType.RunRiskAnalysis, 'global_runRiskAnalysis_label');
		map.set(AuditBehaviorType.LoginFail, 'global_loginFail_label');
		map.set(AuditBehaviorType.InitialData, 'audit_initialData');
		map.set(AuditBehaviorType.ThirdPartyRunAnalysis, 'audit_runTpAnalysis');

		const type = map.get(value);
		if (!type) {
			throw new Error(`Audit action type ${value} is not handled`);
		}

		return this.translationService.get(type);
	}
}

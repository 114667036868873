<rq-header [isPortfolionDropdownVisible]="false"
           [isNotificationVisible]="false"
           (logoutAction)="logout()"></rq-header>

<rq-card [isPage]="true"
         [isLoaded]="true">
  <ng-template rqCardContent>
    <div class="flex-vertical-center">
      <div class="toast-danger">{{'global_message_noLegalEntities' | translate}}</div>
    </div>
  </ng-template>
</rq-card>

<rq-footer></rq-footer>

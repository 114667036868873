import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { TableColumnComponent } from '../table-column/table-column.component';
import { Filter, FilterOutput } from '../../form-elements/filter/filter.model';

@Component({
	selector: 'rq-table-head-data',
	templateUrl: './table-head-data.component.html',
	styleUrls: ['./table-head-data.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableHeadDataComponent {
	@Input()
	public column!: TableColumnComponent;

	@Input()
	public filterOutput!: FilterOutput;

	@Input()
	public isSortable = true;

	@Input()
	public isDisabled!: boolean;

	@HostBinding('class.justify-content-start')
	public get textLeft(): boolean {
		return this.column.textAlign === 'left';
	}

	@HostBinding('class.justify-content-end')
	public get textRight(): boolean {
		return this.column.textAlign === 'right';
	}

	@HostBinding('class.justify-content-center')
	public get textCenter(): boolean {
		return this.column.textAlign === 'center';
	}

	public get isSortedDescending() {
		const filter = this.getFilter(this.column.field) as Filter<unknown>;
		return typeof filter?.isSortedAscending === 'boolean' && !filter?.isSortedAscending;
	}

	public get isSortedAscending() {
		const filter = this.getFilter(this.column.field) as Filter<unknown>;
		return typeof filter?.isSortedAscending === 'boolean' && filter?.isSortedAscending;
	}

	private getFilter(name: string) {
		const entry = Object.entries(this.filterOutput).find(x => x[0] === name);
		return entry ? entry[1] : undefined;
	}
}

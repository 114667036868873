<rq-input #inputControl
          [(ngModel)]="input"
          (keyup.enter)="insertValue($event)"
          (keydown.tab)="insertValue($event)"
          (blur)="insertValue()"
          [disabled]="disabled"
          [placeholder]="placeholder"></rq-input>

<div *ngFor="let value of values; let i = index"
     class="m-b-sm p-l-sm p-r-sm"
     [ngClass]="disabled ? 'badge-archived' : 'badge-secondary'"
     [class.m-r-sm]="i < values.length - 1">
  <label class="d-inline-flex m-b-none">{{ value }}</label>

  <rq-icon *ngIf="!disabled"
           class="pointer d-inline-flex m-l-sm"
           href="icon-close"
           (click)="removeValue(i)"></rq-icon>
</div>

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { MitreAnalysisObservationModel } from '../../models/mitre-analysis-observation-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';

@Injectable()
export class MitreAnalysisObservationDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		scenarioId: string,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<MitreAnalysisObservationModel>> {
		options.params = this.httpService.getHttpParams({
			scenarioId,
			pagination
		});

		return this.httpService
			.request<Pagination<MitreAnalysisObservationModel>>(
				'get',
				environment.api + '/api/Scenario/MitreAnalysisObservation/GetPaginated',
				options
			)
			.toPromise();
	}
}

import { Component, Input, OnChanges, ViewChild, SimpleChanges } from '@angular/core';
import {
	ImprovementViewModel,
	LTRecommendationDetailedConfigViewModel,
	LTRecommendationDetailedFilterViewModel,
	LTRecommendationDetailedViewModel
} from '../../../routes/control-analysis/_common/models/recommendations-detailed-view.viewmodel';
import { ApplicationsModalComponent } from './applications-modal/applications-modal.component';
import { TableBase2Component } from '../../../standalone/components/table/table-base2.component';
import { CurrencyConfig } from '../../models/number.config';
import { FormatterType } from '../../enums/formatter-type.enum';
import { FilterSelectOption, RangeFilter, SelectFilter } from '../../../standalone/components/form-elements/filter/filter.model';
import { Customizer } from '../../services/customizer';

@Component({
	selector: 'rq-recommendation-detailed-table',
	templateUrl: './recommendation-detailed-table.component.html',
	styleUrls: ['./recommendation-detailed-table.component.scss']
})
export class RecommendationDetailedTableComponent
	extends TableBase2Component<LTRecommendationDetailedViewModel, LTRecommendationDetailedFilterViewModel>
	implements OnChanges
{
	@ViewChild(ApplicationsModalComponent, { static: true })
	public applicationsModal!: ApplicationsModalComponent;

	@Input()
	public isLegalEntityVisible = false;

	@Input()
	public isImprovementVisible = false;

	@Input()
	public isSortable = true;

	@Input()
	public hasFilters = true;

	@Input()
	public filterOptions!: LTRecommendationDetailedConfigViewModel;

	@Input()
	public noRecommendationMessage = 'dashboard_control_no_recommendations_message';

	//EPR-8126 - view details is hidden until we have the right data.
	//@Input()
	public isActionsColumnVisible = false;

	public improvementsFilterSetup!: SelectFilter;

	public expectedLossReductionFilterSetup!: RangeFilter;

	public expectedProbabilityReductionFilterSetup!: RangeFilter;

	constructor(private customizer: Customizer) {
		super();
	}

	public get isMessageVisible() {
		return !this.count && !this.hasFiltersApplied;
	}

	private get currencyConfig(): CurrencyConfig {
		const currencyConfig = new CurrencyConfig();
		currencyConfig.isAbbr = true;
		currencyConfig.decimals = 1;
		currencyConfig.rounding = value => value;
		currencyConfig.symbol = this.customizer.currency;

		return currencyConfig;
	}

	public async ngOnChanges(changes: SimpleChanges) {
		await super.ngOnChanges(changes);

		if (changes.filterOptions?.currentValue) {
			this.setupFilters(changes.filterOptions.currentValue as LTRecommendationDetailedConfigViewModel);
		}
	}

	public triggerViewDetails(data: LTRecommendationDetailedViewModel) {
		this.applicationsModal.controlId = data.controlOptionId;
		this.applicationsModal.analysisId = data.analysisId;
		this.applicationsModal.show();
	}

	private setupFilters(filterOptions?: LTRecommendationDetailedConfigViewModel) {
		if (filterOptions) {
			this.improvementsFilterSetup = this.createSelectFilter(
				'global_improves_label',
				this.createImprovementsGroups(filterOptions.improvements)
			);

			this.expectedLossReductionFilterSetup = this.createRangeFilter(
				'recommendation_financialReduction_title',
				filterOptions.expectedLossReduction,
				FormatterType.Currency,
				this.currencyConfig
			);

			this.expectedProbabilityReductionFilterSetup = this.createRangeFilter(
				'recommendation_longTerm_psReduction',
				filterOptions.expectedLossProbabilityReduction,
				FormatterType.Percent,
				this.currencyConfig
			);
		}
	}

	private createImprovementsGroups(options?: Array<ImprovementViewModel>) {
		return [
			{
				options: options?.map(x => new FilterSelectOption<string>(x.displayName, x.id)) ?? []
			}
		];
	}
}

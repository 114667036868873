export interface LegendBarChart {
	label: string;
	value: number;
	color?: string;
}

export enum LegendBarSize {
	Small = 'small',
	Medium = 'medium'
}

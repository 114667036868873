import { NotificationType } from '../../server/enums/notification-type.enum';
import { CustomModelsWizardStep } from './_common/enums/custom-models-wizard-steps.enum';
import { EnterpriseDefaultLeSettingsMenuTypeEnum } from './_common/enums/enterprise-default-le-settings-menu-type.enum';
import { EnterpriseCreateUserWizardStep } from './_common/models/enterprise-user-create.model';
import { LegalEntityWizardStep } from './_common/models/legal-entity-edit.model';

export const ENTERPRISE_ROUTE = {
	path: 'enterprise',
	url: enterpriseUrl,
	portfolioAnalysis: {
		path: 'portfolio-analysis',
		url: enterprisePortfolioAnalysisUrl
	},
	dataExport: {
		path: 'data-export',
		url: enterpriseDataExportUrl
	},
	configuration: {
		path: 'configuration',
		url: enterpriseConfigurationUrl,
		customModels: {
			path: 'custom-models',
			url: enterpriseCustomModelsUrl,
			edit: {
				path: 'edit/:customModelId',
				url: enterpriseCustomModelEditUrl,
				generalInformation: {
					path: `${CustomModelsWizardStep.GeneralInformation}`
				},
				formula: {
					path: `${CustomModelsWizardStep.Formula}`
				},
				summary: {
					path: `${CustomModelsWizardStep.Summary}`
				},
				withSource: {
					path: ':stepType',
					url: enterpriseCustomModelEditWithSourceUrl
				}
			}
		},
		customTables: {
			path: 'custom-tables',
			url: enterpriseConfigurationCustomTablesUrl,
			edit: {
				path: 'edit/:tableId',
				url: enterpriseConfigurationCustomTablesEditUrl
			}
		},
		legalEntities: {
			path: 'legal-entities',
			url: enterpriseLegalEntitiesUrl,
			edit: {
				path: 'edit/:id',
				url: enterpriseLegalEntitiesEditStepUrl,
				generalInformation: {
					path: `${LegalEntityWizardStep.GeneralInformation}`
				},
				assignUsers: {
					path: `${LegalEntityWizardStep.AssignUsers}`
				},
				summary: {
					path: `${LegalEntityWizardStep.Summary}`
				}
			}
		}
	},
	userManagement: {
		path: 'user-management',
		url: enterpriseSettingsUserManagementUrl,
		create: {
			path: 'create',
			url: enterpriseSettingsUserManagementUserCreateUserUrl,
			generalInformation: {
				path: `${EnterpriseCreateUserWizardStep.GeneralInformation}`
			}
		},
		edit: {
			path: 'edit/:userId',
			url: enterpriseSettingsUserManagementUserEditStepUserUrl,
			generalInformation: {
				path: `${EnterpriseCreateUserWizardStep.GeneralInformation}`
			},
			assignLegalEntities: {
				path: `${EnterpriseCreateUserWizardStep.AssignLegalEntities}`
			},
			summary: {
				path: `${EnterpriseCreateUserWizardStep.Summary}`
			}
		}
	},
	defaultLESettings: {
		path: 'default-le-settings',
		url: enterpriseDefaultLESettingsUrl,
		tabs: {
			path: ':id',
			url: enterpriseSettingsDefaultLESettingsTabUrl,
			applicationCharacteristics: {
				path: `${EnterpriseDefaultLeSettingsMenuTypeEnum.ApplicationCharacteristics}`
			},
			applicationType: {
				path: `${EnterpriseDefaultLeSettingsMenuTypeEnum.ApplicationType}`
			},
			customLossVariables: {
				path: `${EnterpriseDefaultLeSettingsMenuTypeEnum.CustomVariables}`
			},
			entityValues: {
				path: `${EnterpriseDefaultLeSettingsMenuTypeEnum.EntityValues}`
			}
		}
	},
	enterpriseSsoManagement: {
		path: 'enterprise-sso-management',
		url: enterpriseEnterpriseSsoManagement
	},
	user: {
		path: 'user',
		url: enterpriseUserUrl,
		notifications: {
			path: 'notifications',
			url: enterpriseUserNotificationsUrl
		},
		preferences: {
			path: 'preferences',
			url: enterprisePreferencesUrl
		},
		activityLog: {
			path: 'activity-log',
			url: enterpriseActivityLogUrl
		}
	}
};

export function enterpriseUrl() {
	return `/${ENTERPRISE_ROUTE.path}`;
}

export function enterpriseLegalEntitiesUrl() {
	return `${enterpriseConfigurationUrl()}/${ENTERPRISE_ROUTE.configuration.legalEntities.path}`;
}

export function enterpriseLegalEntitiesEditUrl(id: string) {
	return `${enterpriseLegalEntitiesUrl()}/edit/${id}`;
}

export function enterpriseLegalEntitiesEditStepUrl(id: string, step: LegalEntityWizardStep = LegalEntityWizardStep.GeneralInformation) {
	return `${enterpriseLegalEntitiesUrl()}/edit/${id}/${step}`;
}

export function enterprisePortfolioAnalysisUrl(fragment?: string) {
	let path: string;
	if (fragment) {
		path = `${ENTERPRISE_ROUTE.portfolioAnalysis.path}#${fragment}`;
	} else {
		path = `${ENTERPRISE_ROUTE.portfolioAnalysis.path}`;
	}

	return `${enterpriseUrl()}/${path}`;
}

export function enterpriseConfigurationUrl() {
	return `${enterpriseUrl()}/${ENTERPRISE_ROUTE.configuration.path}`;
}

export function enterpriseDataExportUrl() {
	return `${enterpriseUrl()}/${ENTERPRISE_ROUTE.dataExport.path}`;
}

export function enterpriseSettingsUserManagementUrl() {
	return `${enterpriseUrl()}/${ENTERPRISE_ROUTE.userManagement.path}`;
}

export function enterpriseSettingsUserManagementUserCreateUserUrl() {
	return `${enterpriseSettingsUserManagementUrl()}/${ENTERPRISE_ROUTE.userManagement.create.path}/${
		EnterpriseCreateUserWizardStep.GeneralInformation
	}`;
}

export function enterpriseSettingsUserManagementUserEditStepUserUrl(userId: string, step: EnterpriseCreateUserWizardStep) {
	return `${enterpriseSettingsUserManagementUrl()}/edit/${userId}/${step}`;
}

export function enterprisePreferencesUrl() {
	return `${enterpriseUserUrl()}/${ENTERPRISE_ROUTE.user.preferences.path}`;
}

export function enterpriseUserUrl() {
	return `${enterpriseUrl()}/${ENTERPRISE_ROUTE.user.path}`;
}

export function enterpriseUserNotificationsUrl(name?: string, date?: string, type?: NotificationType) {
	if (name && date && type) {
		return `${enterpriseUserUrl()}/${ENTERPRISE_ROUTE.user.notifications.path}?name=${name}&date=${date}&type=${type}`;
	} else {
		return `${enterpriseUserUrl()}/${ENTERPRISE_ROUTE.user.notifications.path}`;
	}
}

export function enterpriseCustomModelsUrl() {
	return `${enterpriseConfigurationUrl()}/${ENTERPRISE_ROUTE.configuration.customModels.path}`;
}

export function enterpriseConfigurationCustomTablesUrl() {
	return `${enterpriseConfigurationUrl()}/${ENTERPRISE_ROUTE.configuration.customTables.path}`;
}

export function enterpriseConfigurationCustomTablesEditUrl(tableId: string) {
	return `${enterpriseConfigurationCustomTablesUrl()}/edit/${tableId}`;
}

export function enterpriseActivityLogUrl() {
	return `${enterpriseUserUrl()}/${ENTERPRISE_ROUTE.user.activityLog.path}`;
}

export function enterpriseEnterpriseSsoManagement() {
	return `${enterpriseUrl()}/${ENTERPRISE_ROUTE.enterpriseSsoManagement.path}`;
}

export function enterpriseDefaultLESettingsUrl() {
	return `${enterpriseUrl()}/${ENTERPRISE_ROUTE.defaultLESettings.path}`;
}

export function enterpriseSettingsDefaultLESettingsTabUrl(menuTab: EnterpriseDefaultLeSettingsMenuTypeEnum) {
	return `${enterpriseDefaultLESettingsUrl()}/${menuTab}`;
}

export function enterpriseCustomModelEditUrl(id: string, stepType: CustomModelsWizardStep = CustomModelsWizardStep.GeneralInformation) {
	return `${enterpriseCustomModelsUrl()}/edit/${id}/${stepType}`;
}

export function enterpriseCustomModelEditWithSourceUrl(
	id: string,
	returnUrl: string,
	stepType: CustomModelsWizardStep = CustomModelsWizardStep.GeneralInformation
) {
	return `${enterpriseCustomModelEditUrl(id, stepType)}?returnUrl=${returnUrl}`;
}

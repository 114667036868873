import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ScenarioAnalysisModel } from '../../models/scenario-analysis-model.model';

@Injectable()
export class ScenarioAnalysisDataService {
	constructor(private httpService: RQHttpService) {}

	public getByAnalysisId(analysisId: string, options = new HttpOptionsViewModel()): Promise<ScenarioAnalysisModel> {
		options.params = this.httpService.getHttpParams({
			analysisId
		});

		return this.httpService
			.request<ScenarioAnalysisModel>('get', environment.api + '/api/Scenario/ScenarioAnalysis/GetByAnalysisId', options)
			.toPromise();
	}

	public getByAnalysisApplicationId(
		analysisBusinessApplicationId: string,
		options = new HttpOptionsViewModel()
	): Promise<ScenarioAnalysisModel> {
		options.params = this.httpService.getHttpParams({
			analysisBusinessApplicationId
		});

		return this.httpService
			.request<ScenarioAnalysisModel>('get', environment.api + '/api/Scenario/ScenarioAnalysis/GetByAnalysisApplicationId', options)
			.toPromise();
	}

	public getByScenarioId(scenarioId: string, options = new HttpOptionsViewModel()): Promise<ScenarioAnalysisModel> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<ScenarioAnalysisModel>('get', environment.api + '/api/Scenario/ScenarioAnalysis/GetByScenarioId', options)
			.toPromise();
	}
}

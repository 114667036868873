import { TranslationService } from 'src/app/shared/services/translation.service';
import { PipeTransform, Pipe } from '@angular/core';
import { AttackSubType } from '../../../server/enums/attack-sub-type.enum';

@Pipe({
	name: 'attackSubTypeLabel'
})
export class AttackSubTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: AttackSubType) {
		switch (value) {
			case AttackSubType.DataBreach_PCI:
				return this.translationService.get('global_PCI_label');
			case AttackSubType.DataBreach_PHI:
				return this.translationService.get('global_PHI_label');
			case AttackSubType.DataBreach_PII:
				return this.translationService.get('global_PII_label');
			case AttackSubType.Contract:
				return this.translationService.get('global_percentageOfBusinessAssetAtRisk_label');
			case AttackSubType.ContractSlaViolation:
				return this.translationService.get('global_fixedLossAmount_label');
			case AttackSubType.Invalid:
				return this.translationService.get('global_notApplicable_label');
			default:
				return '';
		}
	}
}

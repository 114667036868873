export enum ProviderType {
	'RQ' = 1,
	'Snowflake' = 2,
	'Surecloud' = 3,
	'AppSoc' = 4,
	'TenableUpload' = 5,
	'TenableApi' = 6,
	'Qualys' = 7,
	'Rapid7' = 8,
	'Archer' = 9,
	'OpenVas' = 10,
	'WindowsDefender' = 11
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { CveSeverityType } from 'src/app/server/enums/cve-severity-type.enum';

@Pipe({
	name: 'cveSeverityTypeLabel'
})
export class CveSeverityTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: CveSeverityType) {
		const values = new Map<CveSeverityType, string>();
		values.set(CveSeverityType.None, 'global_none_label');
		values.set(CveSeverityType.Low, 'global_low_label');
		values.set(CveSeverityType.Medium, 'global_medium_label');
		values.set(CveSeverityType.High, 'global_high_label');
		values.set(CveSeverityType.Critical, 'cveSeverityType_label_Critical');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${CveSeverityType[value]} does not exist`);
		}

		return this.translationService.get(type);
	}
}

import {
	Component,
	ElementRef,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import { GaugeRingChart } from './gauge-ring-chart.model';
import { EChartsType, init } from 'echarts';

@Component({
	selector: 'rq-gauge-ring-chart',
	templateUrl: './gauge-ring-chart.component.html',
	styleUrls: ['./gauge-ring-chart.component.scss']
})
export class GaugeRingChartComponent implements OnChanges, OnInit, OnDestroy {
	@ViewChild('chart', { static: true })
	public chartContainer!: ElementRef;

	@Input()
	public data!: GaugeRingChart;

	private chart?: EChartsType;

	private isResizedForRelativeSizes = false;

	private colorBrand = getComputedStyle(document.documentElement).getPropertyValue('--background-brand');

	private textColorPrimary = getComputedStyle(document.documentElement).getPropertyValue('--text-secondary');

	private colorGrayLight = getComputedStyle(document.documentElement).getPropertyValue('--background-neutral-overlay');

	private resizeObserver!: ResizeObserver;

	constructor(private hostElement: ViewContainerRef) {}

	@HostListener('window:resize')
	public onResize() {
		if (this.chart) {
			this.chart.resize();
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.data) {
			this.setData(changes.data.currentValue as GaugeRingChart);
		}
	}

	public ngOnInit() {
		this.subscribeToResizeLayoutChange();
	}

	public ngOnDestroy() {
		this.resizeObserver.disconnect();
	}

	private setData(data: GaugeRingChart) {
		if (data) {
			if (!this.chart) {
				this.chart = init(this.chartContainer.nativeElement as HTMLElement);
			} else {
				this.chart?.clear();
			}

			const options = {
				series: [
					{
						type: 'gauge',
						startAngle: 90,
						endAngle: -270,
						itemStyle: {
							color: this.textColorPrimary
						},
						pointer: {
							show: false
						},
						progress: {
							show: true,
							overlap: false,
							roundCap: true,
							clip: false,
							itemStyle: {
								color: this.colorBrand
							}
						},
						emphasis: {
							itemStyle: {
								color: this.colorBrand
							}
						},
						axisLine: {
							lineStyle: {
								color: [[1, this.colorGrayLight]],
								width: 20
							}
						},
						splitLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						axisLabel: {
							show: false
						},
						data: [data],
						title: {
							fontSize: 14
						},
						detail: {
							fontSize: 60,
							offsetCenter: ['0%', '5%'],
							color: this.textColorPrimary,
							formatter: (value: string) => {
								return `${value}%`;
							}
						}
					}
				]
			};

			this.chart.setOption(options);

			this.chart.on('rendered', () => {
				if (!this.isResizedForRelativeSizes) {
					this.isResizedForRelativeSizes = true;
					this.chart?.resize();
				}
			});
		} else {
			this.chart?.dispose();
		}
	}

	private subscribeToResizeLayoutChange() {
		this.resizeObserver = new ResizeObserver(() => {
			this.chart?.resize();
		});

		this.resizeObserver.observe(this.hostElement?.element?.nativeElement as Element);
	}
}

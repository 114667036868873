import { Component, Input } from '@angular/core';

@Component({
	selector: 'rq-enterprise-controls-badge',
	templateUrl: './enterprise-controls-badge.component.html',
	styleUrls: ['./enterprise-controls-badge.component.scss']
})
export class EnterpriseControlsBadgeComponent {
	@Input()
	public value!: number;

	@Input()
	public isHighlighted = false;

	public getControlType(value: number) {
		const option = new Map<number, string>([
			[0, 'control-none'],
			[1, 'control-one'],
			[2, 'control-two'],
			[3, 'control-three'],
			[4, 'control-four'],
			[5, 'control-five']
		]);

		const color = option.get(Math.floor(value));

		if (!color) {
			return 'control-disabled';
		}

		return color;
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { LegalEntityStatusType } from '../../../server/enums/legal-entity-status-type.enum';

@Pipe({
	name: 'legalEntityStatusLabel'
})
export class LegalEntityStatusTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value?: LegalEntityStatusType) {
		if (value === undefined) {
			return '';
		}

		const values = new Map<LegalEntityStatusType, string>();
		values.set(LegalEntityStatusType.Complete, 'global_complete_label');
		values.set(LegalEntityStatusType.Draft, 'global_draft_label');
		values.set(LegalEntityStatusType.Archived, 'global_archived_label');
		values.set(LegalEntityStatusType.InDeletion, 'Deleting');

		const type = values.get(value);
		if (!type) {
			throw new Error(`LegalEntityStatusType with value ${value} has no translation configured`);
		}

		return this.translationService.get(type);
	}
}

<section [class.inline-tabs]="displayType === 'inline'"
         [class.block-tabs]="displayType === 'block'">
  <ng-container *ngTemplateOutlet="tabsTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="tabBodyTemplate"></ng-container>
</section>

<ng-template #tabsTemplate>
  <div #container
       class="tabs">
    <span #tabElement
          *ngFor="let tab of tabs; let i = index;"
          class="tab"
          e2e-test="{{tab.tabId}}"
          (click)="selectTab(tab)"
          [class.active]="tab.active"
          [class.disabled]="tab.isDisabled">
      {{ tab.label }}
    </span>
  </div>
</ng-template>

<ng-template #tabBodyTemplate>
  <div *ngIf="scope === 'tab' || scope === 'page'"
       class="tab-body">

    <ng-container *ngIf="scope === 'page'">
      <router-outlet></router-outlet>
    </ng-container>

    <ng-container *ngIf="scope === 'tab'">
      <ng-container *ngFor="let tab of tabs">
        <ng-container *ngIf="!keepTabContent; else keepContent">
          <ng-container *ngIf="tab.active">
            <ng-template [ngTemplateOutlet]="tab.template">
            </ng-template>
          </ng-container>
        </ng-container>

        <ng-template #keepContent>
          <div [hidden]="!tab.active"
               class="flex-height">
            <ng-container *ngTemplateOutlet="tab.template"></ng-container>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { BaseHubService } from './_common/base-hub.service';
import { HUB } from './_common/hub.config';
import { NotificationHubModel } from '../models/notification-hub.model';
import { ConsoleLoggerService } from '../services/console-logger.service';

@Injectable({
	providedIn: 'root'
})
export class NotificationHubService extends BaseHubService {
	constructor(loggerService: ConsoleLoggerService) {
		super(HUB.notification.path, loggerService);
	}

	public statusChange(handler: (status: NotificationHubModel) => void) {
		this.connection?.on('statusChange', handler);
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { ContractProfileType } from 'src/app/server/enums/contract-profile-type.enum';

@Pipe({
	name: 'contractProfileTypeLabel'
})
export class ContractProfileTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: ContractProfileType) {
		const values = new Map<ContractProfileType, string>();
		values.set(ContractProfileType.PercentageOfContractAtRisk, 'global_percentageOfBusinessAssetAtRisk_label');
		values.set(ContractProfileType.SLAViolationCosts, 'contract_profile_type_SLAViolationCosts');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${ContractProfileType[value]} does not exist`);
		}

		return this.translationService.get(type);
	}
}

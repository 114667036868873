import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewContainerRef } from '@angular/core';
import { LegendBarChart, LegendBarSize } from './legend-bar-chart.model';
import { FormatterType } from '../../../../shared/enums/formatter-type.enum';
import { EChartsType, init } from 'echarts';
import { COLORS } from '../../../utils/colors.utils';
import { ChartService } from '../chart.service';

@Component({
	selector: 'rq-legend-bar-chart',
	templateUrl: './legend-bar-chart.component.html',
	styleUrls: ['./legend-bar-chart.component.scss'],
	providers: [ChartService]
})
export class LegendBarChartComponent implements OnChanges, OnInit, OnDestroy {
	@Input()
	public data: LegendBarChart[] = [];

	@Input('bar.size')
	public barSize: LegendBarSize | string = LegendBarSize.Small;

	@Input('tooltip.isVisible')
	public tooltipIsVisible = false;

	@Input('value.formatter')
	public valueFormatter?: FormatterType | string;

	private chart?: EChartsType;

	private isResizedForRelativeSizes = false;

	private resizeObserver!: ResizeObserver;

	constructor(private hostElement: ViewContainerRef, private chartService: ChartService) {}

	@HostListener('window:resize')
	public onResize() {
		if (this.chart) {
			this.resize();
		}
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.data) {
			this.setData(changes.data.currentValue as LegendBarChart[]);
		}
	}

	public ngOnInit() {
		this.subscribeToResizeLayoutChange();
	}

	public ngOnDestroy() {
		this.resizeObserver.disconnect();
	}

	private setData(data: LegendBarChart[]) {
		if (data) {
			if (!this.chart) {
				this.chart = init(this.hostElement.element.nativeElement as HTMLElement);
			} else {
				this.chart?.clear();
				this.chart?.resize();
			}
			const options = {
				color: COLORS.rainbowColorScheme,
				grid: this.getGridConfig(),
				tooltip: this.getTooltipConfig(),
				xAxis: [
					{
						type: 'value',
						axisLabel: { show: false },
						splitLine: {
							show: false
						}
					}
				],
				yAxis: {
					type: 'category',
					show: false
				},
				series: this.getSeries(data)
			};

			this.chart.setOption(options);

			this.chart.on('rendered', () => {
				if (!this.isResizedForRelativeSizes) {
					this.isResizedForRelativeSizes = true;
					this.resize();
				}
			});
		} else {
			this.chart?.dispose();
		}
	}

	private getTooltipConfig() {
		return {
			show: this.tooltipIsVisible,
			formatter: '{a}',
			textStyle: {
				overflow: 'truncate',
				width: 200,
				height: 200
			},
			extraCssText: 'width: 300px; height: auto; display: inline-block; word-wrap:break-word; white-space: normal;'
		};
	}

	private getSeries(data: LegendBarChart[]) {
		const grid = this.getGridConfig();
		const width = this.hostElement.element.nativeElement.childNodes[0].offsetWidth - (grid.left + grid.right);

		return data.map((x, i) => ({
			type: 'bar',
			stack: `${i}`,
			data: [x.value],
			name: x.label,
			barGap: this.barSize === LegendBarSize.Small ? 4 : 0.82,
			barWidth: this.barSize === LegendBarSize.Small ? 7 : 30,
			showBackground: true,
			backgroundStyle: {
				color: getComputedStyle(document.documentElement).getPropertyValue('--background-neutral-overlay')
			},
			itemStyle: {
				color: x?.color ?? (i >= COLORS.rainbowColorScheme.length ? 'black' : COLORS.rainbowColorScheme[i])
			},
			label: {
				show: true,
				position: 'insideTopLeft',
				offset: [-8, -23],
				formatter: () => {
					const maxChars = width * (11 / 100);
					let label = x.label;

					if (x.label.length >= maxChars) {
						label = x.label.substring(0, maxChars) + '...';
					}

					return `{alignLeft| ${label}} {alignRight| ${this.chartService.getFormatter(this.valueFormatter)(x.value)}}`;
				},
				overflow: 'truncate',
				rich: {
					alignLeft: {
						width: width * (70 / 100),
						fontSize: 13,
						align: 'left',
						color: 'black'
					},
					alignRight: {
						width: width * (30 / 100),
						fontSize: 13,
						align: 'right',
						color: 'black'
					}
				}
			}
		}));
	}

	private getGridConfig() {
		return {
			top: 20,
			bottom: 20,
			left: 10,
			right: 30
		};
	}

	private resize() {
		this.chart?.resize();

		this.chart?.setOption(
			{
				series: this.getSeries(this.data)
			},
			{ silent: true }
		);
	}

	private subscribeToResizeLayoutChange() {
		this.resizeObserver = new ResizeObserver(() => {
			this.resize();
		});

		this.resizeObserver.observe(this.hostElement?.element?.nativeElement as Element);
	}
}

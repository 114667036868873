import { Component, ElementRef, HostBinding, HostListener, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateFormatterService } from '../../../../shared/services/date-formatter.service';
import { Customizer } from '../../../../shared/services/customizer';

@Component({
	selector: 'rq-date-picker',
	templateUrl: './date-picker.component.html',
	styleUrls: ['./date-picker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DatePickerComponent),
			multi: true
		}
	]
})
export class DatePickerComponent implements ControlValueAccessor {
	@Input()
	@HostBinding('class.disabled')
	public disabled!: boolean;

	@Input()
	public format!: string;

	@Input()
	public placeholder!: string;

	public calendarVisible = false;

	public value = '';

	private element!: HTMLElement;

	private onTouchedCallback!: () => void;

	private onChangeCallback!: (_: unknown) => void;

	constructor(eRef: ElementRef, customizer: Customizer, private dateFormatterService: DateFormatterService) {
		this.element = eRef.nativeElement as HTMLElement;
		this.format = customizer.date.format;
	}

	public get displayValue() {
		return this.value ? this.dateFormatterService.toLocalDate(this.value).toISOString() : '';
	}

	@HostListener('document:click', ['$event'])
	public clickout(event: Event) {
		if (!this.element.contains(<Node>event.target)) {
			this.calendarVisible = false;
		}
	}

	public registerOnChange(fn: (_: unknown) => void) {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: () => void) {
		this.onTouchedCallback = fn;
	}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	public writeValue(value: string) {
		this.value = value;
	}

	public onFocus() {
		this.calendarVisible = true;
		this.onTouchedCallback();
	}

	public onModelChange(value: string) {
		this.writeValue(value);

		this.onChangeCallback(value);

		this.calendarVisible = false;
	}
}

import {
	ChangeDetectorRef,
	Component,
	ContentChild,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnInit,
	Output,
	TemplateRef
} from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { MAX_NUMBER } from '../../../utils/constants.utils';
import { twoDecimalValidator } from '../../../utils/form-validators.utils';

type CustomUnknownType = string | number | null | unknown[];

@Component({
	selector: 'rq-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss']
})
export class InputComponent implements ControlValueAccessor, OnInit {
	@Input()
	public type: 'text' | 'number' | 'password' | 'email' = 'text';

	@Input()
	public placeholder = '';

	@Input()
	public prefix?: string;

	@Input()
	public suffix?: string;

	@Input()
	@HostBinding('class.input-editing')
	public isEditState = true;

	@Input()
	public isFormatted = true;

	@Input()
	public areNegativeValuesAllowed = false;

	@Input()
	public size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'md';

	@Input()
	public formControlName!: string;

	@Input()
	public isDecimalValidatorDisabled = false;

	@Input()
	public hasShowPasswordButton = false;

	@Input()
	public isEditValueRightAlign = true;

	@HostBinding('class.input-disabled')
	public isDisabled = false;

	@ContentChild(TemplateRef)
	public editTemplate!: TemplateRef<unknown>;

	@Output()
	public readonly focus = new EventEmitter();

	@Output()
	public readonly blur = new EventEmitter();

	public isFocusing = false;

	public innerValue?: string | null;

	public showPassword = false;

	private isSpinnerVisible = false;

	private activeSize?: string;

	private onTouchedCallback!: (value?: CustomUnknownType) => void;

	private onChangeCallback!: (value?: CustomUnknownType) => void;

	constructor(private cd: ChangeDetectorRef, private control: NgControl, private elementRef: ElementRef) {
		control.valueAccessor = this;
	}

	public get isFormattedInputNumber() {
		return this.type === 'number' && this.isFormatted;
	}

	public get isShowPasswordButtonVisible() {
		return this.type === 'password' && this.hasShowPasswordButton;
	}

	public get value() {
		if (this.innerValue === undefined) {
			return undefined;
		}

		if (this.innerValue === null) {
			return null;
		}

		if (this.isFormattedInputNumber) {
			return this.innerValue ? parseFloat(this.innerValue.replace(/,/g, '')) : null;
		}

		return this.innerValue;
	}

	public get input() {
		return (this.elementRef.nativeElement as HTMLElement).querySelector<HTMLInputElement>('input') as HTMLInputElement;
	}

	public get isLoadingVisible() {
		if (!this.control.touched) {
			return false;
		}

		return this.isSpinnerVisible || Boolean(this.control.pending);
	}

	public get hasPrefix() {
		return Boolean(this.prefix);
	}

	public get hasSuffix() {
		return Boolean(this.suffix) || this.hasShowPasswordButton;
	}

	public get isDangerState() {
		return this.control.touched && this.control.invalid;
	}

	private get isFocusable() {
		return !this.isDisabled && !this.isFocusing && this.isEditState;
	}

	public ngOnInit(): void {
		this.setupDimension(this.size, 'input-control');

		if (this.type === 'number') {
			this.control.control?.addValidators(this.createValidator());
		}
	}

	public getInputType() {
		if (this.showPassword || this.isFormattedInputNumber) {
			return 'text';
		} else {
			return this.type;
		}
	}

	public writeValue(value?: string | number | null): void {
		this.innerValue = value?.toString();
	}

	public triggerEnter() {
		if (this.isEditState && this.control.valid) {
			this.input.blur();
		}
	}

	public toggleEditState() {
		if (this.isDisabled || (this.isEditState && this.control.invalid)) {
			return;
		}

		this.isEditState = !this.isEditState;

		this.triggerFocus();
	}

	public async toggleLoading<T>(entity: boolean | (() => Promise<void>) | (() => Promise<T>)) {
		let data: T | void = undefined;

		if (typeof entity !== 'boolean') {
			this.isSpinnerVisible = true;

			this.cd.detectChanges();

			data = await entity();

			this.isSpinnerVisible = false;
		} else {
			this.isSpinnerVisible = entity;
		}

		this.cd.detectChanges();

		return data;
	}

	public triggerFocus() {
		if (this.isFocusable) {
			this.isFocusing = true;

			this.cd.detectChanges();

			this.input.focus();

			this.focus.emit();
		}
	}

	public triggerBlur() {
		this.isFocusing = false;

		this.cd.detectChanges();

		this.onTouchedCallback(this.value);

		this.blur.emit();
	}

	public valueChange() {
		this.onChangeCallback(this.value);
	}

	public setDisabledState(isDisabled: boolean) {
		this.isDisabled = isDisabled;
	}

	public showPasswordAction() {
		this.showPassword = !this.showPassword;
	}

	public registerOnChange(fn: (value?: CustomUnknownType) => void): void {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: (value?: CustomUnknownType) => void): void {
		this.onTouchedCallback = fn;
	}

	private setupDimension(size: string, prefix: string) {
		if (this.activeSize) {
			(this.elementRef.nativeElement as HTMLElement).classList.remove(`${prefix}-${this.activeSize}`);
		}

		this.activeSize = size;

		(this.elementRef.nativeElement as HTMLElement).classList.add(`${prefix}-${size}`);
	}

	private createValidator() {
		const validators = [Validators.max(MAX_NUMBER)];

		if (!this.isDecimalValidatorDisabled) {
			validators.push(twoDecimalValidator());
		}

		return validators;
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { NumberPipeConfig } from './models/number-pipe.model';
import { isNumber } from '../utils/helpers.utils';
import { NumberFormatterService } from '../../shared/services/number-formatter.service';
import { NumberConfig } from '../../shared/models/number.config';

@Pipe({
	name: 'rqNumber',
	standalone: true
})
export class RqNumberPipe implements PipeTransform {
	constructor(private numberFormatter: NumberFormatterService) {}

	public transform(value?: number, config?: NumberPipeConfig): string | null {
		const numberConfig = Object.assign(<NumberConfig>{}, config);

		return isNumber(value) ? this.numberFormatter.number(<number>value, numberConfig) : '';
	}
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ApplicationThreatViewModel } from '../../models/application-threat-view-model.model';
import { IActionResult } from '../../models/iaction-result.model';

@Injectable()
export class ApplicationThreatsDataService {
	constructor(private httpService: RQHttpService) {}

	public getByApplicationId(businessApplicationId: string, options = new HttpOptionsViewModel()): Promise<ApplicationThreatViewModel> {
		options.params = this.httpService.getHttpParams({
			businessApplicationId
		});

		return this.httpService
			.request<ApplicationThreatViewModel>('get', environment.api + '/api/Configuration/ApplicationThreats/GetByApplicationId', options)
			.toPromise();
	}

	public update(applicationThreatsModel: ApplicationThreatViewModel, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.body = applicationThreatsModel;

		return this.httpService
			.request<IActionResult>('post', environment.api + '/api/Configuration/ApplicationThreats/Update', options)
			.toPromise();
	}
}

<div class="time-picker-wrapper">
  <div class="input-parent">
    <input type="text"
           autocomplete="off"
           class="form-control"
           [placeholder]="placeholder"
           [disabled]="disabled"
           [ngModel]="displayValue | rqTime"
           (focus)="onFocus()">
    <span class="time-icon">
      <rq-icon [class.disabled]="disabled"
               href="icon-clock-time">
      </rq-icon>
    </span>
  </div>
  <ng-container *ngIf="isTimepickerVisible">
    <div class="time-picker">
      <form [formGroup]="form"
            class="timer">
        <input type="text"
               class="form-control input-xs"
               placeholder="HH"
               formControlName="hour"
               [mask]="'Hh'"
               (blur)='onChange()'
               (keyup.enter)='onChange()' />

        <span>:</span>

        <input type="text"
               class="form-control input-xs"
               placeholder="mm"
               formControlName="minutes"
               [mask]="'m0'"
               (blur)='onChange()'
               (keyup.enter)='onChange()' />
      </form>
      <div class="suggestions">
        <ng-container *ngFor="let s of suggestions">
          <div class="suggestion-item"
               [ngClass]="{'selected': isSuggestionSelected(s)}"
               (click)="selectSuggestion(s)">{{ s | rqTime}}
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>

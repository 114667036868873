import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { FairLossMagnitudeSubType } from 'src/app/server/enums/fair-loss-magnitude-sub-type.enum';

@Pipe({
	name: 'fairLossMagnitudeSubtypeLabel'
})
export class FairLossMagnitudeSubtypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(type: FairLossMagnitudeSubType) {
		const values = new Map<FairLossMagnitudeSubType, string>();
		values.set(FairLossMagnitudeSubType.Aggregate, '');
		values.set(FairLossMagnitudeSubType.Revenue, 'global_revenue_label');
		values.set(FairLossMagnitudeSubType.PciFines, 'global_PCIFines_label');
		values.set(FairLossMagnitudeSubType.Ransom, 'global_ransom_label');
		values.set(FairLossMagnitudeSubType.Remediation, 'global_remediation_label');
		values.set(FairLossMagnitudeSubType.CreditMonitoring, 'fair_creditMonitoring_label');
		values.set(FairLossMagnitudeSubType.HipaaFines, 'global_HIPAAFines_label');
		values.set(FairLossMagnitudeSubType.Settlement, 'global_settlement_label');
		values.set(FairLossMagnitudeSubType.Legal, 'global_legal_label');

		const value = values.get(type);
		if (!value) {
			return '';
		}

		return this.translationService.get(value);
	}
}

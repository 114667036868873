import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { COLORS } from '../../../utils/colors.utils';

@Component({
	selector: 'rq-single-progress-bar',
	templateUrl: './single-progress-bar.component.html',
	styleUrls: ['./single-progress-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleProgressBarComponent {
	@Input()
	public maxValue!: number;

	@Input()
	public value!: number;

	@Input()
	public label!: string;

	@Input()
	public color = COLORS.infoBackground;

	@Input()
	public barLength = '120px';

	@Input('value.formatter')
	public valueFormatter?: 'number' | 'currency' | 'percent' = 'percent';

	public colorBrand = getComputedStyle(document.documentElement).getPropertyValue('--background-brand');
}

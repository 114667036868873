<div class="page-title"
     [class.w-100]="isLoading"
     [class.with-actions]="pageTitleActionDirective">
  <ng-container *ngIf="!isLoading">
    <ng-container *ngIf="title">
      <ng-template *ngTemplateOutlet="defaultTemplate"></ng-template>
    </ng-container>

    <ng-container *ngIf="!title && customTemplate">
      <ng-template *ngTemplateOutlet="customTemplate"></ng-template>
    </ng-container>

    <ng-container *ngIf="pageTitleActionDirective">
      <ng-template [ngTemplateOutlet]="pageTitleActionDirective.template">
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isLoading">
    <ng-template *ngTemplateOutlet="loadingTemplate"></ng-template>
  </ng-container>
</div>

<ng-template #defaultTemplate>
  {{ title | translate }}
</ng-template>

<ng-template #loadingTemplate>
  <rq-loader [type]="'line'"
             [lineHeight]="'32px'"></rq-loader>
</ng-template>

import { Filter } from './filter.model';
import { FilterGroupModel } from './filter-group-model.model';

export class AnalysisDetailsFilterModel {
	public 'attackId'!: Filter<string>;
	public 'applicationName'!: Filter<string>;
	public 'tTpName'!: Filter<string>;
	public 'frequency'!: Filter<FilterGroupModel<string, number>>;
	public 'expectedLossReduction'!: Filter<FilterGroupModel<string, number>>;
	public 'expectedLossProbabilityReduction'!: Filter<FilterGroupModel<string, number>>;
}

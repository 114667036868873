export enum FairLossMagnitudeSubType {
	'Aggregate' = 1,
	'Revenue' = 2,
	'PciFines' = 3,
	'Ransom' = 4,
	'Remediation' = 5,
	'CreditMonitoring' = 6,
	'HipaaFines' = 7,
	'Settlement' = 8,
	'Legal' = 9
}

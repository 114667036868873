import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { AttackViewModel } from '../../models/attack-view-model.model';

@Injectable()
export class AttackDataService {
	constructor(private httpService: RQHttpService) {}

	public getByLegalEntity(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<AttackViewModel[]> {
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService
			.request<AttackViewModel[]>('get', environment.api + '/api/Configuration/Attack/GetByLegalEntity', options)
			.toPromise();
	}
}

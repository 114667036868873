<ng-container *ngFor="let item of options; index as i">
  <div *ngIf="!item.options.length"
       [class.disabled]="item.isDisabled"
       class="item"
       (click)="clicked(item)">
    <ng-container [ngTemplateOutlet]="item?.customName?.templateRef || defaultName"
                  [ngTemplateOutletContext]="{$implicit: item, index: i}"></ng-container>
  </div>

  <div *ngIf="item.options.length"
       class="expanded-item">
    <div class="item"
         [class.disabled]="item.isDisabled"
         (click)="clicked(item)">
      <ng-container [ngTemplateOutlet]="item?.customName?.templateRef || defaultName"
                    [ngTemplateOutletContext]="{$implicit: item, index: i}"></ng-container>
      <div class="arrowright"></div>
    </div>
    <rq-three-dots-menu-items [options]="item.options"
                              (itemClicked)="clicked()">
    </rq-three-dots-menu-items>
  </div>
</ng-container>

<ng-template #defaultName
             let-item
             let-index="index">
  <p class="menu-name">{{item?.name}}</p>
</ng-template>

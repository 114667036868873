import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { EndpointCveModel } from '../../models/endpoint-cve-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';

@Injectable()
export class EndpointCveDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		legalEntityId: string,
		pagination: PaginationFilter,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<EndpointCveModel>> {
		options.params = this.httpService.getHttpParams({
			legalEntityId,
			pagination
		});

		return this.httpService
			.request<Pagination<EndpointCveModel>>('get', environment.api + '/api/Configuration/EndpointCve/GetPaginated', options)
			.toPromise();
	}
}

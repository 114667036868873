import {
	ChangeDetectionStrategy,
	Component,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewContainerRef
} from '@angular/core';
import { BubbleChart } from './models/bubble-chart.model';
import { FormatterType } from '../../../../shared/enums/formatter-type.enum';
import { BubbleChartAxisConfig } from './models/bubblechart-axis-config.model';
import { EChartsType, init } from 'echarts';
import { ChartService } from '../chart.service';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { COLORS } from '../../../utils/colors.utils';

@Component({
	selector: 'rq-bubble-chart',
	templateUrl: './bubble-chart.component.html',
	styleUrls: ['./bubble-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ChartService]
})
export class BubbleChartComponent implements OnChanges, OnInit, OnDestroy {
	@Input()
	public data: BubbleChart[] = [];

	@Input('legend.isVisible')
	public legendIsVisible = true;

	@Input('legend.tooltip.isVisible')
	public legendTooltipIsVisible = false;

	@Input('xAxis.label')
	public xAxisLabel = 'X Axis Label Here';

	@Input('xAxis.formatter')
	public xAxisFormatter?: FormatterType | string;

	@Input()
	public xAxis: BubbleChartAxisConfig = {
		label: this.xAxisLabel,
		formatter: this.xAxisFormatter
	};

	@Input('yAxis.label')
	public yAxisLabel = 'Y Axis Label Here';

	@Input('yAxis.formatter')
	public yAxisFormatter?: FormatterType | string = FormatterType.Currency;

	@Input()
	public yAxis: BubbleChartAxisConfig = {
		label: this.yAxisLabel,
		formatter: this.yAxisFormatter
	};

	@Input('bubble.formatter')
	public bubbleFormatter: FormatterType | string = FormatterType.Percent;

	private chart?: EChartsType;

	private resizeObserver!: ResizeObserver;

	private grayColor = getComputedStyle(document.documentElement).getPropertyValue('--background-neutral-contrast');

	constructor(private hostElement: ViewContainerRef, private chartService: ChartService) {}

	@HostListener('window:resize')
	public onResize() {
		this.resize();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.xAxisLabel) {
			this.updateXAxisConfiguration({ label: changes.xAxisLabel.currentValue as string } as BubbleChartAxisConfig);
		}

		if (changes.xAxisFormatter) {
			this.updateXAxisConfiguration({ formatter: changes.xAxisFormatter.currentValue as string } as BubbleChartAxisConfig);
		}

		if (changes.yAxisLabel) {
			this.updateYAxisConfiguration({ label: changes.yAxisLabel.currentValue as string } as BubbleChartAxisConfig);
		}

		if (changes.yAxisFormatter) {
			this.updateYAxisConfiguration({ formatter: changes.yAxisFormatter.currentValue as string } as BubbleChartAxisConfig);
		}

		if (changes.data) {
			this.setData(changes.data.currentValue as BubbleChart[]);
		}
	}

	public ngOnInit() {
		this.subscribeToResizeLayoutChange();
	}

	public ngOnDestroy() {
		this.resizeObserver.disconnect();
	}

	private setData(data: BubbleChart[]) {
		if (data) {
			if (!this.chart) {
				this.chart = init(this.hostElement.element.nativeElement as HTMLElement);
			} else {
				this.chart?.clear();
			}

			const options = {
				color: COLORS.rainbowColorScheme,
				tooltip: this.getTooltipConfig(),
				grid: this.getGridConfig(),
				legend: this.getLegendConfig(data),
				xAxis: this.getXAxisConfig(data),
				yAxis: this.getYAxisConfig(),
				series: this.getSeriesConfig(data)
			};

			this.chart.setOption(options);
		} else {
			this.chart?.dispose();
		}
	}

	private getTooltipConfig() {
		return {
			formatter: ({ marker, name, value }: CallbackDataParams) => {
				return `${marker} ${name} - ${this.chartService.getFormatter(this.yAxis.formatter)(value as number)}`;
			}
		};
	}

	private getGridConfig() {
		return {
			top: 40,
			bottom: this.legendIsVisible ? 80 : 55,
			left: 70,
			right: 20
		};
	}

	private getLegendConfig(data: BubbleChart[]) {
		return {
			show: this.legendIsVisible,
			itemWidth: 22,
			itemHeight: 7,
			itemGap: 5,
			bottom: 0,
			icon: 'rect',
			formatter: (value: string) => value.replace(/\n/g, ''),
			textStyle: {
				width: (this.hostElement.element.nativeElement.scrollWidth / data.length) * (80 / 100),
				overflow: 'truncate'
			},
			tooltip: {
				show: this.legendTooltipIsVisible,
				formatter: ({ name }: CallbackDataParams) => `${name}`
			}
		};
	}

	private getXAxisConfig(data: BubbleChart[]) {
		return {
			type: 'category',
			triggerEvent: true,
			axisTick: { show: false },
			name: this.xAxis.label,
			nameLocation: 'middle',
			nameGap: 35,
			nameTextStyle: {
				color: this.grayColor,
				fontSize: 14,
				fontWeight: 600
			},
			axisLine: { show: true, lineStyle: { color: this.grayColor } },
			axisLabel: {
				interval: 0,
				overflow: 'truncate',
				formatter: (value: string) => value.replace(/\n/g, ''),
				width: (this.hostElement.element.nativeElement.scrollWidth / data.length) * (85 / 100)
			},
			data: data.map(val => val.label)
		};
	}

	private getYAxisConfig() {
		return [
			{
				type: 'value',
				axisTick: { show: false },
				name: this.yAxis.label,
				nameLocation: 'middle',
				nameGap: 55,
				nameTextStyle: {
					color: this.grayColor,
					fontSize: 14,
					fontWeight: 600
				},
				axisLine: { show: true, lineStyle: { color: this.grayColor } },
				axisLabel: {
					formatter: this.chartService.getFormatter(this.yAxis.formatter)
				}
			}
		];
	}

	private getSeriesConfig(data: BubbleChart[]) {
		const cappedPercent = 10;
		const maxBubbleSize = 90;
		const defaultBubbleSize = 45;

		return data.map(({ label, value, bubbleValue }, index) => ({
			type: 'scatter',
			name: label,
			data: [...Array<number>(index), value],
			symbolSize: bubbleValue ? Math.max(Math.min(bubbleValue * cappedPercent, maxBubbleSize), defaultBubbleSize) : defaultBubbleSize,
			label: {
				show: Boolean(bubbleValue),
				color: getComputedStyle(document.documentElement).getPropertyValue('--background-primary'),
				formatter: bubbleValue ? this.chartService.getFormatter(this.bubbleFormatter)(bubbleValue) : ''
			},
			itemStyle: {
				borderColor: getComputedStyle(document.documentElement).getPropertyValue('--border-neutral')
			}
		}));
	}

	private updateXAxisConfiguration(config: BubbleChartAxisConfig) {
		this.xAxis = { ...this.xAxis, ...config };
	}

	private updateYAxisConfiguration(config: BubbleChartAxisConfig) {
		this.yAxis = { ...this.yAxis, ...config };
	}

	private resize() {
		if (this.chart) {
			this.chart.resize();

			this.chart.setOption(
				{
					legend: this.getLegendConfig(this.data),
					xAxis: this.getXAxisConfig(this.data)
				},
				{ silent: true }
			);
		}
	}

	private subscribeToResizeLayoutChange() {
		this.resizeObserver = new ResizeObserver(() => {
			this.resize();
		});

		this.resizeObserver.observe(this.hostElement?.element?.nativeElement as Element);
	}
}

import { Component, Input, ViewChild } from '@angular/core';
import { of } from 'rxjs';
import { EvidencesDetailsModalComponent } from '../evidences-details-modal/evidences-details-modal.component';
import { ControlFrameworkDescriptionModel } from '../../../server/models/control-framework-description-model.model';
import { DefensebilityControlFilterViewModel } from '../../../server/models/defensebility-control-filter-view-model.model';
import { DefensebilityDeltaViewModel } from '../../../server/models/defensebility-delta-view-model.model';
import { ControlDefensebilityDataService } from '../../../server/services/configuration/control-defensebility-data.service';
import { ControlFrameworkDescriptionDataService } from '../../../server/services/configuration/control-framework-description-data.service';
import { ControlFrameworkDataService } from '../../../server/services/configuration/control-framework-data.service';

@Component({
	selector: 'rq-analysis-recommendation-level',
	templateUrl: './analysis-recommendation-level.component.html',
	styleUrls: ['./analysis-recommendation-level.component.scss']
})
export class AnalysisRecommendationLevelComponent {
	@ViewChild(EvidencesDetailsModalComponent, { static: true })
	public evidencesDetailsModal!: EvidencesDetailsModalComponent;

	@Input()
	public oldValue!: string;

	@Input()
	public proposedValue!: string;

	@Input()
	public controlOptionName!: string;

	@Input()
	public controlOptionId!: string;

	@Input()
	public hasEvidences!: boolean;

	public multipleData!: DefensebilityDeltaViewModel;

	public controlLevelDescriptions!: Array<ControlFrameworkDescriptionModel>;

	private defensebilityDelta!: DefensebilityDeltaViewModel;

	constructor(
		private controlDefensebilityDataService: ControlDefensebilityDataService,
		private controlFrameworkDescriptionDataService: ControlFrameworkDescriptionDataService,
		private controlFrameworkDataService: ControlFrameworkDataService
	) {}

	public get hasData() {
		return this.proposedValue && this.oldValue ? true : false;
	}

	public async showEvidences() {
		this.evidencesDetailsModal.show();

		if (!this.defensebilityDelta && !this.controlLevelDescriptions) {
			const defaultFrameworkId = await this.getDefaultFrameworkId();
			await Promise.all([this.getDelta(), defaultFrameworkId ? this.getControlFramework(defaultFrameworkId) : of(null)]);
		}

		this.multipleData = { ...this.defensebilityDelta };
	}

	private async getControlFramework(frameworkId: string) {
		this.controlLevelDescriptions = await this.controlFrameworkDescriptionDataService.getByFramework(frameworkId);
	}

	private async getDefaultFrameworkId() {
		const enterpriseFrameworkModel = await this.controlFrameworkDataService.getAll();
		const defaultFramework = enterpriseFrameworkModel.find(item => item.isDefault === true);
		return defaultFramework ? defaultFramework.id : null;
	}

	private async getDelta() {
		const filter = new DefensebilityControlFilterViewModel();
		filter.controlOptionId = this.controlOptionId;
		filter.fromLevel = parseFloat(this.oldValue);
		filter.toLevel = parseFloat(this.proposedValue);

		this.defensebilityDelta = await this.controlDefensebilityDataService.getDelta(filter);
	}
}

import { PipeTransform, Pipe } from '@angular/core';
import { AnalysisSummaryTimeFrameType } from 'src/app/server/enums/analysis-summary-time-frame-type.enum';

@Pipe({
	name: 'analysisSummaryTimeframeIconTypeLabel'
})
export class AnalysisSummaryTimeframeIconTypeTranslate implements PipeTransform {
	public transform(value: AnalysisSummaryTimeFrameType) {
		const values = new Map<AnalysisSummaryTimeFrameType, string>();

		values.set(AnalysisSummaryTimeFrameType.Short, 'icon-clock-time-down');
		values.set(AnalysisSummaryTimeFrameType.Medium, 'icon-clock-time-minus');
		values.set(AnalysisSummaryTimeFrameType.Long, 'icon-clock-time-up');

		const type = values.get(value);
		if (!type) {
			return '';
		}

		return type;
	}
}

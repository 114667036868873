import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import { HeatmapWithScatterPlotsChart } from './heatmap-with-scatter-plots-chart.model';
import { EChartsType, init } from 'echarts';
import { CallbackDataParams } from 'echarts/types/dist/shared';

@Component({
	selector: 'rq-heatmap-with-scatter-plots-chart',
	templateUrl: './heatmap-with-scatter-plots-chart.component.html',
	styleUrls: ['./heatmap-with-scatter-plots-chart.component.scss']
})
export class HeatmapWithScatterPlotsChartComponent implements OnChanges, OnInit, OnDestroy {
	@ViewChild('chart', { static: true })
	public chartContainer!: ElementRef;

	@Input() public data!: Array<HeatmapWithScatterPlotsChart>;

	@Input() public yAxisName!: string;

	@Input() public xAxisName!: string;

	@Input() public xAxisValues!: Array<string>;

	@Input() public yAxisValues!: Array<string>;

	@Input() public yMaxAxisValue!: number;

	@Input() public xMaxAxisValue!: number;

	@Input() public xMinAxisValue = 0;

	@Input() public yMinAxisValue = 0;

	@Input() public showLabel = false;

	@Input() public showScatterAxies = false;

	@Output() public readonly dataPointDetails = new EventEmitter<HeatmapWithScatterPlotsChart>();

	private colorVeryHigh = getComputedStyle(document.documentElement).getPropertyValue('--background-danger-contrast');

	private colorHigh = getComputedStyle(document.documentElement).getPropertyValue('--background-danger');

	private colorMedium = getComputedStyle(document.documentElement).getPropertyValue('--background-warning-fill');

	private colorLow = getComputedStyle(document.documentElement).getPropertyValue('--background-success-contrast');

	private colorVeryLow = getComputedStyle(document.documentElement).getPropertyValue('--background-success');

	private textColorPrimary = getComputedStyle(document.documentElement).getPropertyValue('--text-secondary');

	private textColorSecondary = getComputedStyle(document.documentElement).getPropertyValue('--text-neutral-contrast');

	private colorLight = getComputedStyle(document.documentElement).getPropertyValue('--background-primary');

	private colorGrayLight = getComputedStyle(document.documentElement).getPropertyValue('--background-neutral-overlay');

	private colorGrayDark = getComputedStyle(document.documentElement).getPropertyValue('--background-neutral-contrast');

	private colorBorder = getComputedStyle(document.documentElement).getPropertyValue('--border-neutral');

	private fontSizeSM = getComputedStyle(document.documentElement).getPropertyValue('--font-size-sm');

	private chart?: EChartsType;

	private isResizedForRelativeSizes = false;

	private resizeObserver!: ResizeObserver;

	constructor(private hostElement: ViewContainerRef) {}

	@HostListener('window:resize')
	public onResize() {
		if (this.chart) {
			this.chart.resize();
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.data) {
			this.setupChart(changes.data.currentValue as Array<HeatmapWithScatterPlotsChart>);
		}
	}

	public ngOnInit() {
		this.subscribeToResizeLayoutChange();
	}

	public ngOnDestroy() {
		this.resizeObserver.disconnect();
	}

	public saveChartImage() {
		if (this.chart) {
			const chartImage = this.chart.getDataURL({
				type: 'jpeg',
				pixelRatio: 1,
				backgroundColor: this.colorLight
			});

			const link = document.createElement('a');
			const date = new Date();

			link.download = `heat_map_${date.getTime()}.jpeg`;
			link.href = chartImage;

			link.click();
		}

		return undefined;
	}

	private setupChart(heatmapChartData: Array<HeatmapWithScatterPlotsChart>) {
		if (heatmapChartData) {
			if (!this.chart) {
				this.chart = init(this.chartContainer.nativeElement as HTMLElement);

				this.chart.on('click', 'series.scatter', params => {
					const selectedItem = this.data.find((dataItem: HeatmapWithScatterPlotsChart) => dataItem.name === params.name);

					this.dataPointDetails.emit(selectedItem);
				});
			} else {
				this.chart?.clear();
			}

			const heatMapData = this.getHeatMapArray(this.generatePatternMatrix(5, 5)).map(function (item) {
				return [item[1], item[0], item[2]];
			});

			const options = {
				tooltip: this.getTooltipConfig(),
				grid: {
					top: 40,
					bottom: 50,
					left: 100,
					right: 20
				},
				xAxis: [
					{
						type: 'category',
						show: true,
						data: this.xAxisValues,
						name: this.xAxisName,
						nameLocation: 'center',
						nameGap: 32,
						nameTextStyle: {
							fontWeight: '500',
							color: this.textColorSecondary,
							fontSize: this.fontSizeSM
						},
						splitLine: {
							show: true,
							lineStyle: {
								width: 2,
								color: this.colorLight
							}
						},
						z: 99,
						axisLine: {
							lineStyle: {
								color: this.colorGrayLight
							}
						},
						axisLabel: {
							interval: 0,
							fontSize: 14,
							color: this.textColorSecondary,
							formatter: (value: string) => value.replace(/\n/g, ''),
							overflow: 'truncate',
							width: (this.hostElement.element.nativeElement.scrollWidth / this.xAxisValues.length) * (85 / 100)
						}
					},
					{
						min: this.xMinAxisValue,
						max: this.xMaxAxisValue,
						type: 'value',
						show: this.showScatterAxies
					}
				],
				yAxis: [
					{
						type: 'category',
						show: true,
						data: this.yAxisValues,
						name: this.yAxisName,
						nameLocation: 'end',
						nameTextStyle: {
							fontWeight: '500',
							color: this.textColorSecondary,
							fontSize: this.fontSizeSM
						},
						nameGap: 16,
						splitLine: {
							show: true,
							lineStyle: {
								width: 2,
								color: this.colorLight
							}
						},
						z: 99,
						axisLine: {
							lineStyle: {
								color: this.colorGrayLight
							}
						},
						axisLabel: {
							interval: 0,
							fontSize: 14,
							overflow: 'truncate',
							width: 90,
							color: this.textColorSecondary,
							tooltip: {
								formatter: ({ name }: CallbackDataParams) => `${name}`
							}
						}
					},
					{
						min: this.yMinAxisValue,
						max: this.yMaxAxisValue,
						type: 'value',
						show: this.showScatterAxies
					}
				],
				visualMap: {
					min: 1,
					max: 5,
					seriesIndex: 1,
					show: false,
					calculable: true,
					orient: 'horizontal',
					color: [this.colorVeryHigh, this.colorHigh, this.colorMedium, this.colorLow, this.colorVeryLow]
				},
				series: [
					this.getScatterSeriesConfig(this.data),
					{
						type: 'heatmap',
						coordinateSystem: 'cartesian2d',
						silent: true,
						data: heatMapData,
						label: {
							show: false
						}
					}
				]
			};

			this.chart.setOption(options);

			this.chart.on('rendered', () => {
				if (!this.isResizedForRelativeSizes) {
					this.isResizedForRelativeSizes = true;
					this.chart?.resize();
				}
			});
		} else {
			this.chart?.dispose();
		}
	}

	private subscribeToResizeLayoutChange() {
		this.resizeObserver = new ResizeObserver(() => {
			this.chart?.resize();
		});

		this.resizeObserver.observe(this.hostElement?.element?.nativeElement as Element);
	}

	private getTooltipConfig() {
		return {
			position: 'left',
			textStyle: {
				color: this.textColorSecondary,
				fontSize: 14
			},
			borderColor: this.colorBorder,
			shadow: '0 4px 20px 0 rgba(28, 27, 32, 0.05)',
			formatter: (item: CallbackDataParams) => {
				const selectedItem = this.data.find((dataItem: HeatmapWithScatterPlotsChart) => dataItem.name === item.name);

				if (selectedItem?.type) {
					return `<div>
          <p>Name: <span style="color: ${this.textColorPrimary}">${selectedItem?.name}</span></p>
          <p>Type: <span style="color: ${this.textColorPrimary}">${selectedItem?.type}</span></p>
          <p>Impact: <span style="color: ${this.textColorPrimary}">${selectedItem?.impact}</span></p>
          <p>Likelihood: <span style="color: ${this.textColorPrimary}">${selectedItem?.likelihood}</span></p>
          <p  style="margin-top: 8px; color: ${this.colorGrayDark}">Click to view more details</p>
          </div>`;
				} else {
					return `<p>Name: <span style="color: ${this.textColorPrimary}">${selectedItem?.name}</span></p>`;
				}
			}
		};
	}

	private getScatterSeriesConfig(data: Array<HeatmapWithScatterPlotsChart>) {
		return {
			type: 'scatter',
			coordinateSystem: 'cartesian2d',
			symbolSize: 24,
			xAxisIndex: 1,
			yAxisIndex: 1,
			z: 99,
			data: data.map(item => {
				return {
					name: item.name,
					value: [item.values[0], item.values[1]],
					itemStyle: {
						color: item.itemStyle?.color,
						borderColor: item.itemStyle?.borderColor,
						borderWidth: 2,
						opacity: item.itemStyle?.hideItem ? 0 : 1
					}
				};
			}),
			label: {
				fontSize: 14,
				show: this.showLabel,
				position: 'top',
				formatter: (params: HeatmapWithScatterPlotsChart) => `${params.name}`,
				color: this.textColorPrimary
			},
			select: {
				selectedMode: 'single'
			}
		};
	}

	private generatePatternMatrix(rows: number, cols: number): number[][] {
		const pattern = [
			[3, 4, 5, 5, 5],
			[2, 3, 4, 5, 5],
			[1, 2, 3, 4, 5],
			[1, 1, 2, 3, 4],
			[1, 1, 1, 2, 3]
		];

		const matrix: number[][] = [];

		for (let i = 0; i < rows; i++) {
			const row: number[] = [];
			for (let j = 0; j < cols; j++) {
				const rowIndex = rows - i - 1;
				const colIndex = cols - j - 1;
				const value = pattern[rowIndex % pattern.length][colIndex % pattern[0].length];
				row.push(value);
			}

			const reversedRow = [...row].reverse();
			matrix.push(reversedRow);
		}

		return matrix;
	}

	private getHeatMapArray(matrix: number[][]) {
		const inputArray = [];

		for (let i = 0; i < matrix.length; i++) {
			for (let j = 0; j < matrix[i].length; j++) {
				inputArray.push([i, j, matrix[i][j]]);
			}
		}
		return inputArray;
	}
}

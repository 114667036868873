<div class="date-picker">
  <div class="input-parent">
    <input type="text"
           autocomplete="off"
           class="form-control form-control-date"
           [placeholder]="placeholder || format"
           [disabled]="disabled"
           [value]="displayValue | rqDate:{ pattern: format }"
           (focus)="onFocus()">
    <span class="date-icon">
      <rq-icon [class.disabled]="disabled"
               href="icon-calendar"></rq-icon>
    </span>
  </div>
  <rq-calendar *ngIf="calendarVisible"
               (ngModelChange)="onModelChange($event)"
               [ngModel]="value"
               [placeholder]="placeholder || placeholder">
  </rq-calendar>
</div>

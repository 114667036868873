import { TranslationService } from '../services/translation.service';
import { Injectable } from '@angular/core';
import { CustomModelType } from '../../server/enums/custom-model-type.enum';
import { AbstractControl } from '@angular/forms';
import { from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CustomLossFormulaCreateViewModel } from '../../server/models/custom-loss-formula-create-view-model.model';
import { CustomLossFormulaUpdateViewModel } from '../../server/models/custom-loss-formula-update-view-model.model';
import { CustomModelCreateViewModel } from '../../server/models/custom-model-create-view-model.model';
import { CustomModelFilterViewModel } from '../../server/models/custom-model-filter-view-model.model';
import { CustomModelUpdateViewModel } from '../../server/models/custom-model-update-view-model.model';
import { EnterpriseLossFormulaInputModel } from '../../server/models/enterprise-loss-formula-input-model.model';
import { EnterpriseCustomModelsDataService } from '../../server/services/enterprise/enterprise-custom-models-data.service';
import { EnterpriseLossFormulaDataService } from '../../server/services/enterprise/enterprise-loss-formula-data.service';
import { LossModelFilterModel } from '../../server/models/loss-model-filter-model.model';
import { CustomModelTypeTranslate } from '../pipes/translate-pipes/custom-models-type-label.pipe';
import { TableOutput } from '../../standalone/components/table/models/table-output.model';
import { Filter } from '../../standalone/components/form-elements/filter/filter.model';
import { errorValidator } from '../../standalone/utils/form-validators.utils';

@Injectable()
export class CustomModelsFacade {
	constructor(
		private enterpriseLossFormulaDataService: EnterpriseLossFormulaDataService,
		private enterpriseCustomModelsDataService: EnterpriseCustomModelsDataService,
		private customModelTypeTranslate: CustomModelTypeTranslate,
		private translationService: TranslationService
	) {}

	public createCustomModel(model: CustomModelCreateViewModel) {
		return this.enterpriseCustomModelsDataService.create(model);
	}

	public createCustomLossModel(model: CustomLossFormulaCreateViewModel) {
		return this.enterpriseLossFormulaDataService.create(model);
	}

	public updateCustomLossModel(model: CustomLossFormulaUpdateViewModel) {
		return this.enterpriseLossFormulaDataService.update(model);
	}

	public updateCustomModel(model: CustomModelUpdateViewModel) {
		return this.enterpriseCustomModelsDataService.update(model);
	}

	public async cloneCustomModel(id: string, name: string) {
		await this.enterpriseCustomModelsDataService.clone(id, name);
	}

	public async deleteCustomModel(id: string) {
		await this.enterpriseCustomModelsDataService.delete(id);
	}

	public customModelNameValidator(name?: string) {
		return (control: AbstractControl) => {
			if (name === control.value) {
				return of(null);
			}

			return from(this.enterpriseCustomModelsDataService.hasUniqueName(control.value as string)).pipe(
				map(data => (data ? null : { isUnique: true })),
				catchError(errorValidator)
			);
		};
	}

	public getCustomModel(id: string) {
		return this.enterpriseCustomModelsDataService.getById(id);
	}

	public async getCustomModels(output: TableOutput<CustomModelFilterViewModel>) {
		const data = await this.enterpriseCustomModelsDataService.getPaginated(output.pagination, output.filter);

		return {
			count: data.count,
			values: [...data.values]
		};
	}

	public createCustomModelTypeOptions() {
		return [
			{
				type: CustomModelType.Actor,
				label: `${this.customModelTypeTranslate.transform(CustomModelType.Actor)} (${this.translationService.get(
					'unavailable_type_label'
				)})`
			},
			{
				type: CustomModelType.Loss,
				label: this.customModelTypeTranslate.transform(CustomModelType.Loss)
			}
		];
	}

	public getAllCustomLossParameters(lossModelId: string) {
		const filter = new LossModelFilterModel();
		filter.lossModelId = new Filter(lossModelId);
		return this.enterpriseLossFormulaDataService.getAll(filter);
	}

	public async getLossParameters(id: string) {
		const result = await this.enterpriseLossFormulaDataService.getById(id);
		result.sort((a, b) => a.order - b.order);
		return result;
	}

	public calculateCustomModelFormula(model: EnterpriseLossFormulaInputModel) {
		return this.enterpriseLossFormulaDataService.calculate(model);
	}
}

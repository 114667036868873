import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
	selector: 'rq-point-slider',
	templateUrl: './point-slider.component.html',
	styleUrls: ['./point-slider.component.scss']
})
export class PointSliderComponent implements OnChanges {
	@Input()
	public points: { name: string; value: unknown }[] = [];

	@Input()
	public activePointValue: unknown | null = null;

	@Input()
	public leftLabel!: string;

	@Input()
	public rightLabel!: string;

	@Output()
	public readonly pointClicked = new EventEmitter<unknown>();

	public hoveredPoint: unknown | null = null;

	public activePointIndex!: number;

	public ngOnChanges() {
		if (this.activePointValue && this.points) {
			this.activePointIndex = this.points.findIndex(x => x.value === this.activePointValue);
		}
	}

	public onPointClick(value: unknown, index: number) {
		this.activePointValue = value;
		this.activePointIndex = index;

		this.pointClicked.emit(value);
	}

	public onMouseEnter(value: unknown) {
		this.hoveredPoint = value;
	}

	public onMouseLeave() {
		this.hoveredPoint = null;
	}
}

export class General {
	public opacity = 'opacity';

	public transform = 'transform';

	public class = 'class';
}

export class Stroked extends General {
	public strokeWidth = 'stroke-width';

	public stroke = 'stroke';
}

export class LineAttrs extends Stroked {
	public tag = 'line';

	public x1 = 'x1';

	public y1 = 'y1';

	public x2 = 'x2';

	public y2 = 'y2';
}

export class Fillable extends Stroked {
	public fill = 'fill';
}

export class PathAttrs extends Fillable {
	public tag = 'path';

	public d = 'd';
}

export class CircleAttrs extends Fillable {
	public tag = 'circle';

	public radius = 'r';

	public x = 'cx';

	public y = 'cy';
}

export class TextAttrs extends Stroked {
	public tag = 'text';

	public textAnchor = 'text-anchor';
}

export class GroupAttrs extends Fillable {
	public tag = 'g';
}

export class Events {
	public mouseEnter = 'mouseenter';

	public mouseOver = 'mouseover';

	public mouseLeave = 'mouseleave';

	public mouseClick = 'click';
}

export class SVGAttributes {
	public static line = new LineAttrs();

	public static path = new PathAttrs();

	public static circle = new CircleAttrs();

	public static text = new TextAttrs();

	public static group = new GroupAttrs();

	public static closedPath = new Fillable();

	public static events = new Events();
}

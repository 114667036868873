import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AnalysisThreatsDataService {
	constructor(private httpService: RQHttpService) {}

	public export(legalEntityId: string, options = new HttpOptionsViewModel()): Promise<Blob> {
		options.responseType = options.responseType ? options.responseType : 'blob';
		options.params = this.httpService.getHttpParams({
			legalEntityId
		});

		return this.httpService.request<Blob>('get', environment.api + '/api/Analysis/AnalysisThreats/Export', options).toPromise();
	}
}

import { ThemeColorsType } from '../../../types/theme-colors.type';

export class CardTreeNode<T, V> {
	public theme!: ThemeColorsType;

	public isEditable!: boolean;

	public isDisabled!: boolean;

	public hasMoreDetails?: boolean;

	public children!: CardTreeNode<T, V>[];

	public data!: V;

	public style = { maxWidth: 300, minWidth: 300 };

	constructor(public id: T, public title: string, config?: CardTreeNodeConfig<T, V>) {
		this.theme = config?.theme ? config.theme : ('default' as ThemeColorsType);
		this.isEditable = config?.isEditable ? config.isEditable : false;
		this.hasMoreDetails = config?.hasMoreDetails ? config.hasMoreDetails : false;
		this.children = config?.children ? config.children : [];
		this.data = config?.data ? config.data : ({} as V);
		this.isDisabled = config?.isDisabled ? config.isDisabled : false;
		this.style.maxWidth = config?.style?.maxWidth ? config.style.maxWidth : this.style.maxWidth;
		this.style.minWidth = config?.style?.minWidth ? config.style.minWidth : this.style.minWidth;
	}
}

export class CardTreeNodeConfig<T, V> {
	public theme?: ThemeColorsType;

	public isEditable?: boolean;

	public hasMoreDetails?: boolean;

	public children?: CardTreeNode<T, V>[];

	public data?: V;

	public isDisabled?: boolean;

	public style?: { maxWidth?: number; minWidth?: number };
}

export class CardTreeConfig {
	public legend!: CardTreeLegend[];
}

export class CardTreeLegend {
	constructor(public displayName: string, public theme: ThemeColorsType) {}
}

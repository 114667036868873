import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { TPControlProfileModel } from '../../models/tpcontrol-profile-model.model';
import { TPControlProfileUpdateModel } from '../../models/tpcontrol-profile-update-model.model';

@Injectable()
export class TPControlProfileDataService {
	constructor(private httpService: RQHttpService) {}

	public getById(thirdPartyId: string, options = new HttpOptionsViewModel()): Promise<TPControlProfileModel> {
		options.params = this.httpService.getHttpParams({
			thirdPartyId
		});

		return this.httpService
			.request<TPControlProfileModel>('get', environment.api + '/api/TPConfig/TPControlProfile/GetById', options)
			.toPromise();
	}

	public update(update: TPControlProfileUpdateModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = update;

		return this.httpService.request<void>('post', environment.api + '/api/TPConfig/TPControlProfile/Update', options).toPromise();
	}
}

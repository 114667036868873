import { Component, HostListener, Input, ViewChild, forwardRef } from '@angular/core';
import { FilterAbstractDirective } from '../filter.abstract';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';

@Component({
	selector: 'rq-filter-search',
	templateUrl: './filter-search.component.html',
	styleUrls: ['./filter-search.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FilterSearchComponent),
			multi: true
		}
	]
})
export class FilterSearchComponent extends FilterAbstractDirective<unknown> implements ControlValueAccessor {
	@ViewChild(NgForm)
	public form!: NgForm;

	@Input()
	public placeholder!: string;

	@Input()
	public trigger: 'change' | 'blur' = 'blur';

	@Input()
	public isDisabled!: boolean;

	private onChangeCallback!: (_: unknown) => void;

	private onTouchedCallback!: (_: unknown) => void;

	public get hasValue() {
		return Boolean((this.value as string)?.length);
	}

	@HostListener('click')
	public setTouchedState() {
		this.onTouchedCallback(this.value);
	}

	public writeValue(searchValue: string): void {
		this.value = searchValue;
	}

	public clear(e: Event, input: HTMLInputElement) {
		e.preventDefault();
		this.value = '';
		this.form.form.markAsDirty();

		if (this.trigger === 'blur') {
			if (input !== document.activeElement) {
				this.triggerFilterChange();
			}
		} else if (this.trigger === 'change') {
			this.triggerFilterChange(this.trigger);
		}
	}

	public triggerFilterChange(trigger: 'change' | 'blur' = 'blur') {
		if (!this.form.dirty) {
			return;
		}

		if (this.trigger === trigger) {
			this.onChangeCallback(this.value);
			this.change.next();
			this.form.form.markAsPristine();
		}
	}

	public registerOnChange(fn: (_: unknown) => void): void {
		this.onChangeCallback = fn;
	}

	public triggerChangeOnEnter(keyEvent: KeyboardEvent) {
		if (keyEvent.key === 'Enter') {
			const input = keyEvent.target as HTMLInputElement;
			this.form.form.markAsDirty();
			input?.blur();
		}
	}

	public registerOnTouched(fn: (_: unknown) => void): void {
		this.onTouchedCallback = fn;
	}
}

import * as d3 from 'd3';
import { ChartContext } from '../models/chart.model';
import { RangeChartData, RangeConfig } from '../../range-chart/range-chart.model';

export class RangeChartRenderer {
	private textContainer!: d3.Selection<SVGGElement, undefined, SVGElement, undefined>;

	private chartContainer!: d3.Selection<SVGGElement, undefined, SVGElement, undefined>;

	private config!: RangeConfig;

	private scale!: d3.ScaleLinear<number, number>;

	constructor(private context: ChartContext) {}

	private get padding() {
		const result = { left: 20, right: 20 };

		if (this.config.padding) {
			result.left = typeof this.config.padding.left === 'number' ? this.config.padding.left : result.left;
			result.right = typeof this.config.padding.right === 'number' ? this.config.padding.right : result.right;
		}

		return result;
	}

	private get backgroundCoord() {
		const height = 4;

		return {
			y: this.context.drawArea.rect.height / 2 - height / 2,
			height
		};
	}

	private get foregroundCoord() {
		const height = 10;

		const minX = this.scale(this.config.selectedMin);
		const maxX = this.scale(this.config.selectedMax);

		return {
			y: this.context.drawArea.rect.height / 2 - height / 2,
			x: this.scale(this.config.selectedMin),
			width: maxX - minX + 2,
			height
		};
	}

	public setup(config: RangeConfig): void {
		this.config = config;

		this.chartContainer = this.context.drawArea.selection.append<SVGGElement>('g').attr('class', 'range-chart');
		this.textContainer = this.context.drawArea.selection
			.append('g')
			.attr('class', 'text-xs')
			.attr('transform', `translate(${this.padding.left}, 0)`);

		if (config && config.data.length > 0) {
			this.config.data.sort((a: RangeChartData, b: RangeChartData) => a.value - b.value);

			this.createRange();

			this.createText();
		}
	}

	public redraw(config: RangeConfig): void {
		this.chartContainer.remove();
		this.textContainer.remove();

		this.setup(config);
	}

	public resize(): void {
		this.redraw(this.config);
	}

	private shouldSwitchPosition(current: RangeChartData, prev?: RangeChartData) {
		const SWITCH_SIZE = this.config.positionVariation && this.config.variationSwitchWidth ? this.config.variationSwitchWidth : 100;

		if (!prev) {
			return false;
		}
		const coordCurrent = this.createTickCoord(current.value);
		const coordPrev = this.createTickCoord(prev.value);

		return this.config.positionVariation && coordCurrent.x - coordPrev.x < SWITCH_SIZE;
	}

	private createText() {
		this.config.data.forEach((data: RangeChartData, index) => {
			const container = this.shouldSwitchPosition(data, this.config.data[index - 1])
				? this.createInnerTextContainer(data.value, (index + 1) % 2 === 0)
				: this.createInnerTextContainer(data.value, data.positionBelow);
			const formattedValue = data.formatter ? data.formatter(data.value) : data.value;

			if (data.label) {
				this.createTextElement(data.label, container).attr('class', 'range-text');
			}

			const activeClass = data.isActive ? 'accent-fill' : 'primary-fill';

			const valueText = this.createTextElement(formattedValue, container).attr('class', `range-value  ${activeClass}`).attr('y', '15');

			if (data.tick) {
				const node = <SVGElement>valueText.node();
				const textRect = node.getBoundingClientRect();
				const x = textRect.width / 2 + 5;

				container.append('circle').attr('cy', '11').attr('cx', x).attr('r', '4').attr('class', `${data.tick}-fill`);
			}
		});
	}

	private createTextElement(value: number | string, container: d3.Selection<SVGGElement, undefined, SVGElement, undefined>) {
		return container.append<SVGTextElement>('text').attr('text-anchor', 'middle').text(value);
	}

	private createInnerTextContainer(value: number, below = false) {
		return this.textContainer.append('g').attr('transform', `translate(${this.scale(value)}, ${below ? 50 : 0})`);
	}

	private createRange() {
		this.createScale();

		this.createBackground();

		const foregroundContainer = this.createForegroundContainer();

		this.createForeground(foregroundContainer);

		this.createTick(foregroundContainer);
	}

	private createForegroundContainer() {
		return this.chartContainer
			.append('g')
			.attr('class', 'range-foreground-container')
			.attr('transform', `translate(${this.padding.left}, 0)`);
	}

	private createTick(container: d3.Selection<SVGGElement, undefined, SVGElement, undefined>) {
		this.config.data
			.filter(x => x.tickLine)
			.forEach(x => {
				const coord = this.createTickCoord(x.value);

				container
					.append('rect')
					.attr('width', 2)
					.attr('height', 10)
					.attr('class', 'primary-fill')
					.attr('transform', `translate(${coord.x}, ${coord.y})`);
			});
	}

	private createBackground() {
		this.chartContainer
			.append('g')
			.attr('class', 'primary-l-30-fill')
			.append('rect')
			.attr('width', this.context.drawArea.rect.width)
			.attr('height', this.backgroundCoord.height)
			.attr('transform', `translate(0, ${this.backgroundCoord.y})`);
	}

	private createForeground(container: d3.Selection<SVGGElement, undefined, SVGElement, undefined>) {
		const foreground = this.foregroundCoord;

		container
			.append('rect')
			.attr('width', foreground.width)
			.attr('height', foreground.height)
			.attr('class', 'accent-fill')
			.attr('transform', `translate(${this.foregroundCoord.x}, ${this.foregroundCoord.y})`);
	}

	private createTickCoord(value: number) {
		const height = 10;

		return {
			y: this.context.drawArea.rect.height / 2 - height / 2,
			x: this.scale(value),
			width: 1,
			height
		};
	}

	private createScale() {
		const values = this.config.data.map(x => x.value);

		const min = Math.min(...values);
		const max = Math.max(...values);

		this.scale = d3
			.scaleLinear()
			.domain([min, max])
			.range([0, this.context.drawArea.rect.width - this.padding.left - this.padding.right]);
	}
}

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { ROLE_CONFIG } from '../core/roles/role.config';
import { AuthorizationDataService } from '../../server/services/identity/authorization-data.service';
import { RQ_ROUTES } from '../configs/routes.config';

export const fairOnlyGuard = async (): Promise<boolean> => {
	const router = inject(Router);
	const authorizationDataService = inject(AuthorizationDataService);

	const isFairOnlyUser = await authorizationDataService.hasRole(ROLE_CONFIG.pro.fairOnly);

	if (isFairOnlyUser) {
		await router.navigateByUrl(RQ_ROUTES.infrastructure.pageNotFound.url);
	}

	return !isFairOnlyUser;
};

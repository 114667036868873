<table class="table">
  <tbody class="comparison-table-body">
    <ng-container *ngFor="let row of data.rows; let rowIndex = index">
      <tr *ngIf="row.isTitle"
          class="comparison-table-header">
        <ng-container *ngIf="!isRowLoading(row, rowIndex)">
          <td *ngFor="let column of row.columns; let columnIndex = index"
              [attr.title]="getTitle(getValue(column), columnIndex)"
              [style.maxWidth]="getColumnWidth(columnIndex)">
            <strong>
              <div class="comparison-value-container">
                <ng-container *ngTemplateOutlet="valueTemplate; context: { entity: column }"></ng-container>
              </div>
            </strong>
          </td>
        </ng-container>
        <ng-container *ngIf="isRowLoading(row, rowIndex)">
          <td [colSpan]="row.columns.length"
              class="p-md">
            <div class="loading-bar m-b-xs m-t-xs"></div>
          </td>
        </ng-container>
      </tr>
      <tr (mouseenter)="triggerMouseEnter(row, rowIndex)"
          *ngIf="!row.isTitle"
          [class.comparison-row-highlight]="row.isHighlighted"
          [class.comparison-row-bolded]="row.isBolded"
          [class.comparison-row-hover]="isHoverActive(row, rowIndex)"
          class="comparison-table-row">
        <ng-container *ngIf="!isRowLoading(row, rowIndex)">
          <td *ngFor="let column of row.columns; let columnIndex = index"
              [attr.title]="getTitle(getValue(column), columnIndex)"
              [style.maxWidth]="getColumnWidth(columnIndex)">
            <ng-container *ngIf="!isCustomTemplate(column)">
              <div class="comparison-value-container">
                <ng-container *ngTemplateOutlet="valueTemplate; context: { entity: column }"></ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="isCustomTemplate(column)">
              <div class="comparison-value-container">
                <ng-container *ngTemplateOutlet="getTemplate(column); context: { entity: column }"></ng-container>
              </div>
            </ng-container>
          </td>
        </ng-container>
        <ng-container *ngIf="isRowLoading(row, rowIndex)">
          <td [colSpan]="row.columns.length"
              class="p-md">
            <div class="loading-bar m-b-xs m-t-xs"></div>
          </td>
        </ng-container>
      </tr>
    </ng-container>
  </tbody>
</table>

import { TranslationService } from 'src/app/shared/services/translation.service';
import { Observable } from 'rxjs';
import { Component, Input, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CloneEntityViewModel } from '../../models/clone-entity.viewmodel';
import { noWhiteSpaceValidator } from '../../../standalone/utils/form-validators.utils';
import { ModalComponent } from '../../../standalone/components/modal/modal.component';

@Component({
	selector: 'rq-clone-modal',
	templateUrl: './clone-modal.component.html',
	styleUrls: ['./clone-modal.component.scss']
})
export class CloneModalComponent implements OnInit {
	@ViewChild(ModalComponent, { static: false })
	public modal!: ModalComponent;

	@Input()
	public validator!: (control: AbstractControl) => Observable<{
		isUnique: boolean;
	} | null>;

	@Output()
	public readonly clone = new EventEmitter<CloneEntityViewModel>();

	public form!: UntypedFormGroup;

	private entityId!: string;

	constructor(private translationService: TranslationService) {}

	private get isValid() {
		this.form.markAllAsTouched();
		return this.form.valid;
	}

	public ngOnInit() {
		this.setupForm();
	}

	public save() {
		if (this.isValid) {
			this.clone.emit(<CloneEntityViewModel>{ name: this.form.controls.name.value as string, id: this.entityId });
			this.close();
		}
	}

	public close() {
		this.modal.hide();
		this.form.reset();
	}

	public show(model: CloneEntityViewModel) {
		this.entityId = model.id;
		const displayName = `${this.translationService.get('global_copyOf_label')} ${model.name}`;
		this.form.controls.name.addAsyncValidators(this.validator);
		this.form.updateValueAndValidity();
		this.form.patchValue({ name: displayName });
		this.modal.show();
	}

	private setupForm() {
		if (!this.form) {
			this.form = new UntypedFormGroup({
				name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(100), noWhiteSpaceValidator()])
			});
		}
	}
}

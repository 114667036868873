import { Injectable } from '@angular/core';
import { IVisitor, NgxPendoService } from 'ngx-pendo';
import { InstanceDataService } from '../../server/services/analysis/instance-data.service';
import { IAccount } from 'ngx-pendo/lib/ngx-pendo.interfaces';
import { EFeatureFlag } from '../../server/enums/efeature-flag.enum';
import { Pendo } from '../../system/models/pendo.model';
import { LoggerService } from './logger.service';
import { LogViewModel } from '../models/log.model';
import { LoggerType, LogType } from '../enums/log-type.enum';
import { AccountDataService } from '../../server/services/identity/account-data.service';
import { UserDataService } from '../../server/services/identity/user-data.service';
import { UserRolesDataService } from '../../server/services/identity/user-roles-data.service';
import { FeatureFlagDataService } from '../../server/services/infrastructure/feature-flag-data.service';

declare const pendo: Pendo;

@Injectable({
	providedIn: 'root'
})
export class AnalyticsService {
	private isInitialized = false;

	constructor(
		private ngxPendoService: NgxPendoService,
		private userDataService: UserDataService,
		private instanceDataService: InstanceDataService,
		private accountDataService: AccountDataService,
		private userRoleDataService: UserRolesDataService,
		private featureFlagDataService: FeatureFlagDataService,
		private loggerService: LoggerService
	) {}

	public async setup() {
		try {
			const analytics = await this.loadData();

			if (!analytics.isAnalyticsEnabled) {
				return;
			}

			if (analytics.isAuthenticated) {
				const data = await Promise.all([
					this.userDataService.getCurrent(),
					this.instanceDataService.get(),
					this.userRoleDataService.getByUser()
				]);

				analytics.visitor.id = data[0].id;
				analytics.visitor.name = data[0].name;
				analytics.visitor.email = data[0].email;
				analytics.visitor.role = data[2].join(',  ');

				analytics.account.id = data[1].id;
				analytics.account.name = data[1].companyName;
			}

			this.initialize(analytics.visitor, analytics.account);
		} catch (e) {
			const error = e as Error;

			const log: LogViewModel = { type: LogType.Error, message: error.message, stacktrace: error.stack };
			this.loggerService.log(log, [LoggerType.Api, LoggerType.Console]);
		}
	}

	private initialize(visitor: IVisitor, account: IAccount) {
		if (!this.isInitialized) {
			this.ngxPendoService.initialize(visitor, account);

			this.isInitialized = true;
		} else {
			pendo.updateOptions({ visitor, account });
		}
	}

	private async loadData() {
		const result = await Promise.all([this.accountDataService.isAuthenticated(), this.featureFlagDataService.getAll()]);

		const analyticsFeatureFlag = result[1].find(x => x.feature === EFeatureFlag.ANALYTICS);

		return {
			visitor: { id: '' } as IVisitor,
			account: { id: '' } as IAccount,
			isAuthenticated: result[0],
			isAnalyticsEnabled: analyticsFeatureFlag?.turnedOn
		};
	}
}

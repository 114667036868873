import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { ComparisonButtonConfig, ComparisonItem } from './comparer.model';

@Component({
	selector: 'rq-comparer',
	templateUrl: './comparer.component.html',
	styleUrls: ['./comparer.component.scss']
})
export class ComparerComponent {
	@Input()
	public slotCount!: number;

	@Output()
	public readonly comparationSelected: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

	@HostBinding('class.height-0')
	public isExpanded = false;

	public buttonConfig!: ComparisonButtonConfig;

	public itemsToCompare: Array<ComparisonItem> = [];

	private readonly DEFAULT_SLOT_COUNT = 3;

	@Input()
	public set config(value: ComparisonButtonConfig) {
		this.buttonConfig = value
			? value
			: {
					buttonText: '',
					messageText: '',
					collapsedText: ''
			  };
	}

	@HostListener('click')
	public onClick() {
		if (!this.isExpanded) {
			this.expand();
		} else {
			this.close();
		}
	}

	public createRange() {
		const count = this.slotCount ? this.slotCount : this.DEFAULT_SLOT_COUNT;
		const items: number[] = [];
		for (let i = 1; i <= count; i++) {
			items.push(i);
		}
		return items;
	}

	public add(item: ComparisonItem): void {
		const count = this.slotCount ? this.slotCount : this.DEFAULT_SLOT_COUNT;

		this.isExpanded = true;

		if (this.itemsToCompare.length < count) {
			this.itemsToCompare.push(item);
		}
	}

	public remove(id: string) {
		if (id) {
			this.itemsToCompare = this.itemsToCompare.filter(x => x.id !== id);
		}

		this.isExpanded = this.itemsToCompare.length > 0;
	}

	public expand() {
		this.isExpanded = true;
	}

	public close() {
		this.isExpanded = false;
	}

	public compare() {
		const items = this.itemsToCompare.map(x => x.id);

		this.comparationSelected.emit(items);
	}

	public getItemToCompare(index: number) {
		if (this.itemsToCompare.length > index) {
			return this.itemsToCompare[index];
		}

		return null;
	}
}

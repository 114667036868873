import { Component, Input, ViewChild } from '@angular/core';
import { LTRecommendationDetailedApplicationsViewModel } from '../../../../routes/control-analysis/_common/models/recommendations-detailed-view.viewmodel';
import { RecomendationTTPDataService } from '../../../../server/services/analysis/recomendation-ttp-data.service';
import { RecommendationTTPFilterModel } from '../../../../server/models/recommendation-ttpfilter-model.model';
import { RecommendationTTPFilterOptionModel } from '../../../../server/models/recommendation-ttpfilter-option-model.model';
import { RecommendationTTPModel } from '../../../../server/models/recommendation-ttpmodel.model';
import { ModalComponent } from '../../../../standalone/components/modal/modal.component';
import { CardLayoutComponent } from '../../../../standalone/components/card-layout/card-layout.component';
import { TableOutput } from '../../../../standalone/components/table/models/table-output.model';

@Component({
	selector: 'rq-applications-modal',
	templateUrl: './applications-modal.component.html',
	styleUrls: ['./applications-modal.component.scss']
})
export class ApplicationsModalComponent {
	@ViewChild(ModalComponent, { static: true })
	public modal!: ModalComponent;

	@ViewChild(CardLayoutComponent, { static: true })
	public card!: CardLayoutComponent;

	@Input()
	public controlId!: string;

	@Input()
	public analysisId!: string;

	public tableData!: Array<LTRecommendationDetailedApplicationsViewModel>;

	public tableCount!: number;

	public tableFilterOptions!: RecommendationTTPFilterOptionModel;

	public isVisible = false;

	constructor(private recomendationTTPDataService: RecomendationTTPDataService) {}

	public async show() {
		this.isVisible = true;
		this.modal.show();
		await this.loadData();
	}

	public close() {
		this.isVisible = false;
		this.modal.hide();
	}

	public async loadApplications(output: TableOutput<RecommendationTTPFilterModel>) {
		await this.getData(output);
	}

	private async loadData() {
		await this.card.toggleLoadingBox(async () => {
			await this.loadFilters();
			await this.loadApplications({ filter: new RecommendationTTPFilterModel(), pagination: { skip: 0, take: 5 } });
		});
	}

	private async getData(output: TableOutput<RecommendationTTPFilterModel>) {
		const data = await this.recomendationTTPDataService.getByControlOption(
			this.analysisId,
			this.controlId,
			output.pagination,
			output.filter
		);

		this.tableCount = data.count;
		this.tableData = this.mapData(data.values);
	}

	private mapData(values: Array<RecommendationTTPModel>) {
		return values.map(item => {
			return <LTRecommendationDetailedApplicationsViewModel>{
				techniqueName: item.techniqueName,
				tacticName: item.tacticName,
				frequency: item.frequency
			};
		});
	}

	private async loadFilters() {
		this.tableFilterOptions = await this.recomendationTTPDataService.getByControlOptionFilterOptions(this.analysisId, this.controlId);
	}
}

<rq-modal #modal
          e2e-test="starting-page"
          class="starting-page">
  <rq-modal-header class="modal-header">
    <span>{{'startingPage_title' | translate}}</span>
    <rq-icon href="icon-close"
             class="icon-cancel white-fill"
             e2e-test="starting-page-close-button"
             (click)="close()"></rq-icon>
  </rq-modal-header>
  <div></div>
  <rq-modal-body>
    <section class="wrapper">
      <ng-container *ngFor="let item of items; let i = index">

        <div class="starting-page-item"
             [ngClass]="{'disabled': item.isDisabled}"
             (click)="!item.isDisabled && continue(item)">
          <h3>{{item.name}}</h3>
          <div class="left-corner-line"></div>
          <div class="right-corner-line"></div>
          <div class="icon-row circle">
            <rq-icon class="icon icon-md"
                     href="{{item.iconClass!}}"
                     [class.disabled]="item.isDisabled"></rq-icon>
          </div>
          <div class="circle-border"></div>
        </div>

      </ng-container>
    </section>

    <footer class="footer modal-footer b-top-none">
      <form [formGroup]="formGroup">
        <rq-form-control class="m-b-none">
          <label>
            <input type="checkbox"
                   value="hideStartupPage"
                   e2e-test="starting-page-hide-popup-checkbox"
                   formControlName="hideStartupPage">
            <span>{{'global_hide_message_label' | translate}}</span>
          </label>
        </rq-form-control>
      </form>
    </footer>
  </rq-modal-body>
</rq-modal>

import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { FairConfidenceType } from '../../../routes/control-analysis/_common/enums/fair-confidence-type.enum';

@Pipe({
	name: 'confidenceLabel'
})
export class ConfidenceTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value: string) {
		const values = new Map<string, string>();
		values.set(FairConfidenceType.High, 'global_high_label');
		values.set(FairConfidenceType.Medium, 'global_medium_label');
		values.set(FairConfidenceType.Low, 'global_low_label');

		const type = values.get(value);
		if (!type) {
			throw new Error(`${value} is not handled`);
		}

		return this.translationService.get(type);
	}
}

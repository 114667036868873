import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '../../standalone/utils/helpers.utils';
import { CurrencyConfig, DecimalConfig, NumberConfig, PercentConfig } from '../models/number.config';
import numeral from 'numeral';
import { Customizer } from './customizer';

@Injectable({
	providedIn: 'root'
})
export class NumberFormatterService {
	private numeral = numeral.localeData(this.customizer.culture);

	constructor(private customizer: Customizer) {}

	public number(value: number, config?: NumberConfig) {
		const configInput = config ? Object.assign({}, this.customizer.formatter.number, config) : this.customizer.formatter.number;

		configInput.format = this.createNumberFormat(value, configInput);

		return this.format(value, configInput);
	}

	public currency(value: number, config?: CurrencyConfig) {
		const configInput = config ? Object.assign({}, this.customizer.formatter.currency, config) : this.customizer.formatter.currency;

		this.numeral.currency.symbol = configInput.symbol;

		const symbol = this.getSymbol(config).length > 1 ? '$ ' : '$';
		configInput.format = `${symbol}${this.createNumberFormat(value, configInput)}`;

		return this.format(value, configInput);
	}

	public percent(value: number, config?: PercentConfig) {
		const configInput = config ? Object.assign({}, this.customizer.formatter.percent, config) : this.customizer.formatter.percent;

		configInput.format = this.createNumberFormat(value, configInput);

		const formattedValue = this.format(value, configInput);

		return `${formattedValue}%`;
	}

	public format(value: number, config: NumberConfig) {
		this.setupBaseConfig(config);

		if (config.rounding) {
			value = config.rounding(value);
		}
		if (config.isAbbr && !config.abbr) {
			throw new Error('You must provide abbr configuration for data');
		}

		const formatter = config.isAbbr ? this.getAbbrFormatter(value, config) : this.defaultFormatter;

		return formatter(value, config.format);
	}

	public parse(value: string) {
		return numeral(value).value();
	}

	private getSymbol(config?: CurrencyConfig) {
		return config?.symbol ? config.symbol : this.customizer.currency;
	}

	private setupBaseConfig(config: NumberConfig) {
		if (config.delimiters?.decimal) {
			this.numeral.delimiters.decimal = config.delimiters.decimal;
		}

		if (config.delimiters?.thousands) {
			this.numeral.delimiters.thousands = config.delimiters.thousands;
		}

		if (config.abbr) {
			Object.assign(this.numeral.abbreviations, config.abbr);
		}
	}

	private defaultFormatter = (value: number, format?: string) => numeral(value).format(format);

	private getAbbrFormatter(value: number, config: NumberConfig | CurrencyConfig): (valueParam: number, format?: string) => string {
		const options = [
			{
				isRange: () => (value >= 0 && value < 10) || (value <= 0 && value > -10),
				formatter: (valueParam: number, format: string) => `${this.defaultFormatter(valueParam, format)}${config.abbr.digits}`
			},
			{
				isRange: () => (value >= 10 && value < 100) || (value <= -10 && value > -100),
				formatter: (valueParam: number, format: string) => `${this.defaultFormatter(valueParam, format)}${config.abbr.dozens}`
			},
			{
				isRange: () => (value >= 100 && value < 1000) || (value <= -100 && value > -1000),
				formatter: (valueParam: number, format: string) => `${this.defaultFormatter(valueParam, format)}${config.abbr.hundred}`
			},
			{
				isRange: () => value >= 1000 || value <= -1000,
				formatter: (valueParam: number, format: string) => this.defaultFormatter(valueParam, format)
			}
		];

		const option = options.find(x => x.isRange());

		return option?.formatter as (valueParam: number, format?: string) => string;
	}

	private createNumberFormat(value: number, config: NumberConfig) {
		let format = `0,0`;

		const decimalCount = this.getDecimalCount(value, config);

		if (decimalCount > 0) {
			format += `.[`;

			for (let i = 0; i < decimalCount; i++) {
				format += '0';
			}

			format += ']';
		}

		if (config.isAbbr) {
			format += 'a';
		}

		return format;
	}

	private getDecimalCount(value: number, config: NumberConfig) {
		let decimals = 0;

		if (!isNullOrUndefined(config.decimals)) {
			if (config.decimals instanceof DecimalConfig) {
				const ranges: { [key: string]: (val: number) => boolean } = {
					trillion: (val: number) => val >= 1000000000000,
					billion: (val: number) => val >= 1000000000,
					million: (val: number) => val >= 1000000,
					thousand: (val: number) => val >= 1000,
					hundred: (val: number) => val >= 100,
					dozens: (val: number) => val >= 10,
					digits: (val: number) => val >= 0
				};

				const absValue = Math.abs(value);

				const decimalConfig = config.decimals;

				const decimalConfigKey = Object.keys(decimalConfig).find(key => ranges[key](absValue)) as string;

				decimals = decimalConfig[decimalConfigKey];
			} else {
				decimals = <number>config.decimals;
			}
		} else {
			const valueDecimals = value.toString().split('.')[1];

			if (valueDecimals) {
				decimals = valueDecimals.length;
			}
		}

		return decimals;
	}
}

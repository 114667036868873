import { TranslationService } from 'src/app/shared/services/translation.service';
import { PipeTransform, Pipe } from '@angular/core';
import { MitreObservationType } from '../../../server/enums/mitre-observation-type.enum';

@Pipe({
	name: 'mitigationTypeLabel'
})
export class MitigationTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value?: MitreObservationType) {
		switch (value) {
			case MitreObservationType.Baseline:
				return this.translationService.get('baseline_label');
			case MitreObservationType.Customized:
				return this.translationService.get('customized_label');
			case MitreObservationType.Integrated:
				return this.translationService.get('integrated_label');
			default:
				throw new Error(`${value} does not exist`);
		}
	}
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { WizardConfigService } from './wizard-config.service';
import { WizardSaveStep } from './models/wizard-steps.model';

@Injectable()
export class WizardService {
	public saveStep$ = new Subject<WizardSaveStep>();

	constructor(private configWizardService: WizardConfigService) {}

	public get disabledSteps() {
		return JSON.parse(JSON.stringify(this.configWizardService.disabledSteps)) as Array<number>;
	}

	public changeStep(stepIndex?: number) {
		this.configWizardService.changeStep$.next(stepIndex);
	}

	public triggerSaveStep(saveStep: WizardSaveStep) {
		if (this.saveStep$.observers.length) {
			this.saveStep$.next(saveStep);
		} else {
			this.changeStep(saveStep.selectedStepIndex);
		}
	}

	public toggleSubmitLoading(isLoading: boolean) {
		this.configWizardService.toggleSubmitLoading$.next(isLoading);
	}

	public disableStep(stepIndex: number) {
		if (!this.configWizardService.disabledSteps.includes(stepIndex)) {
			this.configWizardService.disabledSteps.push(stepIndex);
		}
	}

	public enableStep(stepIndex: number) {
		if (this.configWizardService.disabledSteps.includes(stepIndex)) {
			this.configWizardService.disabledSteps = this.configWizardService.disabledSteps.filter(x => x !== stepIndex);
		}
	}
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UserManagementService } from '../../services/user-management.service';
import { AuthorizeInputModel } from '../../core/roles/authorize-input.model';
import { UsersViewModel } from '../../models/users.viewmodel';
import { EnterpriseUserFilterModel } from '../../../server/models/enterprise-user-filter-model.model';
import { LegalEntityModel } from '../../../server/models/legal-entity-model.model';
import { UserFilterOptionModel } from '../../../server/models/user-filter-option-model.model';
import { ExpandableTextWordModel } from '../../../standalone/components/expandable-text/expandable-text.ui-model';
import { TableBase2Component } from '../../../standalone/components/table/table-base2.component';
import { DropdownFilter, SelectFilter } from '../../../standalone/components/form-elements/filter/filter.model';

@Component({
	selector: 'rq-users-management-table',
	templateUrl: './users-management-table.component.html',
	styleUrls: ['./users-management-table.component.scss']
})
export class UsersManagementTableComponent
	extends TableBase2Component<UsersViewModel, EnterpriseUserFilterModel>
	implements OnChanges, OnInit
{
	@Input()
	public filterOptions!: UserFilterOptionModel;

	@Input()
	public isLegalEntityColumnVisible!: boolean;

	@Input()
	public isLegalEntityArchived!: boolean;

	@Input()
	public authorizeState!: Array<AuthorizeInputModel>;

	@Output()
	public readonly editAction = new EventEmitter<UsersViewModel>();

	@Output()
	public readonly unlockAction = new EventEmitter<UsersViewModel>();

	@Output()
	public readonly resetPasswordAction = new EventEmitter<UsersViewModel>();

	@Output()
	public readonly toggleStatusAccountAction = new EventEmitter<UsersViewModel>();

	@Output()
	public readonly addAction = new EventEmitter();

	public roleFilterSetup!: SelectFilter;

	public legalEntityFilterSetup!: SelectFilter;

	public statusFilterSetup!: DropdownFilter;

	constructor(private userManagementService: UserManagementService) {
		super();
	}

	public async ngOnChanges(changes: SimpleChanges): Promise<void> {
		await super.ngOnChanges(changes);
		if (changes?.filterOptions?.currentValue) {
			this.setupFilters(changes.filterOptions.currentValue as UserFilterOptionModel);
		}
	}

	public ngOnInit() {
		this.setupFilters();
	}

	public edit(dataItem: UsersViewModel) {
		this.editAction.emit(dataItem);
	}

	public unlock(dataItem: UsersViewModel) {
		this.unlockAction.emit(dataItem);
	}

	public toggleStatusAccount(dataItem: UsersViewModel) {
		if (!this.isToggleActiveAccountDisabled(dataItem)) {
			this.toggleStatusAccountAction.emit(dataItem);
		}
	}

	public resetPassword(dataItem: UsersViewModel) {
		if (!this.isResetPasswordDisabled(dataItem) && !dataItem.providerUserId) {
			this.resetPasswordAction.emit(dataItem);
		}
	}

	public add() {
		this.addAction.emit();
	}

	public getRoles(dataItem: UsersViewModel) {
		let roles = new Array<ExpandableTextWordModel>();

		if (dataItem.roles) {
			roles = dataItem.roles.map(x => ({ displayName: x.displayName }));
		}

		return roles;
	}

	public getLegalEntities(data: UsersViewModel) {
		const list = new Array<{ displayName: string }>();

		if (data.legalEntities) {
			data.legalEntities.forEach((le: LegalEntityModel) => {
				list.push({
					displayName: le.displayName
				});
			});
		}

		return list;
	}

	public isResetPasswordDisabled(user: UsersViewModel) {
		return user.providerUserId || !user.isActive || user.isRqInstanceManager;
	}

	public isToggleActiveAccountDisabled(user: UsersViewModel) {
		return user.providerUserId || user.isCurrentUser || user.isRqInstanceManager;
	}

	private setupFilters(filterOptions?: UserFilterOptionModel) {
		if (filterOptions) {
			if (filterOptions.roles.length > 0) {
				this.roleFilterSetup = this.createSelectFilter(
					'global_role_label',
					this.userManagementService.userRoleOptions(this.filterOptions.roles)
				);
			}

			if (this.isLegalEntityColumnVisible) {
				this.legalEntityFilterSetup = this.createSelectFilter(
					'global_legalEntity_label',
					this.userManagementService.userLegalEntityOptions(this.filterOptions.legalEntities)
				);
			}

			this.statusFilterSetup = this.createDropdownFilter(
				'settings_user_management_table_user_account_status_label',
				this.userManagementService.userAccountStatusOptions(this.filterOptions.accountStatuses)
			);
		}
	}
}

import { IdentityService } from 'src/app/shared/services/identity.service';
import { IDPDataService } from '../services/idp-data.service';
import { Injectable } from '@angular/core';
import { UserLockModel } from '../../routes/security/_common/models/user-lock.model';
import { UsersViewModel } from '../models/users.viewmodel';
import { ROLE_CONFIG } from '../core/roles/role.config';
import { EnterpriseUserFilterModel } from '../../server/models/enterprise-user-filter-model.model';
import { ResetPasswordModel } from '../../server/models/reset-password-model.model';
import { EnterpriseUserLegalEntityDataService } from '../../server/services/enterprise/enterprise-user-legal-entity-data.service';
import { UserDataService } from '../../server/services/identity/user-data.service';
import { UserFilterModel } from '../../server/models/user-filter-model.model';
import { TableOutput } from '../../standalone/components/table/models/table-output.model';

@Injectable()
export class UserManagementFacade {
	constructor(
		private userDataService: UserDataService,
		private idpDataService: IDPDataService,
		private userLegalEntityDataService: EnterpriseUserLegalEntityDataService,
		private identityService: IdentityService
	) {}

	public async resetPassword(model: UsersViewModel) {
		const rpm = new ResetPasswordModel();

		rpm.email = model.email;
		rpm.origin = location.origin;

		await this.userDataService.resetPassword(rpm);
	}

	public async unlockAccount(model: UsersViewModel) {
		if (model.isActive) {
			const ulm = new UserLockModel();

			ulm.userId = model.id;
			ulm.origin = location.origin;

			await this.idpDataService.sendUnlockAccountEmail(ulm);
		}
	}

	public async getEnterpriseUsers(output: TableOutput<EnterpriseUserFilterModel>) {
		const promises = await Promise.all([
			this.userLegalEntityDataService.getPaginated(output.pagination, output.filter),
			this.identityService.getCurrentUser()
		]);

		const [data, currentUser] = promises;

		const count = data.count;
		const values = data.values.map(x => {
			return <UsersViewModel>{
				id: x.id,
				name: x.name,
				email: x.email,
				locked: x.locked,
				roles: x.roles,
				legalEntities: x.legalEntities,
				isActive: x.isActive,
				isRqInstanceManager: x.roles.some(r => r.name === ROLE_CONFIG.instance.administrator),
				isRqEnterpriseAdministrator: x.roles.some(r => r.name === ROLE_CONFIG.enterprise.administrator),
				isRqEnterpriseReadOnly: x.roles.some(r => r.name === ROLE_CONFIG.enterprise.readOnly),
				isFairOnly: this.identityService.isFairOnly,
				isCurrentUser: x.id === currentUser.id,
				providerUserId: x.providerUserId
			};
		});

		return {
			count,
			values
		};
	}

	public async getProUsers(legalEntityId: string, output: TableOutput<UserFilterModel>) {
		const promises = await Promise.all([
			this.userDataService.getUsers(legalEntityId, output.pagination, output.filter),
			this.identityService.getCurrentUser()
		]);

		const [data, currentUser] = promises;

		const count = data.count;
		const values = data.values.map(x => {
			return <UsersViewModel>{
				id: x.id,
				name: x.name,
				email: x.email,
				locked: x.locked,
				roles: x.roles,
				isActive: x.isActive,
				isRqInstanceManager: x.roles.some(r => r.name === ROLE_CONFIG.instance.administrator),
				isRqEnterpriseAdministrator: x.roles.some(r => r.name === ROLE_CONFIG.enterprise.administrator),
				isRqEnterpriseReadOnly: x.roles.some(r => r.name === ROLE_CONFIG.enterprise.readOnly),
				isFairOnly: this.identityService.isFairOnly,
				isCurrentUser: x.id === currentUser.id
			};
		});

		return {
			count,
			values
		};
	}
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { ScenarioComparisonSummaryModel } from '../../models/scenario-comparison-summary-model.model';
import { ScenarioComparisonFilterInputModel } from '../../models/scenario-comparison-filter-input-model.model';
import { AnalysisBaEnterpriseControlModel } from '../../models/analysis-ba-enterprise-control-model.model';
import { ScenarioComparisonControlInputModel } from '../../models/scenario-comparison-control-input-model.model';
import { ScenarioComparisonRateOfIncidentModel } from '../../models/scenario-comparison-rate-of-incident-model.model';
import { ScenarioComparisonInputModel } from '../../models/scenario-comparison-input-model.model';

@Injectable()
export class ScenarioAnalysisComparisonDataService {
	constructor(private httpService: RQHttpService) {}

	public getSummary(
		input: ScenarioComparisonFilterInputModel[],
		options = new HttpOptionsViewModel()
	): Promise<ScenarioComparisonSummaryModel[]> {
		options.body = input;

		return this.httpService
			.request<ScenarioComparisonSummaryModel[]>('post', environment.api + '/api/Scenario/ScenarioAnalysisComparison/GetSummary', options)
			.toPromise();
	}

	public getControlComparison(
		input: ScenarioComparisonControlInputModel,
		options = new HttpOptionsViewModel()
	): Promise<AnalysisBaEnterpriseControlModel[]> {
		options.body = input;

		return this.httpService
			.request<AnalysisBaEnterpriseControlModel[]>(
				'post',
				environment.api + '/api/Scenario/ScenarioAnalysisComparison/GetControlComparison',
				options
			)
			.toPromise();
	}

	public getRateOfIncidentComparison(
		input: ScenarioComparisonInputModel[],
		options = new HttpOptionsViewModel()
	): Promise<ScenarioComparisonRateOfIncidentModel[]> {
		options.body = input;

		return this.httpService
			.request<ScenarioComparisonRateOfIncidentModel[]>(
				'post',
				environment.api + '/api/Scenario/ScenarioAnalysisComparison/GetRateOfIncidentComparison',
				options
			)
			.toPromise();
	}
}

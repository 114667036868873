<div class="applications-container">
  <ng-container *ngFor="let item of data; let first = first">
    <div class="applications-title-header"
         *ngIf="first">
      <strong>{{ 'global_applications_label' | translate }}</strong>
    </div>
    <div class="application-title-value"
         *ngIf="first">
      <strong>{{ 'global_reduction_label' | translate }}</strong>
    </div>
    <div class="application-title-value"
         *ngIf="first">
      <ng-container *ngIf="item.changeInPs">
        <strong>{{ 'global_changeInProbability_label' | translate }}</strong>
      </ng-container>
      <ng-container *ngIf="item.sleReduction">
        <strong>{{ 'global_change_in_sle_label' | translate }}</strong>
      </ng-container>
    </div>

    <div class="application-title">
      <span class="truncate"
            [title]="item.label">{{ item.label }}</span>
    </div>
    <div [ngClass]="{ 'badge-success-light': item.reduction < 0, 'badge-danger-light': item.reduction >= 0 }">
      {{ item.reduction | rqCurrency }}
    </div>
    <ng-container *ngIf="item.changeInPs">
      <div [ngClass]="{ 'badge-success-light': item.changeInPs < 0, 'badge-danger-light': item.changeInPs >= 0 }">
        {{ item.changeInPs | rqPercent }}
      </div>
    </ng-container>
    <ng-container *ngIf="item.sleReduction !== undefined && !item.changeInPs">
      <div [ngClass]="{ 'badge-success-light': item.sleReduction < 0, 'badge-danger-light': item.sleReduction >= 0 }">
        {{ item.sleReduction | rqCurrency }}
      </div>
    </ng-container>
  </ng-container>
</div>

export enum ModelTuningTabs {
	LossVariables = 'loss-variables',
	AnnualAttackRateOfIncidence = 'anual-attack-rate-of-incidence',
	CveWeighting = 'cve-wighting',
	FairLossMagnitudeValues = 'fair-loss-magnitude-values',
	LossLimits = 'loss-limits',
	SemiFairTEF = 'semi-fair-tef',
	EndpointScoringWeighting = 'endpoint-scoring-weighting',
	QuantitativeScales = 'quantitative-scales',
	TechniquePrevalence = 'technique-prevalence'
}

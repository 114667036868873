import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { LicenseService } from './license.service';
import { LicensePermission } from './license-permission.enum';

@Directive({
	selector: '[rqHasLicense]'
})
export class HasLicenseDirective {
	constructor(
		private templateRef: TemplateRef<unknown>,
		private viewContainerRef: ViewContainerRef,
		private licenseService: LicenseService
	) {}

	@Input()
	public set rqHasLicense(value: Array<LicensePermission>) {
		if (value !== undefined) {
			this.updateView(value);
		}
	}

	private updateView(license: Array<LicensePermission>) {
		if (this.licenseService.hasLicense(license)) {
			this.showComponent();
		} else {
			this.removeComponent();
		}
	}

	private removeComponent(): void {
		this.viewContainerRef.clear();
	}

	private showComponent(): void {
		this.viewContainerRef.clear();
		this.viewContainerRef.createEmbeddedView(this.templateRef);
	}
}

import {
	ComparisonTemplate,
	ComparisonType,
	ComparisonRowBaseConfig,
	ComparisonRowBase,
	ComparisonRowThemeBase,
	ComparisonRowThemeConfig
} from '../comparison.model';

export class ComparisonTable {
	public type: ComparisonType.Table = ComparisonType.Table;

	constructor(public rows: Array<ComparisonTableRow>) {}
}

export class ComparisonTableRow extends ComparisonRowBase {
	public isTitle = false;

	constructor(public columns: Array<ComparisonTableColumn | ComparisonTemplate>, config?: ComparisonRowBaseConfig) {
		super();

		this.setupConfig(config);
	}
}

export class ComparisonTitleRow extends ComparisonTableRow {
	constructor(public columns: Array<ComparisonTableColumn | ComparisonTemplate>) {
		super(columns);

		this.isTitle = true;
	}
}

export class ComparisonTableColumn extends ComparisonRowThemeBase {
	constructor(public value: string, public config?: ComparisonRowThemeConfig) {
		super();

		super.setupConfig(config);
	}
}

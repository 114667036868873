import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NavigationListStatesViewModel } from '../../../../shared/models/navigation-list-states.viewmodel';
import { NavigationListViewModel } from '../../../../shared/models/navigation-list.viewmodel';
import { IsActiveMatchOptions } from '@angular/router';
import { SidebarStateStore } from 'src/app/routes/control-analysis/_common/store/sidebar-state.store';

@Component({
	selector: 'rq-navigation-item',
	templateUrl: './navigation-item.component.html',
	styleUrls: ['./navigation-item.component.scss']
})
export class NavigationItemComponent<T> implements OnChanges {
	@Input()
	public item!: NavigationListViewModel<T>;

	@Input()
	public states!: Array<NavigationListStatesViewModel<T>>;

	@Input()
	public isBeta!: boolean;

	@Input()
	public version!: string;

	public isDisabled?: boolean;

	public readonly myMatchOptions: IsActiveMatchOptions = {
		queryParams: 'ignored',
		matrixParams: 'ignored',
		paths: 'subset',
		fragment: 'ignored'
	};

	constructor(private sidebarStateStore: SidebarStateStore) {}

	public get isSidebarExpanded() {
		return this.sidebarStateStore.state;
	}

	public ngOnChanges(changes: SimpleChanges) {
		const states = changes.states?.currentValue as Array<NavigationListStatesViewModel<T>>;

		if (states && this.item) {
			const newState = this.states.find(x => x.id === this.item.id);

			if (newState) {
				this.isDisabled = newState.isDisabled !== undefined ? newState.isDisabled : this.isDisabled;
			}
		}
	}

	public updateState(isActive: boolean) {
		this.item.isSelected = isActive;
	}
}

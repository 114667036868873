import { Directive, HostListener, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Directive({
	selector: '[formGroup]'
})
export class RqFormGroupDirective {
	@Input()
	public formGroup!: UntypedFormGroup;

	public submited = false;

	@HostListener('ngSubmit')
	public onSubmit() {
		this.submited = true;
		this.validateAllFormFields(this.formGroup);
	}

	private validateAllFormFields(formGroup: UntypedFormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof UntypedFormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof UntypedFormGroup) {
				this.validateAllFormFields(control);
			}
		});
	}
}

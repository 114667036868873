import { Component, Input } from '@angular/core';

@Component({
	selector: 'rq-control-level-info',
	templateUrl: './control-level-info.component.html',
	styleUrls: ['./control-level-info.component.scss']
})
export class ControlLevelInfoComponent {
	@Input()
	public controlLevel!: number;

	@Input()
	public controlLevelName!: string;

	@Input()
	public noOfEvidences!: number;

	@Input()
	public isDelta!: boolean;

	@Input()
	public hasNext!: boolean;
}

import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../system/services/http.service';
import { HttpOptionsViewModel } from '../../../system/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { AnalysisCustomLossModelViewModel } from '../../models/analysis-custom-loss-model-view-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { AnalysisCustomLossFilterViewModel } from '../../models/analysis-custom-loss-filter-view-model.model';

@Injectable()
export class AnalysisCustomLossDataService {
	constructor(private httpService: RQHttpService) {}

	public getPaginated(
		paginationModel: PaginationFilter,
		analysisBusinessApplicationId: string,
		filter: AnalysisCustomLossFilterViewModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<AnalysisCustomLossModelViewModel>> {
		options.params = this.httpService.getHttpParams({
			paginationModel,
			analysisBusinessApplicationId,
			filter
		});

		return this.httpService
			.request<Pagination<AnalysisCustomLossModelViewModel>>(
				'get',
				environment.api + '/api/Analysis/AnalysisCustomLoss/GetPaginated',
				options
			)
			.toPromise();
	}
}

import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslationService } from '../../services/translation.service';
import { AuthorizeInputModel } from '../../core/roles/authorize-input.model';
import { ExchangeRateLabelConfig, ExchangeRateViewModel } from '../../models/exchange-rate-view.model';
import { ExchangeRateModel } from '../../../server/models/exchange-rate-model.model';
import { InputComponent } from '../../../standalone/components/form-elements/input/input.component';

@Component({
	selector: 'rq-exchange-rate-component',
	templateUrl: './exchange-rate.component.html',
	styleUrls: ['./exchange-rate.component.scss']
})
export class ExchangeRateComponent implements OnInit {
	@Input()
	public config!: ExchangeRateLabelConfig;

	@Input()
	public data!: ExchangeRateModel[];

	@Input()
	public authorizeState!: AuthorizeInputModel[];

	@Output()
	public readonly changeExchangeRate: EventEmitter<ExchangeRateViewModel> = new EventEmitter<ExchangeRateViewModel>();

	@ViewChildren(InputComponent)
	public inputComponents!: QueryList<InputComponent>;

	public form: UntypedFormGroup = new UntypedFormGroup({});

	public exchangeRates: ExchangeRateViewModel[] = [];

	constructor(public translationService: TranslationService) {}

	public ngOnInit() {
		this.setupFormGroup(this.data);
	}

	public updateExchange(model: ExchangeRateViewModel) {
		const exchangeRate = this.exchangeRates.find(x => x.id === model.id) as ExchangeRateViewModel;

		const control = this.form.controls[this.getGroupId(exchangeRate.isoCode)];

		if (control.valid) {
			exchangeRate.isEditState = false;

			if (control.value !== model.value) {
				exchangeRate.value = control.value as number;

				const selectedInput = this.getInputByFormIndex(model.isoCode);

				selectedInput?.toggleLoading(true);

				this.changeExchangeRate.emit(exchangeRate);
			}
		}
	}

	public focus(exchangeRate: ExchangeRateViewModel) {
		exchangeRate.isEditState = true;
	}

	public getGroupId(id: string) {
		return 'group-' + id;
	}

	public getForm(id: string) {
		return this.form.get(id) as UntypedFormControl;
	}

	public setData(model: ExchangeRateViewModel) {
		this.getInputByFormIndex(model.isoCode)?.toggleLoading(false);
	}

	private setupFormGroup(exchangeRates: ExchangeRateModel[]) {
		this.form = new UntypedFormGroup({});
		exchangeRates
			.filter(x => x.isoCode !== 'USD')
			.forEach(exchangeRate => {
				this.createFormControl(exchangeRate);

				this.createExchangeRateViewModel(exchangeRate);
			});
	}

	private createFormControl(c: ExchangeRateModel) {
		this.form.addControl(
			this.getGroupId(c.isoCode),
			new UntypedFormControl(c.value, [Validators.required, Validators.min(0.01), Validators.max(10000)])
		);
	}

	private createExchangeRateViewModel(value: ExchangeRateModel) {
		const vm = Object.assign(
			{
				name: this.translationService.get(`global_${value.isoCode.toLocaleLowerCase()}_exchangeToUsd_label`)
			},
			value
		) as ExchangeRateViewModel;

		this.exchangeRates.push(vm);
	}

	private getInputByFormIndex(id: string) {
		const inputIndex = Object.keys(this.form.controls).indexOf(this.getGroupId(id));

		return this.inputComponents.get(inputIndex);
	}
}

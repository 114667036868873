  <section class="step-where-container p-l-sm p-r-sm p-t-md p-b-md">
    <section class="applications-container">
      <rq-application-list [data]="applications"
                           [numberOfItemsToShow]="3"></rq-application-list>
      <button class="btn-secondary m-b-xs"
              *ngIf='selectedItem.isShowMoreApplicationAvailable'
              rqAuthorize
              (click)="showMore()"
              rqPreventDefault>
        {{ 'applications_view_more' | translate}}
      </button>
    </section>

    <section class="evidence-container p-l-sm p-r-sm">
      <div class="evidence-description">
        <p class="text-center">
          <strong>{{'there_are_label' | translate }}</strong>
          <strong class="accent-color"> {{defensebilityDelta?.howTo?.count ?? 0}} </strong>
          <strong>{{'evidences_total_description' | translate }}</strong>
        </p>
        <p class="text-center primary-l-10-color">
          {{'evidences_details_description' | translate }}
        </p>
      </div>
    </section>
    <button rqAuthorize
            [disabled]="!defensebilityDelta?.howTo?.count"
            (click)="showDetails()"
            rqPreventDefault
            class="btn-secondary evidence-button">
      {{'evidences_details_label' | translate }}
    </button>
  </section>

  <rq-application-list-modal [selectedItem]="selectedItem"></rq-application-list-modal>

  <rq-evidences-details-modal [multipleData]="multipleData"
                              [controlOptionName]="selectedItem.controlOptionName"
                              [controlLevelDescriptions]="controlLevelDescriptions"></rq-evidences-details-modal>

import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatterService } from '../../shared/services/date-formatter.service';

@Pipe({
	name: 'rqDate',
	standalone: true
})
export class RqDatePipe implements PipeTransform {
	constructor(private dateService: DateFormatterService) {}

	public transform(date?: string | Date, config?: { pattern: string }): string {
		const configParam = config ? config.pattern : undefined;

		return date ? this.dateService.format(date, configParam) : '';
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { LegalEntityType } from '../../../server/enums/legal-entity-type.enum';

@Pipe({
	name: 'legalEntityTypeLabel'
})
export class LegalEntityTypeTranslate implements PipeTransform {
	constructor(private translationService: TranslationService) {}

	public transform(value?: LegalEntityType) {
		if (value === undefined) {
			return '';
		}

		const values = new Map<LegalEntityType, string>();
		values.set(LegalEntityType.ControlsAnalysis, 'le_type_controlsAnalysis_label');
		values.set(LegalEntityType.FinancialImpacts, 'le_type_financialImpacts_label');
		values.set(LegalEntityType.Mitre, 'mitre_analysis_label');

		const type = values.get(value);
		if (!type) {
			return '';
		}

		return this.translationService.get(type);
	}
}

<section class="step-how-container">
  <ng-container *ngIf="defensebilityDelta && selectedItem">
    <rq-control-option-content [controlLevelDescriptions]="controlLevelDescriptions"
                               [noOfEvidences]="defensebilityDelta.from.count"
                               [controlSubType]="selectedItem.controlSubType"
                               [controlLevel]="defensebilityDelta.from.controlOption.value"
                               [oldValue]="defensebilityDelta.from.controlOption.value"
                               [proposedValue]="defensebilityDelta.to.controlOption.value"
                               (triggerShowCurrentEvidence)="showEvidences(true)">
    </rq-control-option-content>
    <rq-control-option-content [controlLevelDescriptions]="controlLevelDescriptions"
                               [noOfEvidences]="defensebilityDelta.to.count"
                               [controlSubType]="selectedItem.controlSubType"
                               [controlLevel]="defensebilityDelta.to.controlOption.value"
                               [proposedValue]="defensebilityDelta.to.controlOption.value"
                               [expectedSLE]="expectedSLE"
                               [expectedLossReduction]="expectedLossReduction"
                               (triggerShowCurrentEvidence)="showEvidences(false)"></rq-control-option-content>
  </ng-container>
</section>


<rq-evidences-details-modal [singleData]="currentEvidencesData"
                            [controlOptionName]="selectedItem.controlOptionName"
                            [controlLevelDescriptions]="controlLevelDescriptions"></rq-evidences-details-modal>

<p class="text-break"
   *ngFor="let arr of config!.expanded ? wordListFullChunked : wordListLimitedChunked">
  <ng-container *ngFor="let word of arr; last as last">
    <ng-container *ngTemplateOutlet="defaultWord; context: {word: word, last: last}"></ng-container>
  </ng-container>
</p>

<a href="#"
   rqPreventDefault
   *ngIf="wordList!.length! > config!.itemsToShow!"
   (click)="config.expanded = !config.expanded">
  {{getText() | translate}}
</a>

<ng-template #defaultWord
             let-word="word"
             let-last="last">
  <a href="#"
     rqPreventDefault
     *ngIf="word.url"
     (click)="navigateTo(word.url)">
    {{word.displayName}}
  </a>
  <span *ngIf="!word.url">
    {{word.displayName}}
  </span>
  <span *ngIf="!last"
        class="p-r-xs">
    {{word.delimiter ? word.delimiter : ','}}
  </span>
</ng-template>

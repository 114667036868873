import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, AfterViewInit } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[rqEllipsis]'
})
export class EllipsisDirective implements OnChanges, AfterViewInit {
	@Input()
	public charactersCountLimit = 30;

	@Input('rqEllipsis')
	public text?: string;

	private innerText?: string;

	constructor(private readonly elementRef: ElementRef, private renderer: Renderer2) {}

	private get el(): HTMLElement {
		return this.elementRef.nativeElement as HTMLElement;
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (this.innerText !== changes.text.currentValue && changes.text.currentValue) {
			this.innerText = changes.text.currentValue as string;

			if (this.innerText.length > this.charactersCountLimit) {
				this.el.title = this.innerText;
				this.el.innerText = `${this.innerText.slice(0, this.charactersCountLimit)}...`;
			} else {
				this.el.innerText = this.innerText;
			}
		}
	}

	public ngAfterViewInit(): void {
		if (!this.text) {
			this.setStyleOnElement();
			this.setTitleTooltipOnElipsis();
		}
	}

	private setStyleOnElement() {
		const elipsifyme: { [key: string]: string } = {
			'text-overflow': 'ellipsis',
			overflow: 'hidden',
			'white-space': 'nowrap'
		};

		Object.keys(elipsifyme).forEach(element => {
			this.renderer.setStyle(this.el, `${element}`, elipsifyme[element]);
		});
	}

	private setTitleTooltipOnElipsis() {
		if (this.el.offsetWidth < this.el.scrollWidth) {
			this.el.title = this.el.innerHTML;
		}
	}
}

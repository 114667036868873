<rq-table class="m-b-md m-t-md"
          *ngIf="data"
          [dataSource]="data"
          [pagerState]="{ skip: 0, take: 10 }"
          [hasFilters]="hasActions"
          [count]="count"
          (filterChange)="load($event)"
          (sortChange)="load($event)"
          (pageChange)="load($event)">
  <rq-table-column *ngIf="isLegalEntityVisible"
                   field="legalEntityName"
                   [isSortable]="hasActions"
                   title="global_legalEntityName_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{dataItem.legalEntityName}}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="name"
                   filter="string"
                   [isSortable]="hasActions"
                   title="global_impactVector_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{dataItem.name}}</span>
    </ng-template>
  </rq-table-column>


  <rq-table-column field="actorIds"
                   filter="select"
                   [isSortable]="hasActions"
                   [filterSetup]="actorFilterSetup"
                   title="global_actorValue_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{getActorLabel(dataItem.actorId)}}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="attackIds"
                   filter="select"
                   [isSortable]="hasActions"
                   [filterSetup]="attackFilterSetup"
                   title="global_attack_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{getAttackLabel(dataItem.attackId)}}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="rateOfIncident"
                   filter="range"
                   [isSortable]="hasActions"
                   [filterSetup]="roiFilterSetup"
                   title="global_annualAttackRateOfIncidence_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{dataItem.rateOfIncident}}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="attackSuccessProbability"
                   filter="range"
                   [isSortable]="hasActions"
                   [filterSetup]="attackProbabilityFilterSetup"
                   title="global_probability_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{dataItem.attackSuccessProbability | rqPercent}}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="attackedBusinessApplication"
                   filter="string"
                   [isSortable]="hasActions"
                   title="global_target_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{dataItem.attackedBusinessApplication.name}}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="singleLossAmount"
                   filter="range"
                   [isSortable]="hasActions"
                   [filterSetup]="sleFilterSetup"
                   title="global_sle_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{dataItem.singleLossAmount | rqCurrency}}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="annualizedLossAmount"
                   filter="range"
                   [isSortable]="hasActions"
                   [filterSetup]="aleFilterSetup"
                   title="global_ale_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{dataItem.annualizedLossAmount | rqCurrency}}</span>
    </ng-template>
  </rq-table-column>
</rq-table>

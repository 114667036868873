<section class="footer-container">
  <footer class="boxed-wrapper footer-content">
    <a class="footer-company"
       target="_blank"
       rel="noopener"
       href="https://threatconnect.com">
      <span>ThreatConnect</span>
    </a>
    <span>&nbsp;</span>
    <span> {{ 'footer_label_info' | translate : { YEAR: year, VERSION: version } }} </span>
    <span *ngIf="isBeta"
          class="badge-secondary-sm-b m-l-xs">{{ 'global_beta_label' | translate }}</span>
  </footer>
</section>

<rq-table class="m-b-md m-t-md"
          *ngIf="data"
          [dataSource]="data"
          [pagerState]="{ skip: 0, take: 10 }"
          [hasFilters]="true"
          [count]="count"
          (filterChange)="load($event)"
          (sortChange)="load($event)"
          (pageChange)="load($event)">
  <rq-table-column field="type"
                   filter="select"
                   title="global_type_label"
                   [filterSetup]="typeFilterSetup">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span class="wrap-content-300">{{ dataItem.typeDisplayName | translate}}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="behaviorTypes"
                   filter="select"
                   title="audit_actionType_label"
                   [filterSetup]="behaviourTypeFilterSetup">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{dataItem.behaviorType | auditBehaviorTypeLabel}}</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="updatedVariables"
                   filter="select"
                   title="audit_updatedVariable_label"
                   [filterSetup]="updatedVariableFilterSetup">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span *ngIf="dataItem.updatedVariable">{{getValue(dataItem.updatedVariable) | translate}}</span>
      <span *ngIf="!dataItem.updatedVariable">-</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="prevValue"
                   filter="string"
                   title="global_previousValue_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span *ngIf="dataItem.prevValue"
            class="wrap-content-120">{{getValue(dataItem.prevValue, dataItem.formatType) | translate}}</span>
      <span *ngIf="!dataItem.prevValue">-</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="setValue"
                   filter="string"
                   title="global_setValue_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span *ngIf="dataItem.setValue"
            class="wrap-content-120">{{getValue(dataItem.setValue, dataItem.formatType) | translate}}</span>
      <span *ngIf="!dataItem.setValue">-</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="userEmail"
                   filter="string"
                   title="audit_user_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span *ngIf="dataItem.user?.email">{{getValue(dataItem.user?.email)}}</span>
      <span *ngIf="!dataItem.user?.email">-</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column *ngIf="hasLegalEntityColumn"
                   field="legalEntityName"
                   filter="string"
                   title="global_legalEntity_label">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span *ngIf="dataItem.legalEntityName">{{getValue(dataItem.legalEntityName)}}</span>
      <span *ngIf="!dataItem.legalEntityName">-</span>
    </ng-template>
  </rq-table-column>

  <rq-table-column field="changeDate"
                   filter="calendar"
                   title="audit_change_date">
    <ng-template tableData
                 let-dataItem="dataItem">
      <span>{{dataItem.changeDate | rqDate:{pattern: dateTimePattern} }}</span>
    </ng-template>
  </rq-table-column>
</rq-table>

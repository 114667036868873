<h3 class="black-color m-b-sm">
  {{'preferences_userPreferences_label'| translate}}
</h3>

<form [formGroup]="formGroup">
  <rq-form-control class="m-b-none">
    <label>
      <input type="checkbox"
             e2e-test="start-up-page-form"
             value="isEnabledStartupPage"
             formControlName="isEnabledStartupPage">
      <span>{{'preferences_startPage_label' | translate}}</span>
    </label>
  </rq-form-control>
</form>
